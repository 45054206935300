import { faEye, faEyeSlash, faPlus, faQuestionCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'Body/Statuses/Loading';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Table } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import { clone } from '../../../../../Services/schedule';
import ConfirmModal from '../../../Coordinator/Schedule/ConfirmModal';
import style from './style';

let changeTimeout = null;

const DocumentTypesPopover = ({ setShowDocumentTypesPopover, onUpdate }) => {
  const [newDocumentType, setNewDocumentType] = useState({}); // DocumentTypeName, UIIcon
  const [documentTypes, setDocumentTypes] = useState([]);

  const [selectedDocumentType, setSelectedDocumentType] = useState();
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState();
  const [isInitializedDocumentTypes, setIsInitializedDocumentTypes] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    getDocumentTypes();
  }, []);
  const getDocumentTypes = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'documentTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsInitializedDocumentTypes(true);
            setDocumentTypes(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addNewDocumentType = (type) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'documentTypes',
          {
            pk_Department: dContext.department.pk_Department,
          },
          type,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            getDocumentTypes();
            if (onUpdate) {
              onUpdate();
            }
            setNewDocumentType({ DocumentTypeName: '' });
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateDocumentType = (type) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'documentTypes',
          {
            pk_Department: dContext.department.pk_Department,
            pk_DocumentType: type.pk_DocumentType,
          },
          { updateObject: { Hide: type.Hide, DocumentTypeName: type.DocumentTypeName, UIIcon: type.UIIcon } },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            getDocumentTypes();
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const deleteDocumentType = (type) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'documentTypes',
          {
            pk_Department: dContext.department.pk_Department,
            pk_DocumentType: type.pk_DocumentType,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            getDocumentTypes();
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderDeleteConfirmModal = () => {
    return (
      <ConfirmModal
        title={'Delete Document Type'}
        message={`Are you sure you want to delete ${
          selectedDocumentType ? selectedDocumentType.DocumentTypeName : ''
        }?`}
        onAccept={() => {
          deleteDocumentType(selectedDocumentType);
          setTimeout(() => {
            setShowDocumentTypesPopover(true);
            setShowDeleteConfirmationModal(false);
          }, 100);
        }}
        onCancel={() => {
          setShowDeleteConfirmationModal(false);
        }}
        isOpen={showDeleteConfirmationModal}
      />
    );
  };

  const handleChange = (type, key, value, isInstant) => {
    const newType = clone(type);
    newType[key] = value;

    if (isInstant) {
      updateDocumentType(newType);
    } else {
      if (changeTimeout) {
        clearTimeout(changeTimeout);
      }

      changeTimeout = setTimeout(() => {
        updateDocumentType(newType);
      }, 1000);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Table>
        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 100 }}>
          <tr>
            <th className="center_text">Delete</th>
            <th className="center_text">Type</th>
            <th className="center_text">Visibility to Evaluators</th>
          </tr>
        </thead>
        <tbody>
          {documentTypes != null && documentTypes.length > 0 ? (
            documentTypes.map((type) => {
              return (
                <tr>
                  <td className="center_text">
                    <Button
                      disabled={isUpdating}
                      color="danger"
                      onClick={() => {
                        setSelectedDocumentType(type);
                        setShowDeleteConfirmationModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                    {renderDeleteConfirmModal()}
                  </td>
                  <td className="center_text">
                    <Input
                      disabled={isUpdating}
                      style={{ textAlign: 'center' }}
                      defaultValue={`${type.DocumentTypeName}`}
                      onChange={(e) => {
                        handleChange(type, 'DocumentTypeName', e.target.value);
                      }}
                    />
                  </td>
                  <td className="center_text">
                    <Button
                      disabled={isUpdating}
                      color={type.Hide ? 'danger' : 'success'}
                      onClick={() => {
                        handleChange(type, 'Hide', !type.Hide || type.Hide == 0 ? 1 : 0, true);
                      }}
                    >
                      <FontAwesomeIcon icon={type.Hide ? faEyeSlash : faEye} />
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td className="center_text" colSpan={3}>
                {' '}
                <div style={style.centeredText}>
                  {isInitializedDocumentTypes ? 'No Document Types' : 'Loading. . .'}
                </div>{' '}
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <div style={style.documentTypeAddButtonContainer}>
        <div>
          <Button color="danger" style={{ visibility: 'hidden' }}>
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button>
        </div>
        <div style={style.manageFieldsNewName}>
          <Input
            id={`add_${documentTypes.length}`}
            style={{ textAlign: 'center' }}
            placeholder="Add Document Type. . ."
            value={newDocumentType.DocumentTypeName}
            onChange={(e) => {
              const newName = e.target.value;
              const newNewDocumentType = clone(newDocumentType);
              newNewDocumentType.DocumentTypeName = newName;
              setNewDocumentType(newNewDocumentType);
            }}
          />
        </div>
        {/* <div style={style.manageFieldsNewIcon}>
          <Button color="primary">
            <FontAwesomeIcon icon={faQuestionCircle} />
          </Button>
        </div> */}
        <div>
          <Button
            color="success"
            onClick={() => {
              addNewDocumentType(newDocumentType);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DocumentTypesPopover;
