import React, { useState, useEffect, useContext } from 'react';
import { Button, Input, UncontrolledTooltip } from 'reactstrap';

import style from './TemplateItem.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencilAlt,
  faTrash,
  faTimesCircle,
  faRobot,
  faEnvelope,
  faEnvelopeOpen,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth0 } from '../../../../../../../auth0/reactAuth0Spa';
import { deleteData, putData } from '../../../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../../../Common.functions';
import { DepartmentContext } from '../../../../../../../DepartmentWrapper';
import { useAlert } from 'react-alert';
import Swal from 'sweetalert2';

const TemplateItem = ({
  template = {},
  getTemplateById,
  setShowTemplatesPopover,
  showTemplatesPopover,
  selectedTemplate,
  setSelectedTemplate,
  templateToEdit,
  setTemplateToEdit,
  getTemplates,
  pageChanges,
  setPageChanges,
  setSubjectHasChanges,
  setEmailBodyHasChanges,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const { index } = template;
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();

  let backgroundColor = index != null && index % 2 == 0 ? '#c7cbd1' : null;

  useEffect(() => {
    if (
      templateToEdit &&
      templateToEdit.pk_CommunicationTemplate === template.pk_CommunicationTemplate &&
      showTemplatesPopover
    ) {
      setIsEdit(true);
      setTitle(templateToEdit.Title);
      setTimeout(() => {
        const fieldToEdit = document.getElementById(`title_${template.pk_CommunicationTemplate}`);

        if (fieldToEdit) {
          fieldToEdit.focus();
        }
      }, 200);
    } else {
      setIsEdit(false);
    }
  }, [JSON.stringify(templateToEdit)]);

  const deleteTemplate = () => {
    setIsDeleting(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/deleteCommunicationTemplates',
          {
            pk_CommunicationTemplate: template.pk_CommunicationTemplate,
            pk_Department: dContext.department.pk_Department,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsDeleting(false);
            alert.success(`Template "${template.Title}" Deleted`);

            if (selectedTemplate && selectedTemplate.pk_CommunicationTemplate === template.pk_CommunicationTemplate) {
              setSelectedTemplate(null);
              setTemplateToEdit(null);
              setSubjectHasChanges(false);
              setEmailBodyHasChanges(false);
              setPageChanges({ ...pageChanges, sendMessage: false });
            }

            getTemplates(
              selectedTemplate && selectedTemplate.pk_CommunicationTemplate === template.pk_CommunicationTemplate
                ? null
                : selectedTemplate
                ? selectedTemplate.pk_CommunicationTemplate
                : null,
            );
          })
          .catch((err) => {
            setIsDeleting(false);
            alert.error('Template deletion failed!');
          });
      })
      .catch((err) => {
        setIsDeleting(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  if (selectedTemplate && selectedTemplate.pk_CommunicationTemplate === template.pk_CommunicationTemplate) {
    backgroundColor = '#d9f5ff';
  }

  if (isEdit && !template.IsAutomationTemplate) {
    backgroundColor = '#fcd874';
  }

  const updateExistingTemplate = () => {
    const fieldsToUpdate = { title: title };

    getTokenSilently()
      .then((token) => {
        putData(
          'department/updateCommunicationTemplateById',
          {
            pk_CommunicationTemplate: template.pk_CommunicationTemplate,
            pk_Department: dContext.department.pk_Department,
          },
          { fieldsToUpdate },
          formatBearerToken(token),
        )
          .then((result) => {
            getTemplates(template.pk_CommunicationTemplate);
            if (result) {
              alert.success('Template successfully updated.');
            } else {
              alert.error('Template error.');
            }
          })
          .catch((err) => {
            alert.error('Template error.');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  return (
    <div
      style={{ ...style.container, backgroundColor }}
      onClick={(e) => {
        if (pageChanges && pageChanges.sendMessage) {
          Swal.fire({
            title: 'Warning!',
            text: `You have unsaved changes. Are you sure you want to load another template?`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: 'red',
            confirmButtonText: `Discard and Load`,
            stopKeydownPropagation: true,
            keydownListenerCapture: true,
          }).then((result, e) => {
            if (result.value) {
              if (templateToEdit && templateToEdit.pk_CommunicationTemplate == template.pk_CommunicationTemplate) {
                e.stopPropagation();
              } else {
                setPageChanges({ ...pageChanges, sendMessage: false });
                getTemplateById(template.pk_CommunicationTemplate);
                setShowTemplatesPopover(false);
                if (template.IsAutomationTemplate) {
                  setTemplateToEdit(template);
                } else {
                  setTemplateToEdit(null);
                }
              }
            }
          });

          return;
        }

        if (templateToEdit && templateToEdit.pk_CommunicationTemplate == template.pk_CommunicationTemplate) {
          e.stopPropagation();
        } else {
          getTemplateById(template.pk_CommunicationTemplate);
          setShowTemplatesPopover(false);
          if (template.IsAutomationTemplate) {
            setTemplateToEdit(template);
          } else {
            setTemplateToEdit(null);
          }
        }
      }}
    >
      <FontAwesomeIcon
        id={`template_${template.pk_CommunicationTemplate}_icon`}
        icon={template.IsAutomationTemplate ? faRobot : faEnvelopeOpen}
        style={{ marginRight: 10 }}
      />

      {template.IsAutomationTemplate ? (
        <UncontrolledTooltip placement="top" target={`template_${template.pk_CommunicationTemplate}_icon`}>
          The Template is tied to {template.AutomatedTemplateType} automation.
        </UncontrolledTooltip>
      ) : null}
      <div style={{ width: '100%' }}>
        {isEdit && !template.IsAutomationTemplate ? (
          <div>
            <Input
              type="text"
              id={`title_${template.pk_CommunicationTemplate}`}
              defaultValue={template.Title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        ) : (
          template.Title
        )}
      </div>
      <div>
        {template.IsAutomationTemplate ? null : (
          <div style={style.spacedBetweenRow}>
            <Button
              disabled={(isEdit && title === template.Title) || isDeleting}
              color={isEdit ? 'success' : 'secondary'}
              style={style.actionBtn}
              onClick={(e) => {
                e.stopPropagation();

                if (isEdit) {
                  updateExistingTemplate();
                } else {
                  setTemplateToEdit(template);
                }

                setIsEdit(!isEdit);
              }}
            >
              <FontAwesomeIcon icon={isEdit ? faCheck : faPencilAlt} />
            </Button>

            <Button
              color="danger"
              disabled={isDeleting}
              style={style.actionBtn}
              onClick={(e) => {
                e.stopPropagation();

                if (isEdit && templateToEdit.pk_CommunicationTemplate == template.pk_CommunicationTemplate) {
                  setIsEdit(false);
                  setTemplateToEdit(null);
                } else {
                  Swal.fire({
                    title: 'Warning!',
                    text: `Are you sure you want to delete "${template.Title ||
                      '(Unnamed Template)'}"? This cannot be undone.`,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: 'red',
                    confirmButtonText: `Delete`,
                    stopKeydownPropagation: true,
                    keydownListenerCapture: true,
                  }).then((result, e) => {
                    if (result.value) {
                      deleteTemplate();
                    }
                  });
                }
              }}
            >
              <FontAwesomeIcon icon={isEdit ? faTimesCircle : faTrash} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default TemplateItem;
