import {
  faCheck,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faPencilAlt,
  faPencilRuler,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useRef } from 'react';
import momentTimezone from 'moment-timezone';
import Popup from 'reactjs-popup';
import Error from './Body/Statuses/Error';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { clone } from './Services/schedule';
import sanitizeHtml from 'sanitize-html';

const MINUTES_UNTIL_NEXT_MEETING_BUTTON = 1;

const PopupWrapper = styled.div`
  .popup-overlay {
    .popup-content {
      width: 90vw !important;
      max-width: 800px !important;
    }
  }
`;

const copyObject = (objectToCopy) => {
  if (objectToCopy == null) {
    return null;
  }

  return JSON.parse(JSON.stringify(objectToCopy));
};

const isNotificationsSupported = () => {
  // console.log(
  //   "isNotificationsSupported: \n'Notification': ",
  //   'Notification' in window ? 'true' : 'false',
  //   "\n'serviceWorker': ",
  //   'serviceWorker' in navigator ? 'true' : 'false',
  //   "\n'PushManager': ",
  //   'PushManager' in window ? 'true' : 'false',
  // );

  return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;
};

const renderCandidateNotFound = () => {
  const header = 'Invalid ID.';
  const subHeader = 'Please contact your coordinator.';
  return <Error header={header} subHeader={subHeader} />;
};

// these are used for getDefaultInterviewDate function
const fetchInterviewDates = () => {};

// this will get the default display date for a user based on a set of conditions
const getDefaultInterviewDate = (interviewDates, season) => {
  const today = moment();
  let dateIsToday = false;
  let closestDateToday = null;
  let closestDate = null;
  let closestDateDifference = Number.MAX_VALUE;
  let closestFutureDate = null;
  let closestFutureDateDifference = Number.MAX_VALUE;
  let closestPastDate = null;
  let closestPastDateDifference = Number.MAX_VALUE;

  for (let i = 0; i < interviewDates.length; i++) {
    const dateOfInterview = moment(new Date(interviewDates[i].dateTimeInTimezone));
    const createdAt = moment(new Date(interviewDates[i].createdAt));

    if (dateOfInterview.isSame(today, 'day')) {
      dateIsToday = true;
      // if date is today or has an earlier created at timestamp than previous date for today, select it
      if (!closestDateToday || createdAt.isBefore(closestDateToday.createdAt)) {
        closestDateToday = interviewDates[i];
      }
    } else if (dateOfInterview.isAfter(today) && dateOfInterview.isBefore(moment(today).add(3, 'days'))) {
      const difference = Math.abs(today.diff(dateOfInterview));
      if (difference < closestFutureDateDifference) {
        closestFutureDateDifference = difference;
        closestFutureDate = interviewDates[i];
      }
    } else if (dateOfInterview.isBefore(today) && dateOfInterview.isAfter(moment(today).subtract(5, 'days'))) {
      const difference = Math.abs(today.diff(dateOfInterview));
      if (difference < closestPastDateDifference) {
        closestPastDateDifference = difference;
        closestPastDate = interviewDates[i];
      }
    } else {
      const difference = Math.abs(today.diff(dateOfInterview));
      if (difference < closestDateDifference) {
        closestDateDifference = difference;
        closestDate = interviewDates[i];
      }
    }
  }

  // if prescreen mode is enabled and no interview is happening today set default mode as prescreen
  if (season && season.EnablePrescreenMode && !dateIsToday) {
    // becuase other dates are formatted this way, add the key pk_InterviewDate to unify handling variable
    return { pk_InterviewDate: 'Prescreen' };
  }

  if (closestDateToday) {
    return closestDateToday;
  } else if (closestFutureDate) {
    return closestFutureDate;
  } else if (closestPastDate) {
    return closestPastDate;
  } else {
    return closestDate;
  }
};

const getClosestFutureDate = (dateList) => {
  let defaultDate = null;
  let selectedIndex = 0;
  const now = moment();
  console.log("couldn't find default date, finding next closest");

  // Find the earliest date that's later than today.
  while (!defaultDate && selectedIndex < dateList.length) {
    const { DateOfInterview, StartTime } = dateList[selectedIndex];
    const dateToCompare = moment(`${DateOfInterview} ${StartTime}`);
    if (dateToCompare.isAfter(now)) {
      defaultDate = dateList[selectedIndex];
    }
    selectedIndex++;
  }

  // Probably means there's no dates in the future. Just use the last one.
  if (!defaultDate && dateList && dateList.length > 0) {
    defaultDate = dateList[dateList.length - 1];
  }

  return defaultDate;
};
const lightOrDark = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

const customStartsWith = (stringToExamine, phrase) => {
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    };
  } else {
    stringToExamine.startsWith(phrase);
  }
};

const useEventListener = (eventName, handler, element = global) => {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
};

const contactSupport = 'Oops, something went wrong. Please try again or contact support';

function isDev() {
  const { hostname, search } = window.location;
  const values = queryString.parse(search);

  if (values.dev === '1' || values.dev === '0') {
    return Number(values.dev);
  }
  if (hostname === 'https://dev.rezrate.com' || hostname === 'localhost') {
    return 1;
  }
  return 0;
}

const swalErrorMessage = (title, description) => {
  Swal.fire(title || 'Oops...', description || 'Something went wrong, please try again later', 'error');
};

const informUserEvalLocked = () => {
  Swal.fire('Locked', 'Evaluations have been locked by your administrator.', 'warning');
};

function determineCredentials() {
  if (isDev()) {
    return false;
  }

  return true;
}

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const getSelectedDate = (arrayOfDates, dateChosen) => {
  let selectedDate;
  arrayOfDates.forEach((item) => {
    if (item.InterviewDates4Portal__pkUUID_InterviewDate === dateChosen) {
      selectedDate = item.InterviewDates4PortalDate;
    }
  });

  return selectedDate;
};

const isValidUser = (userObject) => {
  const portalNameInvalid = !userObject.Departments4PortalName || userObject.Departments4PortalName === '';
  const firstNameInvalid = !userObject.Candidates4PortalNameFirst || userObject.Candidates4PortalNameFirst === '';
  const lastNameInvalid = !userObject.Candidates4PortalNameLast || userObject.Candidates4PortalNameLast === '';
  const address1Invalid =
    !userObject.Departments4PortalAddressLine1 || userObject.Departments4PortalAddressLine1 === '';
  const externalUUIDInvalid =
    !userObject.Candidates4PortalExternalUUID || userObject.Candidates4PortalExternalUUID === '';
  if (
    !userObject ||
    typeof userObject !== 'object' ||
    (portalNameInvalid && firstNameInvalid && lastNameInvalid && address1Invalid && externalUUIDInvalid)
  ) {
    return false;
  }
  return true;
};

const joinArrays = (array1, key1, array2, key2) => {
  return array1.map((x) =>
    Object.assign(
      x,
      array2.find((y) => y[key2] == x[key1]),
    ),
  );
};

const getNeededDataFromUser = (data) => {
  const userObject = (({
    Candidates4PortalDatePreferenceArray,
    Candidates4Portal_fkUUID_InterviewDate,
    Candidates4PortalDatePreference,
    Candidates4PortalCandDeclined,
    Candidates4PortalCandResponseTS,
    Candidates4PortalNameFirst,
    Candidates4PortalCandComment,
    Candidates4PortalUnsubscribed,
    Candidates4PortalWaitlist,
    Candidates4PortalDateOfInterview,
    Candidates4PortalNameLast,
    Candidates4PortalPhotoSM,
    Departments4PortalAddressLine1,
    Departments4PortalAddressLine2,
    Departments4PortalEmail,
    Departments4PortalLogoURL,
    Departments4PortalLogo,
    Departments4PortalName,
    Departments4PortalOrganization,
    Departments4PortalPhone,
    Departments4PortalState,
    Departments4PortalWebsite,
    Departments4PortalZip,
  }) => ({
    Candidates4PortalDatePreferenceArray,
    Candidates4Portal_fkUUID_InterviewDate,
    Candidates4PortalDatePreference,
    Candidates4PortalCandDeclined,
    Candidates4PortalDateOfInterview,
    Candidates4PortalCandComment,
    Candidates4PortalUnsubscribed,
    Candidates4PortalCandResponseTS,
    Candidates4PortalNameFirst,
    Candidates4PortalNameLast,
    Candidates4PortalPhotoSM,
    Candidates4PortalWaitlist,
    Departments4PortalAddressLine1,
    Departments4PortalAddressLine2,
    Departments4PortalEmail,
    Departments4PortalLogoURL,
    Departments4PortalLogo,
    Departments4PortalName,
    Departments4PortalOrganization,
    Departments4PortalPhone,
    Departments4PortalState,
    Departments4PortalWebsite,
    Departments4PortalZip,
  }))(data);
  return userObject;
};

const isExclusivelyNumbers = (textString) => {
  const re = /^[0-9\b]+$/;
  if (textString === '' || re.test(textString)) {
    return true;
  }

  return false;
};

function isArrayEqual(arr1, arr2, keysToCheck = {}) {
  if (arr1 === null || arr2 === null) {
    return false;
  }

  let differentPoints = {};
  for (var i = arr1.length; i--; ) {
    Object.keys(arr1[i]).map((key, index) => {
      if (
        keysToCheck[key] &&
        arr1 &&
        arr2 &&
        arr1[i] &&
        arr2[i] &&
        arr1[i][key] &&
        arr2[i][key] &&
        arr1[i][key].toString() !== arr2[i][key].toString()
      ) {
        differentPoints[key + i] = true;
      }
      // if ((!arr1[i] && arr2[i]) || (arr1[i] && !arr2[i])) {
      //   differentPoints[key + i] = true;
      // }
    });
  }
  return differentPoints;
}

/**
 * Compared 2 arrays for differences. Returns an array of indexes where they differ.
 * @param {array} arr1 - first array
 * @param {array} arr2 - 2nd array
 * @param {array} keysToCheck - array of strings, contains property keys to check.
 */
export const getArrayIndexesWithDifferences = (arr1 = [], arr2 = [], keysToCheck = [], defaultArray = []) => {
  const indexesWithDifferences = defaultArray ? defaultArray : [];
  if (arr1.length == 0 || arr2.length == 0) {
    return indexesWithDifferences;
  }
  arr1.forEach((item, i) => {
    keysToCheck.forEach((key) => {
      if (
        (!arr1[i] && arr2[i]) ||
        (arr1[i] && !arr2[i]) ||
        (arr1[i] && arr2[i] && JSON.stringify(arr1[i][key]) !== JSON.stringify(arr2[i][key]))
      ) {
        if (!indexesWithDifferences.includes(i)) {
          indexesWithDifferences.push(i);
        }
      } else if (arr2.length > arr1.length) {
        let count = arr2.length - arr1.length;
        let start = arr1.length - 1;

        while (count > 0) {
          if (!indexesWithDifferences.includes(start)) {
            indexesWithDifferences.push(start);
          }

          start++;
          count--;
        }
      }
    });
  });
  return indexesWithDifferences;
};

/**
 * Compares 2 arrays. Used for schedule comparison. arr1 is new schedule/array
 * @param {array} arr1 - first array
 * @param {array} arr2 - 2nd array
 * @param {array} keysToCheck - array of strings, contains property keys to check.
 */
export const getArrayIndexesWithDifferencesViaTimeSlot = (
  arr1 = [],
  arr2 = [],
  keysToCheck = [],
  defaultArray = [],
) => {
  const indexesWithDifferences = defaultArray ? defaultArray : [];

  const arr1SlotIndexTracker = {}; // slots of arr1 with indices as value
  const arr1SlotTracker = {};
  const arr2SlotIndexTracker = {}; // slots of arr2 with indices as value
  const arr2SlotTracker = {};

  if (arr1.length == 0 || arr2.length == 0) {
    return indexesWithDifferences;
  }

  arr1.forEach((item, i) => {
    if (item.isRedirect) {
      return;
    }
    const key = item.StartTimeAsMoment.format('hh:mm A');
    arr1SlotIndexTracker[key] = i;
    arr1SlotTracker[key] = item;
  });

  arr2.forEach((item, i) => {
    if (item.isRedirect) {
      return;
    }
    const key = item.StartTimeAsMoment.format('hh:mm A');
    arr2SlotIndexTracker[key] = i;
    arr2SlotTracker[key] = item;
  });

  if (arr2.length > arr1.length) {
    let count = arr2.length - arr1.length;
    let start = arr1.length - 1;

    while (count > 0) {
      if (!indexesWithDifferences.includes(start)) {
        indexesWithDifferences.push(start);
      }

      start++;
      count--;
    }
  }

  const longerList = Object.keys(arr1SlotTracker).length > Object.keys(arr2SlotTracker).length ? 0 : 1;

  Object.keys(longerList == 0 ? arr1SlotTracker : arr2SlotTracker).forEach((key) => {
    keysToCheck.forEach((keyToCheck) => {
      const oldItem = longerList == 0 ? arr2SlotTracker[key] : arr1SlotTracker[key];
      const newItem = longerList == 0 ? arr1SlotTracker[key] : arr2SlotTracker[key];

      if (
        (!oldItem && newItem) ||
        (newItem && !oldItem) ||
        (newItem && oldItem && JSON.stringify(newItem[keyToCheck]) !== JSON.stringify(oldItem[keyToCheck]))
      ) {
        const i = longerList == 0 ? arr1SlotIndexTracker[key] : arr2SlotIndexTracker[key];
        if (!indexesWithDifferences.includes(i)) {
          indexesWithDifferences.push(i);
        }
      }
    });
  });

  return indexesWithDifferences;
};

const getCorrectEvalToken = (isAdmin) => {
  if (isAdmin) {
    return localStorage.getItem('adminEvalToken');
  } else {
    return localStorage.getItem('evalToken');
  }
};

const setCorrectEvalToken = (isAdmin, token) => {
  if (isAdmin) {
    localStorage.setItem('adminEvalToken', token);
  } else {
    localStorage.setItem('evalToken', token);
  }
};

const removeCorrectEvalTOken = (isAdmin, token) => {
  if (isAdmin) {
    localStorage.removeItem('adminEvalToken');
  } else {
    localStorage.removeItem('evalToken');
  }
};

const smoothScrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

/**
 * Scrolls to element with id
 * @param {string} id - id of element to scroll to
 * @param {object} options - options to pass to scrollIntoView
 * @param {object} blurOptions - options to pass to blur element
 * @param {string} blurOptions.fieldToBlur - id of element to blur
 * @param {string} blurOptions.selectBlurInput - id of element to blur
 * @param {string} blurOptions.elementToListenScroll - id of element to listen to scroll
 *
 */
const scrollToElement = (id, options, blurOptions) => {
  const guiltyRow = document.getElementById(id);
  const defaultOptions = {
    behavior: 'smooth',
    block: 'center',
  };

  const { fieldToBlur, selectBlurInput, elementToListenScroll, callback } = blurOptions ? blurOptions : {};

  const doBlur =
    document.activeElement && fieldToBlur && elementToListenScroll && document.activeElement.id === fieldToBlur;

  const optionsToUse = options ? options : defaultOptions;
  if (guiltyRow) {
    let blurElement = null;
    let scrollElement = null;

    if (doBlur) {
      blurElement = document.getElementById(fieldToBlur);
      scrollElement = document.getElementById(elementToListenScroll);

      if (blurElement) {
        blurElement.blur();
        blurElement.disabled = true;
      }
    }

    guiltyRow.scrollIntoView(optionsToUse);

    if (doBlur) {
      let scrollTimeout = null;

      const scrollEnd = () => {
        blurElement.disabled = false;
        blurElement.select();
        if (selectBlurInput && blurElement.value) {
          blurElement.type = 'text';
          blurElement.setSelectionRange(0, blurElement.value.length);
          blurElement.type = 'number';
        }
        if (scrollElement) {
          scrollElement.removeEventListener('scroll', scrollListener);
        }

        if (callback) {
          callback();
        }
      };
      const scrollListener = (e) => {
        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          scrollEnd();
        }, 100);
      };

      if (scrollElement) {
        scrollListener();
        scrollElement.addEventListener('scroll', scrollListener);
      } else {
        setTimeout(() => {
          scrollEnd();
        }, 1000);
      }
    }
  }
};

const getPercentageTime = (currentTime, tableRowOpacity, beginning, end, showDebugger) => {
  if (showDebugger) {
    console.log('currentTime - beginning: ', currentTime - beginning);
    console.log('end - beginning: ', end - beginning);
  }

  if (currentTime > end) {
    return { height: '100%', border: '1px solid #0C7489', width: '1px' };
  }

  if (beginning > currentTime) {
    return { height: 0, width: '1px' };
  }
  const timeElapsed = currentTime - beginning;
  const totalTime = end - beginning;
  const percentageElapsed = timeElapsed / totalTime;
  const pixelHeight = percentageElapsed * tableRowOpacity;

  return {
    height: `${percentageElapsed * 100}%`,
    borderBottom: '1px solid #0C7489',
    border: '1px solid #0C7489',
    width: '1px',
  };
};

const getURLParameter = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const isValidURL = (str) => {
  if (/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(str)) {
    return true;
  } else {
    return false;
  }
};

const checkIfZoomURLAndIfHasPassword = (url) => {
  let isZoomURL = false;
  let hasPassword = false;
  if (url) {
    if (url.includes('zoom.us')) {
      isZoomURL = true;
      if (url.includes('pwd=')) {
        hasPassword = true;
      }
    }
  }
  return { isZoomURL, hasPassword };
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const determineProgress = (status) => {
  let color;
  let icon = faPencilAlt;
  let text = 'what';

  let statusToUpperCase = null;

  if (status && status !== '') {
    statusToUpperCase = status.toUpperCase();
  }

  if (statusToUpperCase == null || statusToUpperCase === 'PENDING') {
    // color = '#FD9A00';
    color = '#0080ff';
    icon = faPencilRuler;
    text = 'Evaluation Pending';
  }

  if (statusToUpperCase === 'IN PROGRESS') {
    // color = '#FD9A00';
    color = '#FF8C00';
    icon = faPencilAlt;
    text = 'Evaluation In Progress';
  }
  if (statusToUpperCase === 'COMPLETE' || statusToUpperCase === 'UNABLE TO EVALUATE') {
    // color = '#66B131';
    color = '#00b300';
    icon = faCheck;
    text = 'Evaluation Complete';
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', color: color }}>
        <FontAwesomeIcon icon={icon} />
        <p style={{ paddingLeft: '10px', margin: 0 }}>{text}</p>
      </div>
      <div>
        {statusToUpperCase === 'UNABLE TO EVALUATE' && (
          <p style={{ margin: 0, fontSize: '.7em' }}>(Unable To Evaluate)</p>
        )}
      </div>
    </div>
  );
};

const determineIcon = (MimeType) => {
  if (MimeType.toUpperCase().includes('PDF')) {
    return faFilePdf;
  }
  if (MimeType.toUpperCase().includes('DOC')) {
    return faFileAlt;
  }
  return faFileImage;
};

const adaptToTimeZone = (
  TimeSlots4Portal,
  serverTime,
  startKey,
  endKey,
  startKeyTimeOnly,
  endKeyTimeOnly,
  dayOfInterview,
) => {
  const rightNow = new Date(Date.now());

  const differenceAsDateObject = new Date(serverTime);

  var timeZoneDifference = differenceAsDateObject.getTime() - rightNow.getTime(); // This will give difference in milliseconds
  var resultInMinutes = Math.round(timeZoneDifference / 60000);

  TimeSlots4Portal.forEach((item) => {
    const start = new Date(item[startKey]);
    const end = new Date(item[endKey]);

    start.setMinutes(start.getMinutes() - resultInMinutes);
    end.setMinutes(end.getMinutes() - resultInMinutes);

    const startTimeFormatBackToString = moment(start).format('HH:mm:ss');
    const endTimeFormatBackToString = moment(end).format('HH:mm:ss');
    const startDateFormatBackToString = moment(start).format('MM/DD/yyyy');
    const endDateFormatBackToString = moment(end).format('MM/DD/yyyy');

    item[startKey] = `${startDateFormatBackToString} ${startTimeFormatBackToString}`;
    item[endKey] = `${endDateFormatBackToString} ${endTimeFormatBackToString}`;
    item[startKeyTimeOnly] = startTimeFormatBackToString;
    item[endKeyTimeOnly] = endTimeFormatBackToString;
  });
  return TimeSlots4Portal;
};

const formatScheduleBlocksFromTimeslots = (arr) => {
  const arrOfScheduleBlocks = [];
  arr.forEach((item) => {
    const numberOfBlocks = item.scheduleBlockEntries.length;
    const { StartTimeAsMoment, EndTimeAsMoment } = item;
    let slotCopy = moment(StartTimeAsMoment);
    // compute diff in ms
    var diffInMs = Math.abs(moment(slotCopy).diff(EndTimeAsMoment));
    // half
    var gap = diffInMs / numberOfBlocks;

    item.scheduleBlockEntries.forEach((scheudleBlock, i) => {
      const isLastLoop = i === item.scheduleBlockEntries.length - 1;

      if (!isLastLoop) {
        const slotFirst = moment(slotCopy);
        slotCopy = moment(slotCopy).add(gap, 'ms');
        const slotLast = moment(slotCopy);

        arrOfScheduleBlocks.push({ ...scheudleBlock, StartTimeAsMoment: slotFirst, EndTimeAsMoment: slotLast });
      } else {
        const slotFirst = moment(slotCopy);
        const slotLast = moment(EndTimeAsMoment);
        arrOfScheduleBlocks.push({ ...scheudleBlock, StartTimeAsMoment: slotFirst, EndTimeAsMoment: slotLast });
      }
    });
  });

  return arrOfScheduleBlocks;
};

// WARNING: this is done on the Zoom Microservice as well. If this logic is changed it needs to be changed on the microservice
const addTimeToTimeSlots = (data) => {
  const metaData = data.metaData || {};
  const newData = clone(data);

  const { StandardDurationInMinutes, StandardPassingDurationInMinutes, StartDateAndTime } = metaData;
  const startDateAndTimeObject = new Date(StartDateAndTime);
  const time = moment(startDateAndTimeObject);

  const newBody = [];

  if (newData.body) {
    newData.body.map((row) => {
      const { CustomDurationInMinutes, CustomPassingDurationInMinutes } = row;
      let duration = 0;

      if (CustomDurationInMinutes != null) {
        duration = CustomDurationInMinutes;
      } else {
        duration = StandardDurationInMinutes;
      }

      if (CustomPassingDurationInMinutes != null) {
        duration += CustomPassingDurationInMinutes;
      } else {
        duration += StandardPassingDurationInMinutes;
      }

      row.slot = moment(time);
      time.add(duration, 'minutes');
      row.slotEnd = moment(time);
      newBody.push(row);
    });
    newData.body = newBody;
  }

  return newData;
};

const shouldJoinMeetingButtonBeVisisble = (item, demo, currentTime) => {
  if (!item) {
    return false;
  }

  if (item.isPassingPeriod) {
    return false;
  }
  if (demo) {
    return true;
  }

  const {
    JoinMeetingButtonDisplayTimestampEnd,
    JoinMeetingButtonDisplayTimestampStart,
    DisplayButtonAsDisabledBeforeStartTimestamp,
  } = item;

  if (!JoinMeetingButtonDisplayTimestampEnd || !JoinMeetingButtonDisplayTimestampStart || !currentTime) {
    return false;
  }

  if (DisplayButtonAsDisabledBeforeStartTimestamp) {
    return currentTime.isBefore(JoinMeetingButtonDisplayTimestampEnd);
  }

  const withinCurrent = currentTime.isBetween(
    JoinMeetingButtonDisplayTimestampStart,
    JoinMeetingButtonDisplayTimestampEnd,
  );

  return withinCurrent;

  // let end;
  // let start;
  // let prevStart;
  // let prevEnd;

  // if (prevItem) {
  //   if (prevItem.isPassingPeriod) {
  //     prevStart = prevItem[keyOne];
  //     prevEnd = prevItem[keyTwo];
  //   } else {
  //     prevStart = moment(item[keyOne]).subtract(MINUTES_UNTIL_NEXT_MEETING_BUTTON, 'minutes');
  //     prevEnd = item[keyOne];
  //   }
  // }

  // start = item[keyOne];
  // end = item[keyTwo];

  // // // if it's the first meeting show the join meeting button 15 minutes before starting time
  // if (!prevItem) {
  //   start = moment(item.StartTimeAsMoment);

  //   // if no first block early is specified just do 15 minutes
  //   const minutesToSubtract = firstBlockEarlyJoin || 5;
  //   start.subtract(minutesToSubtract, 'minutes');
  // }
  // const withinCurrent = currentTime.isBetween(start, end);
  // const withinPrevious = currentTime.isBetween(prevStart, prevEnd);

  // return withinCurrent || withinPrevious;
};

const getMeetingIdFromUrl = (url) => {
  const urlSplit = url.split('/');
  let meetingId;

  urlSplit.forEach((item, i) => {
    if (item === 'j') {
      meetingId = urlSplit[i + 1];
    }
  });
  if (meetingId) {
    const meetingIdWIthoutPassword = meetingId.split('?')[0];
    return meetingIdWIthoutPassword;
  }
};

const msieVersion = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');

  if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    return true;
  } // If another browser, return 0
  else {
    return false;
  }

  return false;
};

const timer = (ms) => new Promise((res) => setTimeout(res, ms));

const urlWithHttpProtocol = (url = '') => {
  if (!url || url == null) {
    return '';
  }

  url = url.replace(/\s+/g, '').trim();

  if (!url || url == null) {
    return '';
  }

  const isHttps = url.match(/^https?:\/\//i);
  const isHttp = url.match(/^http?:\/\//i);

  if (isHttps || isHttp) {
    return url;
  }

  return 'https://' + url;
};

const dateFormatToDBFormat = (dateObject, options) => {
  const { giveTime } = options || {};
  const dateToFormat = dateObject;
  const year = dateToFormat.getFullYear();
  const month = String(dateToFormat.getMonth() + 1).padStart(2, '0');
  const day = String(dateToFormat.getDate()).padStart(2, '0');
  const hours = String(dateToFormat.getHours()).padStart(2, '0');
  const minutes = String(dateToFormat.getMinutes()).padStart(2, '0');
  const AMorPM = hours >= 12 ? 'PM' : 'AM';

  const formattedDate = `${year}-${month}-${day}${
    giveTime ? ` ${hours > 12 ? hours - 12 : hours}:${minutes} ${AMorPM}` : ''
  }`;
  return formattedDate;
};

// validate email.
// requires topend domain.
// allows for special characters.
// does not allow for spaces.
// does not allow for multiple @symbols.
// does not allow for multiple periods.
// does not allow for periods at the end.
// does not allow for periods at the beginning.
// does not allow for periods next to each other.
// does not allow for periods next to @ symbol.
// does not allow for periods next to each other.
const isInvalidEmail = (email) => {
  if (!email) {
    return false;
  }

  if (
    email == null ||
    email == '' ||
    email == undefined ||
    // !email.match(/^(?!.*\.{2})(?!.*\.@)[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
    !email.match(/^(?!.*\.{2})(?!.*\.@)[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
    // !email.match(
    //   /([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@([0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)*|\[((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|IPv6:((((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){6}|::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){5}|[0-9A-Fa-f]{0,4}::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){4}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):)?(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){3}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,2}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){2}|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,3}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,4}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,5}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3})|(((0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}):){0,6}(0|[1-9A-Fa-f][0-9A-Fa-f]{0,3}))?::)|(?!IPv6:)[0-9A-Za-z-]*[0-9A-Za-z]:[!-Z^-~]+)])/,
    // ) ||
    // !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  ) {
    return true;
  }
  return false;
};

const zoomPopupJSX = (
  showZoomLink,
  setShowZoomLink,
  zoomLink,
  setCopied,
  copied,
  zoomInfo,
  zoomPassword,
  mainRoomDescription,
  meetingId,
) => {
  return (
    <PopupWrapper style={{ color: '#000' }}>
      <Popup open={showZoomLink} closeOnDocumentClick={false} style={{ width: '100vw' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '15px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <div
              onClick={() => {
                setShowZoomLink(false);
              }}
              style={{ padding: '10px', cursor: 'pointer' }}
            >
              <FontAwesomeIcon color="#FF0000" size="lg" icon={faTimes} />
            </div>
          </div>
          <h2 style={{ textAlign: 'center' }} className="h2_mobile">
            Your meeting should open in a new tab.
          </h2>
          <h5 className="h5_mobile" style={{ paddingBottom: '20px', textAlign: 'center' }}>
            If the tab does not open,{' '}
            <a href={zoomLink} target="_blank">
              click here
            </a>{' '}
            or copy and paste the link into a new tab.
          </h5>
          {mainRoomDescription && <p>{mainRoomDescription}</p>}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <a href={zoomLink} target="_blank" id="myInput">
              {zoomLink}
            </a>
            <div>
              {meetingId && (
                <p style={{ marginTop: '1rem', marginBottom: 'none !important' }}>
                  <span className="bold">Meeting ID: </span>
                  {meetingId}
                </p>
              )}
              {zoomPassword && zoomPassword.length > 0 && (
                <p style={{ margin: 0, padding: 0 }}>
                  <span style={{ fontWeight: '700' }}>Password:</span> {zoomPassword}
                </p>
              )}
            </div>
            <div style={{ paddingTop: '15px' }}>
              <Button
                size="sm"
                color={copied ? 'success' : 'warning'}
                onClick={(e) => {
                  e.preventDefault();

                  const el = document.createElement('textarea');
                  el.value = zoomLink;
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand('copy');
                  document.body.removeChild(el);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
              >
                {copied ? 'Copied!' : 'Copy Link'}
              </Button>
            </div>
            <div style={{ maxHeight: 300, display: 'flex', flexDirection: 'row', width: '100%' }}>
              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {zoomInfo.length > 0 && <h5 style={{ textAlign: 'center', paddingTop: '15px' }}>Call-In Numbers</h5>}
                </div>
                <div
                  style={{ display: 'flex', overflowY: 'scroll', flexDirection: 'row', width: '100%', maxHeight: 250 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {zoomInfo.map((item) => {
                      return (
                        <div key={item.number} style={{ display: 'flex' }}>
                          <p style={{ fontWeight: '700', margin: 0 }}>Country: &nbsp;</p>
                          <p style={{ margin: 0 }}>{item.country} &nbsp; </p>{' '}
                          <p style={{ fontWeight: '700', margin: 0 }}>Number: &nbsp;</p>
                          <a href={`tel:+${item.number}`}>{item.number} </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </PopupWrapper>
  );
};

const addColors = (arrayToAdd, color1 = '#FFFFFF', color2 = '#f2f2f2') => {
  arrayToAdd.forEach((item, i) => {
    const color = i % 2 === 0 ? color1 : color2;
    item.color = color;
  });
  return arrayToAdd;
};

const constructFilemakerBaseUrl = () => {
  const testApi = localStorage.getItem('testApi');
  if (testApi) {
    return 'https://tools.rezrate.com/api/v2b';
  }

  return 'https://tools.rezrate.com/api/v2';
};

const sanitizeWithOptions = (htmlToSanitize) => {
  return sanitizeHtml(htmlToSanitize, {
    allowedTags: tagOptions,
    allowedAttributes: false,
  });
};

const reduceArrayIntoSubArray = (items, itemKey, childKey, extraKeys) => {
  return _.map(_.groupBy(items, itemKey), (obj, key) => {
    const result = {
      [itemKey]: key,
      [childKey]: obj,
    };
    if (extraKeys && extraKeys.length > 0) {
      extraKeys.forEach((item) => {
        result[item] = obj[0][item];
      });
    }
    return result;
  });
};

const userExistsInSystem = (userObject) => {
  const { Auth0Email, UserFirst, UserLast } = userObject;
  Swal.fire(
    'Info',
    `A user with the email address <strong>${Auth0Email}</strong> under the name <strong>${UserFirst} ${UserLast}</strong> already exists within the RezRATE system. We've imported this user into this department.`,
    'info',
  );
};

const formatBearerToken = (token) => {
  return { Authorization: `Bearer ${token}` };
};

const roundToDecimalPlace = (number = 0, decimalPlaces, keepTrailingZeroes = false) => {
  if (number != null && number != undefined && number != NaN && number != Infinity && number != -Infinity) {
    let numberToReturn = number;
    try {
      numberToReturn = number.toFixed(decimalPlaces);
      if (!keepTrailingZeroes) {
        numberToReturn = parseFloat(numberToReturn);
      }
    } catch (e) {
      console.log('roundToDecimalPlace bad number: ', number);
      return 0;
    }

    return numberToReturn;
  } else {
    return 0;
  }

  // let toRound = '1';
  // for (let index = 0; index < decimalPlaces; index++) {
  //   toRound += '0';
  // }
  // return Math.round((number + Number.EPSILON) * Number(toRound)) / toRound;
};

const getCookie = (name) => {
  var value = '; ' + document.cookie;
  var parts = value.split('; ' + name + '=');
  if (parts.length == 2)
    return parts
      .pop()
      .split(';')
      .shift();
};

const isOdd = (num) => num % 2 === 0;

function CSVToArray(strData, strDelimiter) {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  strDelimiter = strDelimiter || ',';

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    // Delimiters.
    '(\\' +
      strDelimiter +
      '|\\r?\\n|\\r|^)' +
      // Quoted fields.
      '(?:"([^"]*(?:""[^"]*)*)"|' +
      // Standard fields.
      '([^"\\' +
      strDelimiter +
      '\\r\\n]*))',
    'gi',
  );

  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;

  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while ((arrMatches = objPattern.exec(strData))) {
    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[1];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (strMatchedDelimiter.length && strMatchedDelimiter != strDelimiter) {
      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push([]);
    }

    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[2]) {
      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      var strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
    } else {
      // We found a non-quoted value.
      var strMatchedValue = arrMatches[3];
    }

    // Now that we have our value string, let's add
    // it to the data array.
    arrData[arrData.length - 1].push(strMatchedValue);
  }

  // Return the parsed data.
  return arrData;
}

const processData = (data) => {
  data.forEach((arrayOfItems) => {});
  // alert(lines);
};

const splitArray = (array, condition) => {
  const isTruthy = (candidate) => {
    return candidate[condition] === true;
  };
  return array.reduce(
    ([array1, array2], item) => {
      return isTruthy(item) ? [[...array1, item], array2] : [array1, [...array2, item]];
    },
    [[], []],
  );
};

const checkForScheduleDisabledButton = (item, now) => {
  if (!item.DisplayButtonAsDisabledBeforeStartTimestamp) {
    return false;
  }

  // if the button is supposed to be displayed before the startime and it's before the start time disable it
  if (now.isBefore(item.JoinMeetingButtonDisplayTimestampStart)) {
    return true;
  }

  return false;
};

// this is on the backend, so make sure to change api if you make changes to this
const calculateMinMaxSpread = (MaxOrYesValue, MinOrNoValue, scale, decimalToRound) => {
  const difference = MaxOrYesValue - MinOrNoValue;
  let minCopy = MinOrNoValue;

  // top value is the last value so need to reduce the scale by one to properly calculate
  const valueToAdd = difference / (scale - 1);
  const finalSpread = [];
  finalSpread.push(minCopy);
  for (let index = 0; index < scale - 1; index++) {
    minCopy += valueToAdd;
    finalSpread.push(Number(minCopy.toFixed(decimalToRound)));
  }

  return finalSpread;
};

const calculateWeight = (
  type,
  question,
  numOfQuestionsInWeight,
  questionWeights,
  pk_EvaluationQuestionWeight,
  decimalPlaces,
) => {
  const { EnableEvaluationForm, EnablePrescreenForm } = question;

  // if the requested value is false don't return a percentage as it's not factroed in
  if (type === 'prescreen' && !EnablePrescreenForm) {
    return '-';
  }

  if (type === 'evaluation' && !EnableEvaluationForm) {
    return '-';
  }

  const numOfQuestions = numOfQuestionsInWeight[pk_EvaluationQuestionWeight];
  //if there aren't any questions just return (probably resolving promise)
  if (!numOfQuestions) return '-';

  const weightObject = questionWeights.find((item) => {
    return item.pk_EvaluationQuestionWeight === pk_EvaluationQuestionWeight;
  });

  const { EvaluationWeight, PrescreenWeight } = weightObject;
  const percentageToUse = type === 'prescreen' ? PrescreenWeight : EvaluationWeight;
  const numQuestionType = type === 'prescreen' ? numOfQuestions.prescreen : numOfQuestions.evaluation;

  const result = calculatePercentage(numQuestionType, percentageToUse, decimalPlaces);

  return `${result}%`;
};

const calculatePercentage = (numberOfQuestions, weightPercentage, decimalPlaces) => {
  const decimalQuestionIsWorth = 1 / numberOfQuestions;

  const toPercentage = decimalQuestionIsWorth * weightPercentage;

  if (decimalPlaces) {
    return (toPercentage + Number.EPSILON).toFixed(decimalPlaces);
  } else {
    return Math.round((toPercentage + Number.EPSILON) * 100) / 100;
  }
};

// basically answers "is A > B?"
export const compareVersionStrings = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const evaluatorTypeEnum = {
  evaluator: 'evaluator',
  admin: 'admin',
};

const determineEvent = (item) => {
  const {
    isFlexEvent,
    isPassingPeriod,
    isCustomMeeting,
    isFiller,
    Users,
    EventName,
    Candidates,
    Schedules4EvalAltText,
    CustomMeetingTitle,
  } = item;

  const defaultMargin = [15, 2, 0, 2];
  if (isFlexEvent) {
    return {
      text: EventName || 'Unknown Flex Event',
      style: { alignment: 'left' },
      fontSize: 12,
      margin: defaultMargin,
    };
  }

  if (isPassingPeriod) {
    return {
      text: 'Passing Period',
      style: { alignment: 'left' },
      fontSize: 12,
      margin: defaultMargin,
    };
  }

  if (isCustomMeeting) {
    return {
      text: CustomMeetingTitle,
      style: { alignment: 'left' },
      fontSize: 12,
      margin: defaultMargin,
    };
  }

  if (isFiller) {
    return {
      text: 'Break',
      style: { alignment: 'left' },
      fontSize: 12,
      margin: defaultMargin,
    };
  }

  if (Users && Users.length > 0) {
    return Users.map((evaluator, i) => {
      const { UserFirst, UserLast } = evaluator;
      return {
        text: `${UserFirst} ${UserLast}`,
        style: { alignment: 'left' },
        margin: [15, i == 0 ? 2 : 2, 0, Users && Users.length && i == Users.length - 1 ? 2 : 0],
        fontSize: 12,
      };
    });
  }

  if (Candidates && Candidates.length > 0) {
    return Candidates.map((candidate, i) => {
      const { FirstName, LastName } = candidate;

      return {
        text: `${LastName}, ${FirstName} `,
        style: { alignment: 'left' },
        fontSize: 12,
        margin: [15, i == 0 ? 2 : 2, 0, Users && Users.length && i == Users.length - 1 ? 2 : 0],
      };
    });
  }

  if (Schedules4EvalAltText && Schedules4EvalAltText.length > 0) {
    return {
      text: Schedules4EvalAltText,
      style: { alignment: 'left' },
      fontSize: 12,
      margin: defaultMargin,
    };
  }

  return {
    text: 'Break',
    style: { alignment: 'left' },
    fontSize: 12,
    margin: defaultMargin,
  };
};

/**
 * Creates list of user names, last name first. Used for Tooltips.
 * @param {array} users
 * @returns JSX
 */
const listUserNames = (users) => {
  let listOfNames = [];

  users.forEach((u) => {
    const { UserFirst, UserLast } = u;
    listOfNames.push(`${UserFirst ? `${UserFirst}` : ''} ${UserLast || ''}`);
  });

  return listOfNames;
};

export const applyCSVProtection = (stringToProtect = '', showLog) => {
  if (showLog) {
    console.log('stringToProtect: ', stringToProtect);
  }
  try {
    let stringToReturn = stringToProtect || '';
    stringToReturn = stringToReturn
      .replace(/\n/g, '\u2028')
      .replace(/,/g, '\u002C')
      .replace(/"/g, "'");

    if (showLog) {
      console.log('stringToReturn: ', `"${stringToReturn}"`);
    }
    return `${stringToReturn}`;
    return `"${stringToReturn}"`;
  } catch (e) {
    if (showLog) {
      console.log('applyCSVProtection failure: ', e);
      console.log('stringToProtect: ', stringToProtect);
    }

    return stringToProtect;
  }
};

export const checkIfMeetingIsBeforeCurrentJoinedMeeting = (data) => {
  const { lastJoinedMeeting, itemToCheck } = data;
  const { StartTimeAsMoment, EndTimeAsMoment } = itemToCheck;

  if (!lastJoinedMeeting) {
    return false;
  }

  const lastJoinedMeetingStartTimeAsMoment = moment(lastJoinedMeeting.StartTimeAsMoment);

  const isBefore = moment(StartTimeAsMoment).isBefore(lastJoinedMeetingStartTimeAsMoment);

  return isBefore;
};

export const mergeUniqueObjects = (array1, array2, key) => {
  const combinedArray = array1.concat(array2);
  const uniqueMap = new Map();

  combinedArray.forEach((item) => {
    uniqueMap.set(item[key], item);
  });

  return Array.from(uniqueMap.values());
};

export {
  adaptToTimeZone,
  addColors,
  addTimeToTimeSlots,
  calculateMinMaxSpread,
  calculatePercentage,
  calculateWeight,
  checkForScheduleDisabledButton,
  checkIfZoomURLAndIfHasPassword,
  constructFilemakerBaseUrl,
  contactSupport,
  copyObject,
  CSVToArray,
  customStartsWith,
  dateFormatToDBFormat,
  determineCredentials,
  determineEvent,
  determineIcon,
  determineProgress,
  emailIsValid,
  evaluatorTypeEnum,
  fetchInterviewDates,
  formatBearerToken,
  formatScheduleBlocksFromTimeslots,
  getClosestFutureDate,
  getCookie,
  getCorrectEvalToken,
  getDefaultInterviewDate,
  getMeetingIdFromUrl,
  getNeededDataFromUser,
  getPercentageTime,
  getSelectedDate,
  getURLParameter,
  informUserEvalLocked,
  isArrayEqual,
  isDev,
  isExclusivelyNumbers,
  isInvalidEmail,
  isNotificationsSupported,
  isOdd,
  isValidURL,
  isValidUser,
  joinArrays,
  lightOrDark,
  listUserNames,
  msieVersion,
  processData,
  reduceArrayIntoSubArray,
  removeCorrectEvalTOken,
  renderCandidateNotFound,
  roundToDecimalPlace,
  sanitizeWithOptions,
  scrollToElement,
  setCorrectEvalToken,
  shouldJoinMeetingButtonBeVisisble,
  smoothScrollTop,
  splitArray,
  swalErrorMessage,
  timer,
  urlWithHttpProtocol,
  useEventListener,
  usePrevious,
  userExistsInSystem,
  zoomPopupJSX,
};

// this is currently done on both the backend and frontend so update both sources
const tagOptions = [
  'html',
  'a',
  'abbr',
  'address',
  // 'area',
  // 'article',
  'aside',
  // 'audio',
  // 'b',
  'base',
  // 'bdi',
  'bdo',
  'blockquote',
  'body',
  'br',
  'button',
  // 'canvas',
  // 'caption',
  // 'cite',
  'code',
  'col',
  'colgroup',
  // 'data',
  // 'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  // 'embed',
  'fieldset',
  'figure',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  // 'iframe',
  'img',
  'input',
  'ins',
  'kbd',
  // 'keygen',
  'label',
  'legend',
  'li',
  // 'link',
  // 'main',
  // 'map',
  'mark',
  'menu',
  'menuitem',
  'meta',
  'meter',
  'nav',
  // 'noscript',
  // 'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'pre',
  'progress',
  'q',
  'rb',
  'rp',
  'rt',
  'rtc',
  // 'ruby',
  's',
  'samp',
  // 'script',
  'section',
  'select',
  // 'small',
  'source',
  'span',
  'strong',
  // 'style', -vulnerable to XSS attacks
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  // 'var',
  // 'video',
  // 'wbr',
];
