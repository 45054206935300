import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  CustomInput,
  Card,
  CardHeader,
  CardBody,
  UncontrolledTooltip,
  PopoverHeader,
  PopoverBody,
  Popover,
} from 'reactstrap';
import style from './EditFlexEventModal.style';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckSquare,
  faSquare,
  faUser,
  faInfoCircle,
  faExclamationCircle,
  faDotCircle,
  faCircle,
  faCog,
  faLink,
  faTag,
  faTags,
  faFilter,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  clone,
  getEvaluatorColumn,
  getFlexEventsWithinTimeInterval,
  getTimeSlotsWithinTimeInterval,
} from '../../../../../../Services/schedule';
import moment from 'moment-timezone';
import { deleteData, postDataAgnostic, putData } from '../../../../../../Services/dataApi';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, urlWithHttpProtocol } from '../../../../../../Common.functions';
import Swal from 'sweetalert2';
import tinycolor from 'tinycolor2';
import TagFilterPopover from 'Body/RezRATE/AdminLanding/AdminCandidates/TagFilterPopover/TagFilterPopover';

const EditFlexEventModal = ({
  flexEventToEdit,
  forcedContext,
  pk_InterviewDate,
  rowTracker = [],
  columnTracker = [],
  indexedFlexEventEvaluators = [],
  candidates,
  evaluators,
  isOpen,
  scheduleData,
  selectedCell,
  setShowEditFlexEventModal,
  setShowFlexEventDetailModal,
  updateAllData,
  fireConfirmationForEditingPastSchedule,
  dateIsInThePast,
  willEditThePast,
  showPastScheduleWarning,
}) => {
  const [EventName, setEventName] = useState('');
  const [StartDateTime, setStartDateTime] = useState('11:00 AM');
  const [EndDateTime, setEndDateTime] = useState();
  const [Duration, setDuration] = useState();
  const [URLType, setURLType] = useState('AllDay');
  const [URL, setURL] = useState();
  const [customURL, setCustomURL] = useState();
  const [Details, setDetails] = useState();
  const [timeSlot, setTimeslot] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedEvaluators, setSelectedEvaluators] = useState([]);

  const [oldEvaluators, setOldEvaluators] = useState([]);
  const [oldCandidates, setOldCandidates] = useState([]);

  const [selectAllCandidates, setSelectAllCandidates] = useState(false);
  const [selectAllEvaluators, setSelectAllEvaluators] = useState(false);

  const [showTagFilterPopover, setShowTagFilterPopover] = useState(false);
  const [tagFilter, setTagFilter] = useState({ pk_Tag: null, Tag: 'All' });
  const [filteredCandidates, setFilteredCandidates] = useState([]);

  const timeOuts = {};

  const { metaData, body = [] } = scheduleData || {};
  const { DateOfInterview } = metaData || {};

  const history = useHistory();

  const deptContext = useContext(DepartmentContext);
  const dContext = forcedContext ? forcedContext : deptContext;
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const mainRoomURL = scheduleData && scheduleData.metaData ? scheduleData.metaData.MainRoomURL : '';
  const mainRoomURLDetails = scheduleData && scheduleData.metaData ? scheduleData.metaData.MainRoomURLDetails : '';

  const scheduleStart = moment.tz(`${body && body[0] ? body[0].slot : ''}`, null);

  const scheduleEnd = moment.tz(`${body[body.length - 1] ? body[body.length - 1].slotEnd : ''}`, null);

  const monthDate = DateOfInterview
    ? moment.tz(DateOfInterview, null).format('MMM DD, YYYY')
    : moment.tz(null, null).format('MMM DD, YYYY');

  useEffect(() => {
    if (flexEventToEdit) {
      const {
        FlexTimeStart,
        FlexDateStart,
        FlexTimeEnd,
        FlexDateEnd,
        EventName,
        FlexEventName,
        AttendingCandidates,
        CustomUrl,
        UrlType,
        CustomUrlDescription,
      } = flexEventToEdit;

      const newfilteredCandidates = candidates
        ? candidates.filter((c) => {
            const { Tags = [] } = c;
            const isTagMatch = Tags.find((tag) => {
              return tagFilter == null || tagFilter.pk_Tag == null || tag.pk_Tag === tagFilter.pk_Tag;
            });

            return isTagMatch || tagFilter.pk_Tag == null;
          })
        : [];

      setFilteredCandidates(newfilteredCandidates);

      const newStart = moment.tz(`${FlexDateStart} ${FlexTimeStart}`, null);
      const newEnd = moment.tz(`${FlexDateEnd} ${FlexTimeEnd}`, null);
      setEventName(EventName || FlexEventName);
      setStartDateTime(newStart.format('hh:mm A'));
      setEndDateTime(newEnd.format('hh:mm A'));
      setDuration(newEnd.diff(newStart, 'minutes'));

      const userKeys = indexedFlexEventEvaluators[flexEventToEdit.pk_FlexEvent] || [];
      setSelectedEvaluators(userKeys);
      setOldEvaluators(userKeys);

      const candidateKeys = AttendingCandidates.map((c) => {
        return c.pk_Candidate;
      });
      setURL(CustomUrl);
      setCustomURL(CustomUrl);
      setURLType(UrlType || 'AllDay');
      setDetails(CustomUrlDescription);
      setSelectedCandidates(candidateKeys);
      setOldCandidates(candidateKeys);
    }
  }, [isOpen, tagFilter, candidates]);

  useEffect(() => {}, []);

  const clearFields = () => {
    setEventName('');
    setStartDateTime();
    setEndDateTime();
    setDuration();
    setURLType();
    setURL();
    setDetails();
    setSelectedCandidates([]);
    setSelectedEvaluators([]);
  };

  const renderFilterByTagButton = () => {
    return (
      <Button
        id="tags_popover"
        style={{ width: '100%' }}
        onClick={async () => {
          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
          setShowTagFilterPopover(!showTagFilterPopover);
        }}
      >
        <div style={style.spaceBetweenRow}>
          <div
            style={{
              ...style.simpleColumn,
              width: 50,
              marginRight: 10,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon icon={faTag} />
          </div>
          <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {tagFilter.pk_Tag ? tagFilter.Tag : 'All'}
          </div>
          <div style={{ ...style.simpleColumn, width: 50, justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faFilter} />
          </div>
        </div>
        {renderTagFilterPopover()}
      </Button>
    );
  };

  const renderTagFilterPopover = () => {
    return (
      <Popover
        flip={false}
        trigger="legacy"
        target="tags_popover"
        placementPrefix={'tagFilter bs-popover'}
        modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        isOpen={showTagFilterPopover}
        toggle={() => {
          // setShowTagFilterPopover(!showTagFilterPopover)
        }}
      >
        <PopoverHeader>
          {' '}
          <div style={style.spaceBetweenRow}>
            <div style={style.buttonLabel}>Tag Filter</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowTagFilterPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={{ height: 200 }}>
          <TagFilterPopover
            tagFilter={tagFilter}
            setTagFilter={setTagFilter}
            setShowTagFilter={setShowTagFilterPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderTabContent = () => {
    const momentStart = moment.tz(`${monthDate} ${StartDateTime}`, null);
    const momentEnd = moment.tz(`${monthDate} ${EndDateTime}`, null);

    const slotsInInterval = scheduleData
      ? getTimeSlotsWithinTimeInterval(scheduleData, StartDateTime, momentStart, momentEnd)
      : [];

    const candidatesInRow = [];
    const candidatesInSlotsInInterval = []; // list of entire Candidate objects, for marking Evaluators as busy or not.

    slotsInInterval.forEach((s) => {
      const candidates = rowTracker[s.pk_Timeslot] || [];
      if (candidates && candidates.length > 0) {
        candidates.forEach((c) => {
          candidatesInRow.push(c);
        });
      }

      Object.keys(s).forEach((pk_ScheduleAssignment) => {
        if (s[pk_ScheduleAssignment] && s[pk_ScheduleAssignment].ScheduleBlockEntries) {
          s[pk_ScheduleAssignment].ScheduleBlockEntries.forEach((scheduleBlock) => {
            if (scheduleBlock.Candidates) {
              scheduleBlock.Candidates.forEach((c) => {
                candidatesInSlotsInInterval.push(c);
              });
            }
          });
        }
      });
    });

    return (
      <>
        <div style={{ ...style.simpleRow, fontWeight: 'bold' }}>Assign Users {renderAssignError()}</div>
        <div style={style.spaceBetweenRow}>
          <div style={{ ...style.simpleColumn, width: '49%' }}>
            <Card>
              <CardHeader>
                <div style={style.spaceBetweenRow}>
                  <div>
                    Candidates ({selectedCandidates.length}/{filteredCandidates.length})
                  </div>
                  <div>
                    <div style={style.spaceBetweenRow}>
                      <FontAwesomeIcon
                        icon={selectedCandidates.length == filteredCandidates.length ? faCheckSquare : faSquare}
                        style={{ fontSize: 24, marginRight: 10, marginLeft: 30, cursor: 'pointer' }}
                        onClick={async () => {
                          if (dateIsInThePast && !willEditThePast) {
                            const continueProcess = await fireConfirmationForEditingPastSchedule();
                            if (!continueProcess) {
                              return;
                            }
                          }
                          toggleSelectAll('candidates');
                        }}
                      />
                      <div style={{ fontWeight: 'bold' }}>All</div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody style={{ padding: 5, paddingBottom: 10 }}>
                <div>
                  <div style={{ ...style.simpleRow, marginBottom: 5 }}>{renderFilterByTagButton()}</div>
                  <div style={style.simpleRow}>
                    {' '}
                    <div style={{ ...style.simpleColumn, height: 'calc(100vh - 350px)', overflowY: 'scroll' }}>
                      {filteredCandidates.map((c) => {
                        const { pk_Candidate, PhotoUrl, Tags = [] } = c;
                        const isBusy = candidatesInRow.includes(pk_Candidate);
                        const isSelected = selectedCandidates.includes(pk_Candidate);
                        const borderColor = isBusy ? '#f55c5c' : null;
                        const backgroundColor = isSelected ? '#d9f5ff' : c.color; //'white',
                        const id = `candidate_${pk_Candidate}`;
                        let tagString = 'Tags: ';
                        Tags.forEach((tag, index) => {
                          tagString += tag.Tag;
                          if (index < Tags.length - 1) {
                            tagString += ', ';
                          }
                        });

                        return (
                          <div
                            key={id}
                            id={id}
                            style={{
                              ...style.simpleRow,
                              backgroundColor: backgroundColor,
                              cursor: Tags.length > 0 ? 'pointer' : 'default',
                              border: isBusy ? `3px solid ${borderColor}` : null,
                              color: tinycolor(backgroundColor).isLight() ? 'black' : 'white',
                              alignItems: 'center',
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            <div style={{ ...style.simpleColumn, width: 50 }}>
                              <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon
                                  icon={isSelected ? faCheckSquare : faSquare}
                                  style={{ cursor: 'pointer', fontSize: 20 }}
                                  onClick={async () => {
                                    if (dateIsInThePast && !willEditThePast) {
                                      const continueProcess = await fireConfirmationForEditingPastSchedule();
                                      if (!continueProcess) {
                                        return;
                                      }
                                    }
                                    const newSelectedCandidates = selectedCandidates.slice();
                                    if (isSelected) {
                                      newSelectedCandidates.splice(selectedCandidates.indexOf(pk_Candidate), 1);
                                    } else {
                                      newSelectedCandidates.push(pk_Candidate);
                                    }
                                    setSelectedCandidates(newSelectedCandidates);
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ ...style.simpleColumn, width: 50, marginRight: 10 }}>
                              {PhotoUrl ? (
                                <img
                                  src={PhotoUrl}
                                  width={50}
                                  height={50}
                                  style={{ borderRadius: 25, objectFit: 'cover' }}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: 35 }} />
                              )}
                            </div>
                            <div style={{ ...style.simpleColumn }}>
                              <div style={{ ...style.simpleRow, alignItems: 'center' }}>
                                <div> {`${c.LastName}, ${c.FirstName}`}</div>
                                {/* {Tags.length > 0 ? (
                                  <FontAwesomeIcon
                                    icon={Tags.length > 1 ? faTags : faTag}
                                    style={{ marginLeft: 5, marginTop: 5 }}
                                  />
                                ) : null} */}
                              </div>
                              {Tags.length > 0 ? (
                                <div
                                  style={{ ...style.simpleRow, alignItems: 'center', fontSize: 9, fontWeight: 'bold' }}
                                >
                                  {tagString}
                                </div>
                              ) : null}
                            </div>
                            {isBusy ? (
                              <div>
                                <FontAwesomeIcon
                                  id={`alert_icon_${pk_Candidate}`}
                                  icon={faExclamationCircle}
                                  style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }}
                                />
                                <UncontrolledTooltip target={`alert_icon_${pk_Candidate}`}>
                                  Candidate is Scheduled for Interview sometime between the start and end time!
                                </UncontrolledTooltip>
                              </div>
                            ) : null}
                            {/* {Tags.length > 0 ? (
                              <UncontrolledTooltip target={'candidate_' + pk_Candidate}>
                                {tagString}
                              </UncontrolledTooltip>
                            ) : null} */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
          <div style={{ ...style.simpleColumn, width: '49%' }}>
            <Card>
              <CardHeader>
                <div style={style.spaceBetweenRow}>
                  <div>
                    Evaluators ({selectedEvaluators.length}/{evaluators.length})
                  </div>
                  <div>
                    <div style={style.spaceBetweenRow}>
                      <FontAwesomeIcon
                        icon={selectedEvaluators.length == evaluators.length ? faCheckSquare : faSquare}
                        style={{ fontSize: 24, marginRight: 10, marginLeft: 30, cursor: 'pointer' }}
                        onClick={async () => {
                          if (dateIsInThePast && !willEditThePast) {
                            const continueProcess = await fireConfirmationForEditingPastSchedule();
                            if (!continueProcess) {
                              return;
                            }
                          }
                          toggleSelectAll('evaluators');
                        }}
                      />
                      <div style={{ fontWeight: 'bold' }}>All</div>
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardBody style={{ padding: 5, paddingBottom: 10 }}>
                <div>
                  <div style={{ ...style.simpleColumn, height: 'calc(100vh - 307px)', overflowY: 'scroll' }}>
                    {evaluators
                      // .sort((a, b) => {
                      //   const aString = `${a.UserLast}, ${a.UserFirst}`;
                      //   const bString = `${b.UserLast}, ${b.UserFirst} `;

                      //   if (aString < bString) {
                      //     return -1;
                      //   }
                      //   if (aString > bString) {
                      //     return 1;
                      //   }
                      //   return 0;
                      // })
                      .map((e) => {
                        const { pk_User, UserPhotoUrl } = e;
                        const { metaData = {} } = scheduleData;
                        const { FlexEvents = [] } = metaData;
                        const isSelected = selectedEvaluators.includes(pk_User);
                        const flexEventsInInterval = e.FlexEvents
                          ? getFlexEventsWithinTimeInterval(
                              [...e.FlexEvents, ...FlexEvents],
                              StartDateTime,
                              momentStart,
                              momentEnd,
                            )
                          : [];
                        let isBusy = false;
                        if (e.FlexEvents) {
                          let index = 0;
                          while (index < e.FlexEvents.length && !isBusy) {
                            const flexEvent = e.FlexEvents[index];
                            const exists = flexEventsInInterval.find((f) => {
                              return (
                                f.pk_FlexEvent === flexEvent.pk_FlexEvent &&
                                f.pk_FlexEvent != flexEventToEdit.pk_FlexEvent
                              );
                            });

                            if (exists) {
                              isBusy = true;
                            }
                            index++;
                          }
                        }

                        if (!isBusy) {
                          const evaluatorColumn = getEvaluatorColumn(scheduleData, e);
                          if (evaluatorColumn) {
                            if (
                              candidatesInSlotsInInterval.find((c) => {
                                return c.fk_ScheduleAssignment === evaluatorColumn.pk_ScheduleAssignment;
                              })
                            ) {
                              isBusy = true;
                            }
                          }
                        }

                        return (
                          <div
                            key={'evaluator_' + pk_User}
                            style={{
                              ...style.simpleRow,
                              backgroundColor: isSelected ? '#d9f5ff' : isBusy ? '#f55c5c' : 'white',
                              alignItems: 'center',
                              paddingTop: 10,
                              paddingBottom: 10,
                            }}
                          >
                            <div style={{ ...style.simpleColumn, width: 50 }}>
                              <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
                                <FontAwesomeIcon
                                  icon={isSelected ? faCheckSquare : faSquare}
                                  style={{ cursor: 'pointer', fontSize: 20 }}
                                  onClick={async () => {
                                    if (dateIsInThePast && !willEditThePast) {
                                      const continueProcess = await fireConfirmationForEditingPastSchedule();
                                      if (!continueProcess) {
                                        return;
                                      }
                                    }
                                    const newSelectedEvaluators = selectedEvaluators.slice();
                                    if (isSelected) {
                                      newSelectedEvaluators.splice(selectedEvaluators.indexOf(pk_User), 1);
                                    } else {
                                      newSelectedEvaluators.push(pk_User);
                                    }
                                    setSelectedEvaluators(newSelectedEvaluators);
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ ...style.simpleColumn, width: 50, marginRight: 10 }}>
                              {UserPhotoUrl ? (
                                <img
                                  src={UserPhotoUrl}
                                  width={50}
                                  height={50}
                                  style={{ borderRadius: 25, objectFit: 'cover' }}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faUser} style={{ fontSize: 35 }} />
                              )}
                            </div>
                            <div style={{ ...style.simpleColumn }}>{`${e.UserLast}, ${e.UserFirst}`}</div>
                            {isBusy ? (
                              <div>
                                <FontAwesomeIcon
                                  id={`alert_icon_${pk_User}`}
                                  icon={faExclamationCircle}
                                  style={{ marginRight: 10, marginLeft: 10, cursor: 'pointer' }}
                                />
                                <UncontrolledTooltip target={`alert_icon_${pk_User}`}>
                                  Evaluator is Scheduled for a candidate Interview or an Event sometime between the
                                  start and end time!
                                </UncontrolledTooltip>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
        <div style={{ ...style.simpleRow, marginTop: 10 }}>
          <div style={{ ...style.simpleColumn, height: '100%', overflowY: 'scroll' }}></div>
        </div>
      </>
    );
  };

  const saveEvent = async () => {
    if (dateIsInThePast && !willEditThePast) {
      const continueProcess = await fireConfirmationForEditingPastSchedule();
      if (!continueProcess) {
        return;
      }
    }

    if (!checkEventErrors()) {
      return;
    }
    const pathExploded = history.location.pathname.split('/');
    const scheduleID = pathExploded[pathExploded.length - 1];
    const monthDate = DateOfInterview
      ? moment.tz(DateOfInterview, null).format('MMM DD, YYYY')
      : moment.tz(null, null).format('MMM DD, YYYY');

    const event = {
      EventName,
      EnableForAllUsers: false,
      EnableAbsoluteTime: true,
      EnableForAllCandidates: false,
      CustomUrl: URL ? urlWithHttpProtocol(URL) : null,
      UrlType: URLType,
      CustomUrlDescription: Details,
    };

    const momentStart = moment.tz(`${monthDate} ${StartDateTime}`, null);
    const momentEnd = moment.tz(`${monthDate} ${EndDateTime}`, null);
    event.FlexTimeStart = momentStart.format('HH:mm:ss');
    event.FlexDateStart = momentStart.format('YYYY-MM-DD');

    event.FlexTimeEnd = momentEnd.format('HH:mm:ss');
    event.FlexDateEnd = momentEnd.format('YYYY-MM-DD');

    event.StartDateTime = moment.tz(`${monthDate} ${StartDateTime}`, null).format();
    event.EndDateTime = moment.tz(`${monthDate} ${EndDateTime}`, null).format();

    const writeEvaluatorPrimaryKeys = [];
    const deleteEvaluatorPrimaryKeys = [];
    const writeCandidatePrimaryKeys = [];
    const deleteCandidatePrimaryKeys = [];

    selectedEvaluators.forEach((pk_User) => {
      if (!oldEvaluators.includes(pk_User) && !writeEvaluatorPrimaryKeys.includes(pk_User)) {
        writeEvaluatorPrimaryKeys.push(pk_User);
      }
    });

    oldEvaluators.forEach((pk_User) => {
      if (!selectedEvaluators.includes(pk_User) && !deleteEvaluatorPrimaryKeys.includes(pk_User)) {
        deleteEvaluatorPrimaryKeys.push(pk_User);
      }
    });

    selectedCandidates.forEach((pk_Candidate) => {
      if (!oldCandidates.includes(pk_Candidate) && !writeCandidatePrimaryKeys.includes(pk_Candidate)) {
        writeCandidatePrimaryKeys.push(pk_Candidate);
      }
    });

    oldCandidates.forEach((pk_Candidate) => {
      if (!selectedCandidates.includes(pk_Candidate) && !deleteCandidatePrimaryKeys.includes(pk_Candidate)) {
        deleteCandidatePrimaryKeys.push(pk_Candidate);
      }
    });

    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
            pk_FlexEvent: flexEventToEdit.pk_FlexEvent,
          },
          {
            event,
            writeEvaluatorPrimaryKeys,
            deleteEvaluatorPrimaryKeys,
            writeCandidatePrimaryKeys,
            deleteCandidatePrimaryKeys,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            updateAllData();
            setShowEditFlexEventModal(false);
            setShowFlexEventDetailModal(false);
            setIsSubmitting(false);
          })
          .catch((err) => {
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const deleteEvent = () => {
    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
          },
          { pk_FlexEvents: [flexEventToEdit.pk_FlexEvent] },
          formatBearerToken(token),
        )
          .then((res) => {
            updateAllData();
            setShowEditFlexEventModal(false);
            setShowFlexEventDetailModal(false);
            setIsSubmitting(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const isValid = () => {
    return (
      EventName &&
      EventName.toLowerCase() !== 'unavailable' &&
      (selectedCandidates.length > 0 || selectedEvaluators.length > 0) &&
      StartDateTime &&
      EndDateTime
    );
  };

  const toggleSelectAll = (type) => {
    if (type === 'candidates') {
      if (candidates && selectedCandidates.length != 0 && selectedCandidates.length === candidates.length) {
        setSelectAllCandidates(false);
        setSelectedCandidates([]);
      } else {
        const newSelectedCandidates = [];
        candidates.forEach((c) => {
          if (!newSelectedCandidates.includes(c.pk_Candidate)) {
            newSelectedCandidates.push(c.pk_Candidate);
          }
        });
        setSelectAllCandidates(true);
        setSelectedCandidates(newSelectedCandidates);
      }
    } else {
      if (selectedEvaluators.length != 0 && selectedEvaluators.length === evaluators.length) {
        setSelectAllEvaluators(false);
        setSelectedEvaluators([]);
      } else {
        const newSelectedEvaluators = [];
        evaluators.forEach((e) => {
          if (!newSelectedEvaluators.includes(e.pk_User)) {
            newSelectedEvaluators.push(e.pk_User);
          }
        });
        setSelectAllEvaluators(true);
        setSelectedEvaluators(newSelectedEvaluators);
      }
    }
  };

  // For lighting up invalid feedback text
  const checkFieldValidity = (field) => {
    let type = 'beyond_other';
    let isValid = true;
    const { metaData, body = [] } = scheduleData || {};

    const timeStart = moment.tz(`${body && body[0] ? body[0].slot : ''}`, null);
    const timeEnd = moment.tz(`${body && body[body.length - 1] ? body[body.length - 1].slotEnd : ''}`, null);

    // if (body && body[body.length - 1]) {
    //   const lastTimeSlot = body[body.length - 1];
    //   if (lastTimeSlot.CustomDurationInMinutes) {
    //     timeEnd.add(lastTimeSlot.CustomDurationInMinutes, 'minutes');
    //   } else {
    //     timeEnd.add(metaData.StandardDurationInMinutes, 'minutes');
    //   }

    //   if (lastTimeSlot.CustomPassingDurationInMinutes) {
    //     timeEnd.add(lastTimeSlot.CustomPassingDurationInMinutes, 'minutes');
    //   } else {
    //     timeEnd.add(metaData.StandardPassingDurationInMinutes, 'minutes');
    //   }
    // }
    switch (field) {
      case 'Title':
        return EventName != null && EventName && EventName.toLowerCase() !== 'unavailable';
      case 'Time Start':
        isValid =
          StartDateTime != null &&
          moment
            .tz(`${monthDate} ${StartDateTime}`, null)
            .isSameOrBefore(moment.tz(`${monthDate} ${EndDateTime}`, null)) &&
          moment.tz(`${monthDate} ${StartDateTime}`, null).isSameOrAfter(timeStart);

        if (!moment.tz(`${monthDate} ${StartDateTime}`, null).isSameOrAfter(timeStart)) {
          type = 'out_of_bounds';
        }

        return { isValid, type };
        break;
      case 'Time End':
        isValid =
          EndDateTime != null &&
          moment.tz(`${monthDate} ${EndDateTime}`, null).isSameOrBefore(timeEnd) &&
          moment
            .tz(`${monthDate} ${EndDateTime}`, null)
            .isSameOrAfter(moment.tz(`${monthDate} ${StartDateTime}`, null));

        if (!moment.tz(`${monthDate} ${EndDateTime}`, null).isSameOrBefore(timeEnd)) {
          type = 'out_of_bounds';
        }
        return { isValid, type };
        break;
    }
  };

  // For popping erorr alerts.
  const checkEventErrors = () => {
    if (moment.tz(`${monthDate} ${StartDateTime}`, null).isAfter(moment.tz(`${monthDate} ${EndDateTime}`, null))) {
      Swal.fire('Invalid Time Values!', 'Time End is earlier than Time Start. Fix before Saving!', 'error');
      return false;
    }
    return true;
  };

  const renderAssignError = () => {
    if (selectedCandidates.length <= 0 && selectedEvaluators.length <= 0) {
      return (
        <div style={{ color: 'red', fontSize: 12, fontWeight: 'normal', marginLeft: 10 }}>
          Must select at least 1 or more Evaluators or Candidates.
        </div>
      );
    }
  };

  const timeStart = moment.tz(`${body && body[0] ? body[0].slot : ''}`, null);
  const timeEnd = moment.tz(`${body && body[body.length - 1] ? body[body.length - 1].slotEnd : ''}`, null);
  const useAllDayLink = URLType === 'AllDay';

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      style={{ minWidth: '85vw' }}
      onClosed={() => {
        clearFields();
      }}
    >
      <ModalHeader
        closeButton
        toggle={() => {
          setShowEditFlexEventModal(false);
        }}
      >
        <div style={{ ...style.spaceBetweenRow, width: '73vw' }}>
          <div>Edit Flex Event</div>
        </div>
      </ModalHeader>
      <ModalBody>
        <div
          style={style.spaceBetweenRow}
          onClick={async (e) => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            e.stopPropagation();
          }}
        >
          <div style={{ ...style.simpleColumn, width: '39%' }}>
            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <div style={style.simpleColumn}>
                <Label>Title</Label>
                <Input
                  invalid={!checkFieldValidity('Title')}
                  defaultValue={EventName}
                  placeholder="Flex Event Title"
                  name="title"
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (timeOuts.title) {
                      clearTimeout(timeOuts.title);
                    }

                    timeOuts.title = setTimeout(() => {
                      setEventName(newValue);
                    }, 300);
                  }}
                />
                <FormFeedback invalid={!checkFieldValidity('Title').isValid}>
                  {`Invalid Event name! Cannot be empty or 'UNAVAILABLE'.`}
                </FormFeedback>
              </div>
            </div>

            <div style={{ ...style.spaceBetweenRow, marginBottom: 10 }}>
              <div style={{ ...style.simpleColumn, width: '49%' }}>
                <Label>Time Start</Label>
                <Input
                  invalid={!checkFieldValidity('Time Start').isValid}
                  value={moment.tz(`${monthDate} ${StartDateTime}`, null).format('HH:mm')}
                  type="time"
                  name="timeStart"
                  onChange={(e) => {
                    const newStart = moment.tz(`${monthDate} ${e.target.value}`, null);
                    const newMomentEnd = moment.tz(`${monthDate} ${e.target.value}`, null);
                    newMomentEnd.add(Duration, 'minutes');
                    const { body = [] } = scheduleData;

                    const scheduleEnd = moment.tz(`${body[body.length - 1].slotEnd}`, null);
                    const scheduleStart = moment.tz(`${body[0].slot}`, null);
                    console.log("newMomentEnd.format('hh:mm A'): ", newMomentEnd.format('hh:mm A'));
                    if (
                      newStart.isSameOrAfter(moment.tz(DateOfInterview, null)) &&
                      newMomentEnd.isSameOrBefore(scheduleEnd)
                    ) {
                      setEndDateTime(newMomentEnd.format('hh:mm A'));
                      setStartDateTime(e.target.value);
                      setTimeslot('custom');
                    } else {
                      Swal.fire(
                        'Oops...',
                        `The value you selected is beyond the schedule. Please select a time value between ${scheduleStart.format(
                          'hh:mm A',
                        )} and ${scheduleEnd.format('hh:mm A')}`,
                        'error',
                      );
                    }
                  }}
                />
                <FormFeedback invalid={!checkFieldValidity('Time Start').isValid}>
                  {checkFieldValidity('Time Start').type === 'beyond_other'
                    ? `Start Time MUST be equal to or before ${moment
                        .tz(`${monthDate} ${EndDateTime}`, null)
                        .format('hh:mm A')}`
                    : `Start Time MUST be equal to or after ${timeStart.format('hh:mm A')}`}
                </FormFeedback>
              </div>
              <div style={{ ...style.simpleColumn, width: '49%' }}>
                <Label for="title">Time End</Label>
                <Input
                  invalid={!checkFieldValidity('Time End').isValid}
                  value={moment.tz(`${monthDate} ${EndDateTime}`, null).format('HH:mm')}
                  type="time"
                  name="timeEnd"
                  onChange={(e) => {
                    const momentStart = moment.tz(`${monthDate} ${StartDateTime}`, null);
                    const momentEnd = moment.tz(`${monthDate} ${e.target.value}`, null);
                    let newDuration = momentEnd.diff(momentStart, 'minutes');
                    if (newDuration < 0) {
                      newDuration = 0;
                    }
                    setDuration(newDuration);
                    setEndDateTime(e.target.value);
                    setTimeslot('custom');
                  }}
                />
                <FormFeedback invalid={!checkFieldValidity('Time End').isValid}>
                  {checkFieldValidity('Time End').type === 'beyond_other'
                    ? `End Time MUST be equal to or after ${moment
                        .tz(`${monthDate} ${StartDateTime}`, null)
                        .format('hh:mm A')}`
                    : `End Time MUST be equal to or before ${timeEnd.format('HH:mm')}`}
                </FormFeedback>
              </div>
            </div>

            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <div style={style.simpleColumn}>
                <Label>Duration (minutes)</Label>
                <Input
                  value={Duration}
                  type="number"
                  placeholder="Duration"
                  name="title"
                  onChange={(e) => {
                    const newDuration = Math.abs(parseInt(e.target.value));
                    const momentStart = moment.tz(`${monthDate} ${StartDateTime}`, null);
                    momentStart.add(newDuration, 'minutes');
                    setDuration(newDuration);
                    setEndDateTime(momentStart.format('HH:mm'));
                    setTimeslot('custom');
                  }}
                />
              </div>
            </div>

            <div style={style.simpleRow}>
              <div style={{ ...style.simpleColumn, width: 50 }}>URL:</div>
              <div style={{ ...style.simpleColumn, width: '100%' }}></div>
            </div>

            <div
              style={{ ...style.simpleRow, cursor: 'pointer' }}
              onClick={async (e) => {
                e.stopPropagation();

                setURLType('AllDay');
                setDetails(mainRoomURLDetails);
              }}
            >
              <div style={{ ...style.simpleColumn, width: 50, marginBottom: 0, marginTop: 4 }}>
                <FontAwesomeIcon style={style.checkIcon} icon={useAllDayLink ? faDotCircle : faCircle} />
              </div>
              <div style={{ ...style.simpleColumn, width: 200 }}>
                <div style={style.simpleRow}>
                  Use All Day Meeting Link
                  <FontAwesomeIcon
                    id={`useAllDayMeetingLabel`}
                    icon={faInfoCircle}
                    style={{ marginTop: 4, marginLeft: 5 }}
                  />
                  <UncontrolledTooltip target={`useAllDayMeetingLabel`}>
                    {' '}
                    Use the All Day Meeting link for this meeting.
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
            <div
              style={{
                ...style.simpleRow,
                paddingLeft: 45,
                marginBottom: 10,
              }}
            >
              {mainRoomURL ? (
                <Input
                  type="textarea"
                  invalid={useAllDayLink && !mainRoomURL}
                  rows={2}
                  disabled
                  value={mainRoomURL}
                  placeholder={'No Main Room URL. . .'}
                />
              ) : (
                <div
                  style={{
                    ...style.simpleColumn,
                    width: '100%',
                    backgroundColor: '#e9ecef',
                    border: `1px solid ${useAllDayLink ? `red` : '#ced4da'}`,
                    color: '#495057',
                    borderRadius: 5,
                    paddingLeft: 10,
                    height: 80,
                  }}
                >
                  <div>Missing All Day Meeting Link!</div>
                  <div style={{ ...style.simpleRow, alignItems: 'stretch', height: 40 }}>
                    <span style={{ marginRight: 0 }}>
                      {`The All Day Meeting Link can be configured by clicking the `}
                      <span style={{ display: 'inline-flex', alignItems: 'center', width: 20 }}>
                        <FontAwesomeIcon icon={faLink} />
                        <span style={{ position: 'relative' }}>
                          <FontAwesomeIcon
                            icon={faCog}
                            style={{ position: 'absolute', fontSize: 10, left: -6, top: 3, color: 'inherit' }}
                          />
                        </span>
                      </span>
                      {` button above schedule. . .`}
                    </span>
                  </div>
                </div>

                // <div
                //   style={{
                //     ...style.simpleColumn,
                //     width: '100%',
                //     backgroundColor: '#e9ecef',
                //     border: `1px solid ${useAllDayLink ? `red` : '#ced4da'}`,
                //     color: '#495057',
                //     borderRadius: 5,
                //     paddingLeft: 10,
                //     height: 80,
                //   }}
                // >
                //   <div>Missing All Day Meeting Link!</div>
                //   <div style={{ ...style.simpleRow, alignItems: 'stretch', height: 40 }}>
                //     <div
                //       style={{ marginRight: 0 }}
                //     >{`The All Day Meeting Link can be configured by clicking the `}</div>{' '}
                //     <div style={{ ...style.simpleColumn, width: 30, justifyContent: 'center', alignItems: 'center' }}>
                //       <div style={style.simpleRow}>
                //         <FontAwesomeIcon icon={faLink} />
                //         <div style={{ position: 'relative' }}>
                //           <FontAwesomeIcon
                //             icon={faCog}
                //             style={{ position: 'absolute', fontSize: 10, left: -6, top: 8, color: 'inherit' }}
                //           />
                //         </div>
                //       </div>
                //     </div>
                //     <div>{`button above schedule. . .`}</div>
                //   </div>
                // </div>
              )}
            </div>

            <div style={{ ...style.simpleRow, marginBottom: 10 }}>
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                {/* <div style={{ ...style.simpleRow, marginBottom: 10 }}>
                  <div
                    style={{ ...style.simpleColumn, width: 100, justifyContent: 'center', cursor: 'pointer' }}
                    onClick={() => {
                      setURL(customURL);
                      setURLType('Custom');
                    }}
                  >
                    <Input type="radio" name="urlRadio" checked={URLType === 'Custom'} />
                    Custom
                  </div>
                  <div style={{ ...style.simpleColumn, width: '100%' }}>
                    <Input
                      type="text"
                      invalid={URLType !== 'AllDay' && !customURL}
                      disabled={URLType === 'AllDay'}
                      placeholder="Custom URL"
                      value={customURL}
                      onChange={(e) => {
                        const newURL = e.target.value;
                        setURL(newURL);
                        setCustomURL(newURL);
                      }}
                    />
                  </div>
                </div> */}

                <div
                  style={{ ...style.simpleRow, cursor: 'pointer' }}
                  onClick={async (e) => {
                    e.stopPropagation();
                    setURL(customURL);
                    setURLType('Custom');
                  }}
                >
                  <div style={{ ...style.simpleColumn, width: 50, marginBottom: 0, marginTop: 4 }}>
                    <FontAwesomeIcon style={style.checkIcon} icon={!useAllDayLink ? faDotCircle : faCircle} />
                  </div>
                  <div style={{ ...style.simpleColumn, width: 200 }}>
                    <div style={style.simpleRow}>
                      Use Custom Meeting Link
                      <FontAwesomeIcon
                        id="useCustomLinkLabel"
                        icon={faInfoCircle}
                        style={{ marginTop: 4, marginLeft: 5 }}
                      />
                      <UncontrolledTooltip target={`useCustomLinkLabel`}>
                        {' '}
                        Use a unique link for this meeting
                      </UncontrolledTooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ ...style.simpleRow, paddingLeft: 45, marginBottom: 10 }}>
              <Input
                type="textarea"
                invalid={!useAllDayLink && !customURL}
                disabled={useAllDayLink}
                name="text"
                placeholder="Enter Custom Link. . ."
                value={customURL}
                onChange={(e) => {
                  const newURL = e.target.value;
                  setURL(newURL);
                  setCustomURL(newURL);
                }}
              />
            </div>
            <div style={{ ...style.simpleRow, paddingLeft: 45 }}>
              <Input
                disabled={URLType === 'AllDay'}
                rows={5}
                type="textarea"
                value={Details}
                placeholder="Details"
                onChange={(e) => {
                  setDetails(e.target.value);
                }}
              />
            </div>
          </div>
          <div style={{ ...style.simpleColumn, width: '59%' }}>{renderTabContent()}</div>
        </div>
        <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
          <Button
            color="danger"
            disabled={isSubmitting}
            style={{ width: 150, marginRight: 10 }}
            onClick={async () => {
              if (dateIsInThePast && !willEditThePast) {
                const continueProcess = await fireConfirmationForEditingPastSchedule();
                if (!continueProcess) {
                  return;
                }
              }
              Swal.fire({
                title: 'Delete Flex Event!',
                text: `Are you sure you want to delete ${flexEventToEdit.FlexEventName}?`,
                icon: 'warning',
                showCancelButton: true,
                cancelButtonColor: 'gray',
                confirmButtonText: 'Yes',
              }).then((result) => {
                if (result.isConfirmed) {
                  deleteEvent();
                } else {
                }
              });
            }}
          >
            Delete
          </Button>
          <Button disabled={!isValid() || isSubmitting} color="success" style={{ width: 150 }} onClick={saveEvent}>
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditFlexEventModal;
