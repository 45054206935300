import {
  faAngleLeft,
  faAngleRight,
  faAngleDoubleLeft,
  faArrowCircleLeft,
  faLink,
  faClipboard,
  faUserSlash,
  faEnvelope,
  faClipboardList,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone } from 'lodash';
import moment from 'moment';
import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ButtonGroup, Input, InputGroup, InputGroupAddon, Nav, NavItem, UncontrolledTooltip } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchDataAgnostic } from '../../../../Services/dataApi';

import Schedule from '../../Coordinator/Schedule';

import style from './AdminSuperUserPanel.style';
import ManageScores from './ManageScores/ManageScores';
import UserSearch from './UserSearch/UserSearch';
import EntityWrapper from 'Body/CreateNewEntities/EntityWapper';

const AdminSuperUserPanel = ({ departments, setShowSuperUserPanel, selectDepartment, selectRole }) => {
  const [currentDate, setCurrentDate] = useState('');
  const [selectedSession, setSelectedSession] = useState();
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedScheduleData, setSelectedScheduleData] = useState();
  const [myDepartments, setMyDepartments] = useState([]);
  const [forcedContext, setForcedContext] = useState({});
  const [timeNow, setTimeNow] = useState(moment());
  const [activeTab, setActiveTab] = useState(0);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const history = useHistory();
  const tabs = [
    {
      label: 'Sessions',
      index: 0,
    },
    {
      label: 'Organizations',
      index: 1,
    },
    {
      label: 'Scores',
      index: 2,
    },
    {
      label: 'User Search',
      index: 3,
    },
  ];
  useEffect(() => {
    setCurrentDate(moment().format('YYYY-MM-DD'));
    setInterval(() => {
      setTimeNow(moment.tz(moment.tz.guess()));
    }, 1000);
  }, []);

  useEffect(() => {
    if (departments) {
      setMyDepartments(clone(departments));
    }
  }, [departments]);

  useEffect(() => {
    if (currentDate) {
      getData(currentDate);
      setSelectedSession(null);
    }
  }, [currentDate]);

  const getData = (interviewDate) => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('schedule/all/date', { interviewDate }, formatBearerToken(token))
          .then((res) => {
            setSessions(res.data);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderContent = () => {
    switch (activeTab) {
      case 0:
        return renderSessionsOverview();
      case 1:
        return <EntityWrapper />;
      case 2:
        return <ManageScores departments={myDepartments} />;
      case 3:
        return (
          <UserSearch
            setShowSuperUserPanel={setShowSuperUserPanel}
            selectDepartment={selectDepartment}
            selectRole={selectRole}
          />
        );
    }
  };

  const renderSessionsOverview = () => {
    return (
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <div
          style={{
            ...style.simpleColumn,
            width: 300,
            minWidth: 300,
            justifyContent: 'start',
            alignItems: 'center',
            border: '1px gray solid',
            borderRadius: 5,
            height: '60vh',
            paddingTop: 10,
            paddingRight: 10,
            paddingLeft: 10,
          }}
        >
          <div style={{ ...style.simpleRow, fontWeight: 'bold' }}>
            {`${sessions.length || 0} Session${sessions.length > 1 ? 's' : ''} found`}{' '}
          </div>
          <div style={{ ...style.simpleRow, marginBottom: 10 }}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    const newDate = moment(currentDate);
                    newDate.subtract(1, 'days');
                    setCurrentDate(newDate.format('YYYY-MM-DD'));
                  }}
                >
                  <FontAwesomeIcon icon={faAngleLeft} />
                </Button>
              </InputGroupAddon>
              <Input
                style={{ width: 200 }}
                disabled={isLoading}
                type={'date'}
                value={currentDate}
                onChange={(e) => {
                  setCurrentDate(e.target.value);
                }}
              />
              <InputGroupAddon addonType="append">
                <Button
                  size="sm"
                  color="secondary"
                  onClick={() => {
                    const newDate = moment(currentDate);
                    newDate.add(1, 'days');
                    setCurrentDate(newDate.format('YYYY-MM-DD'));
                  }}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
          <div style={{ height: '100%', overflowY: 'auto' }}>{renderSessionList()}</div>
        </div>
        <div
          style={{
            ...style.simpleColumn,
            width: 'calc(100% - 300px)',
            justifyContent: 'center',
            alignItems: 'center',
            border: '1px gray solid',
            borderRadius: 5,
            height: '60vh',
            marginLeft: 10,
          }}
        >
          <div style={{ width: '100%', overflow: 'scroll', height: '80vh' }}>
            {selectedSession ? (
              <Schedule
                // isSuperUserPanel={true}
                isEmbedded={true}
                history={history}
                pk_InterviewDate={selectedSession.pk_InterviewDate}
                showTopBar={true}
                setParentScheduleData={setSelectedScheduleData}
                forcedContext={forcedContext}
                allowScheduleInteraction={true}
              />
            ) : (
              <div
                style={{
                  ...style.simpleRow,
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                No Session Selected
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderFlagsForSession = (session) => {
    const {
      MainRoomURL,
      EnablePrescreenMode,
      pk_InterviewDate,
      pk_Department,
      CandidatesNotSentEmailCount,
      CandidatesWithoutAScheduleBlockCount,
      StartTime,
    } = session;
    const key = `${pk_InterviewDate}_${pk_Department}`;
    const flagsToRender = [];

    const momentStart = moment(StartTime);
    const dayDifference = momentStart.diff(moment(), 'days', true);

    if (!MainRoomURL) {
      flagsToRender.push(
        <div style={{ ...style.simpleColumn, width: 15, cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faLink} id={`no_URL_${key}`} />
          <UncontrolledTooltip target={`no_URL_${key}`}>Missing All Day Link!</UncontrolledTooltip>
        </div>,
      );
    }

    if (EnablePrescreenMode) {
      flagsToRender.push(
        <div style={{ ...style.simpleColumn, width: 15, cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faClipboardList} id={`not_Evaluation_${key}`} />
          <UncontrolledTooltip target={`not_Evaluation_${key}`}>Not set to Evaluation Mode!</UncontrolledTooltip>
        </div>,
      );
    }

    if (CandidatesWithoutAScheduleBlockCount && CandidatesWithoutAScheduleBlockCount > 0) {
      flagsToRender.push(
        <div style={{ ...style.simpleColumn, width: 15, cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faUserSlash} id={`no_scheduleBlock_${key}`} />
          <UncontrolledTooltip target={`no_scheduleBlock_${key}`}>
            {CandidatesWithoutAScheduleBlockCount}{' '}
            {CandidatesWithoutAScheduleBlockCount > 1 ? 'candidates have' : 'candidate has'} not been assigned a
            schedule slot.
          </UncontrolledTooltip>
        </div>,
      );
    }

    if (
      // dayDifference <= 7 && dayDifference >= 0 &&
      CandidatesNotSentEmailCount &&
      CandidatesNotSentEmailCount > 0
    ) {
      flagsToRender.push(
        <div style={{ ...style.simpleColumn, maxWidth: 80, minWidth: 15, cursor: 'pointer' }}>
          <div
            style={{
              ...style.simpleRow,
              alignItems: 'top',
              justifyContent: 'left',
              textAlign: 'top',
              // border: '1px solid red',
            }}
          >
            <FontAwesomeIcon icon={faEnvelope} id={`not_sent_Email_${key}`} style={{ marginRight: 2 }} />
            {/* <div style={{ fontSize: 8 }}>{CandidatesNotSentEmailCount}</div> */}
          </div>

          <UncontrolledTooltip target={`not_sent_Email_${key}`}>
            {CandidatesNotSentEmailCount} {CandidatesNotSentEmailCount > 1 ? 'candidates have' : 'candidate has'} not
            been sent their schedule yet!
          </UncontrolledTooltip>
        </div>,
      );
    }

    // TODO:
    // Candidates yet to receive interview day schedules.
    // One or more registered candidates is not on the schedule.

    return (
      <div style={{ ...style.simpleRow, justifyContent: 'left', color: 'red', fontSize: 10 }}>{flagsToRender}</div>
    );
  };

  const renderSessionList = () => {
    return sessions && sessions.length > 0 ? (
      sessions.map((session, i) => {
        const {
          OrganizationName,
          DepartmentName,
          DepartmentLogo,
          StartTime,
          EndTime,
          TimeZone,
          Label,
          pk_Department,
          pk_InterviewDate,
        } = session;
        const key = `${pk_InterviewDate}_${pk_Department}`;
        const momentStartTime = moment(StartTime);
        const momentEndTime = moment(EndTime);

        let sessionStatus = 'Not happening today.';
        let sessionStatusColor = 'gray';

        if (timeNow.isSameOrAfter(momentStartTime) && timeNow.isSameOrBefore(momentEndTime)) {
          sessionStatusColor = '#35911f';
          sessionStatus = 'Happening Now!';
        } else if (
          timeNow.isBefore(momentStartTime) &&
          (timeNow.format('MMM DD, YYYY') === momentStartTime.format('MMM DD, YYYY') ||
            timeNow.format('MMM DD, YYYY') === momentEndTime.format('MMM DD, YYYY'))
        ) {
          let timeDifference = momentStartTime.diff(timeNow, 'hours');
          let timeUnit = 'hrs';

          if (timeDifference < 1) {
            timeDifference = momentStartTime.diff(timeNow, 'minutes');
            timeUnit = 'mins';
          }

          sessionStatusColor = '#ffc180';
          sessionStatus = `Happening later today. (in ${timeDifference}${timeUnit})`;
        }

        const rowStyle = {
          ...style.simpleRow,
          width: '100%',
          marginLeft: 10,
          marginRight: 10,
          paddingRight: 10,
          cursor: 'pointer',
          backgroundColor:
            selectedSession && selectedSession.pk_InterviewDate == session.pk_InterviewDate ? '#c1e1ec' : 'white',
        };

        if (i == 0) {
          rowStyle.borderTop = '1px solid gray';
        }
        rowStyle.borderBottom = '1px solid gray';

        return (
          <div
            style={rowStyle}
            onClick={() => {
              // const departmentActual = departments.find((d) => {
              //   return d.pk_Department == session.pk_Department;
              // });
              const newForcedContext = clone(forcedContext);
              newForcedContext.department = session; // departmentActual;
              newForcedContext.season = session; // { pk_Season: session.pk_Season };
              setForcedContext(newForcedContext);
              setSelectedSession(session);
              // dContext.setDepartment(departmentActual);
              // const { pk_Department } = departmentActual;
              // sessionStorage.setItem('department', pk_Department);
            }}
          >
            <div
              style={{
                ...style.simpleColumn,
                alignItems: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src={DepartmentLogo} width={50} height={50} />
              <div style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                {' '}
                <div
                  style={{ borderRadius: 25, backgroundColor: sessionStatusColor, height: 8, width: 8 }}
                  id={`status_icon_${key}`}
                ></div>
                <UncontrolledTooltip target={`status_icon_${key}`}>{sessionStatus}</UncontrolledTooltip>
              </div>
            </div>
            <div
              style={{
                ...style.simpleColumn,
                paddingLeft: 10,
                paddingTop: 10,
                paddingBottom: 10,
                fontSize: 12,
                justifyContent: 'center',
                alignItems: 'flex-start',

                width: '100%',
              }}
            >
              <div style={{ fontWeight: 'bold' }}>
                {moment.tz(StartTime, TimeZone).format('hh:mm A')} - {moment.tz(EndTime, TimeZone).format('hh:mm A')}
              </div>
              <div style={{ ...style.simpleRow, color: 'gray', fontWeight: 'bold' }}>{Label ? Label : <br />}</div>
              <div style={style.simpleRow}>{OrganizationName}</div>
              <div style={style.simpleRow}>{DepartmentName}</div>

              {renderFlagsForSession(session)}
            </div>
            <div
              style={{
                ...style.simpleColumn,
                width: 50,
                alignItems: 'center',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                color={'secondary'}
                onClick={(e) => {
                  e.stopPropagation();
                  const departmentActual = departments.find((d) => {
                    return d.pk_Department == session.pk_Department;
                  });
                  dContext.setDepartment(departmentActual);
                  const { pk_Department } = session;
                  sessionStorage.setItem('department', pk_Department);
                }}
              >
                <FontAwesomeIcon icon={faArrowCircleLeft} />
              </Button>
            </div>
          </div>
        );
      })
    ) : (
      <div style={style.simpleRow}> No Sessions </div>
    );
  };

  return (
    <div style={{ ...style.simpleRow, width: '100%' }}>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        <div style={{ ...style.simpleRow, width: '100%', marginBottom: 10 }}>
          <Button
            onClick={() => {
              if (setShowSuperUserPanel) {
                setShowSuperUserPanel(false);
              }
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} />
          </Button>

          {/* <Button
            color="primary"
            size="sm"
            disabled={!departments || departments.length <= 0}
            style={{ marginLeft: 10 }}
            onClick={() => {
              const department = departments[0];
              dContext.setDepartment(department);
              const { pk_Department } = department;
              sessionStorage.setItem('department', pk_Department);
              history.push('/organizations');
            }}
          >
            Create Organization
          </Button> */}

          <Nav className="nav-tabs" style={{ marginLeft: 10 }}>
            {tabs.map((tab, index) => {
              const isActive = index === activeTab;
              return (
                <NavItem
                  className="nav-item"
                  style={{
                    cursor: 'pointer',
                    fontWeight: isActive ? 'bold' : 'normal',
                  }}
                  // size="sm"
                  // color={index === activeTab ? 'primary' : 'secondary'}
                  key={index}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  <div
                    className={`nav-link ${isActive ? 'active' : ''}`}
                    style={{ color: isActive ? '#007bff' : 'black' }}
                  >
                    {tab.label}
                  </div>
                </NavItem>
              );
            })}
          </Nav>

          {/* <ButtonGroup >
            {tabs.map((tab, index) => {
              return (
                <Button
                  size="sm"
                  color={index === activeTab ? 'primary' : 'secondary'}
                  key={index}
                  onClick={() => {
                    setActiveTab(index);
                  }}
                >
                  {tab.label}
                </Button>
              );
            })}
          </ButtonGroup> */}
        </div>

        {renderContent()}
      </div>
    </div>
  );
};

export default AdminSuperUserPanel;
