import { faDownload, faFilePdf, faImage, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
// import { fetchDataAgnostic } from '../../Services/dataApi';

import { formatBearerToken, swalErrorMessage } from 'Common.functions';
import { DepartmentContext } from 'DepartmentWrapper';
import { fetchDataAgnostic } from 'Services/dataApi';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import logoPlaceHolder from '../../../../../assets/images/header/rezRate.gif';
import Swal from 'sweetalert2';
import { truncate } from 'Services/candidates';

const AttachmentViewer = (props) => {
  const [uri, setUri] = useState(null);
  const [MimeType, setMimeType] = useState(null);
  const [info, setInfo] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [attachment, setAttachment] = useState();

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    if (props.match.params.uuid) {
      getAttachment(props.match.params.uuid);
    }
    // getFile(props.match.params);
  }, []);

  useEffect(() => {
    document.title = `${attachment ? attachment.FileName : 'RezRATE'} - ${
      attachment ? `${attachment.DepartmentName || ''}` : ''
    }`;
  }, [attachment]);

  const getAttachment = (uuid) => {
    setIsLoading(true);
    fetchDataAgnostic(`department/attachment/${uuid}`)
      .then((res) => {
        setAttachment(res.data);
        setMimeType(res.data.MimeType);
      })
      .catch((err) => {
        console.log('getAttachment err: ', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderImage = () => {
    return (
      <img
        src={attachment ? attachment.FileURL : ''}
        width={'100%'}
        height={'100%'}
        style={{ objectFit: 'scale-down' }}
      />
    );
  };

  const renderWithIframe = () => {
    return (
      <iframe
        src={attachment ? attachment.FileURL : ''}
        title={attachment ? attachment.FileName : ''}
        style={{ height: '100%', width: '100%' }}
      />
    );
  };

  const renderWithDownloadButton = () => {
    return (
      <Button
        style={{ width: 250, height: 280 }}
        disabled={!attachment}
        color="primary"
        onClick={() => {
          document.getElementById(`fileDownload`).click();
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faDownload} style={{ marginBottom: 10, fontSize: 200 }} />
          <div>Download {attachment ? `${attachment.FileName}` : ''}</div>
        </div>
      </Button>
    );
  };

  const renderUnknown = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: '70vh',
          width: '100%',
          backgroundColor: '#d6d6d6',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'center',
          fontWeight: 'bold',
        }}
      >
        {' '}
        {isLoading ? (
          <Spinner color="primary" size="sm" style={{ marginRight: 10 }} />
        ) : (
          'This attachment is invalid or an unsupported file type.'
        )}
      </div>
    );
  };
  const processRenderer = () => {
    switch (MimeType) {
      case 'image/png':
      case 'image/jpeg':
        return renderImage();

      case 'application/pdf':
        return renderWithIframe();
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/msword':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return renderWithDownloadButton();
      default:
        return renderUnknown();
    }
  };

  const renderTopBar = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          minHeight: 80,
          padding: 5,
          paddingLeft: 30,
          paddingRight: 30,
          paddingBottom: 10,
          paddingTop: 10,
          marginBottom: 30,
          borderBottom: '1px solid grey',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <img
              src={attachment ? attachment.DepartmentLogoURL : logoPlaceHolder}
              width={70}
              style={{ marginRight: 10, objectFit: 'scale-down' }}
            />
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ fontSize: 30, fontWeight: 'bold' }}>
                {`${attachment ? attachment.DepartmentName : 'RezRATE'}`}
              </div>
              <div style={{ fontSize: 15 }}>
                <span style={{}}>{attachment ? attachment.FileName : 'No File Name'}</span>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'right' }}>
          {attachment ? (
            <div style={{ dislay: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
              <Button
                disabled={!attachment}
                color="primary"
                onClick={() => {
                  document.getElementById(`fileDownload`).click();
                }}
              >
                Download
              </Button>

              <a
                id="fileDownload"
                style={{ visibility: 'collapse' }}
                href={attachment ? attachment.FileURL : ''}
                target="_blank"
                download={`${attachment ? attachment.DepartmentName : ''}_${attachment ? attachment.DisplayName : ''}`}
              >
                Download
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {renderTopBar()}
      <div style={{ height: 'calc(100vh - 43px)' }}>
        {/* {correctViewer()} */}
        {/* <div style={{ fontWeight: 'bold', justifyContent: 'center', textAlign: 'center', width: '30%' }}>Documents</div> */}
        <div style={{ display: 'flex', flexDirection: 'row', height: '80vh' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              paddingRight: 10,
              paddingLeft: 10,
              maxHeight: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {attachment ? (
              processRenderer()
            ) : isLoading ? (
              <Spinner color="primary" size="sm" style={{ marginRight: 10 }} />
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '70vh',
                  width: '100%',
                  backgroundColor: '#d6d6d6',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  fontWeight: 'bold',
                }}
              >
                This attachment is invalid or no longer available.
              </div>
            )}
            {/* {attachment ? (
              attachment.MimeType.indexOf('image') >= 0 ? (
                <img src={attachment.FileURL} width={'100%'} height={'100%'} style={{ objectFit: 'scale-down' }} />
              ) : (
                <iframe
                  src={attachment.FileURL}
                  title={attachment.FileName}
                  style={{ height: '100%', width: '100%' }}
                />
              )
            ) : (
              // )
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '70vh',
                  width: '100%',
                  backgroundColor: '#d6d6d6',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                {isLoading ? (
                  <Spinner color="primary" size="sm" style={{ marginRight: 10 }} />
                ) : (
                  'This attachment is invalid or no longer available.'
                )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentViewer;
