import React, { useEffect, useState, Fragment } from 'react';
import { fetchDataAgnostic } from '../../../../Services/dataApi';
import { formatBearerToken } from '../../../../Common.functions';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import Style from '../../EntityWrapper.style';
import DepartmentListItem from './DepartmentListItem';
import { Button, Input, InputGroup, InputGroupAddon, Table } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';

const DepartmentList = ({
  setSelectedDepartment,
  selectedOrganization,
  selectedDepartment,
  organizations,
  departments,
  setDepartments,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [newDepartmentPopup, setNewDepartmentPopup] = useState(false);
  const [organizationName, setOrganizationName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [filterBy, setFilterBy] = useState('');
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  let typingTimeout = null;
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const selectedOrgObject = organizations.find((org) => org.pk_Organization === selectedOrganization);

    if (selectedOrgObject) {
      setOrganizationName(selectedOrgObject.OrganizationName);
    }
  }, [selectedOrganization, organizations]);

  useEffect(() => {
    if (!filterBy) {
      setFilteredDepartments(departments);
    } else {
      setFilteredDepartments(
        departments.filter((department) => {
          console.log(department.DepartmentName.toLowerCase(), ' vs ', filterBy.toLowerCase());
          return department.DepartmentName.toLowerCase().includes(filterBy.toLowerCase());
        }),
      );
    }
  }, [filterBy, departments]);

  useEffect(() => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'organization/departments',
          { pk_Organization: selectedOrganization },
          formatBearerToken(token),
        )
          .then((result) => {
            setDepartments(result.data);
          })
          .catch((err) => {})
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  }, [selectedOrganization]);

  const changeDepartment = (e, pk_Department) => {
    e.preventDefault();
    if (selectedDepartment === pk_Department) {
      setSelectedDepartment(null);
    } else {
      setSelectedDepartment(pk_Department);
    }
  };

  const renderDepartmentHeader = () => {
    if (selectedOrganization === null) {
      return 'Departments';
    }
    if (organizationName && organizationName.trim() !== '') {
      return organizationName;
    } else {
      return '-';
    }
  };
  return (
    <div className="full_height">
      <div style={Style.headerWrapper}>{renderDepartmentHeader()}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 5,
          paddingBottom: 5,
          paddingRight: 2,
          paddingLeft: 2,
        }}
      >
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button color="secondary" style={{ cursor: 'normal' }}>
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </InputGroupAddon>
          <Input
            // value={filterBy}
            id={`searchDepartments`}
            type="text"
            placeholder="Search Departments. . ."
            onChange={(e) => {
              clearTimeout(typingTimeout);
              const newValue = e.target.value;
              typingTimeout = setTimeout(() => {
                setFilterBy(newValue);
              }, 300);
            }}
          />
          <InputGroupAddon addonType="append">
            <Button
              color="secondary"
              onClick={() => {
                setFilterBy('');
                document.getElementById('searchDepartments').value = '';
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      {!isLoading && (
        <Fragment>
          <div className="overflowy_scroll" style={{ ...Style.scroll, height: 'calc(100% - 95px)' }}>
            <Table className="admin_table">
              <tbody>
                {filteredDepartments
                  .sort((a, b) => {
                    const stringA = a.DepartmentName ? a.DepartmentName.toLowerCase() : '';
                    const stringB = b.DepartmentName ? b.DepartmentName.toLowerCase() : '';

                    if (stringA < stringB) {
                      return -1;
                    }
                    if (stringA > stringB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((department, i) => {
                    return (
                      <tr
                        className={
                          'hover_blue' + (selectedDepartment === department.pk_Department ? ' blue_selected' : '')
                        }
                        onClick={(e) => {
                          changeDepartment(e, department.pk_Department);
                        }}
                      >
                        <DepartmentListItem
                          key={department.pk_Department}
                          {...department}
                          setSelectedDepartment={setSelectedDepartment}
                          selectedDepartment={selectedDepartment}
                        />
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default DepartmentList;
