import React, { useContext, useEffect, useRef, useState } from 'react';

import style from '../style';
import { fetchDataAgnostic } from 'Services/dataApi';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { DepartmentContext } from 'DepartmentWrapper';
import { formatBearerToken } from 'Common.functions';
const TagFilterPopover = ({ tagFilter, setTagFilter, setShowTagFilter }) => {
  const [tags, setTags] = useState([{ pk_Tag: null, Tag: 'All' }]);
  const [isLoading, setIsLoading] = useState(false);
  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/tags',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setTags([{ pk_Tag: null, Tag: 'All' }, ...res.data]);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  return (
    <div style={{ ...style.simpleRow, overflowY: 'scroll', height: 340 }}>
      <div style={{ ...style.simpleColumn, width: '100%' }}>
        {tags && tags.length > 1 ? (
          tags
            .sort((a, b) => {
              return a.Tag > b.Tag ? 1 : a.Tag < b.Tag ? -1 : 0;
            })
            .map((tag, i) => {
              const backgroundColor = i % 2 == 0 ? 'white' : '#f2f2f2';

              return (
                <div
                  style={{
                    ...style.simpleRow,
                    backgroundColor:
                      (tagFilter && tagFilter.pk_Tag == tag.pk_Tag) || (tag.pk_Tag == null && tagFilter == null)
                        ? '#caecfa'
                        : backgroundColor,
                    paddingTop: 5,
                    paddingBottom: 5,
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setTagFilter(tag);
                    setShowTagFilter(false);
                  }}
                >
                  {tag.Tag}
                </div>
              );
            })
        ) : isLoading ? (
          <div
            style={{
              ...style.simpleRow,

              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            {' '}
            Loading...
          </div>
        ) : (
          <div
            style={{
              ...style.simpleRow,

              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            No tags found
          </div>
        )}
      </div>
    </div>
  );

  //   return (
  //     <Popover
  //       trigger="legacy"
  //       target="tags_popover"
  //       placementPrefix={'tagFilter bs-popover'}
  //       isOpen={showTagFilter}
  //       toggle={() => setShowTagFilter(!showTagFilter)}
  //     >
  //       <PopoverHeader>
  //         {' '}
  //         <div style={style.spacedBetweenRow}>
  //           <div style={style.buttonLabel}>Tag Filter</div>
  //           <div>
  //             <FontAwesomeIcon
  //               icon={faTimesCircle}
  //               style={style.clickable}
  //               onClick={() => {
  //                 setShowTagFilter(false);
  //               }}
  //             />
  //           </div>
  //         </div>
  //       </PopoverHeader>
  //       <PopoverBody style={{ height: 200 }}>
  //         <div style={style.simpleRow}>
  //           <div style={style.simpleColumn}>
  //             {tags.map((tag, i) => {
  //               const backgroundColor = i % 2 == 0 ? 'white' : '#f2f2f2';

  //               return (
  //                 <div
  //                   style={{
  //                     ...style.simpleRow,
  //                     backgroundColor:
  //                       (tagFilter && tagFilter.pk_Tag == tag.pk_Tag) || (tag.pk_Tag == null && tagFilter == null)
  //                         ? '#caecfa'
  //                         : backgroundColor,
  //                     paddingTop: 5,
  //                     paddingBottom: 5,
  //                     justifyContent: 'center',
  //                     alignItems: 'center',
  //                     cursor: 'pointer',
  //                   }}
  //                   onClick={() => {
  //                     setTagFilter(tag);
  //                     setShowTagFilter(false);
  //                   }}
  //                 >
  //                   {tag.Tag}
  //                 </div>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </PopoverBody>
  //     </Popover>
  //   );
};

export default TagFilterPopover;
