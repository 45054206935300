import {
  faCheckSquare,
  faFilter,
  faSearch,
  faSquare,
  faTag,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Input, InputGroup, InputGroupAddon, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { clone } from '../../../../Services/schedule';

import CandidateListItem from './CandidateListItem';
import FilterPopover from './FilterPopover';
import style from './style.js';

import './CandidateList.style.css';
import { CandidateStatusIcons } from 'enums';
import TagFilterPopover from '../AdminCandidates/TagFilterPopover/TagFilterPopover';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

let typeTimeout = null;

const CandidateList = ({
  candidates,
  dateFromURL,
  discardChanges,
  forcedSelectedCandidateId,
  hasChanges,
  height,
  interviewDates,
  isGettingCandidateDetails,
  multiselect,
  selectedCandidate,
  setForcedSelectedCandidateId,
  setParentFilteredCandidates,
  setSelectedCandidate,
  showTagFilter,
  uniqueId,
  updateParentValues,
  useUnsavedChangesAlert,
  setResetList,
}) => {
  const [filterBy, setFilterBy] = useState('All by Interview Date');
  const [searchString, setSearchString] = useState('');
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [filteredCandidates, setFilteredCandidates] = useState([]);
  const [tagFilter, setTagFilter] = useState({ Tag: 'All', pk_Tag: null });
  const [showTagFilterPopover, setShowTagFilterPopover] = useState(false);

  const [selectedCandidateIds, setSelectedCandidateIds] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [urlInterviewDate, setUrlInterviewDate] = useState();

  const history = useHistory();
  const { pathname } = useLocation();
  const query = new URLSearchParams(useLocation().search);
  let searchBar = useRef();

  const {
    markedForInvite,
    pendingInvite,
    invited,
    waitlisted,
    confirmed,
    unconfirmed,
    rejected,
    declined,
    scheduled,
    unscheduled,
    unresponsive,
    notSentSchedule,
  } = CandidateStatusIcons;

  const toggleCandidateSelection = (pk_Candidate) => {
    const newSelectedCandidateIds = clone(selectedCandidateIds);
    if (newSelectedCandidateIds[pk_Candidate] == null) {
      newSelectedCandidateIds[pk_Candidate] = true;
    } else {
      newSelectedCandidateIds[pk_Candidate] = !newSelectedCandidateIds[pk_Candidate];
    }

    setSelectedCandidateIds(newSelectedCandidateIds);
    // setSelectedCandidateIds((prevState) => {
    //   // make a copy to prevent state mutation
    //   const copy = Object.assign({}, prevState);

    //   // If the entry hasn't been added just add it
    //   if (!copy[pk_Candidate]) {
    //     copy[pk_Candidate] = true;
    //     return copy;
    //   }

    //   // if it has been added, toggle it's boolean

    //   copy[pk_Candidate] = !copy[pk_Candidate];
    //   return copy;
    // });
  };

  useEffect(() => {
    searchBar.current = document.getElementById('candidateListSearchBar');
    if (setResetList) {
      setResetList(resetList);
    }

    const newFilterBy = query.get('filterBy');
    if (newFilterBy) {
      setFilterBy(newFilterBy ? newFilterBy : 'All by Interview Date');
      filterByButton('', newFilterBy ? newFilterBy : 'All by Interview Date');
    }
  }, []);

  useEffect(() => {
    if (dateFromURL) {
      // setFilterBy(dateFromURL);
      filterByButton('', dateFromURL);
    }
  }, [dateFromURL]);

  useEffect(() => {
    if (candidates) {
      filterByButton('', '', forcedSelectedCandidateId, {
        updateSelectedItem: forcedSelectedCandidateId || selectedCandidate == null,
      });
    }
  }, [forcedSelectedCandidateId]);

  useEffect(() => {
    if (candidates) {
      filterByButton('', '', forcedSelectedCandidateId, {
        updateSelectedItem: forcedSelectedCandidateId || selectedCandidate == null,
      });
    }
    // setShowFilterPopover(true);
  }, [tagFilter]);

  useEffect(() => {
    if (selectAll) {
      const newSelectedCandidateIDs = {};
      filteredCandidates.forEach((c) => {
        newSelectedCandidateIDs[c.pk_Candidate] = true;
      });
      setSelectedCandidateIds(newSelectedCandidateIDs);
    } else {
      setSelectedCandidateIds({});
    }
  }, [selectAll]);

  useEffect(() => {
    if (selectedCandidateIds && updateParentValues) {
      updateParentValues(clone(selectedCandidateIds));
    }
  }, [selectedCandidateIds]);

  const resetList = () => {
    const newFilterBy = query.get('filterBy');
    setSelectedCandidateIds({});
    setSelectedCandidate(null);

    setTagFilter({ Tag: 'All', pk_Tag: null });
    setFilterBy(newFilterBy ? newFilterBy : 'All by Interview Date');
    filterByButton('', newFilterBy ? newFilterBy : 'All by Interview Date');
  };

  const onFilterBy = (filterString) => {
    setSelectedCandidateIds({});
    setSelectedCandidate(null);
    setFilterBy(filterString);
    setShowFilterPopover(false);

    setSelectAll(false);
    filterByButton('', filterString);
  };

  const filterBySearch = (searchString = '', isInline) => {
    if (candidates == null) {
      if (isInline) {
        return [];
      } else {
        setFilteredCandidates([]);
        return;
      }
    }
    const newFilteredCandidates = Object.values(candidates).filter((c) => {
      if (c == null) {
        return;
      }

      let {
        NRMPID,
        AAMCID,
        SFMatchID,
        MedicalSchoolOfGraduation,
        PreferredName = '',
        FirstName = '',
        MiddleName = '',
        LastName = '',
      } = c;
      NRMPID = NRMPID || '';
      AAMCID = AAMCID || '';
      SFMatchID = SFMatchID || '';
      MedicalSchoolOfGraduation = MedicalSchoolOfGraduation || '';
      PreferredName = PreferredName || '';

      const name = `${FirstName} ${MiddleName} ${LastName}`;
      const formattedName = `${LastName || ''}, ${FirstName || ''}`;
      const email = c.Email || '';
      const lowerCaseSearchString = searchString.toLowerCase();
      const accentlessName = name
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');

      const accentlessPreferredName = PreferredName.toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '');

      let matchesTag = false;
      const { Tags = [] } = c || {};
      Tags.forEach((tag) => {
        if (tag.Tag && tag.Tag.toLowerCase().indexOf(lowerCaseSearchString) >= 0) {
          matchesTag = true;
        }
      });

      // Debugger. Don't delete
      // console.log(
      //   'name: ',
      //   name,
      //   '\nemail: ',
      //   email,
      //   '\nNRMPID: ',
      //   NRMPID,
      //   '\nAAMCID: ',
      //   AAMCID,
      //   '\nSFMatchID: ',
      //   SFMatchID,
      //   '\nMedicalSchoolOfGraduation: ',
      //   MedicalSchoolOfGraduation,
      // );

      return (
        name.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        PreferredName.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        formattedName.toLocaleLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        email.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        NRMPID.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        AAMCID.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        SFMatchID.toLowerCase().indexOf(lowerCaseSearchString) >= 0 ||
        accentlessName.indexOf(lowerCaseSearchString) >= 0 ||
        accentlessPreferredName.indexOf(lowerCaseSearchString) >= 0
        // || MedicalSchoolOfGraduation.toLowerCase().indexOf(searchString.toLowerCase()) >= 0
        // || matchesTag
      );
    });

    if (isInline) {
      return newFilteredCandidates;
    } else {
      setFilterBy('All by Name');
      setFilteredCandidates(newFilteredCandidates || []);
      setSelectedCandidate(newFilteredCandidates.length > 0 ? newFilteredCandidates[0] : null);
    }
  };

  useEffect(() => {
    if (selectedCandidate) {
      scrollToItem(`candidateList_${selectedCandidate.pk_Candidate}_${uniqueId || ''}`);
    }
  }, [JSON.stringify(filteredCandidates)]);

  useEffect(() => {
    if (candidates) {
      filterByButton('', '', forcedSelectedCandidateId, {
        updateSelectedItem:
          (forcedSelectedCandidateId && forcedSelectedCandidateId == selectedCandidate.pk_Candidate) ||
          selectedCandidate == null,
      });
    }
  }, [candidates]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const pk_Candidate = searchParams.get('pk_Candidate');

    if (pk_Candidate && selectedCandidate) {
      const inputField = document.getElementById('candidateListSearchBar');
      const newSelectedCandidateIDs = {};
      newSelectedCandidateIDs[parseInt(pk_Candidate)] = true;
      history.push(pathname);
      const nameToSearch = `${selectedCandidate.LastName || ''}, ${selectedCandidate.FirstName || ''}`;
      setSelectedCandidateIds(newSelectedCandidateIDs);
      inputField.value = nameToSearch;

      setSearchString(nameToSearch);
      filterBySearch(nameToSearch);
      // setTimeout(() => {
      //   console.log('scroll string: ', `candidateList_${pk_Candidate}_${uniqueId || ''}`);
      //   scrollToItem(`candidateList_${pk_Candidate}_${uniqueId || ''}`);
      // }, 1000);
    }
  }, [pathname, selectedCandidate, candidates]);

  const processFilterButtonName = () => {
    switch (filterBy) {
      case 'All by Name':
        return `All (${filteredCandidates.length}) sorted by Name.`;

      case 'Unscheduled':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={unscheduled.icon} style={{ color: unscheduled.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`${filteredCandidates.length} Unregistered`}</div>
          </div>
        );
      case 'Scheduled':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={scheduled.icon} style={{ color: scheduled.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`${filteredCandidates.length} Scheduled`}</div>
          </div>
        );
      case 'Waitlisted':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={waitlisted.icon} style={{ color: waitlisted.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`${filteredCandidates.length} Waitlisted`}</div>
          </div>
        );
      case 'Unresponsive':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={unresponsive.icon} style={{ color: 'white' }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`${filteredCandidates.length} Unresponsive`}</div>
          </div>
        );
      case 'All by Import Date':
        return `All (${filteredCandidates.length}) sorted by Import Date`;
      case 'All by Interview Date':
        return `All (${filteredCandidates.length}) sorted by Interview Date`;
      case 'All Invited':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={invited.icon} style={{ color: invited.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`All (${filteredCandidates.length}) Invited`}</div>
          </div>
        );
      case 'All Pending Invite':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={pendingInvite.icon} style={{ color: pendingInvite.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`All (${filteredCandidates.length}) Pending Invite`}</div>
          </div>
        );
      case 'All Marked for Invite':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={markedForInvite.icon} style={{ color: markedForInvite.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`All (${filteredCandidates.length}) Marked for Invite`}</div>
          </div>
        );
      case 'Declined':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={declined.icon} style={{ color: declined.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`${filteredCandidates.length} Declined`}</div>
          </div>
        );
        return;
      case 'All Not Sent Schedule':
        return (
          <div style={{ ...style.simpleRow, marginBottom: 0 }}>
            <div style={{ ...style.simpleColumn, width: 50, alignItems: 'center', justifyContent: 'center' }}>
              <FontAwesomeIcon icon={notSentSchedule.icon} style={{ color: notSentSchedule.color }} />
            </div>
            <div
              style={{ ...style.simpleColumn, width: '100%', alignItems: 'center', justifyContent: 'center' }}
            >{`All (${filteredCandidates.length}) Not Sent Schedule`}</div>
          </div>
        );

      default:
        return `${filteredCandidates.length} registered for ${moment
          .tz(filterBy.DateOfInterview, null)
          .format('MMM DD, YYYY')} ${filterBy.Label ? `(${filterBy.Label})` : ''} `;
    }
  };

  const scrollToItem = (id) => {
    if (multiselect) {
      return;
    }
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const processButtonFilterValue = (valueToFilterBy, candidateToFilter) => {
    // we should return an object saying if it's a non-compliant candidate but is the currently selected candidate.
  };

  // TODO: Filter via explicit button press should default selectedCandidate to index 0.
  // TODO: Filter should respect forcedSelectedCandidate
  const filterByButton = (newSearchString, forcedFilter, forcedSelectedCandidate, options = {}) => {
    const { updateSelectedItem = true } = options;
    let newCandidates = filterBySearch(newSearchString, true).slice();
    let filterActual = forcedFilter || filterBy;

    let sortByalpha = true;

    let selectedCandidatePkCandidate = null;

    if (forcedSelectedCandidate) {
      selectedCandidatePkCandidate = forcedSelectedCandidate;
    }
    // else if (selectedCandidate) {
    //   selectedCandidatePkCandidate = selectedCandidate.pk_Candidate;
    // }

    if (!newSearchString && searchBar) {
      searchBar.current.value = '';
    }

    if (forcedFilter) {
      setFilterBy(forcedFilter);
    }

    // TODO: Filter by Pending invite not matching Pending Invite filter in Candidates list
    switch (filterActual) {
      case 'Scheduled':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, IsScheduled } = c;
          return IsScheduled || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });
        sortByalpha = false;
        break;
      case 'Unscheduled':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, IsScheduled } = c;
          return !IsScheduled || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });
        break;
      case 'All Invited':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, HasBeenSentInvitation } = c;
          return HasBeenSentInvitation || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });
        break;
      case 'All Pending Invite':
        newCandidates = newCandidates.filter((c) => {
          // return c.EnableMarkedForInvitation && !c.HasBeenSentInvitation;

          return !c.IsScheduled && !c.IsWaitlisted && !c.HasBeenSentInvitation && c.EnableMarkedForInvitation; // EnableMarkedForInvitation maybe.
        });
        break;

      case 'All Marked for Invite':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, EnableMarkedForInvitation } = c;
          return EnableMarkedForInvitation || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });
        break;
      case 'Declined':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, HasDeclinedInterview } = c;
          return HasDeclinedInterview || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });
        break;
      case 'Waitlisted':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, IsWaitlisted } = c;
          return IsWaitlisted || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });

        break;
      case 'Unresponsive':
        newCandidates = newCandidates.filter((c) => {
          const { pk_Candidate, IsUnresponsive, HasBeenSentInvitation, WaitlistDates = [], DateOfInterview } = c;

          return (
            (HasBeenSentInvitation &&
              ((WaitlistDates && WaitlistDates.length == 0) || !WaitlistDates) &&
              !DateOfInterview) ||
            IsUnresponsive
          );
          return IsUnresponsive || (pk_Candidate == selectedCandidatePkCandidate && !multiselect);
        });
        break;
      case 'All by Name': {
        newCandidates.sort((a, b) => {
          const textA = `${a.LastName}, ${a.FirstName}`;
          const textB = `${b.LastName}, ${b.FirstName}`;
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
        sortByalpha = false;
        break;
      }
      case 'All by Import Date':
        // Compare import dates. No import  date in candidates yet.
        newCandidates.sort((a, b) => {
          const date1 = a.createdAt ? new Date(a.createdAt) : null;
          const date2 = b.createdAt ? new Date(b.createdAt) : null;

          let sortValue = 0;
          if (date1 == null && date2 != null) {
            sortValue = 1;
          } else if (date2 == null && date1 != null) {
            sortValue = -1;
          } else if (date1 != null && date2 != null && date1 < date2) {
            sortValue = -1;
          } else if ((date1 != null && date2 != null && date1 > date2) || (date1 != null && date2 == null)) {
            sortValue = 1;
          } else if (date1 == null) {
          } else {
            const textA = `${a.LastName}, ${a.FirstName}`;
            const textB = `${b.LastName}, ${b.FirstName}`;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }
          sortByalpha = false;
          return sortValue;
        });
        break;
      case 'All by Interview Date':
        newCandidates
          .sort((a, b) => {
            const aString = `${a.LastName}, ${a.FirstName}`;
            const bString = `${b.LastName}, ${b.FirstName} `;

            if (aString.toLowerCase() < bString.toLowerCase()) {
              return -1;
            }
            if (aString.toLowerCase() > bString.toLowerCase()) {
              return 1;
            }
            return 0;
          })
          .sort((a, b) => {
            const isWaitlisted1 = a.IsWaitlisted;
            const isWaitlisted2 = b.IsWaitlisted;

            if (isWaitlisted1 && !isWaitlisted2) {
              return -1;
            } else if (!isWaitlisted1 && isWaitlisted2) {
              return 1;
            } else {
              return 0;
            }
          })
          .sort((a, b) => {
            const date1 = a.DateOfInterview ? new Date(a.DateOfInterview) : null;
            const date2 = b.DateOfInterview ? new Date(b.DateOfInterview) : null;

            let sortValue = 0;
            if (date1 == null && date2 != null) {
              sortValue = 1;
            } else if (date2 == null && date1 != null) {
              sortValue = -1;
            } else if (date1 != null && date2 != null && date1 < date2) {
              sortValue = -1;
            } else if ((date1 != null && date2 != null && date1 > date2) || (date1 != null && date2 == null)) {
              sortValue = 1;
            } else if (date1 == null) {
            }
            return sortValue;
          });
        sortByalpha = false;
        break;
      case 'All Not Sent Schedule':
        newCandidates = newCandidates
          .filter((c) => {
            const { pk_Candidate, HasBeenSentSchedule, IsScheduled } = c;
            return (
              (IsScheduled && !HasBeenSentSchedule) || (pk_Candidate == selectedCandidatePkCandidate && !multiselect)
            );
          })
          .sort((a, b) => {
            const textA = `${a.LastName}, ${a.FirstName}`;
            const textB = `${b.LastName}, ${b.FirstName}`;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        sortByalpha = false;
        break;
      default:
        newCandidates = newCandidates
          .filter((c) => {
            return c.pk_InterviewDate == filterActual.pk_InterviewDate;
            // return c.DateOfInterview && moment(c.DateOfInterview).format() == moment(filterBy).format();
          })
          .sort((a, b) => {
            const aString = `${a.LastName} ${a.FirstName}`;
            const bString = `${b.LastName} ${b.FirstName} `;

            if (aString < bString) {
              return -1;
            }
            if (aString > bString) {
              return 1;
            }
            return 0;
          });
        sortByalpha = false;
        // setFilteredCandidates(newFilteredCandidates);
        // setSelectedCandidate(newFilteredCandidates.length >= 0 ? newFilteredCandidates[0] : null);
        // return;
        break;
    }

    // Remove isRemoved = true. Do not display.
    newCandidates = newCandidates.filter((c) => {
      return !c.isRemoved;
    });

    // Filter by tag
    newCandidates = newCandidates.filter((c) => {
      if (tagFilter.pk_Tag == null) {
        return true;
      }

      const { Tags = [], pk_Candidate } = c || {};

      if (pk_Candidate == selectedCandidatePkCandidate && !multiselect) {
        return true;
      }
      const existsIntags = Tags.find((tag) => {
        return tag.pk_Tag != null && tag.pk_Tag == tagFilter.pk_Tag;
      });

      return existsIntags != null;
    });

    const id = query.get('pk_Candidate');
    let indexToSelect = 0;

    if (selectedCandidatePkCandidate) {
      const selectedCandidateIndex = newCandidates.findIndex((item) => {
        return item.pk_Candidate == selectedCandidatePkCandidate;
      });
      if (selectedCandidateIndex > 0) {
        indexToSelect = selectedCandidateIndex;
      }
    } else if (id) {
      const selectedCandidateIndex = newCandidates.findIndex((item) => {
        return item.pk_Candidate == id;
      });
      if (selectedCandidateIndex > 0) {
        indexToSelect = selectedCandidateIndex;
      }
    }
    const valueToReturn = newCandidates.length >= 0 ? newCandidates[indexToSelect] : null;

    const selectedCandidateStillExists = newCandidates.find((c) => {
      return c.pk_Candidate == selectedCandidatePkCandidate;
    });

    if (
      updateSelectedItem &&
      ((!selectedCandidateStillExists && valueToReturn) || (selectedCandidate && !valueToReturn))
    ) {
      setSelectedCandidate(valueToReturn);
    }

    if (sortByalpha) {
      newCandidates.sort((a, b) => {
        const textA = `${a.LastName}, ${a.FirstName}`;
        const textB = `${b.LastName}, ${b.FirstName}`;
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    }

    setFilteredCandidates(newCandidates);
  };

  const renderSearchInput = () => {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Button
            onClick={() => {
              setFilterBy('All by Interview Date');
              setTagFilter({ Tag: 'All', pk_Tag: null });
              // setSearchString('');
              filterByButton('', 'All by Interview Date');
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroupAddon>
        <Input
          // value={searchString}
          id="candidateListSearchBar"
          type="text"
          placeholder="Search Candidate. . ."
          onChange={(e) => {
            const newString = e.target.value;
            clearTimeout(typeTimeout);
            typeTimeout = setTimeout(() => {
              // setFilterBy('All by Interview Date');
              setSearchString(newString);
              if (setForcedSelectedCandidateId) {
                setForcedSelectedCandidateId(null);
              }

              filterByButton(newString);
            }, 500);
          }}
        />
        <InputGroupAddon addonType="append">
          <Button color="success">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  };

  const renderFilterButton = () => {
    return (
      <>
        <Button id="filter_button" style={style.filterButton}>
          <div style={style.spacedBetweenRow}>
            <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
              {processFilterButtonName()}
            </div>
            <div style={{ ...style.simpleColumn, width: 50, justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faFilter} />
            </div>
          </div>
        </Button>

        <Popover
          flip={false} //
          placementPrefix="filterPopover bs-popover"
          isOpen={showFilterPopover}
          trigger="legacy"
          target="filter_button"
          modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          toggle={() => {
            setShowFilterPopover(!showFilterPopover);
          }}
        >
          <PopoverHeader>
            <div style={style.spacedBetweenRow}>
              <div>Filter/Sort</div>
              <div>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={style.clickable}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowFilterPopover(false);
                  }}
                />
              </div>
            </div>
          </PopoverHeader>
          <PopoverBody>
            <FilterPopover
              setShowFilterPopover={setShowFilterPopover}
              setFilterBy={(filterString) => {
                onFilterBy(filterString);
              }}
              filterBy={filterBy}
              interviewDates={interviewDates}
              urlInterviewDate={urlInterviewDate}
            />
          </PopoverBody>
        </Popover>
      </>
    );
  };

  const renderFilterByTagButton = () => {
    return (
      <Button id="tags_popover" style={style.filterButton}>
        <div style={style.spacedBetweenRow}>
          <div style={{ ...style.simpleColumn, marginRight: 10, justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faTag} />
          </div>
          <div style={{ ...style.simpleColumn, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {tagFilter.pk_Tag ? tagFilter.Tag : 'All'}
          </div>
          <div style={{ ...style.simpleColumn, width: 50, justifyContent: 'center', alignItems: 'center' }}>
            <FontAwesomeIcon icon={faFilter} />
          </div>
        </div>
        {renderTagFilterPopover()}
      </Button>
    );
  };

  const renderTagFilterPopover = () => {
    return (
      <Popover
        flip={false}
        trigger="legacy"
        target="tags_popover"
        placementPrefix={'tagFilter bs-popover'}
        modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        isOpen={showTagFilterPopover}
        toggle={() => setShowTagFilterPopover(!showTagFilterPopover)}
      >
        <PopoverHeader>
          {' '}
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Tag Filter</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowTagFilterPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={{ height: 200 }}>
          <TagFilterPopover
            tagFilter={tagFilter}
            setTagFilter={(newTagFilter) => {
              setSelectedCandidateIds({});
              setSelectedCandidate(null);

              setSelectAll(false);
              setTagFilter(newTagFilter);
            }}
            setShowTagFilter={setShowTagFilterPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  let selectedCandidatesCount = 0;
  Object.values(selectedCandidateIds).forEach((value) => {
    if (value) {
      selectedCandidatesCount++;
    }
  });

  return (
    <>
      <div>
        <div style={style.simpleRow}>{renderSearchInput()}</div>
        <div style={{ ...style.simpleRow }}>{renderFilterButton()}</div>
        {showTagFilter ? <div style={{ ...style.simpleRow }}>{renderFilterByTagButton()}</div> : null}
        {multiselect ? (
          <div style={{ ...style.spacedBetweenRow, margin: 15, fontSize: 14, fontWeight: 'bold', paddingRight: 15 }}>
            <div style={{ ...style.simpleColumn, width: 110 }}>
              <div style={style.simpleRow}>
                <FontAwesomeIcon
                  icon={selectAll ? faCheckSquare : faSquare}
                  style={{ fontSize: 20, cursor: 'pointer' }}
                  onClick={() => {
                    setSelectAll(!selectAll);
                  }}
                />
                <div style={{ marginLeft: 20 }}>Select All</div>
              </div>
            </div>
            <div style={{ ...style.simpleColumn, width: 'auto', textAlign: 'right' }}>
              {selectedCandidatesCount || 0} / {filteredCandidates ? filteredCandidates.length : 0}
            </div>
          </div>
        ) : null}
      </div>
      <div style={{ position: 'sticky', overflowY: 'scroll' }}>
        <div style={{ marginBottom: 10 }}>
          {Object.values(filteredCandidates).map((candidate, i) => {
            const { pk_Candidate, PhotoUrl, LastName, FirstName, Email } = candidate;

            return (
              <CandidateListItem
                uniqueId={uniqueId}
                useUnsavedChangesAlert={useUnsavedChangesAlert}
                hasChanges={hasChanges}
                discardChanges={discardChanges}
                multiselect={multiselect}
                selectedCandidateIds={selectedCandidateIds}
                candidate={candidate}
                selectedCandidate={selectedCandidate}
                setSelectedCandidate={setSelectedCandidate}
                toggleCandidateSelection={toggleCandidateSelection}
                index={i}
                filterBy={filterBy}
                isGettingCandidateDetails={isGettingCandidateDetails}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CandidateList;
