import moment from 'moment';
import React, { useContext, useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, swalErrorMessage } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchDataAgnostic, postDataAgnostic } from '../../../../Services/dataApi';

import { Button } from 'reactstrap';

import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark, faDownload, faUndoAlt, faPortrait } from '@fortawesome/free-solid-svg-icons';

import './ImagePlaceholder.style.css';
const AdminRankHistory = ({
  candidates,
  addCandidateToRank,
  getActiveCandidates,
  selectedTagFilter = {},
  postChange,
  candidateRanks,
  orderRanksByFinalScore,
  mode,
  determineMode,
  setOpenRankHistory,
  setSelectedUser,
}) => {
  const [rankHistory, setRankHistory] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  // const csvDownload = useRef();

  const [token, setToken] = useState();

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);

  const restoreRankList = (pk_RankHistory) => {
    const { pk_Tag } = selectedTagFilter;
    return getTokenSilently()
      .then((token) => {
        return postDataAgnostic(
          'department/season/rankHistory/restore',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_RankHistory,
            pk_Tag,
            mode,
          },
          null,
          formatBearerToken(token),
        )
          .then((result) => {
            setSelectedUser();
            getActiveCandidates(mode === 2 ? 'prescreen' : 'evaluation', {
              callback: () => {
                console.log('getActiveCandidates callback');
                setOpenRankHistory(false);
              },
            });
            // getRankHistory();
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleNameChange = (customName, pk_RankHistory) => {
    return getTokenSilently()
      .then((token) => {
        return postDataAgnostic(
          'department/season/rankHistory/name',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season, pk_RankHistory },
          { customName },
          formatBearerToken(token),
        )
          .then((result) => {
            //stitch name into the rank history data
            setRankHistory((prevState) => {
              // eslint-disable-next-line
              let clone = structuredClone(prevState);
              clone.forEach((item) => {
                if (item.pk_RankHistory === pk_RankHistory) {
                  item.CustomName = customName;
                }
              });
              return clone;
            });
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleRevertClick = (pk_RankHistory) => {
    const message = 'Do you want to Name or Restore this checkpoint?';

    Swal.fire({
      title: message,
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: 'Restore',
      denyButtonText: `Name`,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        restoreRankList(pk_RankHistory);
      } else if (result.isDenied) {
        Swal.fire({
          title: 'What would you like to name this version?',
          input: 'text',
          inputAttributes: {
            input: 'text',
            required: 'true',
          },
          inputPlaceholder: 'Enter Name',
          showCancelButton: true,
        }).then(async (result) => {
          const nameChangeResult = await handleNameChange(result.value, pk_RankHistory);
        });
      } else {
      }
    });
  };

  useEffect(() => {
    // getRankHistory();
  }, []);

  useEffect(() => {
    getRankHistory();
  }, [candidateRanks]);

  useEffect(() => {
    if (candidates) {
      processCSVData(candidates);
    }
  }, [candidates]);

  const processCSVData = (newCandidates) => {
    // AAMCID
    // Email
    // SFMatchID
    // NRMPID
    // CellPhone
    // PreferredPhone
    // Tags
    // ProgramSignal
    // InterviewDate
    // InterviewLabel(Coming back from API as “Label” here already)
    // Final Score (Remember to multiply by 100 and round to 2 decimal places)
    // Note that we have a “CandidateSubHeading” field that contains Medical School of Graduation if it’s a Residency or contains “Last Residency” if it’s a fellowship. so the heading needs to be dynamic based on which program type is in settings and we’ll display that heading for this column.
    // RankPositionBasedOnScore
    // DNR Count (count of how many people marked as DNR – should be an int 0 if none)

    const { department } = dContext;
    const { ProgramType } = department;
    console.log('ProgramType', ProgramType);
    const dataOrder = [
      { label: 'Rank', value: 'EvalPosition' },
      { label: 'AAMC ID', value: 'AAMCID' },
      { label: 'First Name', value: 'FirstName' },
      { label: 'Last Name', value: 'LastName' },
      { label: 'Email Address', value: 'Email' },
      { label: 'SFMatch ID', value: 'SFMatchID' },
      {
        label: ProgramType === 'Residency' ? 'Medical School of Graduation' : 'Last Residency',
        value: 'CandidateSubHeading',
      },
      { label: 'Tags', value: 'Tags' },
      { label: 'Program Signal', value: 'ProgramSignal' },
      { label: 'Interview Date', value: 'DateOfInterview' },
      { label: 'Interview Label', value: 'Label' },
      { label: 'Final Score', value: 'CandidateFinalScoreAsDecimal' },
      { label: 'Candidate Sub Heading', value: 'CandidateSubHeading' },
      { label: 'Rank Position Based On Score', value: 'RankPositionBasedOnScore' },
      { label: 'DNR Count', value: 'DNR' },
    ];

    const newCSVData = [
      dataOrder.map((item) => {
        return item.label;
      }),
    ];

    newCandidates.forEach((candidate) => {
      if (candidate.EnableRemoveFromList) {
        return;
      }

      // const {
      //   EvalPosition,
      //   AAMCID,
      //   FirstName,
      //   LastName,
      //   Email,
      //   SFMatchID,
      //   MedicalSchoolOfGraduation,
      //   CellPhone,
      //   PreferredPhone,
      //   Tags,
      //   ProgramSignal,
      //   InterviewDate,
      //   Label,
      //   FinalScore,
      //   CandidateSubHeading,
      //   RankPositionBasedOnScore,
      //   DNR,
      // } = candidate;
      // const newRow = [
      //   `${EvalPosition ? `${EvalPosition},` : ''}`,
      //   AAMCID,
      //   FirstName,
      //   LastName,
      //   Email,
      //   SFMatchID,
      //   MedicalSchoolOfGraduation,
      // ];

      const newRow = dataOrder.map((item) => {
        if (item.value === 'Tags') {
          return candidate[item.value].join('\n');
        }
        return candidate[item.value] || '';
      });
      newCSVData.push(newRow);
    });

    setCsvData(newCSVData);
  };

  const getRankHistory = () => {
    const { pk_Tag } = selectedTagFilter || {};

    setIsLoadingHistory(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/rankHistoryDescription',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Tag: pk_Tag,
            mode: determineMode(mode),
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setRankHistory(result.data);
          })
          .catch((err) => {
            swalErrorMessage();
          })
          .finally(() => {
            setIsLoadingHistory(false);
          });
      })
      .catch((err) => {
        swalErrorMessage();
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const resetRankHistory = () => {
    // eslint-disable-next-line
    let clone = structuredClone(candidateRanks);
    clone = orderRanksByFinalScore(clone);
    postChange(clone, candidateRanks, 'Reset rank by final scores.', null, null, null, (newCandidates) => {
      setSelectedUser(null);
      setOpenRankHistory(false);
    });
  };

  return (
    <div className="card text-center">
      <div className="card-header p-2">
        <h4 className="m-0">Rank Modification History</h4>
      </div>
      <div className="card-body" style={{ padding: '0px' }}>
        <div style={{ height: '70vh', overflowY: 'scroll' }}>
          <table className="table table-striped m-0">
            {rankHistory && rankHistory.length ? (
              rankHistory.map((rankChange) => {
                const { Label, createdAt, PhotoUrl, pk_RankHistory, CustomName } = rankChange;
                const isRestored = Label && Label.indexOf('Restored') >= 0;

                return (
                  <tr
                    className="hover_blue"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRevertClick(pk_RankHistory);
                    }}
                  >
                    <td className="text-center" style={{ verticalAlign: 'middle' }}>
                      <div style={{ width: 80, height: 80, position: 'relative' }}>
                        {PhotoUrl && (
                          <img
                            className="rankImage"
                            // className="rounded-circle"
                            alt="Candidate Headshot"
                            style={{ width: '80px', height: '80px' }}
                            src={PhotoUrl}
                            data-holder-rendered="true"
                          />
                        )}
                        {!PhotoUrl && (
                          <div>
                            <FontAwesomeIcon icon={isRestored ? faUndoAlt : faPortrait} size="5x" />
                          </div>
                        )}
                      </div>
                    </td>
                    <td className="text-left" style={{ verticalAlign: 'middle' }}>
                      <h6>{moment(createdAt).format('LLL')}</h6>
                      {CustomName && CustomName.trim() !== '' && <p>{CustomName}</p>}
                      <p style={{ margin: '0px', fontSize: '10pt', fontWeight: 'normal' }}>{Label}</p>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center" style={{ verticalAlign: 'middle' }}>
                  {isLoadingHistory ? 'Loading . . .' : 'No History'}
                </td>
              </tr>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminRankHistory;

// delete scores tab and just have rnak list
