import {
  faExclamationTriangle,
  faEyeSlash,
  faFile,
  faFileImage,
  faFilePdf,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../Common.functions';
import { fetchDataAgnosticWithoutCredentials } from '../../Services/dataApi';

const ROOT_LOGO_URL = 'https://rezratefiles.blob.core.windows.net/logos/';
const WIDTH_DIFFERENCE = 10;

const viewWidth = `100%`;
const BOX_WIDTH = 'calc(100% - 20px)';
const height = '100px';

// function contactFilemaker() {
//   /*eslint-disable no-undef*/
//   FileMaker.PerformScript('FMDocumentView', file);
//   /*eslint-enable no-undef*/
// }

// const iconStyle = {
//   height: '100%',
//   width: '40px',
//   display: 'flex',
//   justifyContent: 'center',
//   alignItems: 'center',
// };

const SingleCandidateDragAndDropItem = ({
  item,
  attachedDoc,
  setFiles,
  setSelectedDocumentType,
  urlObject,
  initiateFileUpload,
  isCandidateDocuments,
  getCandidateDetails,
  selectedCandidate,
  uploadLogo,
  currentLogo,
  deleteFile,
  candidateFiles,
  postDataAgnostic,
  confirmDelete,
  setConfirmDelete,
  iconStyle,
}) => {
  const [preventClick, setPreventClick] = useState(false);
  const [validDepartment, setValidDepartment] = useState(null);
  const [confirmReplace, setConfirmReplace] = useState(false);
  const [chooseViewOrReplace, setChooseViewOrReplace] = useState(false);
  const [pendingFile, setPendingFile] = useState(null);
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const [file, setFile] = useState({});

  const history = useHistory();
  const { pk_DocumentType } = item;
  useEffect(() => {
    if (uploadLogo) {
      const validateDepartment = async () => {
        const { pk_UUID_FM, department, organization, sessionID, tempToken } = urlObject;
        const validDepartment = fetchDataAgnosticWithoutCredentials('organization/department', urlObject)
          .then((res) => {
            if (res && res.data && res.data.pk_Department) {
              setValidDepartment(true);
            } else {
              setValidDepartment(false);
            }
          })
          .catch((err) => {});
      };
      validateDepartment();
    }
  }, [uploadLogo]);

  const BOX_STYLE = {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    width: viewWidth,
  };

  const BOX_STYLE_NO_POINTER = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: viewWidth,
  };

  const sendFile = (acceptedFiles) => {
    let filesToUse = null;
    filesToUse = acceptedFiles;
    if (!filesToUse) {
      filesToUse = pendingFile;
    }

    setPreventClick(true);
    setSelectedDocumentType(pk_DocumentType);
    setFiles([filesToUse]);
    return initiateFileUpload([filesToUse], pk_DocumentType);
  };

  const initiateSending = async (acceptedFiles) => {
    const fileSent = await sendFile(acceptedFiles);
    return Promise.all([fileSent]).then((values) => {
      setPreventClick(false);
      return getCandidateDetails(selectedCandidate);
    });
  };

  const confirmReplaceAlert = (pendingFiles) => {
    return Swal.fire({
      title: 'Please confirm!',
      text: `Are you sure you want to replace the current file ${file.DocumentTypeName}?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: `Replace`,
    }).then(async (result) => {
      if (result.value) {
        setPreventClick(true);
        const deleted = await deleteFile(file);
        const fileSent = await initiateSending(pendingFiles);
        Promise.all([deleted, fileSent]).then((values) => {
          setPreventClick(false);
          setConfirmReplace(false);
        });
      } else {
        setConfirmReplace(false);
        setPendingFile(null);
      }
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        Swal.fire('Oops', 'Please limit 1 file per category', 'error');
      } else if (uploadLogo) {
        if (file && file.deptLogoExists === true) {
          setPendingFile(acceptedFiles);
          confirmReplaceAlert(acceptedFiles);
        } else {
          initiateSending(acceptedFiles);
        }
      } else {
        if (file && file.Name) {
          setPendingFile(acceptedFiles);
          confirmReplaceAlert(acceptedFiles);
        } else {
          initiateSending(acceptedFiles);
        }
      }
    },
    [file],
  );

  const determineIcon = () => {
    if (file.MimeType && file.MimeType.includes('pdf')) {
      return faFilePdf;
    }
    if (file.MimeType && file.MimeType.includes('image')) {
      return faFileImage;
    }
    // if (file.MimeType && file.MimeType.includes('image')) {
    //   return faFileWord;
    // }
    return faFile;
  };

  // const displayViewOrReplace = () => {

  //   Swal.fire({
  //     title: `<h1>What do you want to do with <b>${item.DocumentTypeName}</b>?</h1>`,
  //     confirmButtonText: 'View',
  //     showCloseButton: true,
  //     showDenyButton: true,
  //     denyButtonText: `Replace`,
  //     showCancelButton: true,
  //     cancelButtonText: 'Cancel',
  //   }).then((results) => {
  //     if (results.isConfirmed) {
  //       const { FullUrl } = file;
  //       window.open(FullUrl, '_blank').focus();
  //     } else if (results.isDenied) {
  //     } else if (results.isDismissed) {
  //     }
  //   });
  // };

  const determineDragText = (hasFile) => {
    if (hasFile) return 'Replace Document';

    return 'Click or Drag';
  };

  const documentContent = () => {
    if (preventClick) {
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            style={{
              ...BOX_STYLE,
              width: '100%',
              height,
              flexDirection: 'column',
              padding: '5px',
            }}
          >
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          </div>
        </div>
      );
    }

    if (chooseViewOrReplace) {
      return (
        <div className="boxWrapper" style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="boxSecondWrapper"
            style={{
              ...BOX_STYLE_NO_POINTER,
              height,
              flexDirection: 'column',
              padding: '5px',
              width: '100%',
            }}
          ></div>
        </div>
      );
    }

    if (confirmDelete) {
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            style={{
              ...BOX_STYLE_NO_POINTER,
              height,
              flexDirection: 'column',
              padding: '5px',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <h6 style={{ paddingRight: '5px' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
              </h6>
              <h6>Delete?</h6>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>Are you sure you want to delete {file.DocumentTypeName}?</p>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setPreventClick(true);
                    deleteFile(file);
                    setPreventClick(false);
                  }}
                  style={{ backgroundColor: '#d11a2a' }}
                >
                  Yes
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(false);
                  }}
                  style={{ backgroundColor: '#32CD32' }}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const hasFile = file && file.Name;

    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {hasFile && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                const { FullUrl, Name, pk_File, fk_DocumentType } = file;

                ///viewFile/:pk_File/:pk_DocumentType/:fileName
                // window.open(FullUrl, '_blank').focus();

                // history.push(`/viewFile/${pk_File}/${pk_DocumentType}/${Name}`);
                window
                  .open(`/viewFile/${selectedCandidate.pk_Candidate}/${pk_File}/${pk_DocumentType}/${Name}`, '_blank')
                  .focus();
              }}
            >
              View Document
            </Button>
          </div>
        )}
        <div style={{ height: '10px' }}></div>
        <div
          className={hasFile ? 'drop_wrapper_as_button' : 'drop_wrapper'}
          style={{ width: BOX_WIDTH, borderRadius: 5, border: 'dashed' }}
        >
          <div
            className={!hasFile && 'hover_blue'}
            {...getRootProps()}
            style={{
              ...BOX_STYLE,
              height: hasFile ? 'auto' : height,
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'row',
                textAlign: 'center',
                fontSize: 13,
                // verticalAlign: 'middle',
              }}
            >
              <p>{determineDragText(hasFile)}</p>
              {/* {file.Hide && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ paddingRight: '5px' }}>
                    <FontAwesomeIcon icon={faEyeSlash} />
                  </div>
                  <h6 style={{ margin: 0 }}>Hidden</h6>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const logoContent = () => {
    if (validDepartment === null) {
      return <div>No Valid Department</div>;
    }
    if (!validDepartment) {
      return (
        <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            style={{
              ...BOX_STYLE,
              height: uploadLogo ? '90vh' : 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <p style={{ color: 'rgb(255, 0, 0)' }}>Invalid Department</p>
              <p style={{ fontWeight: '700' }}>{file.DocumentTypeName}</p>
              {/* {file.Hide && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ paddingRight: '5px' }}>
                    <FontAwesomeIcon icon={faEyeSlash} />
                  </div>
                  <h6 style={{ margin: 0 }}>Hidden</h6>
                </div>
              )} */}
            </div>
          </div>
        </div>
      );
    }
    if (confirmReplace) {
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            style={{
              ...BOX_STYLE_NO_POINTER,
              height,
              flexDirection: 'column',
              padding: '5px',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <h6 style={{ paddingRight: '5px' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
              </h6>
              <h6>Replace</h6>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>
                Are you sure you want to replace the current file ({file.DocumentTypeName})?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
              <div>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    setPreventClick(true);
                    const deleted = await deleteFile(file);
                    const fileSent = await sendFile();
                    Promise.all([deleted, fileSent]).then((values) => {
                      setPreventClick(false);
                      setConfirmReplace(false);
                    });
                  }}
                  style={{ backgroundColor: '#d11a2a' }}
                >
                  Yes
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmReplace(false);
                    setPendingFile(null);
                  }}
                  style={{ backgroundColor: '#32CD32' }}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (currentLogo.deptLogoExists) {
      const fullLogoUrl = currentLogo.logoUrl;
      return (
        <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            {...getRootProps()}
            style={{
              ...BOX_STYLE,
              height: uploadLogo ? '90vh' : 'auto',
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={fullLogoUrl} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
        <div
          className="hover_blue"
          {...getRootProps()}
          style={{
            ...BOX_STYLE,
            height: uploadLogo ? '90vh' : 'auto',
          }}
        >
          <input {...getInputProps()} />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <p>Click or Drag</p>
            <p style={{ fontWeight: '700' }}>{file.DocumentTypeName}</p>
            {/* {file.Hide && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                </div>
                <h6 style={{ margin: 0 }}>Hidden</h6>
              </div>
            )} */}
          </div>
        </div>
      </div>
    );
  };

  const determineContent = () => {
    if (uploadLogo) {
      return logoContent();
    } else {
      return documentContent();
    }
  };

  useEffect(() => {
    if (attachedDoc) {
      setFile(attachedDoc);
    }
  }, [attachedDoc]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  return (
    <Row
      style={{
        width: '100%',
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
      }}
    >
      {determineContent()}
    </Row>
  );
};

export default SingleCandidateDragAndDropItem;
