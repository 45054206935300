import {
  faArrowRight,
  faCalendarAlt,
  faCalendarXmark,
  faClipboardList,
  faEnvelope,
  faHandPaper,
  faPencilAlt,
  faTag,
  faThumbsDown,
  faTimes,
  faTimesCircle,
  faTrash,
  faUserCheck,
  faUserTimes,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import TabContent from 'reactstrap/lib/TabContent';
import TabPane from 'reactstrap/lib/TabPane';
import { formatBearerToken, swalErrorMessage } from '../../../../../Common.functions';
import Swal from 'sweetalert2';
import { clone } from '../../../../../Services/schedule';
import UserPhoto from '../../../../DragAndDropFiles/UserPhoto/UserPhoto';
import UserPhotoTypes from '../../../../DragAndDropFiles/UserPhoto/UserPhotoTypes';
import ConfirmModal from '../../../Coordinator/Schedule/ConfirmModal';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa.js';
import TagPopover from '../TagPopover';
import AddDocuments from './AddDocuments/AddDocuments';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import AdditionalDetails from './AdditionalDetails';
import Communication from './Communication';
import ScoresAndAwards from './ScoresAndAwards';
import style from './style.js';
import Waitlist from './Waitlist/Waitlist';
import MarkPopover from './MarkPopover/MarkPopover';
import { CandidateStatusIcons } from 'enums';
import Loading from 'Body/Statuses/Loading';
import { useAlert } from 'react-alert';
import GroupsPopover from '../GroupsPopover/GroupsPopover';

const CandidateWidget = ({
  activeDepartment,
  dataTypes = [],
  dateFromURL,
  documentTypes,
  embedded,
  getCandidates,
  interviewDates = [],
  replacePhotoinCandidatesArray,
  isSaving,
  isSingleCandidate,
  isUpdatingCandidateList,
  isInitializedCandidates,
  match,
  saveResults,
  selectedCandidate,
  setDocumentTypes,
  setIsSaving,
  setMainChanges,
  setSelectedCandidate,
  mainChanges,
  token,
  resetFunctions,
  abortController,
  isGettingCandidateDetails,
  setIsGettingCandidateDetails,
}) => {
  const [data, setData] = useState();
  const [changes, setChanges] = useState({});
  const [tabToGo, setTabToGo] = useState(1);
  const dataRef = useRef();

  const [activeTab, setActiveTab] = useState('1');
  const [showInterviewDatePopover, setShowInterviewDatePopover] = useState(false);
  const [selectedInterviewDate, setSelectedInterviewDate] = useState(null);

  const [showOverBookedAlert, setShowOverBookedAlert] = useState();
  const [showAlreadyScheduledAlert, setShowAlreadyScheduledAlert] = useState();
  const [showTagsPopover, setShowTagsPopover] = useState();
  const [showGroupsPopover, setShowGroupsPopover] = useState();

  const [showTagLimitAlert, setShowTagLimitAlert] = useState(false);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [showStatusPopover, setShowStatusPopover] = useState(false);
  const [overbookCandidate, setOverbookCandidate] = useState(false);
  const [oldSelectedCandidate, setOldSelectedCandidate] = useState();
  const [detailedCandidate, setDetailedCandidate] = useState(selectedCandidate || {});

  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const selectedCandidateRef = useRef();

  const alert = useAlert();
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const history = useHistory();
  const dContext = useContext(DepartmentContext);
  const statusOptions = { '-1': 'None', '3': 'Prescreen', '4': 'To Invite', '5': 'Rejected', '8': 'Declined' };
  const { location } = history;
  const { pathname } = location;

  const { PhotoUrl, FirstName, LastName, HasDeclinedInterview, pk_InterviewDate } = detailedCandidate || {};

  const { pk_Candidate, DateOfInterview } = selectedCandidate || {};

  const hasChanges = Object.values(changes).includes(true);
  const selectedDate = interviewDates.find((date) => {
    return date.pk_InterviewDate == pk_InterviewDate;
  });

  const { CannotBeUnlocked, StartTime, pk_Tag } = selectedDate || {};
  const selectedDateHasTag = pk_Tag;
  const dateInThisTimeZone = moment.tz(`${DateOfInterview} ${StartTime}`, moment.tz.guess());

  // const dateToCompare = moment.tz(`${dateInThisTimeZone.format('MMM DD,  YYYY')} 23:59`, null);

  const isPastInterviewDate = !dateInThisTimeZone.isSameOrAfter(moment(), 'd');

  const dateIsLocked = isPastInterviewDate || CannotBeUnlocked;
  const { department } = dContext || {};
  const { EnableGroups } = department || {};

  // Import - 2
  // Prescreen - 3
  // Invite - 4
  // Reject - 5
  // Decline - 8

  useEffect(() => {
    if (data) {
      dataRef.current = data;
    }
  }, [data]);

  useEffect(() => {
    if (changes) {
      const newHasChange = Object.values(changes).includes(true);
      if (mainChanges.candidateDetails != newHasChange) {
        setMainChanges({ candidateDetails: newHasChange });
      }
    }
  }, [changes]);

  useEffect(() => {
    if (mainChanges && !mainChanges.candidateDetails) {
      setChanges({});
    }
  }, [mainChanges]);

  useEffect(() => {
    updateTabFromURL();
  }, [pathname]);

  useEffect(() => {
    setData(clone(selectedCandidate));
    setShowStatusPopover(false);

    if (
      selectedCandidate &&
      (!selectedCandidateRef.current || selectedCandidateRef.current.pk_Candidate != selectedCandidate.pk_Candidate)
    ) {
      selectedCandidateRef.current = selectedCandidate;
      setDetailedCandidate(selectedCandidate);
      getCandidateDetails(selectedCandidate);
    } else {
    }
  }, [selectedCandidate]);

  const getCandidateDetails = (candidate, options) => {
    const { callback } = options || {};

    if (candidate) {
      if (abortController && abortController.current) {
        abortController.current.abort();
      }

      abortController.current = new AbortController();

      setIsGettingCandidateDetails(true);
      const { pk_Candidate } = candidate;
      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/candidate',
            { pk_Candidate, pk_Department: dContext.department.pk_Department },
            formatBearerToken(token),
            {
              abortController: abortController.current,
              callback: (result) => {
                setIsGettingCandidateDetails(false);
                if (result.data && result.data.error) {
                  swalErrorMessage();
                  return;
                }
                if (dateFromURL.current != null) {
                  // Stops selecting candidate that is not the first in list filtered by date
                  // setSelectedCandidate(null);
                  return;
                }

                const newCandidateDetails = result.data;
                const newSelectedCandidate = { ...newCandidateDetails };
                newSelectedCandidate.DateOfInterview = candidate.DateOfInterview;

                if (
                  selectedCandidateRef &&
                  selectedCandidateRef.current.pk_Candidate == newSelectedCandidate.pk_Candidate
                ) {
                  setOldSelectedCandidate(newSelectedCandidate);
                  setDetailedCandidate(newSelectedCandidate);
                  if (callback) {
                    callback(newSelectedCandidate);
                  }
                } else {
                  // console.log(
                  //   `loading for ${newCandidateDetails.FirstName} ${newCandidateDetails.LastName} cancelled.`,
                  // );
                }
              },
            },
          );
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    } else {
      setIsGettingCandidateDetails(false);
      // setSelectedCandidate(null);
    }
  };

  const updateTabFromURL = () => {
    const tabURLs = {
      dataFields: 1,
      additionalDetails: 2,
      documents: 3,
      waitlist: 4,
      communication: 5,
    };
    Object.keys(tabURLs).forEach((key) => {
      if (pathname.indexOf(key) >= 0 && activeTab != tabURLs[key]) {
        setActiveTab(tabURLs[key].toString());
      }
    });
  };

  const unscheduleCandidate = (decline = false, callback) => {
    return getTokenSilently()
      .then((token) => {
        return postDataAgnostic(
          'department/season/candidates/unschedule',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            decline,
          },
          { candidateIds: [selectedCandidate.pk_Candidate] },
          formatBearerToken(token),
        )
          .then((res) => {
            getCandidates(null, { forcedSelectedCandidateId: selectedCandidate.pk_Candidate });
            getCandidateDetails(selectedCandidate);
            if (callback) {
              callback();
            }
            // need to set the candidate as unregistered
            // setSelectedCandidate((prevState) => {
            //   // eslint-disable-next-line
            //   let clone = structuredClone(prevState);
            //   clone.DateOfInterview = null;
            //   clone.Label = null;
            //   return clone;
            // });
          })
          .catch((err) => {
            if (callback) {
              callback(err);
            }
            return Swal.fire('Something went wrong...', 'Please try again later or contact support', 'error');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  /**
   *
   * @param {string} key optional for only sendign a single field to update
   * @param {object} forcedData optional for forced updates. func will look to this object instead of the global one.
   */
  const saveChanges = (keyToSubmit, forcedData, overbook) => {
    const validFields = [
      'DateOfInterview',
      'FirstName',
      'LastName',
      'MiddleName',
      'PreferredName',
      'HigherEducationInstitution',
      'HigherEducationMajor',
      'Email',
      'PreferredPhone',
      'NRMPID',
      'AAMCID',
      'SFMatchID',
      'pk_InterviewDate',
    ];

    const allowOverbook = overbook || overbookCandidate;
    const newData = forcedData ? forcedData : clone(dataRef.current);

    Object.keys(dataRef.current).forEach((key) => {
      if ((keyToSubmit == null && !validFields.includes(key)) || key != keyToSubmit) {
        delete newData[key];
      }
    });

    if (selectedCandidate && selectedCandidate.pk_Candidate) {
      saveResults({
        candidatesToUpdate: [data.pk_Candidate],
        fieldsToUpdate: newData,
        allowOverbook,
        forcedSelectedCandidateId: data.pk_Candidate,
        callback: () => {
          getCandidateDetails(selectedCandidate);
        },
      });
    } else {
      saveResults({
        candidatesToUpdate: [selectedCandidate.pk_Candidate],
        fieldsToUpdate: data,
        allowOverbook,
        callback: () => {
          getCandidateDetails(selectedCandidate);
        },
      });
    }
  };

  const lockInterviewDate = (pk_InterviewDate, callback) => {
    // Need to put Tag Filter here. If date has Tag Filter, Tag Filter should be passed ALWAYS, otherwise,
    // back end would strip it.
    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: pk_InterviewDate,
            pk_Season: dContext.season.pk_Season,
          },
          { entry: { EnableLockedStatus: true } },
          formatBearerToken(token),
        )
          .then((result) => {
            alert.success('Locked Date!');
            setIsSaving(false);
            callback();
          })
          .catch((err) => {
            alert.error('Failed to lock Date!');
            callback(true);
            setIsSaving(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleChange = (key, value, overbook) => {
    const newData = clone(data);
    newData[key] = value;

    if (key === 'pk_InterviewDate') {
      saveChanges(key, clone(newData), overbook);
      // setData(newData);
      return;
    }
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setData(newData);
    setTypingTimeout(
      setTimeout(() => {
        saveChanges(key, null, overbook);
      }, 1000),
    );
  };

  const deleteCandidatePhoto = () => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'files/admin/candidate/photo',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Candidate: selectedCandidate.pk_Candidate,
          },
          {},
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Successfully deleted photo');

            getCandidateDetails(detailedCandidate, {
              callback: () => {
                replacePhotoinCandidatesArray(detailedCandidate.pk_Candidate, null);
              },
            });
          })
          .catch((err) => {
            alert.error('Failed to delete photo.');
          });
      })
      .catch((err) => {
        alert.error('Failed to delete photo');
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };
  const renderOverBookedAlert = () => {
    const { DateOfInterview, CandidateRegistrationLimit = 0, CountOfCandidates = 0, Label } =
      selectedInterviewDate || {};
    const message = `"${moment.tz(DateOfInterview, null).format('dddd, MMM. DD, YYYY')}" ${
      Label ? `(${Label})` : ''
    } is fully booked (${CountOfCandidates || 0} of ${CandidateRegistrationLimit || 0} spots filled). Proceed anyway?`;

    return (
      <ConfirmModal
        title={'Overbooked!'}
        message={message}
        isOpen={showOverBookedAlert}
        buttonStyle={{ cancelBtnColor: 'secondary' }}
        onAccept={() => {
          setOverbookCandidate(true);
          setShowOverBookedAlert(false);

          if (selectedCandidate.DateOfInterview != null) {
            setShowAlreadyScheduledAlert(true);
            return;
          } else {
            handleChange('pk_InterviewDate', selectedInterviewDate.pk_InterviewDate, true);
          }
        }}
        onCancel={() => {
          setShowOverBookedAlert(false);
        }}
      />
    );
  };

  const renderAlreadyScheduledAlert = () => {
    const message =
      selectedCandidate && selectedInterviewDate ? (
        <>
          <div>
            {`${moment.tz(selectedCandidate.DateOfInterview, null).format('MMM. DD, YYYY')} ${
              selectedCandidate.Label ? `(${selectedCandidate.Label})` : ''
            }`}
            {'  '}
            <FontAwesomeIcon icon={faArrowRight} />
            {'  '}
            {`${moment.tz(selectedInterviewDate.DateOfInterview, null).format('MMM. DD, YYYY')} ${
              selectedInterviewDate.Label ? `(${selectedInterviewDate.Label})` : ''
            }`}
          </div>
          <br />
          <div>
            {' '}
            {`${moment.tz(selectedCandidate.DateOfInterview, null).format('MMM. DD, YYYY')} ${
              selectedCandidate.Label ? `(${selectedCandidate.Label})` : ''
            }`}{' '}
            will be LOCKED to prevent automated slotting of additional candidates.
          </div>
        </>
      ) : (
        ''
      );
    // selectedCandidate
    // ? `${selectedCandidate.LastName} is already assigned to an interview date - ${moment
    //     .tz(selectedCandidate.DateOfInterview, null)
    //     .format('dddd, MMM. DD, YYYY')}. Schedule for ${selectedCandidate.CountOfScheduleInterviews || 0} interview${
    //     selectedCandidate.CountOfScheduleInterviews > 1 ? `s` : ''
    //   }. Do you want to reassign anyway?`
    // : '';
    return (
      <ConfirmModal
        title={`Move ${selectedCandidate.FirstName} ${selectedCandidate.LastName}?`}
        message={message}
        isOpen={showAlreadyScheduledAlert}
        buttonStyle={{ acceptText: 'Lock and Move', cancelBtnColor: 'secondary' }}
        onAccept={() => {
          lockInterviewDate(selectedCandidate.pk_InterviewDate, (err) => {
            if (!err) {
              handleChange('pk_InterviewDate', selectedInterviewDate.pk_InterviewDate);
            } else {
              Swal.fire(
                'Error!',
                'Failed to lock session. Cannot move candidate. Please try again or contact support.',
                'error',
              );
            }
            setShowAlreadyScheduledAlert(false);
          });
        }}
        onCancel={() => {
          setShowAlreadyScheduledAlert(false);
        }}
      />
    );
  };

  const renderTagLimitAlert = () => {
    const { DateOfInterview, CandidateRegistrationLimit = 0, CountOfCandidates = 0, Label } =
      selectedInterviewDate || {};
    const message = `Tag filtering is in effect for this session. If you move this candidate, the session will be locked to prevent automation from registering additional candidates.`;

    return (
      <ConfirmModal
        title={'Tagged Date!'}
        message={message}
        isOpen={showTagLimitAlert}
        buttonChoices={
          <>
            <Button
              color="secondary"
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 10,
                marginRight: 10,
                paddignRight: 20,
                paddingBottom: 10,
                paddingTop: 10,
                minWidth: 100,
                backgroundColor: null,
              }}
            >
              <div
                style={{ width: '100%', textAlign: 'center', fontWeight: 600 }}
                onClick={() => {
                  setShowTagLimitAlert(false);
                }}
              >
                Cancel
              </div>
            </Button>
            <Button
              color="success"
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginLeft: 10,
                marginRight: 10,
                paddignRight: 20,
                paddingBottom: 10,
                paddingTop: 10,
                minWidth: 100,
                backgroundColor: null,
              }}
              onClick={() => {
                handleChange('pk_InterviewDate', selectedInterviewDate.pk_InterviewDate, true);
                setShowTagLimitAlert(false);
              }}
            >
              <div style={{ width: '100%', textAlign: 'center', fontWeight: 600 }}>Lock and Move</div>
            </Button>
          </>
        }
      />
    );
  };

  const renderDatePickerPopover = () => {
    return (
      <Popover
        flip={false}
        popperClassName="filterPopover"
        isOpen={showInterviewDatePopover}
        trigger="legacy"
        target="interviewDate"
        modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={(e) => {
          if (e.target.className.includes && e.target.className.includes('swal2')) {
            return;
          }
          setShowInterviewDatePopover(!showInterviewDatePopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div>Available Interview Sessions</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInterviewDatePopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div>
            <div style={{ height: 310, overflowY: 'scroll' }}>
              {interviewDates.map((interviewDate, i) => {
                const backgroundColor =
                  selectedCandidate && interviewDate.pk_InterviewDate == selectedCandidate.pk_InterviewDate
                    ? '#d9f5ff'
                    : i % 2 == 0
                    ? null
                    : '#c7cbd1';
                const { CandidateRegistrationLimit = 0, CountOfCandidates = 0 } = interviewDate || {};
                const { DateOfInterview } = selectedCandidate || {};

                return (
                  <div
                    style={{ ...style.dateListItem, backgroundColor }}
                    onClick={() => {
                      const { department = {} } = dContext || {};
                      const { EnableTagFiltering } = department;
                      // if (!dateIsLocked && selectedDateHasTag && EnableTagFiltering) {
                      //   setShowTagLimitAlert(true);
                      //   return;
                      // }

                      if (interviewDate.pk_InterviewDate == selectedCandidate.pk_InterviewDate) {
                        return;
                      }
                      setSelectedInterviewDate(interviewDate);
                      if (CountOfCandidates >= CandidateRegistrationLimit) {
                        setShowOverBookedAlert(true);
                      } else if (DateOfInterview != null) {
                        setShowAlreadyScheduledAlert(true);
                      } else {
                        handleChange('pk_InterviewDate', interviewDate.pk_InterviewDate);
                      }

                      setShowInterviewDatePopover(false);
                    }}
                  >
                    <div>
                      {`${moment
                        .tz(interviewDate.DateOfInterview, null)
                        .format('dddd, MMM. DD, YYYY')} (${CountOfCandidates || 0}/${CandidateRegistrationLimit || 0})`}
                    </div>
                    <div> {interviewDate.Label}</div>
                  </div>
                );
              })}
            </div>
            {/* <div style={style.simpleRow}>
              <Button style={{ ...style.genButton, backgroundColor: '#ffbb00' }}>
                <div style={style.spacedBetweenRow}>
                  <div>
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </div>
                  <div style={style.buttonLabel}>Manage Interview Dates</div>
                </div>
              </Button>
            </div> */}
            <div style={style.simpleRow}>
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                <Button
                  onClick={(e) => {
                    const { FirstName, LastName, MiddleName } = selectedCandidate || {};

                    Swal.fire({
                      title: `Unregister ${FirstName || ''} ${LastName || ''}?`,
                      text: `This will UNREGISTER the candidate, but they could be registered for another session if automation is enabled.`,
                      showCancelButton: true,
                      cancelButtonColor: 'gray',
                      showConfirmButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: `Unregister`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        unscheduleCandidate();
                      }
                    });

                    // Swal.fire({
                    //  title: `Unregister ${FirstName || ''} ${LastName || ''}?`,
                    //   text: `This will UNREGISTER the candidate, but they could be registered for another session if automation is enabled.`,
                    //   type: 'warning',
                    //   showCancelButton: true,
                    //   confirmButtonColor: 'red',
                    //   confirmButtonText: `Unregister`,
                    // }).then((result) => {
                    //   if (result.value) {
                    //     unscheduleCandidate();
                    //   }
                    // });
                  }}
                  color="danger"
                  style={{ ...style.genButton, marginTop: 5 }}
                  disabled={
                    selectedCandidate == null ||
                    selectedCandidate.DateOfInterview == null ||
                    isSaving ||
                    isGettingCandidateDetails
                  }
                >
                  <div style={style.spacedBetweenRow}>
                    <div>
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                    <div style={style.buttonLabel}>Unregister</div>
                  </div>
                </Button>
                <Button
                  color="danger"
                  disabled={isSaving || isGettingCandidateDetails}
                  style={{ ...style.genButton, marginTop: 5 }}
                  onClick={(e) => {
                    const { FirstName, LastName } = selectedCandidate || {};
                    Swal.fire({
                      title: `${FirstName || ''} ${LastName || ''} has Declined?`,
                      text: `This will prevent the candidate from slotting onto another session.`,
                      showCancelButton: true,
                      cancelButtonColor: 'gray',
                      showConfirmButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: `Decline`,
                    }).then((result) => {
                      if (result.isConfirmed) {
                        unscheduleCandidate(true);
                      }
                    });
                  }}
                >
                  <div style={style.spacedBetweenRow}>
                    <div>
                      <FontAwesomeIcon icon={faCalendarXmark} />
                    </div>
                    <div style={style.buttonLabel}>Declined All Interviews</div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderTagsPopover = () => {
    return (
      <Popover
        flip={false}
        popperClassName="markPopover"
        isOpen={showTagsPopover}
        trigger="legacy"
        target="tags"
        modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={() => {
          setShowTagsPopover(!showTagsPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Tags</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowTagsPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <TagPopover
            token={token}
            selectedCandidates={[selectedCandidate]}
            getCandidates={() => {
              getCandidates(null, { forcedSelectedCandidateId: selectedCandidate.pk_Candidate });
              getCandidateDetails(selectedCandidate);
            }}
            setShowPopover={setShowTagsPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderGroupsPopover = () => {
    return (
      <Popover
        flip={false}
        popperClassName="markPopover"
        isOpen={showGroupsPopover}
        trigger="legacy"
        target="groups"
        modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={() => {
          setShowGroupsPopover(!showGroupsPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Groups</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setShowGroupsPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <GroupsPopover
            selectedGroup={detailedCandidate.Group}
            candidate={selectedCandidate}
            getCandidates={() => {
              getCandidates(null, { forcedSelectedCandidateId: selectedCandidate.pk_Candidate });
              getCandidateDetails(selectedCandidate);
            }}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderStatusBar = () => {
    const statuses = [];
    const statusStyle = { marginRight: 5, cursor: 'pointer' };
    const { season } = dContext || {};
    const { AllowPrescreen } = season || {};
    const {
      pk_Candidate,
      EnableMarkedForInvitation,
      EnableMarkedForPrescreen,
      HasBeenRejectedForInterview,
      HasBeenSentConfirmation,
      HasBeenSentInvitation,
      HasDeclinedInterview,
      IsScheduled,
      IsWaitlisted,
    } = selectedCandidate;
    const {
      pendingInvite,
      invited,
      waitlisted,
      confirmed,
      unconfirmed,
      rejected,
      scheduled,
      markedForInvite,
      prescreen,
    } = CandidateStatusIcons;

    if (EnableMarkedForInvitation) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`marked_for_invite_widgetBar`}
            icon={markedForInvite.icon}
            style={{ color: markedForInvite.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`marked_for_invite_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Marked for Invite - This candidate was marked for Invitation.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (HasBeenSentInvitation) {
      // Invited, waiting response
      statuses.push(
        <>
          <FontAwesomeIcon id={`invited`} icon={invited.icon} style={{ color: invited.color, ...statusStyle }} />
          <UncontrolledTooltip target={`invited`}>
            Invited - This candidate has been sent an invitation.
          </UncontrolledTooltip>
        </>,
      );
    } else if (EnableMarkedForInvitation && !HasBeenSentInvitation && !IsWaitlisted && !IsScheduled) {
      // Pending Invi
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`pending_invite_widgetBar`}
            icon={pendingInvite.icon}
            style={{ color: pendingInvite.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`pending_invite_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Pending Invite - This candidate was marked for Invitation but has not been sent an invite yet.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (IsWaitlisted) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`waitlisted_widgetBar`}
            icon={waitlisted.icon}
            style={{ color: waitlisted.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`waitlisted_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Waitlisted - This candidate is currently waitlisted.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (AllowPrescreen && EnableMarkedForPrescreen) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`marked_for_prescreen_widgetBar`}
            icon={prescreen.icon}
            style={{ color: prescreen.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`marked_for_prescreen_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Marked for Prescreen - This candidate is marked for Prescreen.
          </UncontrolledTooltip>
        </>,
      );
    }

    if (IsScheduled) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`confirmation_widgetBar`}
            icon={HasBeenSentConfirmation ? confirmed.icon : unconfirmed.icon}
            style={{ color: HasBeenSentConfirmation ? confirmed.color : unconfirmed.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`confirmation_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            {HasBeenSentConfirmation
              ? 'Confirmation Sent - Candidate has been sent a registration confirmation email.'
              : 'Unconfirmed - Candidate has not been sent a registration confirmation email.'}
          </UncontrolledTooltip>
        </>,
      );

      statuses.push(
        <>
          <FontAwesomeIcon
            id={`scheduled_widgetBar`}
            icon={scheduled.icon}
            style={{ color: scheduled.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`scheduled_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Scheduled - This candidate is registered for an interview date! (
            {moment(DateOfInterview).format('dddd, MMM. DD, YYYY')})
          </UncontrolledTooltip>
        </>,
      );
    }

    if (HasBeenRejectedForInterview) {
      statuses.push(
        <>
          <FontAwesomeIcon
            id={`rejected_widgetBar`}
            icon={rejected.icon}
            style={{ color: rejected.color, ...statusStyle }}
          />
          <UncontrolledTooltip
            target={`rejected_widgetBar`}
            flip={false}
            modifiers={{ flip: { behavior: ['top'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          >
            Rejected - This candidate was marked as Rejected by Program.
          </UncontrolledTooltip>
        </>,
      );
    }

    return statuses;
  };

  const renderMarkPopover = () => {
    return (
      <MarkPopover
        showStatusPopover={showStatusPopover}
        setShowStatusPopover={setShowStatusPopover}
        selectedCandidate={selectedCandidate}
        saveResults={saveResults}
        isUpdatingStatus={isUpdatingStatus}
        setIsUpdatingStatus={setIsUpdatingStatus}
      />
    );
  };

  if (
    selectedCandidate == null ||
    (selectedCandidate && Object.keys(selectedCandidate).length == 0) ||
    !isInitializedCandidates
  ) {
    return (
      <div
        style={{
          width: '100%',
          height: '40vh',
          fontWeight: 'bold',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        {!isInitializedCandidates ? (
          <div style={{ height: 100, width: 100 }}>
            <Loading
              options={{
                labelText: 'Loading. . .',
              }}
            />
          </div>
        ) : (
          'Please Add a Candidate'
        )}
      </div>
    );
  }

  return (
    <Col
      className="wrapperfortabcontainer"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        // width: '29%',
        padding: 10,
        paddingTop: 5,
        overflowY: 'hidden',
        overflowX: 'hidden',
      }}
    >
      <div style={style.stickyContainer}>
        <Row style={style.topSectionContainer}>
          <Col md={8}>
            <Row>
              <Col md={4} style={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                {isGettingCandidateDetails ? (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',

                      height: '100%',
                      backgroundColor: 'black',
                      opacity: 0,
                    }}
                  ></div>
                ) : null}
                <UserPhoto
                  externalAPI={true}
                  id={pk_Candidate}
                  photoUrl={PhotoUrl}
                  callback={(id, uri) => {
                    alert.success('Photo Updated!');

                    getCandidateDetails(detailedCandidate, {
                      callback: () => {
                        replacePhotoinCandidatesArray(id, uri);
                      },
                    });
                  }}
                  type={UserPhotoTypes.AdminCandidate}
                  handleDelete={() => {
                    deleteCandidatePhoto();
                    // files/admin/candidate/photo
                  }}
                />
              </Col>
              <Col md={8}>
                <Row
                  style={{ ...style.selectedCandidateName, color: isGettingCandidateDetails ? 'gray' : '' }}
                >{`${FirstName || '---'} ${LastName || '---'}`}</Row>
                <Row style={{ verticalAlign: 'bottom', marginTop: 10, marginBottom: 5 }}>
                  <Button
                    // disabled={isGettingCandidateDetails}
                    outline
                    color={isGettingCandidateDetails ? 'secondary' : 'primary'}
                    name="tags"
                    id="tags"
                    onClick={() => {}}
                    style={{ width: '100%' }}
                  >
                    <div style={{ ...style.simpleRow, alignItems: 'center', verticalAlign: 'middle', margin: 0 }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: 40 }}>
                        <FontAwesomeIcon icon={faTag} />
                      </div>
                      <div style={{ ...style.simpleColumn, width: '100%' }}>
                        {detailedCandidate && detailedCandidate.Tags && detailedCandidate.Tags.length > 0 ? (
                          <div style={{ textAlign: 'left' }}>
                            {detailedCandidate.Tags.map((tag) => {
                              if (tag && tag.Tag) {
                                return tag.Tag;
                              }
                            }).join(', ')}
                          </div>
                        ) : (
                          <div style={{ textAlign: 'left' }}>Add Tag(s) </div>
                        )}
                      </div>
                      <div style={{ ...style.simpleColumn, width: 37 }}>
                        <FontAwesomeIcon icon={faPencilAlt} style={{ marginLeft: 10, cursor: 'pointer' }} />
                      </div>
                    </div>
                  </Button>
                  {renderTagsPopover()}
                </Row>
                {EnableGroups ? (
                  <Row style={{ verticalAlign: 'bottom', marginTop: 0, marginBottom: 5 }}>
                    <Button
                      outline
                      color={isGettingCandidateDetails ? 'secondary' : 'primary'}
                      name="tags"
                      id="groups"
                      onClick={() => {}}
                      style={{ width: '100%' }}
                    >
                      <div style={{ ...style.simpleRow, alignItems: 'center', verticalAlign: 'middle', margin: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 40 }}>
                          <FontAwesomeIcon icon={faUsers} />
                        </div>
                        <div style={{ ...style.simpleColumn, width: '100%' }}>
                          {detailedCandidate && detailedCandidate.Group && detailedCandidate.Group.pk_Group > 0 ? (
                            <div style={{ textAlign: 'left' }}>{detailedCandidate.Group.GroupName}</div>
                          ) : (
                            <div style={{ textAlign: 'left' }}>Add Group</div>
                          )}
                        </div>
                        <div style={{ ...style.simpleColumn, width: 37 }}>
                          <FontAwesomeIcon icon={faPencilAlt} style={{ marginLeft: 10, cursor: 'pointer' }} />
                        </div>
                      </div>
                    </Button>
                    {renderGroupsPopover()}
                  </Row>
                ) : null}

                <Row style={{ alignItems: 'center' }}>
                  <Button
                    color={isGettingCandidateDetails ? 'secondary' : 'primary'}
                    // disabled={isGettingCandidateDetails}
                    outline
                    id={`edit_${pk_Candidate}`}
                    onClick={() => {
                      setShowStatusPopover(!showStatusPopover);
                    }}
                    style={{ width: '100%' }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: 100 }}>Status: {` `}</div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {renderStatusBar()}
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column', width: 50 }}>
                        <div>
                          <FontAwesomeIcon icon={faPencilAlt} style={{ marginLeft: 10, cursor: 'pointer' }} />
                        </div>
                      </div>
                    </div>
                  </Button>
                  {renderMarkPopover()}
                </Row>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Form>
              <FormGroup>
                <div>
                  <Label for="interviewDate">
                    {DateOfInterview ? 'Registered to Interview on:' : 'Register to Interview on:'}
                  </Label>
                </div>
                {HasDeclinedInterview ? (
                  <div id="declined">
                    <Button
                      color="danger"
                      disabled={isGettingCandidateDetails || isSaving}
                      outline
                      style={{ width: '100%', height: 80, alignSelf: 'bottom' }}
                      onClick={() => {}}
                    >
                      <div
                        style={{
                          ...style.simpleRow,
                          alignItems: 'center',
                          verticalAlign: 'middle',
                          justifyContent: 'center',
                        }}
                      >
                        DECLINED
                      </div>
                    </Button>
                    <UncontrolledPopover
                      target="declined"
                      trigger="legacy"
                      flip={false}
                      modifiers={{ flip: { behavior: ['left'] }, preventOverflow: { boundariesElement: 'viewport' } }}
                    >
                      <PopoverHeader>Decline Reason</PopoverHeader>
                      <PopoverBody>
                        <div>
                          <div style={{ marginBottom: 10 }}>
                            {data && data.DeclinedComments ? data.DeclinedComments : 'No Reason given.'}
                          </div>
                          <div>
                            {' '}
                            <Button
                              size="sm"
                              disabled={isGettingCandidateDetails || isSaving}
                              color="danger"
                              onClick={() => {
                                Swal.fire({
                                  title: 'Warning!',
                                  text: `Are you sure?`,
                                  type: 'warning',
                                  showCancelButton: true,
                                  confirmButtonColor: 'red',
                                  confirmButtonText: `Remove`,
                                }).then((result) => {
                                  if (result && result.isConfirmed) {
                                    saveResults({
                                      candidatesToUpdate: [data.pk_Candidate],
                                      forcedSelectedCandidateId: data.pk_Candidate,
                                      fieldsToUpdate: {
                                        HasDeclinedInterview: false,
                                        DeclinedComments: '',
                                      },
                                      callback: () => {
                                        setShowInterviewDatePopover(false);
                                        getCandidateDetails(selectedCandidate);
                                      },
                                    });
                                  }
                                });
                              }}
                            >
                              {' '}
                              Remove Declined Status
                            </Button>
                          </div>
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </div>
                ) : (
                  <>
                    <Button
                      outline
                      disabled={isGettingCandidateDetails || isSaving}
                      name="interviewDate"
                      id="interviewDate"
                      onClick={() => {}}
                      style={{ width: '100%', height: 80, alignSelf: 'bottom' }}
                    >
                      <div style={{ ...style.simpleRow, alignItems: 'center', verticalAlign: 'middle' }}>
                        <div style={{ ...style.simpleColumn, width: 30 }}>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                        <div style={{ ...style.simpleColumn, width: '100%' }}>
                          {DateOfInterview
                            ? moment.tz(DateOfInterview, null).format('dddd, MMM. DD, YYYY')
                            : 'Not Registered'}
                          <br />
                          {data && data.Label ? `\n${data.Label}` : ''}
                        </div>
                      </div>
                      {renderDatePickerPopover()}
                    </Button>
                  </>
                )}
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row style={{ marginBottom: 20, marginTop: 10 }}>
          <Col style={{ width: '100%' }}>
            <ButtonGroup style={{ width: '100%' }}>
              <Button
                style={{
                  ...style.buttonLabel,
                  width: '25%',
                  border: '2px solid black',
                  color: activeTab === '1' ? 'black' : 'white',
                  backgroundColor: activeTab === '1' ? '#fffd7d' : '#0a91ff',
                }}
                onClick={() => {
                  if (!hasChanges) {
                    setActiveTab('1');
                  }
                  setTabToGo('1');
                  history.push('/administrator/candidates/dataFields');
                }}
              >
                Data Fields
              </Button>
              <Button
                style={{
                  ...style.buttonLabel,
                  width: '25%',
                  border: '2px solid black',
                  color: activeTab === '2' ? 'black' : 'white',
                  backgroundColor: activeTab === '2' ? '#fffd7d' : '#0a91ff',
                }}
                onClick={() => {
                  if (!hasChanges) {
                    setActiveTab('2');
                  }
                  setTabToGo('2');
                  history.push('/administrator/candidates/additionalDetails');
                }}
              >
                Additional Details
              </Button>
              <Button
                style={{
                  ...style.buttonLabel,
                  width: '25%',
                  border: '2px solid black',
                  color: activeTab === '3' ? 'black' : 'white',
                  backgroundColor: activeTab === '3' ? '#fffd7d' : '#0a91ff',
                }}
                onClick={() => {
                  if (!hasChanges) {
                    setActiveTab('3');
                  }
                  setTabToGo('3');
                  history.push('/administrator/candidates/documents');
                }}
              >
                Documents
              </Button>
              <Button
                style={{
                  ...style.buttonLabel,
                  width: '25%',
                  border: '2px solid black',
                  color: activeTab === '4' ? 'black' : 'white',
                  backgroundColor: activeTab === '4' ? '#fffd7d' : '#0a91ff',
                }}
                onClick={() => {
                  if (!hasChanges) {
                    setActiveTab('4');
                  }
                  setTabToGo('4');
                  history.push('/administrator/candidates/waitlist');
                }}
              >
                Waitlist
              </Button>
              <Button
                style={{
                  ...style.buttonLabel,
                  width: '25%',

                  border: '2px solid black',
                  color: activeTab === '5' ? 'black' : 'white',
                  backgroundColor: activeTab === '5' ? '#fffd7d' : '#0a91ff',
                }}
                onClick={() => {
                  if (!hasChanges) {
                    setActiveTab('5');
                  }
                  setTabToGo('5');
                  history.push('/administrator/candidates/communication');
                }}
              >
                Communication
              </Button>
            </ButtonGroup>
          </Col>
        </Row>

        <div style={{ ...style.spacedAroundRow }}>
          {activeTab === '2' ? (
            <Button
              color="primary"
              disabled={data == null || data.CandidateUUID == null}
              onClick={() => {
                window.open(
                  `/candidate/${Array.isArray(data.CandidateUUID) ? data.CandidateUUID[0] : data.CandidateUUID}`,
                  '_blank',
                );
              }}
            >
              <div style={style.spacedBetweenRow}>
                <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: 10 }} />{' '}
                <div>View Candidate's Date Selection</div>
              </div>
            </Button>
          ) : null}
          {activeTab === '2' ? (
            <Button
              color="primary"
              disabled={data == null || data.ScheduleUUID == null}
              onClick={() => {
                window.open(`/candidate/schedule/${data.ScheduleUUID}`, '_blank');
              }}
            >
              <div style={style.spacedBetweenRow}>
                <FontAwesomeIcon icon={faClipboardList} style={{ marginRight: 10 }} />{' '}
                <div>View Candidate's Interview Schedule</div>
              </div>
            </Button>
          ) : null}
        </div>
      </div>

      <div className="tabContainer" style={style.tabContainer}>
        <TabContent activeTab={activeTab} style={{ width: '100%', height: 'calc(100% - 20px)' }}>
          <TabPane tabId="1">
            <ScoresAndAwards
              selectedCandidate={detailedCandidate}
              dataTypes={dataTypes}
              saveResults={saveResults}
              isSaving={isSaving}
              isGettingCandidateDetails={isGettingCandidateDetails}
              setChanges={setChanges}
              changes={changes}
              resetFunctions={resetFunctions}
              abortController={abortController}
              getCandidateDetails={getCandidateDetails}
            />
          </TabPane>
          <TabPane tabId="2">
            <AdditionalDetails
              selectedCandidate={detailedCandidate}
              saveResults={saveResults}
              isSaving={isSaving}
              isGettingCandidateDetails={isGettingCandidateDetails}
              isUpdatingCandidateList={isUpdatingCandidateList}
              setChanges={setChanges}
              changes={changes}
              resetFunctions={resetFunctions}
              abortController={abortController}
              getCandidateDetails={getCandidateDetails}
            />
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <AddDocuments
                documentTypes={documentTypes}
                setDocumentTypes={setDocumentTypes}
                activeDepartment={activeDepartment}
                embedded={embedded}
                match={match}
                getCandidateDetails={getCandidateDetails}
                selectedCandidate={detailedCandidate}
                isSingleCandidate={isSingleCandidate}
                abortController={abortController}
                isLoading={isGettingCandidateDetails}
              />
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Waitlist
              selectedCandidate={detailedCandidate}
              setChanges={setChanges}
              changes={changes}
              resetFunctions={resetFunctions}
              saveResults={saveResults}
              abortController={abortController}
              getCandidateDetails={getCandidateDetails}
            />
          </TabPane>
          <TabPane tabId="5">
            <Communication
              selectedCandidate={detailedCandidate}
              columnSentItems={true}
              abortController={abortController}
            />
          </TabPane>
        </TabContent>
      </div>

      {renderOverBookedAlert()}
      {renderAlreadyScheduledAlert()}
      {renderTagLimitAlert()}
    </Col>
  );
};

export default CandidateWidget;
