import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, UncontrolledTooltip } from 'reactstrap';
import style from './TagFilter.style';
import { faCheckSquare, faSquare } from '@fortawesome/free-solid-svg-icons';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { fetchDataAgnostic } from '../../../../../Services/dataApi';

const TagFilter = ({
  tags = [],
  setSelectedTagFilter,
  selectedFilter,
  selectedSeason,
  isUpdating,
  mode,
  defaultModes,
  setMode,
  updateSeason,
}) => {
  const [tagChoices, setTagChoices] = useState([]);
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const isPrescreen = mode === defaultModes.prescreen;

  useEffect(() => {
    getTags();
    if (mode == null) {
      const { EnablePrescreenMode, AllowPrescreen } = dContext.season || {};

      if (EnablePrescreenMode && AllowPrescreen) {
        setMode(defaultModes.prescreen);
      } else {
        setMode(defaultModes.evaluation);
      }
    }
  }, []);

  const getTags = () => {
    // setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/tags',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setTagChoices(res.data);
            // setIsLoading(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const { AllowPrescreen } = (dContext && dContext.season) || {};

  return (
    <div style={{ height: 350 }}>
      <div id={'prescreen_container'}>
        <Button
          disabled={isUpdating || !AllowPrescreen}
          style={{ width: '100%', marginBottom: 5 }}
          color={isPrescreen ? 'success' : null}
          onClick={() => {
            setMode(defaultModes.prescreen);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={isPrescreen ? faCheckSquare : faSquare} />
            </div>
            <div style={style.centeredText}>Prescreen</div>
          </div>
        </Button>
        {!AllowPrescreen ? (
          <UncontrolledTooltip target="prescreen_container">Prescreen is disabled.</UncontrolledTooltip>
        ) : null}
      </div>
      <div>
        <Button
          disabled={isUpdating}
          style={{ width: '100%', marginBottom: 10 }}
          color={isPrescreen ? null : 'success'}
          onClick={() => {
            setMode(defaultModes.evaluation);
          }}
        >
          <div style={style.spacedBetweenRow}>
            <div>
              <FontAwesomeIcon icon={isPrescreen ? faSquare : faCheckSquare} />
            </div>
            <div style={style.centeredText}>Evaluation</div>
          </div>
        </Button>
      </div>
      <div style={{ height: 250, overflowY: 'scroll' }}>
        <div
          style={{ ...style.itemRow, backgroundColor: selectedFilter == null ? '#d9f5ff' : null }}
          onClick={() => {
            setSelectedTagFilter(null);
          }}
        >
          All Candidates
        </div>
        {tagChoices
          .sort((a, b) => {
            return a.Tag > b.Tag ? 1 : a.Tag < b.Tag ? -1 : 0;
          })
          .map((tag, i) => {
            const backgroundColor =
              selectedFilter && selectedFilter.pk_Tag == tag.pk_Tag ? '#d9f5ff' : i % 2 == 0 ? '#c7cbd1' : null;
            return (
              <div
                style={{ ...style.itemRow, backgroundColor }}
                value={tag.pk_Tag}
                onClick={() => {
                  setSelectedTagFilter(tag);
                }}
              >
                {tag.Tag}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TagFilter;
