import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { determineProgress, urlWithHttpProtocol } from '../../../../Common.functions';
import { Circle } from '../../../../Common.jsx';
import { sortByLastName } from '../../../../SortableList';
import { candidates } from '../../mockData';
import { renderAvatar } from './Common';

const url =
  'https://tools.druksys.com/rezrate/api/evalportal/?token=kv78czgx78yb6gr0un1rg896y34645126262063170788677958307489916498670013146389725866310kthb05bde';

// This Component appears to b eexclusively

const CandidatesAlphabetical = ({
  candidateInfo,
  changeToCandidateTab,
  disableJoinMeetingButton,
  getCandidate,
  handleZoomClick,
  hasSchedule,
  hideShowMeetingButton,
  interviewIsToday,
  isIncompleteCandidates,
  isMobile,
  isPrescreen,
  requestZoomMeeting,
  Schedules4Eval__pkUUID_Schedule,
  Schedules4EvalJoinManualURL,
  selectedCandidateIdLocal,
  selectedCandidate,
  setCarouselIndex,
  userIdOnCurrentInterview,
}) => {
  const [alphabetizedCandidates, setAlphabetizedCandidates] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  if (!candidateInfo || candidateInfo.length <= 0)
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: 'calc(100vh - 390px)',
        }}
      >
        {' '}
        {isPrescreen
          ? 'You have been assigned 0 Candidates to screen.'
          : hasSchedule
          ? 'There are no registered candidates for this session.'
          : 'The schedule has not been created yet.'}
        {/* {hasSchedule ? 'You have been assigned 0 Candidates to screen.' : 'The schedule has not been created yet.'} */}
      </div>
    );

  return (
    <div
      id="evaluation-table-wrapper"
      style={{ width: '100%', height: isMobile ? 'calc(100vh - 290px)' : 'calc(100vh - 475px)', overflowY: 'scroll' }}
    >
      <Table responsive={true} style={{ marginBottom: 0 }}>
        <tbody>
          {candidateInfo.map((item) => {
            const {
              FirstName,
              LastName,
              PreferredFirst,
              PreferredLast,
              pk_Candidate,
              EnableDoNotRank,
              Status,
              CandidateSubHeading,
              PhotoUrl,
            } = item;

            let chosenCandidate = false;

            if (item && selectedCandidate) {
              chosenCandidate = pk_Candidate === selectedCandidate.pk_Candidate;
            }

            const showMeetingButton = pk_Candidate === userIdOnCurrentInterview;

            return (
              <tr
                id={`candidates_scrollID_${pk_Candidate}`}
                key={pk_Candidate}
                className={'hover_blue ' + (chosenCandidate ? 'chosen-candidate' : '')}
                onClick={() => {
                  if (selectedCandidate === 'fetching') return;

                  if (isMobile && pk_Candidate === selectedCandidateIdLocal) {
                    changeToCandidateTab();
                  }

                  if (setCarouselIndex) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }
                  setSelectedId(item.pk_Candidate);
                  getCandidate(item.pk_Candidate, isIncompleteCandidates ? item.fk_InterviewDate : null);
                }}
                style={{ cursor: 'pointer' }}
              >
                <td style={{ width: '78px' }}>{renderAvatar(PhotoUrl, EnableDoNotRank)}</td>
                <td style={{ height: '100%' }}>
                  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h4 style={{ margin: 0, fontWeight: 700 }}>
                        {PreferredLast || LastName}, {PreferredFirst || FirstName}
                      </h4>
                      <p style={{ margin: 0, fontWeight: 700 }}>{CandidateSubHeading}</p>
                      {determineProgress(Status)}
                    </div>
                  </div>
                </td>
                <td>
                  {showMeetingButton && !hideShowMeetingButton && interviewIsToday && (
                    <Button
                      color="success"
                      disabled={disableJoinMeetingButton}
                      style={{ marginTop: '20px', minWidth: '95px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          typeof Schedules4EvalJoinManualURL !== 'undefined' &&
                          Schedules4EvalJoinManualURL !== '' &&
                          Schedules4EvalJoinManualURL !== null
                        ) {
                          handleZoomClick({ url: urlWithHttpProtocol(Schedules4EvalJoinManualURL) });
                        } else {
                          requestZoomMeeting(Schedules4Eval__pkUUID_Schedule);
                        }
                      }}
                    >
                      {disableJoinMeetingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Join Meeting'}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
};
export default CandidatesAlphabetical;
