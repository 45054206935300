import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './UserList.style';
import { faKey, faPortrait, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAlert } from 'react-alert';
import { Button, UncontrolledTooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { DepartmentContext } from 'DepartmentWrapper';
import moment from 'moment';

const SingleUser = ({
  user,
  selectedUser,
  index,
  setSelectedUser,
  filterBy,
  postUserInAuth,
  getUsers,
  hasUserDetailChanges,
  discardChanges,
}) => {
  const {
    UserPhotoUrl,
    UserFirst,
    UserLast,
    LastLoginDate,
    EnableAdmin,
    EnableAssistant,
    EnableEvaluator,
    EnablePointOfContact,
    LastLoggedIn,
    Auth0Id,
    LastLoggedInDepartment,
    Deleted,
    pk_User,
    PendingUserMigration,
    SingleSignOn,
  } = user;
  const [userStatusToolTipOpen, setUserStatusToolTipOpen] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [lastPasswordResetPast, setLastPasswordResetPast] = useState(0);
  const lastPasswordReset = useRef(null);
  const passwordResetChecker = useRef(null);
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();
  const passwordCheckerExpiry = 30;

  useEffect(() => {
    getLastPasswordReset();
    initializePasswordChecker();
  }, []);

  useEffect(() => {
    if (lastPasswordResetPast > passwordCheckerExpiry) {
      clearInterval(passwordResetChecker.current);
      setLastPasswordResetPast(0);
    }
  }, [lastPasswordResetPast]);
  const initializePasswordChecker = () => {
    if (passwordResetChecker.current) {
      clearInterval(passwordResetChecker.current);
      setLastPasswordResetPast(0);
    }
    passwordResetChecker.current = setInterval(() => {
      if (lastPasswordReset.current) {
        const difference = moment().diff(lastPasswordReset.current, 'seconds');
        setLastPasswordResetPast(difference);
      }
    });
  };

  const getLastPasswordReset = () => {
    let passWordObject = localStorage.getItem('lastPasswordReset');

    if (passWordObject) {
      passWordObject = JSON.parse(passWordObject);
    } else {
      passWordObject = {};
    }

    const newLastPasswordReset = passWordObject
      ? passWordObject[`${pk_User}_${dContext.department.pk_Department}`]
      : null;

    lastPasswordReset.current = newLastPasswordReset ? moment(newLastPasswordReset) : null;
  };

  const handlePost = async (type) => {
    setDisableButton(true);
    const result = await postUserInAuth(pk_User, type);
    if (result.error === true) {
      alert.error(`Something went wrong saving ${UserLast}, ${UserFirst}. Please contact support`);
    } else {
      let passWordObject = localStorage.getItem('lastPasswordReset');
      if (passWordObject) {
        passWordObject = JSON.parse(passWordObject);
      } else {
        passWordObject = {};
      }
      const newLastPasswordReset = moment();
      passWordObject[`${pk_User}_${dContext.department.pk_Department}`] = newLastPasswordReset;
      lastPasswordReset.current = newLastPasswordReset;
      localStorage.setItem('lastPasswordReset', JSON.stringify(passWordObject));
      initializePasswordChecker();
    }

    setDisableButton(false);
  };

  const renderUserStatusButton = () => {
    const renderFontAwesomeIcon = () => {
      return <FontAwesomeIcon icon={disableButton ? faSpinner : faKey} spin={disableButton} />;
    };
    const lastPasswordResetDifference = lastPasswordReset.current
      ? moment().diff(lastPasswordReset.current, 'seconds')
      : 0;

    if (SingleSignOn === true) {
      return (
        <>
          <Button
            color="primary"
            disabled={true}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handlePost('welcome');
            }}
            id={`tool_tip_resend_welcome${pk_User}`}
          >
            {renderFontAwesomeIcon()}
          </Button>
          <UncontrolledTooltip
            flip={false}
            placement="right"
            modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
            target={`tool_tip_resend_welcome${pk_User}`}
          >
            This user logs in with Single Sign On
          </UncontrolledTooltip>
        </>
      );
    }

    // if user has logged in then we can assume they have an auth0 id
    if (LastLoggedIn !== null) {
      return (
        <>
          <Button
            color="primary"
            disabled={disableButton || (lastPasswordReset.current && lastPasswordResetDifference < 10)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Swal.fire({
                icon: 'warning',
                text: 'Are you sure you want to reset this user password?',
                showCancelButton: true,
                confirmButtonText: `Reset`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.value) {
                  handlePost('reset');
                }
              });
            }}
            id={`tool_tip_reset${pk_User}`}
          >
            {renderFontAwesomeIcon()}
          </Button>
          <UncontrolledTooltip
            flip={false}
            placement="right"
            modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
            target={`tool_tip_reset${pk_User}`}
          >
            Send password reset
          </UncontrolledTooltip>
        </>
      );
    }

    // if auth0 id is not null but they have no previously logged in date they'res setup in auth0 but have
    // never accessed their account
    if (Auth0Id !== null) {
      return (
        <>
          <Button
            color="warning"
            disabled={disableButton || (lastPasswordReset.current && lastPasswordResetDifference < 10)}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              Swal.fire({
                icon: 'warning',
                text: 'Are you sure you want to reset this user password?',
                showCancelButton: true,
                confirmButtonText: `Reset`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.value) {
                  handlePost('welcome');
                }
              });
            }}
            id={`tool_tip_resend_welcome${pk_User}`}
          >
            {renderFontAwesomeIcon()}
          </Button>
          <UncontrolledTooltip
            flip={false}
            placement="right"
            modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
            target={`tool_tip_resend_welcome${pk_User}`}
          >
            User has never logged in (resend welcome)
          </UncontrolledTooltip>
        </>
      );
    }

    // if (PendingUserMigration === true) {
    //   return (
    //     <>
    //       <Button
    //         color="secondary"
    //         placement="right"
    //         modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
    //         disabled={disableButton}
    //         onClick={(e) => {
    //           e.preventDefault();
    //           e.stopPropagation();
    //           handlePost('welcome');
    //         }}
    //         id={`tool_tip_resend_welcome${pk_User}`}
    //       >
    //         {renderFontAwesomeIcon()}
    //       </Button>
    //       <UncontrolledTooltip
    //         flip={false}
    //         placement="right"
    //         modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
    //         target={`tool_tip_resend_welcome${pk_User}`}
    //       >
    //         Existing User has not yet logged into the new version of RezRATE
    //       </UncontrolledTooltip>
    //     </>
    //   );
    // }

    // if neither condition above is met, they are not registered in auth0

    return (
      <>
        <Button
          color="danger"
          disabled={disableButton || (lastPasswordReset.current && lastPasswordResetDifference < 10)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            Swal.fire({
              icon: 'warning',
              text: 'Are you sure you want to reset this user password?',
              showCancelButton: true,
              confirmButtonText: `Reset`,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.value) {
                handlePost('welcome');
              }
            });
          }}
          id={`tool_tip_welcome${pk_User}`}
        >
          {renderFontAwesomeIcon()}
        </Button>{' '}
        <UncontrolledTooltip
          flip={false}
          placement="right"
          modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          target={`tool_tip_welcome${pk_User}`}
        >
          Send welcome email
        </UncontrolledTooltip>
      </>
    );
  };

  if (Deleted && filterBy !== 'Deactivated') {
    return;
  }
  let Role = '';

  const roleLabels = {
    EnableAssistant: 'Assistant',
    EnableAdmin: 'Administrator',
    EnableEvaluator: 'Evaluator',
    EnablePointOfContact: 'Point of Contact',
  };

  const affixRole = (role, name) => {
    if (role) {
      if (Role === '') {
        Role = roleLabels[name];
      } else {
        Role += `, ${roleLabels[name]}`;
      }
    }
  };

  affixRole(EnableAdmin, 'EnableAdmin');
  affixRole(EnableAssistant, 'EnableAssistant');
  affixRole(EnableEvaluator, 'EnableEvaluator');
  affixRole(EnablePointOfContact, 'EnablePointOfContact');

  let backgroundColor = null;
  if (selectedUser && selectedUser.pk_User == user.pk_User) {
    backgroundColor = '#d9f5ff';
  } else if (index % 2 == 0) {
    backgroundColor = '#c7cbd1';
  }

  return (
    <div
      key={pk_User}
      id={`user_${pk_User}`}
      style={{ ...style.itemRow, backgroundColor }}
      onClick={() => {
        if (hasUserDetailChanges) {
          Swal.fire({
            title: 'Warning!',
            text: 'There are unsaved changes. Are you sure want to load a different user?',
            // showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Discard`,
            // denyButtonText: `Cancel`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.value) {
              if (discardChanges) {
                discardChanges();
                setSelectedUser(user);
              }
            }
          });
          return;
        } else {
          setSelectedUser(user);
        }
      }}
    >
      <div>
        {UserPhotoUrl ? (
          <img src={UserPhotoUrl} height={50} style={style.imgPlaceHolder} />
        ) : (
          <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
        )}
      </div>
      <div style={style.candidateInfo}>
        <div>{`${UserLast ? `${UserLast}, ` : ''} ${UserFirst ? UserFirst : ''}`}</div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: 11,
            fontWeight: 'bold',
            height: !Role ? 20 : null,
            color: !Role ? '#b10542' : 'black',
          }}
        >
          {Role || 'No Role'}
        </div>
      </div>

      <div>{renderUserStatusButton()}</div>
    </div>
  );
};

export default SingleUser;
