import React, { useState, useEffect, Fragment } from 'react';
import { faBuilding, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Popover, PopoverBody, PopoverHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OrganizationCreationAndEditWidget from './OrganizationCreationAndEditWidget';

const OrganizationListItem = ({
  OrganizationLogo,
  OrganizationName,
  setOrganizations,
  UserCount,
  DepartmentCount,
  OrganizationShortName,
  OrganizationWebsite,
  pk_Organization,
  updateOrganizationArrayValues,
  setSelectedOrganization,
  selectedOrganization,
  orgPopoverOpen,
  setOrgPopoverOpen,
}) => {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(pk_Organization === selectedOrganization);
  }, [selectedOrganization, pk_Organization]);

  const renderImage = () => {
    if (OrganizationLogo) return <img width="50px" height="50px" src={OrganizationLogo} alt={OrganizationName} />;

    return (
      <div>
        <FontAwesomeIcon icon={faBuilding} size="3x" />
      </div>
    );
  };

  const handleOrganizationToggle = (e) => {
    setOrgPopoverOpen((prevState) => {
      // if it matches the current organization, close it
      if (prevState === pk_Organization) return false;

      // if it doesn't match, open it
      return pk_Organization;
    });
  };
  const editOrganization = () => {};
  return (
    <Fragment>
      <td>
        {/* <div onClick={changeOrganization} style={{ cursor: 'pointer' }} className={isSelected ? 'selected' : ''}> */}
        <div>{renderImage()}</div>
      </td>
      <td>
        <div>
          <h6>{OrganizationName}</h6>
          <h6>Departments: {DepartmentCount}</h6>
          <h6>Users: {UserCount}</h6>
        </div>
        {/* </div> */}
      </td>
      <td>
        <div id={`Popover_${pk_Organization}`} onClick={handleOrganizationToggle}>
          <FontAwesomeIcon icon={faPencilAlt} />
        </div>
      </td>
      <td className="">
        <Popover
          className="admin_popover"
          placement="right"
          isOpen={orgPopoverOpen === pk_Organization}
          modifiers={{ flip: { behavior: ['bottom'] }, preventOverflow: { boundariesElement: 'viewport' } }}
          trigger="legacy"
          target={`Popover_${pk_Organization}`}
          toggle={handleOrganizationToggle}
        >
          <PopoverHeader>Organization</PopoverHeader>
          <PopoverBody>
            <OrganizationCreationAndEditWidget
              OrganizationLogo={OrganizationLogo}
              OrganizationName={OrganizationName}
              setOrganizations={setOrganizations}
              updateOrganizationArrayValues={updateOrganizationArrayValues}
              OrganizationWebsite={OrganizationWebsite}
              OrganizationShortName={OrganizationShortName}
              pk_Organization={pk_Organization}
            />
          </PopoverBody>
        </Popover>
      </td>
    </Fragment>
  );
};

export default OrganizationListItem;
