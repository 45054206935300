import { faCheckSquare, faCircle, faSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCheck,
  faChevronLeft,
  faChevronRight,
  faDotCircle,
  faExclamationCircle,
  faMagic,
  faPlusSquare,
  faRedo,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUserMd,
  faUserCircle,
  faInfo,
  faInfoCircle,
  faExclamationTriangle,
  faExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'Body/Statuses/Loading.js';
import { keys } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next'; // docs: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/about.html
import {
  Alert,
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import CustomInput from 'reactstrap/lib/CustomInput';
import Swal from 'sweetalert2';
import tinycolor from 'tinycolor2'; // docs: https://www.npmjs.com/package/tinycolor2
import { useAuth0 } from '../../../../auth0/reactAuth0Spa.js';
import { formatBearerToken, urlWithHttpProtocol } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../Services/dataApi';
import {
  addTimeToTimeSlots,
  clone,
  colors,
  generateSchedule,
  isCandidateWillBeUnscheduled,
  setupSplitCountTrackers,
  isTimeRangeOverlap,
  extractHeaderIds,
  getScheduleStartAndEndTime,
  generateScheduleColumn,
  clearTimeslots,
  clearColumns,
  setupSplitBlockCandidateTrackers,
} from '../../../../Services/schedule';
import AddColumnPopover from './AddColumnPopover';
import CloneModal from './CloneModal/CloneModal.js';
import ConfirmModal from './ConfirmModal';
import FlexEventDetailModal from './FlexEventDetailModal/FlexEventDetailModal.js';
import HeaderDetailDropDown from './HeaderDetailDropdown';
import './Schedule.style.css';
import ScheduleDetailModal from './ScheduleDetailModal';
import ScheduleWizard from './ScheduleWizard/ScheduleWizard.js';
import style from './style.js';
import TimeSlotHeaderPopover from './TimeSlotHeaderPopover';
import TimeSlotPopover from './TimeSlotPopover';
import TopBar from './TopBar';
import { useAlert } from 'react-alert';

let attendanceChecker = null;

let fileInputs = document.getElementsByClassName('swal2-file');

const Schedule = ({
  history,
  showTopBar,
  isEmbedded,
  isSuperUserPanel,
  pk_InterviewDate,
  forcedContext,
  embeddedRef,
  setParentScheduleData,
  setParentColumnTracker,
  setParentRowTracker,
  setParentCandidates,
  allowScheduleInteraction = false,
}) => {
  const [evaluators, setEvaluators] = useState({});
  const [candidates, setCandidates] = useState({}); // get from API, contains bgcolor
  const [candidatesArray, setCandidatesArray] = useState([]); // used for child components. turning object into array messes up useEffects in children watching that prop.
  const [scheduleData, setScheduleData] = useState(null);
  const scheduleLastUpdate = useRef();

  const [scheduleID, setScheduleID] = useState(null);

  const [generatedScheduleData, setGeneratedScheduleData] = useState(null);
  const [candidateLimit, setCandidateLimit] = useState(1);

  const [selectedCell, setSelectedCell] = useState(null);
  const [selectedScheduleBlock, setSelectedScheduleBlock] = useState(null); // Can't get it off of normal click events on table
  const [splitData, setSplitData] = useState(null); // data to be submitted before splitting. Used when Split confirm modal is triggered.

  const [showScheduleDetailModal, setShowScheduleDetailModal] = useState(false);
  const [showUnsavedChangeModal, setShowUnsavedChangeModal] = useState(false);
  const [showGenerateDataPanel, setShowGenerateDataPanel] = useState(false);
  const [showSplitCellModal, setShowSplitCellModal] = useState(false);
  const [showSplitCellConfirmModal, setShowSplitCellConfirmModal] = useState(false);
  const [showHeaderPopOver, setShowHeaderPopOver] = useState({});
  const [showTimeSlotPopover, setShowTimeSlotPopover] = useState({});
  const [showAddColumnPopover, setShowAddColumnPopover] = useState(false);
  const [showFlexEventDetailModal, setShowFlexEventDetailModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  // ====================Clone Schedule ========================//
  const [willCopyHeaders, setWillCopyHeaders] = useState(true);
  const [willCopyStartTime, setWillCopyStartTime] = useState(true);
  const [willCopyInterviewDuration, setWillCopyInterviewDuration] = useState(true);
  const [willCopyPassingDuration, setWillCopyPassingDuration] = useState(true);
  const [willCopyCandidateAssignments, setWillCopyCandidateAssignments] = useState(true);

  const [willCopyTimeSlots, setWillCopyTimeSlots] = useState(true);
  const [willCopyCustomEvents, setWillCopyCustomEvents] = useState(true);
  const [willCopyCustomDurations, setWillCopyCustomDurations] = useState(true);

  const [willCopyFlexEvents, setWillCopyFlexEvents] = useState(true);
  const [willCopyFlexEventEvaluators, setWillCopyFlexEventEvaluators] = useState(true);
  const [willCopyEvaluators, setWillCopyEvaluators] = useState(true);
  const [willCopyFocuses, setWillCopyFocuses] = useState(true);
  const [interviewDateToClone, setInterviewDateToClone] = useState();
  // ============================================================== //

  const [isAddingColumn, setIsAddingColumn] = useState(false);
  const [isGeneratingSchedule, setIsGeneratingSchedule] = useState(false);
  const [isAddingCandidate, setIsAddingCandidate] = useState(false);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const [isScheduleDataError, setIsScheduleDataError] = useState(false);

  // ====== For Load indicators on topbar ============== //
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isCloning, setIsCloning] = useState(false);
  // ==================================================== //

  const [saveHooks, setSaveHooks] = useState({});

  // ==================== Trackers ==============================//
  const [rowTracker, setRowTracker] = useState({}); // pk_Timeslot keys with array of candidates in them.
  const [columnTracker, setColumnTracker] = useState({}); // pk_ScheduleAssignment keys with array of candidates in them
  const [indexedFlexEvents, setIndexedFlexEvents] = useState({}); // pk_FlexEvent keys with flex event value
  const [indexedFlexEventEvaluators, setIndexedFlexEventEvaluators] = useState({}); // pk_FlexEvent keys with array of pk_User
  const [indexedEvaluatorFlexEvents, setIndexedEvaluatorFlexEvents] = useState({}); // pk_User with array of pk_FlexEvent
  const [splitCountTracker, setSplitCountTracker] = useState({});
  const [splitBlockCandidateTracker, setSplitBlockCandidateTracker] = useState({}); // pk_Candidate keys and array of pk_ScheduleBlock, SortOrder
  // ============================================================ //

  const [interviewDates, setInterviewDates] = useState([]);
  const [pendingCandidates, setPendingCandidates] = useState({}); // keys are "<pk_Timeslot>_<pk_ScheduleAssignment>". // For indicating which cells are being modified.
  const pendingCandidatesStateRef = useRef(); // Use this in accessing pendingCandidates inside callbacks/async
  const scheduleDataRef = useRef();
  const selectedCellRef = useRef();
  const selectedScheduleBlockRef = useRef();

  const [allowInterviewLimit, setAllowInterviewLimit] = useState(true);
  const [cellSplitCount, setCellSplitCount] = useState(1);

  const [headerHoveredOn, setHeaderHoveredOn] = useState(null);

  const [cellHoveredOn, setCellHoveredOn] = useState(null);
  const [token, setToken] = useState(null);

  const [attendanceData, setAttendanceData] = useState([]);
  const [interviewLimit, setInterviewLimit] = useState(null);
  const [dateIsInThePast, setDateIsInThePast] = useState(false);
  const [interviewDateIsToday, setInterviewDateIsToday] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // Used for table overflow handling
  const [hasFlexColumn, setHasFlexColumn] = useState(false); // Dummy. Or something. to enable/disable the Add Flex column button in ADd Column popover
  const [willEditThePast, setWillEditThePast] = useState(false);
  const [generateMode, setGenerateMode] = useState('ordered'); // ordered, distributed, intelligent
  const [currentTime, setCurrentTime] = useState(''); // value isn't actually used, more like a toggle to refresh the screen

  const [showScheduleWizard, setShowScheduleWizard] = useState(false);
  const [showPastScheduleWarning, setShowPastScheduleWarning] = useState();

  let hasFiredInactivityPopup = false;

  const alert = useAlert();
  const scheduleDate =
    scheduleData && scheduleData.metaData && scheduleData.metaData.StartDateAndTime
      ? moment.tz(scheduleData.metaData.StartDateAndTime, null)
      : null;

  const deptContext = useContext(DepartmentContext);
  const dContext = forcedContext ? forcedContext : deptContext;
  const inactivityWait = 15; //15 in minutes

  const { department = {} } = dContext;
  const { VirtualMeetingMode } = department;
  const { getTokenSilently, logout, loginWithRedirect } = useAuth0();

  fileInputs = document.getElementsByClassName('swal2-file');

  useEffect(() => {
    let fileInputs = document.getElementsByClassName('swal2-file');

    if (fileInputs && fileInputs.length > 0) {
      fileInputs[0].remove();
    }
  }, [fileInputs]);

  useEffect(() => {
    if (scheduleDate) {
      setDateIsInThePast(scheduleDate.isBefore(moment.tz()));
      setInterviewDateIsToday(scheduleDate.isSame(new Date(), 'day'));
    }
  }, [scheduleDate]);

  useEffect(() => {
    pendingCandidatesStateRef.current = clone(pendingCandidates);
  }, [pendingCandidates]);

  useEffect(() => {
    if (showCloneModal) {
      hideTimeSlotPopoversExcept();
      hideHeaderPopoversExcept();
      setShowAddColumnPopover(false);
    }
  }, [showCloneModal]);

  useEffect(() => {
    if (scheduleData) {
      scheduleDataRef.current = scheduleData;

      // ==============================hide timeslot popovers ====================//
      if (scheduleData.body) {
        const newShowTimeSlotPopover = {};
        scheduleData.body.forEach((r) => {
          newShowTimeSlotPopover[r.pk_Timeslot] = { self: false };
        });
        setShowTimeSlotPopover(newShowTimeSlotPopover);
      }
      //========================================================================//
    }
  }, [scheduleData]);

  useEffect(() => {
    if (indexedFlexEvents) {
      if (countFlexEvents() > 0) {
        setHasFlexColumn(true);
      }
    }
  }, [indexedFlexEvents]);

  useEffect(() => {
    selectedCellRef.current = clone(selectedCell);
  }, [selectedCell]);

  useEffect(() => {
    selectedScheduleBlockRef.current = clone(selectedScheduleBlock);
  }, [selectedScheduleBlock]);

  useEffect(() => {
    const interval = 60000;
    // const interval = 5000;
    attendanceChecker = setInterval(() => {
      if (scheduleDataRef.current) {
        const { StartTime, EndTime } = getScheduleStartAndEndTime(scheduleDataRef.current);
        const { headers, body } = scheduleDataRef.current;
        const now = moment(); //.format('MMM DD, YYYY hh:mm A');
        const isMeetingInProgress = now.isSameOrAfter(StartTime) && now.isSameOrBefore(EndTime); //isTimeRangeOverlap([StartTime, now], [now, EndTime]);

        if (isMeetingInProgress && headers.length > 0 && body.length > 1) {
          fetchAttendanceData();
        }
      }
    }, interval);

    getTokenSilently()
      .then((token) => {
        setToken(token);
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });

    getInterviewDates();
    const parent = document.getElementById('schedule_proper').parentElement;
    window.addEventListener('resize', () => {
      setWindowWidth(parent.clientWidth - 40);
    });
    fetchAttendanceData();
    setInterval(() => {
      setCurrentTime(moment().valueOf());
    }, 5000);

    document.addEventListener('mousemove', () => {
      const now = moment();
      localStorage.setItem('lastActivity', now.toISOString());
    });
    document.addEventListener('click', () => {
      const now = moment();
      localStorage.setItem('lastActivity', now.toISOString());
    });

    checkInactivity(true);

    setInterval(() => {
      checkInactivity();
    }, 10000);

    return () => {
      attendanceChecker && clearInterval(attendanceChecker);
      return;
    };
  }, []);

  useEffect(() => {
    if (pk_InterviewDate != null) {
      updateAllData();
    }
  }, [pk_InterviewDate]);

  useEffect(() => {
    if (Object.values(candidates)) {
      setCandidatesArray(Object.values(candidates));
    }
  }, [candidates]);
  // sets up trackers for flex events vs evaluators.
  const setupTrackers = (scheduleDataToTrack) => {
    const { headers = [], body = [] } = scheduleDataToTrack;
    const newRowTracker = {};
    const newColumnTracker = {};

    headers.forEach((header) => {
      if (newColumnTracker[header.pk_ScheduleAssignment] == null) {
        newColumnTracker[header.pk_ScheduleAssignment] = [];
      }
      body.forEach((timeSlotRow) => {
        const rowColumn = timeSlotRow[header.pk_ScheduleAssignment];
        if (newRowTracker[timeSlotRow.pk_Timeslot] == null) {
          newRowTracker[timeSlotRow.pk_Timeslot] = [];
        }
        if (rowColumn && rowColumn.ScheduleBlockEntries) {
          rowColumn.ScheduleBlockEntries.forEach((scheduleBlock) => {
            if (scheduleBlock.Candidates) {
              scheduleBlock.Candidates.forEach((candidate) => {
                if (!candidate) {
                  return;
                }

                newRowTracker[timeSlotRow.pk_Timeslot].push(candidate.pk_Candidate);
                newColumnTracker[header.pk_ScheduleAssignment].push(candidate.pk_Candidate);
              });
            } else {
            }
          });
        }
      });
    });

    setSplitCountTracker(setupSplitCountTrackers(scheduleDataToTrack));
    setSplitBlockCandidateTracker(setupSplitBlockCandidateTrackers(scheduleDataToTrack));
    setRowTracker(newRowTracker);
    setColumnTracker(newColumnTracker);
    if (setParentColumnTracker) {
      setParentColumnTracker(newColumnTracker);
    }

    if (setParentRowTracker) {
      setParentRowTracker(newRowTracker);
    }
  };

  const resetCopyValues = () => {
    setWillCopyHeaders(true);
    setWillCopyStartTime(true);
    setWillCopyInterviewDuration(true);
    setWillCopyPassingDuration(true);
    setWillCopyCandidateAssignments(true);
    setWillCopyTimeSlots(true);
    setWillCopyCustomEvents(true);
    setWillCopyCustomDurations(true);
    setWillCopyFlexEvents(true);
    setInterviewDateToClone(null);
  };

  const indexFlexEvents = (newScheduleData, evaluators = []) => {
    const newIndexedFlexEvents = {};
    const newIndexedEvaluatorFlexEvents = {};
    const newIndexedFlexEventEvaluators = {};

    // Object.keys(evaluators).forEach((key) => {
    //   const evaluator = evaluators[key];
    //   newIndexedEvaluatorFlexEvents[evaluator.pk_User] = [];
    //   if (evaluator.FlexEvents) {
    //     evaluator.FlexEvents.forEach((flexEvent) => {
    //       newIndexedEvaluatorFlexEvents[evaluator.pk_User].push(flexEvent.pk_FlexEvent);
    //       if (newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent] == null) {
    //         newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent] = [evaluator.pk_User];
    //       } else {
    //         newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent].push(evaluator.pk_User);
    //       }

    //       newIndexedFlexEvents[flexEvent.pk_FlexEvent] = flexEvent;
    //     });
    //   }
    // });

    const { metaData = {} } = newScheduleData || {};
    const { FlexEvents = [] } = metaData;
    FlexEvents.forEach((flexEvent) => {
      const { pk_FlexEvent, FlexDateStart, FlexDateEnd } = flexEvent;
      const flexEventStartDate = FlexDateStart;
      const flexEventEndDate = FlexDateEnd;

      if (
        // (flexEventStartDate === metaData.DateOfInterview || flexEventEndDate === metaData.DateOfInterview) &&
        newIndexedFlexEvents[pk_FlexEvent] == null
      ) {
        newIndexedFlexEvents[pk_FlexEvent] = flexEvent;

        // indexedEvaluatorFlexEvents
        flexEvent.AttendingUsers.forEach((evaluator) => {
          const evaluatorFlexEvents = clone(newIndexedEvaluatorFlexEvents[evaluator.pk_User]) || [];

          if (
            !evaluatorFlexEvents.find((pk_FlexEvent) => {
              return pk_FlexEvent == flexEvent.pk_FlexEvent;
            })
          ) {
            evaluatorFlexEvents.push(flexEvent);
          }

          newIndexedEvaluatorFlexEvents[evaluator.pk_User] = evaluatorFlexEvents;
        });
      }
    });

    const eventKeys = Object.keys(newIndexedFlexEvents);

    // TODO: Remove this altogether. Get Evaluators off of Flex Events,
    // flexEvent.evaluators or something. Jeff needs to do it first
    Object.keys(evaluators).forEach((key) => {
      const evaluator = evaluators[key];
      const evaluatorFlexEventsActual = []; // Flex Events off of metaData.FlexEvents. Has better shape/
      if (evaluator.FlexEvents) {
        evaluator.FlexEvents.forEach((flexEvent, i) => {
          if (eventKeys.includes(flexEvent.pk_FlexEvent.toString())) {
            const oldValue = newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent];
            evaluatorFlexEventsActual.push(newIndexedFlexEvents[flexEvent.pk_FlexEvent.toString()]);
            if (oldValue) {
              if (!oldValue.includes(evaluator.pk_User.toString())) {
                oldValue.push(evaluator.pk_User);
                newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent] = oldValue;
              }
            } else {
              newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent] = [evaluator.pk_User];
            }
          }
        });
      }

      // newIndexedEvaluatorFlexEvents[evaluator.pk_User] = evaluatorFlexEventsActual || [];
    });

    setIndexedFlexEvents(newIndexedFlexEvents);
    setIndexedEvaluatorFlexEvents(newIndexedEvaluatorFlexEvents);
    setIndexedFlexEventEvaluators(newIndexedFlexEventEvaluators);

    return { newIndexedFlexEvents, newIndexedEvaluatorFlexEvents, newIndexedFlexEventEvaluators };
  };

  const fetchSchedule = (token, options) => {
    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = !isEmbedded ? pathExploded[pathExploded.length - 1] : pk_InterviewDate;
    setScheduleID(scheduleID);

    if (scheduleData == null) {
      setIsTableLoaded(false);
    }

    setIsFetching(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/interviewDate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: parseInt(scheduleID),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            const { resolve } = options || {};
            const timeZone = dContext.department.timeZone;

            if (res.data.metaData == null || res.data.metaData.StartDateAndTime == null) {
              Swal.fire({
                title: 'Broken Schedule',
                icon: 'error',
                text:
                  'Problematic schedule data detected! Closing this popup would redirect to the Interview dates list.',
                type: 'error',
              }).then((result) => {
                if (result.value) {
                  history.push('/administrator');
                }
              });
            } else {
              try {
                // debugger;
                const tempSchedData = res.data;
                tempSchedData.headers = sortHeaders(res.data);

                const scheduleDataWithTime = addTimeToTimeSlots(tempSchedData, timeZone);

                setupTrackers(scheduleDataWithTime);

                if (resolve) {
                  resolve(scheduleDataWithTime);
                  return;
                }

                setIsFetching(false);

                fetchEvaluators(token, scheduleDataWithTime, options);
              } catch (err) {
                console.log('err', err);
              }
            }
          })
          .catch((e) => {
            const { reject } = options || {};
            if (reject) {
              reject(e);
              return;
            }

            setIsFetching(false);
            setIsGeneratingSchedule(false);
            setIsScheduleDataError(true);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const fetchCandidates = (token, options) => {
    setIsFetching(true);
    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/candidates',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: parseInt(scheduleID),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            const { resolve } = options || {};
            const newCandidates = { ...res.data };

            let colorKey = 0; // Math.floor(Math.random() * colors.length);
            Object.keys(newCandidates).forEach((key, i) => {
              if (colorKey >= colors.length) {
                colorKey = 0;
              }
              newCandidates[key].color = colors[colorKey];
              newCandidates[key].originalColor = colors[colorKey];
              colorKey++;
            });

            if (resolve) {
              resolve(newCandidates);
              return;
            }
            setIsFetching(false);
            setCandidates(newCandidates);
            if (setParentCandidates) {
              setParentCandidates(newCandidates);
            }
          })
          .catch((e) => {
            setIsFetching(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const fetchAttendanceData = (willUpdateAllData = true) => {
    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/interviewDate/attendance',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: parseInt(scheduleID),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setAttendanceData(res.data.Attendances);
            const scheduleLastUpdated = moment.tz(res.data.ScheduleLastUpdated, null);
            if (
              willUpdateAllData &&
              (!scheduleLastUpdate.current ||
                (scheduleLastUpdate.current && scheduleLastUpdate.current.isBefore(scheduleLastUpdated)))
            ) {
              scheduleLastUpdate.current = scheduleLastUpdated;
              updateAllData();
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getPendingCandidates = () => {
    return clone(pendingCandidates);
  };

  const getInterviewDates = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/InterviewDates',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setInterviewDates(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getUpdatedSelectedCellFromScheduleData = (oldCell, newScheduleData) => {
    const { row, column } = oldCell;
    const { body } = newScheduleData;

    const headers = makeColumns(newScheduleData);
    let newColumnIndex = 0;
    let newRowIndex = 0;
    const newRow = body.find((b, i) => {
      if (b.pk_Timeslot == row.pk_Timeslot) {
        newRowIndex = i;
      }

      return b.pk_Timeslot == row.pk_Timeslot;
    });

    const newColumn = headers.find((h, i) => {
      if (h.dataField == column.dataField) {
        newColumnIndex = i;
      }
      return h.dataField == column.dataField;
    });

    const newCell = {
      row: newRow,
      column: newColumn,
      columnIndex: newColumnIndex,
      rowIndex: newRowIndex,
    };
    return newCell;
  };

  const getUpdatedScheduleBlockFromSelectedCell = (oldBlock, newSelectedCell) => {
    const { pk_ScheduleBlock } = oldBlock;
    const { row, column } = newSelectedCell;
    if (!column) {
      return null;
    }
    try {
      const cellActual = row[column.dataField];
      const ScheduleBlockEntries = cellActual.ScheduleBlockEntries || [];
      const newBlock = ScheduleBlockEntries.find((s) => {
        return s.pk_ScheduleBlock == pk_ScheduleBlock;
      });
      return newBlock;
    } catch (e) {
      console.log('getUpdatedScheduleBlockFromSelectedCell error: ', e);
    }
  };

  const updateSelectedCellAndBlockFromScheduleData = (newScheduleData) => {
    if (
      selectedCellRef.current &&
      selectedCellRef.current.rowIndex == selectedCell.rowIndex &&
      selectedCellRef.current.columnIndex == selectedCell.columnIndex
    ) {
      const newSelectedCell = getUpdatedSelectedCellFromScheduleData(selectedCellRef.current, newScheduleData);
      setSelectedCell(newSelectedCell);

      if (
        selectedScheduleBlockRef.current &&
        selectedScheduleBlockRef.current.pk_ScheduleBlock == selectedScheduleBlock.pk_ScheduleBlock
      ) {
        const newSelectedBlock = getUpdatedScheduleBlockFromSelectedCell(
          selectedScheduleBlockRef.current,
          newSelectedCell,
        );
        setSelectedScheduleBlock(newSelectedBlock);
      }
    }

    // const newSelectedBlock = getUpdatedScheduleBlockFromSelectedCell(selectedScheduleBlockRef.current, newSelectedCell);

    // if (
    //   selectedCellRef.current &&
    //   selectedCellRef.current.rowIndex == newSelectedCell.rowIndex &&
    //   selectedCellRef.current.columnIndex == newSelectedCell.columnIndex
    // ) {
    //   setSelectedCell(newSelectedCell);
    // }
    // if (
    //   selectedScheduleBlockRef.current &&
    //   selectedScheduleBlockRef.current.pk_ScheduleBlock == newSelectedBlock.pk_ScheduleBlock
    // ) {
    //   setSelectedScheduleBlock(newSelectedBlock);
    // }
  };

  // NOTE: This is the last fetch to be done on refresh of table data. Reset
  // states here, like generatedScheduleData, so that all data is loaded at the same
  // time. This is to avoid gaps in cells when some data are loaded before others.
  const fetchEvaluators = (token, newScheduleData = {}, options) => {
    setIsGeneratingSchedule(true);
    setIsFetching(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/users',
          {
            pk_Department: dContext.department.pk_Department,
            IncludeEvaluators: 1,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            const { resolve } = options || {};
            if (resolve) {
              resolve(res.data);
              return;
            }
            setIsFetching(false);
            if (newScheduleData) {
              const indexedData = indexFlexEvents(newScheduleData, res.data);
              const scheduleDataWithFlexEvents = attachFlexEvents(newScheduleData, res.data, indexedData);
              setIsGeneratingSchedule(false);
              if (selectedCell && selectedScheduleBlock) {
                updateSelectedCellAndBlockFromScheduleData(scheduleDataWithFlexEvents);
              }
              setScheduleData(scheduleDataWithFlexEvents);
              if (setParentScheduleData) {
                setParentScheduleData(scheduleDataWithFlexEvents);
              }

              setGeneratedScheduleData(null);

              setEvaluators(res.data);
            }

            setIsTableLoaded(true);

            const { callingPendingCandidates = [] } = options || {};
            if (callingPendingCandidates.length) {
              const debuggerObj = { callingPendingCandidates, pendingCandidates: getPendingCandidates() };

              const newPendingCandidates = clone(pendingCandidatesStateRef.current);
              callingPendingCandidates.forEach((c) => {
                if (newPendingCandidates[c]) {
                  delete newPendingCandidates[c];
                }
              });

              setPendingCandidates(clone(newPendingCandidates));
            }
          })
          .catch((e) => {
            const { reject } = options || {};
            if (reject) {
              reject(e);
              return;
            }
            setIsFetching(false);
            setIsGeneratingSchedule(false);
            setIsTableLoaded(true);
          });
      })
      .catch((err) => {
        console.log('fetchEvaluators getToken err: ', err);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addColumn = async (data, resolve, reject, callback) => {
    if (dateIsInThePast && !willEditThePast) {
      const continueProcess = await fireConfirmationForEditingPastSchedule();
      if (!continueProcess) {
        return;
      }
    }
    if (isInPreviewMode()) {
      setShowUnsavedChangeModal(!isSubmitting);
      return;
    }

    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    let payload = data;

    if (Array.isArray(payload)) {
      payload = {
        payload: data,
      };
    } else {
      const { evaluatorList, TotalNumberOfSplits, pk_MeetingRoom } = data;

      payload = {
        payload: [
          {
            TotalNumberOfSplits: TotalNumberOfSplits,
            pk_MeetingRoom: pk_MeetingRoom,
            evaluatorPrimaryKeys: evaluatorList || [],
          },
        ],
      };
    }
    setIsAddingColumn(true);
    setIsSubmitting(true);

    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/schedule/column',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: parseInt(scheduleID),
          },
          payload,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsSubmitting(false);
            if (callback) {
              callback(true);
              // return;
            }
            if (resolve) {
              resolve(true);
            } else {
              setIsAddingColumn(false);
              setShowAddColumnPopover(false);
              fetchSchedule(token);
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            if (callback) {
              callback(false);
            }
            if (reject) {
              reject();
            } else {
              setIsAddingColumn(false);
            }
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addColumns = async (data, callback) => {
    if (dateIsInThePast && !willEditThePast) {
      const continueProcess = await fireConfirmationForEditingPastSchedule();
      if (!continueProcess) {
        return;
      }
    }
    if (isInPreviewMode()) {
      setShowUnsavedChangeModal(!isSubmitting);
      return;
    }
    const { evaluatorList } = data;
    setIsAddingColumn(true);
    setIsSubmitting(true);
    Promise.all(
      evaluatorList.map((evaluator) => {
        return new Promise((resolve, reject) => {
          addColumn({ evaluatorList: Array.isArray(evaluator) ? evaluator : [evaluator] }, resolve, reject);
        });
      }),
    )
      .then((values) => {
        setIsSubmitting(false);
        setIsAddingColumn(false);
        setShowAddColumnPopover(false);
        fetchSchedule(token);
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        setIsSubmitting(false);
        setIsAddingColumn(false);
      });
  };

  const onDeleteColumn = async (columnToDelete, resolve, reject, callback) => {
    if (dateIsInThePast && !willEditThePast) {
      const continueProcess = await fireConfirmationForEditingPastSchedule();
      if (!continueProcess) {
        return;
      }
    }
    setIsSubmitting(true);
    if (isInPreviewMode()) {
      setShowUnsavedChangeModal(!isSubmitting);
      return;
    }
    // TODO: Update to use Room id or something, need actual data
    const newEvaluators = { ...evaluators };
    const newShowHeaderPopOver = { ...showHeaderPopOver };
    delete newShowHeaderPopOver[columnToDelete.dataField];

    setShowHeaderPopOver(newShowHeaderPopOver);
    setEvaluators(newEvaluators);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/schedule/column',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: parseInt(scheduleID),
          },
          {
            pk_ScheduleAssignments: Array.isArray(columnToDelete) ? columnToDelete : [columnToDelete.dataField],
            type: 'delete',
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (callback) {
              callback(true);
              return;
            }

            if (resolve) {
              resolve(true);
            } else {
              setIsSubmitting(false);
              fetchSchedule(token);
              fetchCandidates(token);
            }
          })
          .catch((err) => {
            if (callback) {
              callback(false);
            }
            if (reject) {
              reject();
            } else {
              setIsSubmitting(false);
            }
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onClearColumn = async (columnsToClear, callBack) => {
    // const newScheduleData = clearColumns(clone(scheduleData), columnsToClear);

    // await submitGeneratedSchedule(newScheduleData.body);

    setIsSubmitting(true);

    return getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/schedule/column',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            pk_ScheduleAssignments: columnsToClear,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (callBack) {
              callBack(res);
            }
            setIsSubmitting(false);
            fetchSchedule(token);
            fetchCandidates(token);
            return true;
          })
          .catch((err) => {
            updateAllData({ pendingItemKey: 'all' });
            setTimeout(() => {
              setIsSubmitting(false);
              setIsGeneratingSchedule(false);
            }, 1000);
            return false;
          })
          .finally(() => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addFlexColumn = () => {
    setHasFlexColumn(true);
  };

  const removeFlexColumn = () => {
    setHasFlexColumn(false);
  };

  const cloneFromExistingSchedule = () => {
    setIsCloning(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/interviewDate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: parseInt(interviewDateToClone.pk_InterviewDate),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            const scheduleDataToClone = res.data;
            const { metaData = {} } = scheduleDataToClone;
            const { StartDateAndTime, StandardDurationInMinutes, StandardPassingDurationInMinutes } = metaData;

            const itemsToClone = [];

            if (willCopyHeaders) {
              const processHeaders = new Promise((resolve, reject) => {
                cloneHeaders(scheduleDataToClone, resolve, reject);
              });
              itemsToClone.push(processHeaders);
            }

            if (willCopyStartTime || willCopyInterviewDuration || willCopyPassingDuration) {
              const processDurations = new Promise((resolve, reject) => {
                const payload = {};
                if (willCopyStartTime) {
                  payload.startTime = moment.tz(StartDateAndTime, null).format('HH:mm');
                }
                if (willCopyInterviewDuration) {
                  payload.standardDuration = StandardDurationInMinutes;
                }
                if (willCopyPassingDuration) {
                  payload.standardPassingDuration = StandardPassingDurationInMinutes;
                }

                cloneDurations(payload, resolve, reject);
              });

              itemsToClone.push(processDurations);
            }

            if (willCopyTimeSlots) {
              const processCustomEvents = new Promise((resolve, reject) => {
                cloneTimeSlots({ scheduleDataToClone }, resolve, reject);
              });

              itemsToClone.push(processCustomEvents);
            }

            if (willCopyFlexEvents) {
              const processFlexEvents = new Promise((resolve, reject) => {
                cloneFlexEvents({ scheduleDataToClone }, resolve, reject);
              });
              itemsToClone.push(processFlexEvents);
            }

            Promise.all(itemsToClone)
              .then((res) => {
                Swal.fire(
                  'Clone Success!',
                  `Schedule configuration copied from  ${
                    interviewDateToClone && interviewDateToClone.DateOfInterview
                      ? moment(interviewDateToClone.DateOfInterview).format('MMM DD, YYYY')
                      : ''
                  } ${interviewDateToClone.Label || '(No Label)'}`,
                  'success',
                );
                setIsCloning(false);
                setIsSubmitting(false);
                setShowCloneModal(false);
                setInterviewDateToClone(null);
                updateAllData();
              })
              .catch((err) => {
                setIsCloning(false);
                setIsSubmitting(false);
                Swal.fire({
                  title: 'Failed Cloning',
                  icon: 'error',
                  text: 'Cloning process failed partway through.',
                  type: 'error',
                });
              });
          })
          .catch((err) => {
            Swal.fire({
              title: 'Failed Cloning',
              icon: 'error',
              text: 'Cloning process failed partway through.',
              type: 'error',
            });
            setIsCloning(false);
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const cloneHeaders = (scheduleDataToClone, resolve, reject, callback) => {
    const headersToCopy = scheduleDataToClone.headers
      ? scheduleDataToClone.headers.map((header) => {
          const { ColumnTotalNumberSplits, pk_MeetingRoom } = header;
          const headerEvaluators = [];

          if (header.Evaluators && header.Evaluators.Evaluators) {
            header.Evaluators.Evaluators.forEach((e) => {
              headerEvaluators.push(e.pk_User);
            });
          } else {
            // return {};
          }
          const toReturn = {
            TotalNumberOfSplits: ColumnTotalNumberSplits,
            evaluatorPrimaryKeys: headerEvaluators,
            pk_MeetingRoom,
          };
          return toReturn;
        })
      : [];

    const headersToDelete = scheduleData.headers.map((header) => {
      return header.pk_ScheduleAssignment;
    });

    onDeleteColumn(headersToDelete, null, null, (res) => {
      if (headersToCopy && headersToCopy.length > 0) {
        addColumn(headersToCopy, null, null, (res) => {
          if (res) {
            resolve(true);
          } else {
            reject(res);
          }
        });
      } else if (callback) {
        // fetchSchedule();
        callback();
      } else {
        if (res) {
          resolve(true);
        } else {
          reject(res);
        }
      }
    });
  };

  const cloneDurations = (data, resolve, reject, callback) => {
    const { department, season } = dContext;
    const { startTime, standardDuration, standardPassingDuration } = data;
    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];

    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: department.pk_Department,
            pk_InterviewDate: scheduleID,
            pk_Season: season.pk_Season,
          },
          {
            entry: {
              StartTime: startTime,
              StandardDurationInMinutes: standardDuration,
              StandardPassingDurationInMinutes: standardPassingDuration,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (callback) {
              callback();
            }

            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const cloneTimeSlots = (data, resolve, reject, callback) => {
    postDataAgnostic(
      'department/season/schedule/timeslots/duplicate',
      {
        pk_Department: dContext.department.pk_Department,
        includeEvaluators: willCopyHeaders, // willCopyFlexEventEvaluators ? 1 : 0,
      },
      {
        pk_InterviewDate_CopiedTo: parseInt(scheduleID),
        pk_InterviewDate_CopiedFrom: interviewDateToClone.pk_InterviewDate,
      },
      formatBearerToken(token),
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  };

  const cloneFlexEvents = (data, resolve, reject, callback) => {
    const { scheduleDataToClone } = data;
    const metaDataOld = scheduleData.metaData || {};
    const metaDataToClone = scheduleDataToClone.metaData || {};

    postDataAgnostic(
      'department/season/schedule/flexEvents/duplicate',
      {
        pk_Department: dContext.department.pk_Department,
        includeEvaluators: willCopyEvaluators ? 1 : 0,
      },
      {
        pk_InterviewDate_CopiedTo: parseInt(scheduleID),
        pk_InterviewDate_CopiedFrom: interviewDateToClone.pk_InterviewDate,
      },
      formatBearerToken(token),
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  };

  // TO DO: Separate API for auto-fill column
  const submitGeneratedSchedule = (newSchedule) => {
    setIsGeneratingSchedule(true);
    setIsSubmitting(true);
    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];

    return getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/schedule/generate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: parseInt(scheduleID),
          },
          {
            schedule: newSchedule,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsSubmitting(false);
            updateAllData({ pendingItemKey: 'all' });
            hideHeaderPopoversExcept();
            return true;
          })
          .catch((err) => {
            updateAllData({ pendingItemKey: 'all' });
            setTimeout(() => {
              setIsSubmitting(false);
              setIsGeneratingSchedule(false);
            }, 1000);
            return false;
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addCandidateToSchedule = (data) => {
    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];
    const candidateKeys = data.candidates.map((c) => {
      return c.pk_Candidate;
    });

    const candidatesToDelete =
      selectedScheduleBlock && selectedScheduleBlock.Candidates
        ? selectedScheduleBlock.Candidates.map((c) => {
            return c.pk_Candidate;
          })
        : [];

    const pendingItemKey = `${data.pk_Timeslot}_${data.pk_ScheduleAssignment}${
      selectedScheduleBlock ? `_${selectedScheduleBlock.SortOrder}` : `_0`
    }`;
    // ${timeSlot}_${h}${scheduleBlockCount > 1 ? `_${scheduleBlockIndex}` : `_0`}
    const newCandidates = clone(pendingCandidates);

    // Potential breakage here. Untested with Split cells.
    newCandidates[pendingItemKey] = data.candidates.slice();

    setPendingCandidates(newCandidates);

    setIsSubmitting(true);
    setIsAddingCandidate(true);
    setShowScheduleDetailModal(false);
    setIsGeneratingSchedule(true);

    getTokenSilently()
      .then((token) => {
        // Add to Existing schedule blcok.
        if (selectedScheduleBlock && selectedScheduleBlock.pk_ScheduleBlock) {
          putData(
            'department/season/schedule/candidate',
            {
              pk_Department: dContext.department.pk_Department,
              pk_ScheduleBlock: selectedScheduleBlock ? selectedScheduleBlock.pk_ScheduleBlock : null,
              pk_interviewDate: Number(scheduleID),
            },
            {
              SortOrder: selectedScheduleBlock.SortOrder,
              writeCandidatePrimaryKeys: candidateKeys || [],
              deleteCandidatePrimaryKeys: candidatesToDelete || [],
            },
            formatBearerToken(token),
          )
            .then((res) => {
              setIsSubmitting(false);
              setIsAddingCandidate(false);
              updateAllData({ pendingItemKey });
              // setSelectedScheduleBlock(null);
            })
            .catch((e) => {
              if (e.response && e.response.status == 400) {
                Swal.fire({
                  title: 'Outdated Schedule',
                  text:
                    'Unable to add candidate. Schedule may have been modified elsewhere. Click ok to reload schedule.',
                  icon: 'info',
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.value) {
                    updateAllData();
                  }
                });
              }
              setIsAddingCandidate(false);
              setIsSubmitting(false);
              const newPendingCandidates = clone(pendingCandidatesStateRef.current);
              delete newPendingCandidates[pendingItemKey];
              setPendingCandidates(newPendingCandidates);
            });
        } else {
          // Add a new Schedule block with candidates
          const { pk_ScheduleAssignment, pk_Timeslot } = data;

          postDataAgnostic(
            'department/season/schedule/block',
            {
              pk_Department: dContext.department.pk_Department,
              pk_ScheduleAssignment,
              pk_InterviewDate: scheduleID,
              pk_Timeslot,
            },
            {
              SortOrder:
                selectedScheduleBlock && selectedScheduleBlock.SortOrder != null
                  ? selectedScheduleBlock.SortOrder
                  : null,
              candidates: candidateKeys || [],
            },
            formatBearerToken(token),
          )
            .then((res) => {
              setIsSubmitting(false);
              setIsAddingCandidate(false);
              updateAllData({ pendingItemKey });
              // setSelectedScheduleBlock(null);
              // ======to replicate in Promise.all========//
              // fetchSchedule(token, { callingPendingCandidates: [pendingItemKey], newCandidates });
              // fetchCandidates(token);
              // ============================================//
            })
            .catch((e) => {
              // Add handling for when api sends error for current schedule being outdated, and to re-fetch schedule before populating again.

              if (e.response && e.response.status == 400) {
                Swal.fire({
                  title: 'Outdated Schedule',
                  text:
                    'Unable to add candidate. Schedule may have been modified elsewhere. Click ok to reload schedule.',
                  icon: 'info',
                }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.value) {
                    updateAllData();
                  }
                });
              }

              setIsAddingCandidate(false);
              setIsSubmitting(false);
              const newPendingCandidates = clone(pendingCandidatesStateRef.current);
              delete newPendingCandidates[pendingItemKey];
              setPendingCandidates(newPendingCandidates);
            });
        }
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const clearScheduleBlock = (blocksToClear, callback) => {
    setIsSubmitting(true);
    setIsGeneratingSchedule(true);
    getTokenSilently()
      .then((token) => {
        if (blocksToClear && blocksToClear.length > 0) {
          Promise.all(
            blocksToClear.map((b) => {
              return new Promise((resolve, reject) => {
                deleteData(
                  'department/season/schedule/block',
                  {
                    pk_Department: dContext.department.pk_Department,
                    pk_InterviewDate: Number(scheduleID),
                    pk_ScheduleBlock: b,
                  },
                  null,
                  formatBearerToken(token),
                )
                  .then((res) => {
                    resolve();
                  })
                  .catch((err) => {
                    reject();
                  });
              });
            }),
          )
            .then((res) => {
              if (callback) {
                callback();
              } else {
                setIsSubmitting(false);
                fetchSchedule(token);
                fetchCandidates(token);
                setShowScheduleDetailModal(false);
              }
            })
            .catch((err) => {
              setIsSubmitting(false);
            });
        } else {
          deleteData(
            'department/season/schedule/block',
            {
              pk_Department: dContext.department.pk_Department,
              pk_InterviewDate: Number(scheduleID),
              pk_ScheduleBlock: selectedScheduleBlock.pk_ScheduleBlock,
            },
            null,
            formatBearerToken(token),
          )
            .then((res) => {
              setIsSubmitting(false);
              fetchSchedule(token);
              fetchCandidates(token);
              setShowScheduleDetailModal(false);
            })
            .catch((err) => {
              setIsSubmitting(false);
            });
        }
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addUnavailableSlot = async (data, resolve, reject, callback) => {
    if (dateIsInThePast && !willEditThePast) {
      const continueProcess = await fireConfirmationForEditingPastSchedule();
      if (!continueProcess) {
        return;
      }
    }

    const {
      newEvaluators,
      candidates,
      FlexTimeStart,
      FlexTimeEnd,
      EventName,
      StartDateTime,
      EndDateTime,
      FlexEventName,
      hideScheduleDetailModal = true,
    } = data;

    if (isInPreviewMode()) {
      setShowUnsavedChangeModal(!isSubmitting);
      return;
    }
    setIsSubmitting(true);
    setIsGeneratingSchedule(true);

    if (hideScheduleDetailModal) {
      setShowScheduleDetailModal(false);
    }

    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];

    const { metaData } = scheduleData;
    const { DateOfInterview } = metaData;
    const monthDate = DateOfInterview
      ? moment.tz(DateOfInterview, null).format('MMM DD, YYYY')
      : moment.tz(null, null).format('MMM DD, YYYY');

    const momentStart = moment.tz(`${monthDate} ${FlexTimeStart}`, null);
    const momentEnd = moment.tz(`${monthDate} ${FlexTimeEnd}`, null);

    const event = {
      // StartDateTime,
      EndDateTime,
      EnableForAllUsers: false,
      EnableForAllCandidates: false,
      EnableAbsoluteTime: true,
      EventName: EventName ? EventName : FlexEventName ? FlexEventName : 'UNAVAILABLE',
      FlexTimeStart,
      FlexTimeEnd,
    };

    event.FlexDateStart = momentStart.format('YYYY-MM-DD');
    event.FlexDateEnd = momentEnd.format('YYYY-MM-DD');

    const params = {
      payload: [
        {
          event,
          evaluators: newEvaluators || [],
          candidates: candidates || [],
        },
      ],
    };

    // setIsAddingColumn(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: parseInt(scheduleID),
          },
          params,
          formatBearerToken(token),
        )
          .then((res) => {
            if (callback) {
              callback();
            }
            if (resolve) {
              resolve(res);
            } else {
              setIsSubmitting(false);
              setIsGeneratingSchedule(false);
              fetchSchedule(token);
              fetchCandidates(token);
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            setIsGeneratingSchedule(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addUnavailableSlots = async (data, resolve, reject, callback) => {
    if (dateIsInThePast && !willEditThePast) {
      const continueProcess = await fireConfirmationForEditingPastSchedule();
      if (!continueProcess) {
        return;
      }
    }

    if (isInPreviewMode()) {
      setShowUnsavedChangeModal(!isSubmitting);
      return;
    }
    setIsSubmitting(true);
    setIsGeneratingSchedule(true);

    if (data.length > 0 && data[0].hideScheduleDetailModal) {
      setShowScheduleDetailModal(false);
    }

    const pathExploded = !isEmbedded ? history.location.pathname.split('/') : '';
    const scheduleID = pk_InterviewDate != null ? pk_InterviewDate : pathExploded[pathExploded.length - 1];

    const { metaData } = scheduleData;
    const { DateOfInterview } = metaData;
    const monthDate = DateOfInterview
      ? moment.tz(DateOfInterview, null).format('MMM DD, YYYY')
      : moment.tz(null, null).format('MMM DD, YYYY');

    const payload = data.map((newSlot) => {
      const {
        newEvaluators,
        candidates,
        FlexTimeStart,
        FlexTimeEnd,
        EventName,
        StartDateTime,
        EndDateTime,
        FlexEventName,
        hideScheduleDetailModal = true,
      } = newSlot;

      const momentStart = moment.tz(`${monthDate} ${FlexTimeStart}`, null);
      const momentEnd = moment.tz(`${monthDate} ${FlexTimeEnd}`, null);

      const event = {
        // StartDateTime,
        EndDateTime,
        EnableForAllUsers: false,
        EnableForAllCandidates: false,
        EnableAbsoluteTime: true,
        EventName: EventName ? EventName : FlexEventName ? FlexEventName : 'UNAVAILABLE',
        FlexTimeStart,
        FlexTimeEnd,
      };

      event.FlexDateStart = momentStart.format('YYYY-MM-DD');
      event.FlexDateEnd = momentEnd.format('YYYY-MM-DD');

      return { event, evaluators: newEvaluators || [], candidates: candidates || [] };
    });

    const params = {
      payload,
    };

    // setIsAddingColumn(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: parseInt(scheduleID),
          },
          params,
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Changes saved!');
            setIsSubmitting(false);
            if (callback) {
              callback();
            } else {
              setIsGeneratingSchedule(false);
              fetchSchedule(token);
              fetchCandidates(token);
            }
            if (resolve) {
              resolve(res);
            } else {
            }
          })
          .catch((err) => {
            alert.success('Changes saved!');
            if (callback) {
              callback(err);
            }
            console.log('addUnavailableSlots err: ', err);
            setIsSubmitting(false);
            setIsGeneratingSchedule(false);
          });
      })
      .catch((err) => {
        alert.success('Changes saved!');
        if (callback) {
          callback(err);
        }
        console.log('addUnavailableSlots getToken err: ', err);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };
  // Update single Flex event via API
  const updateFlexEvent = (data, callback) => {
    setIsGeneratingSchedule(true);
    setIsSubmitting(true);
    const {
      evaluatorsToAdd,
      evaluatorsToDelete,
      FlexTimeEnd,
      FlexTimeStart,
      EventName,
      EndDateTime,
      FlexEventName,
    } = data;

    const params = {
      event: {
        EndDateTime,
        EnableForAllUsers: false,
        EnableForAllCandidates: false,
        EnableAbsoluteTime: true,
        EventName: EventName ? EventName : FlexEventName ? FlexEventName : 'UNAVAILABLE',
        FlexTimeStart,
        FlexTimeEnd,
      },
      writeEvaluatorPrimaryKeys: evaluatorsToAdd,
      deleteEvaluatorPrimaryKeys: evaluatorsToDelete,
    };

    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
            pk_FlexEvent: data.pk_FlexEvent,
            pk_InterviewDate: Number(scheduleID),
          },
          params,
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Changes saved!');
            setIsSubmitting(false);
            fetchSchedule(token);
            if (callback) {
              callback();
            }
          })
          .catch((err) => {
            alert.error('Failed to save changes!');
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        alert.error('Failed to save changes!');
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  // Update multiple Flex events via API
  const updateFlexEvents = (data, callback) => {
    setIsGeneratingSchedule(true);
    setIsSubmitting(true);

    const events = data.map((event) => {
      const {
        evaluatorsToAdd,
        evaluatorsToDelete,
        FlexTimeEnd,
        FlexTimeStart,
        EventName,
        EndDateTime,
        FlexEventName,
        pk_FlexEvent,
      } = event;

      return {
        pk_FlexEvent,
        event: {
          EndDateTime,
          EnableForAllUsers: false,
          EnableForAllCandidates: false,
          EnableAbsoluteTime: true,
          EventName: EventName ? EventName : FlexEventName ? FlexEventName : 'UNAVAILABLE',
          FlexTimeStart,
          FlexTimeEnd,
        },
        writeEvaluatorPrimaryKeys: evaluatorsToAdd,
        deleteEvaluatorPrimaryKeys: evaluatorsToDelete,
      };
    });

    const params = {
      events,
    };

    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/schedule/flexEvents',
          {
            pk_Department: dContext.department.pk_Department,
            // pk_FlexEvent: data.pk_FlexEvent,
            pk_InterviewDate: Number(scheduleID),
          },
          params,
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Changes saved!');
            setIsSubmitting(false);

            if (callback) {
              callback();
            } else {
              fetchSchedule(token);
            }
          })
          .catch((err) => {
            alert.error('Failed to save changes!');
            setIsSubmitting(false);
            if (callback) {
              callback(err);
            }
          });
      })
      .catch((err) => {
        alert.error('Failed to save changes!');
        if (callback) {
          callback(err);
        }
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const deleteFlexEvent = (pk_FlexEvent, resolve, reject, callBack) => {
    setIsGeneratingSchedule(true);
    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/schedule/flexEvent',
          {
            pk_Department: dContext.department.pk_Department,
          },
          { pk_FlexEvents: [pk_FlexEvent] },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Deleted successfully!');
            if (callBack) {
              callBack();
            }

            if (resolve) {
              resolve(true);
            } else {
              setIsSubmitting(false);
              fetchSchedule(token);
              fetchCandidates(token);
              // setShowScheduleDetailModal(false);
            }
          })
          .catch((err) => {
            if (callBack) {
              callBack(true);
            }
            alert.error('Failed to delete!');
            reject(err);
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        alert.error('Failed to delete!');
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addRowCustomEvent = (data, resolve, reject, callback) => {
    setIsGeneratingSchedule(true);
    setIsSubmitting(true);
    const pk_Timeslot = data.pk_Timeslot;
    delete data.pk_Timeslot;
    const params = { timeslot: { ...data } };

    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/interviewDate/timeslot',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Timeslot: pk_Timeslot,
          },
          params,
          formatBearerToken(token),
        )
          .then((res) => {
            if (resolve) {
              resolve(true);
            } else {
              setIsSubmitting(false);
              setIsGeneratingSchedule(false);
              fetchSchedule(token);
            }
          })
          .catch((err) => {
            if (reject) {
              reject(err);
            } else {
              setIsSubmitting(false);
              setIsGeneratingSchedule(false);
            }
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const setColumnSplit = (data, callback) => {
    updateEvaluators(data, null, null, callback);
  };

  const setCellSplit = (data, callback) => {
    const { TotalNumberOfSplits, pk_ScheduleAssignment, pk_Timeslot } = data;
    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/interviewDate/splitblock',
          {
            pk_Department: dContext.department.pk_Department,
          },
          {
            TotalNumberOfSplits,
            pk_ScheduleAssignment,
            pk_Timeslot,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsSubmitting(false);
            if (callback) {
              callback();
            }
            updateAllData();
          })
          .catch((err) => {
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const removeCellSplits = (data, callback) => {
    const { pk_Timeslot, pk_ScheduleAssignment } = data;
    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/interviewDate/splitblock',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Timeslot,
            pk_ScheduleAssignment,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            setIsSubmitting(false);
            updateAllData();
            if (callback) {
              callback();
            }
          })
          .catch((err) => {
            setIsSubmitting(false);
            if (callback) {
              callback();
            }
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const hideHeaderPopoversExcept = (key = '') => {
    const newShowHeaderPopOver = { ...showHeaderPopOver };
    if (newShowHeaderPopOver[key] == null) {
      newShowHeaderPopOver[key] = {};
    }

    Object.keys(newShowHeaderPopOver).forEach((headerKey) => {
      const visibility = newShowHeaderPopOver[headerKey] || {};
      if (headerKey === key) {
        visibility.self = true;
        visibility.showFocusPopover = false;
      } else {
        Object.keys(visibility).forEach((key) => {
          visibility[key] = false;
        });
        // visibility.self = false;
        // visibility.showFocusPopover = false;
      }

      newShowHeaderPopOver[headerKey] = visibility;
    });

    setShowHeaderPopOver(newShowHeaderPopOver);
  };

  const hideTimeSlotPopoversExcept = (pk_Timeslot = '') => {
    if (pk_Timeslot == null) {
      return;
    }
    const newShowTimeSlotPopover = { ...showTimeSlotPopover };

    if (showTimeSlotPopover[pk_Timeslot] == null) {
      showTimeSlotPopover[pk_Timeslot] = {};
      newShowTimeSlotPopover[pk_Timeslot] = {};
    }
    Object.keys(newShowTimeSlotPopover).forEach((key) => {
      const visibility = newShowTimeSlotPopover[key];

      if (key != null && key.toString() === pk_Timeslot.toString()) {
        visibility.self = true;
      } else {
        visibility.self = false;
      }
      newShowTimeSlotPopover[key] = visibility;
    });
    setShowTimeSlotPopover(newShowTimeSlotPopover);
  };

  const generateScheduleForEntireTable = () => {
    const clearTable = generatedScheduleData ? generatedScheduleData.clearTable : true;
    const headersToFillUp = generatedScheduleData ? generatedScheduleData.headersToFillUp : [];
    const functionToCall = generateMode === 'intelligent' ? generateScheduleColumn : generateSchedule;
    const newScheduleBody = functionToCall(JSON.parse(JSON.stringify(scheduleData)), candidates, {
      interviewLimit,
      allowInterviewLimit,
      clearTable,
      mode: generateMode,
      headersToFillUp: generateMode === 'intelligent' ? Object.keys(columnTracker) : headersToFillUp,
    });

    const newScheduleData = JSON.parse(JSON.stringify(scheduleData));
    newScheduleData.body =
      generatedScheduleData && generatedScheduleData.headersToFillUp ? newScheduleBody : newScheduleBody.newBody;
    newScheduleData.clearTable = clearTable;
    newScheduleData.headersToFillUp = headersToFillUp;
    newScheduleData.interviewCountTracker = newScheduleBody.interviewCountTracker;

    setPendingCandidates(newScheduleBody.pendingItems);
    setGeneratedScheduleData(newScheduleData);
    setupTrackers(newScheduleData);
  };

  const generateScheduleForColumn = (pk_ScheduleAssignments, clearTable = false) => {
    setGenerateMode('intelligent');
    const processedSchedule = generateScheduleColumn(clone(scheduleData), candidates, {
      interviewLimit,
      allowInterviewLimit,
      clearTable,
      mode: generateMode,
      headersToFillUp: pk_ScheduleAssignments,
    });

    const newScheduleData = clone(scheduleData);
    newScheduleData.body = processedSchedule.newBody;
    newScheduleData.headersToFillUp = clone(processedSchedule.headersToFillUp);
    newScheduleData.clearTable = false;
    newScheduleData.interviewCountTracker = processedSchedule.interviewCountTracker;
    setGeneratedScheduleData(newScheduleData);
    setShowGenerateDataPanel(true);
    setPendingCandidates(processedSchedule.pendingItems);
    setupTrackers(newScheduleData);
  };

  const processStyle = (cell, row, rowIndex, colIndex) => {
    const style = {
      cursor: 'pointer',
      textAlign: 'center',
      verticalAlign: 'middle',
      padding: 3,
      backgroundColor: rowIndex % 2 == 0 ? '#ffffff' : '#f2f2f2',
    };

    const { metaData = {}, headers = [] } = scheduleData || {};
    if (colIndex == 0) {
      style.fontWeight = 'bold';
      style.position = 'sticky';
      style.textAlign = 'center';
      style.left = -2;
      style.verticalAlign = 'middle';
      style.zIndex = 1;

      if (
        (row.CustomDurationInMinutes != null && row.CustomDurationInMinutes != metaData.StandardDurationInMinutes) ||
        (row.CustomPassingDurationInMinutes != null &&
          row.CustomPassingDurationInMinutes != metaData.StandardPassingDurationInMinutes)
      ) {
        style.backgroundColor = '#ffec45';
      }
    } else {
      // style.padding = '2px';
    }

    if (
      generatedScheduleData == null &&
      cellHoveredOn != null &&
      (rowIndex == cellHoveredOn.rowIndex || colIndex == cellHoveredOn.columnIndex) &&
      cellHoveredOn.rowIndex < scheduleData.body.length - 1
    ) {
      if (rowIndex % 2 == 0) {
        style.backgroundColor = '#e8f8ff';
      } else {
        style.backgroundColor = '#caecfa';
      }
    } else {
      // if (!meetingTitle) {
      //   style.backgroundColor = backgroundColor;
      // }
    }

    const { slot, slotEnd } = row;
    const rowStartTime = moment.tz(row.slot, null).format('MMM DD, YYYY hh:mm A');
    const rowEndTime = moment
      .tz(row.slotEnd, null)
      .subtract(5, 'seconds')
      .format('MMM DD, YYYY hh:mm A');
    const cTime = moment.tz(metaData.TimeZone).format('MMM DD, YYYY hh:mm A');

    const isCurrentTime = isTimeRangeOverlap([slot, slotEnd], [cTime, cTime]);

    if (isCurrentTime) {
      style.borderTop = '3px solid green';
      style.borderBottom = '3px solid green';

      if (colIndex == 0) {
        style.borderLeft = '3px solid green';
      } else if (colIndex == headers.length && !hasFlexColumn) {
        style.borderRight = '3px solid green';
      }
    }

    return style;
  };

  const processFlexCellStyle = (cell, row, rowIndex, colIndex) => {
    const style = {
      cursor: isFetching || isSubmitting ? 'not-allowed' : 'pointer',
      textAlign: 'center',
      verticalAlign: 'middle',
      padding: 3,
      backgroundColor: '#afafaf',
    };
    const { headers = [] } = scheduleData || {};
    // const rowStartTime = parseInt(moment(row.slot).valueOf());
    // const rowEndTime = parseInt(moment(row.slotEnd).valueOf());
    // const cTime = parseInt(moment().valueOf());
    // const isCurrentTime = parseInt(cTime) >= parseInt(rowStartTime) && parseInt(cTime) <= parseInt(rowEndTime);
    const { metaData = {} } = scheduleData || {};
    const rowStartTime = moment
      .tz(row.slot, null)
      .add(1, 'minutes')
      .format('MMM DD, YYYY hh:mm A');
    const rowEndTime = moment.tz(row.slotEnd, null).format('MMM DD, YYYY hh:mm A');
    const cTime = moment.tz(metaData.TimeZone).format('MMM DD, YYYY hh:mm A');

    const { slot, slotEnd } = row;

    const isCurrentTime = isTimeRangeOverlap([slot, slotEnd], [cTime, cTime]); // cTime.isSameOrAfter(rowStartTime) && cTime.isSameOrBefore(rowEndTime);

    if (isCurrentTime) {
      style.borderTop = '3px solid green';
      style.borderBottom = '3px solid green';
      style.borderRight = '3px solid green';
    }
    return style;
  };

  const headerProcessStyle = (column, colIndex) => {
    const style = {
      alignItems: 'center',
      color: 'black',
      cursor: 'pointer',
      maxWidth: 280,
      minWidth: colIndex > 0 ? 180 : 90, // comment out for squeezy demo
      position: 'sticky',
      textAlign: 'center',
      top: -5,
      verticalAlign: 'middle',
      zIndex: 2,
    };

    if (headerHoveredOn !== null && headerHoveredOn.columnIndex === colIndex) {
      style.backgroundColor = '#deb887';
      style.color = 'white';
    } else if (
      generatedScheduleData == null &&
      cellHoveredOn != null &&
      colIndex == cellHoveredOn.columnIndex &&
      cellHoveredOn.rowIndex < scheduleData.body.length - 1
    ) {
      style.backgroundColor = '#caecfa';
    } else {
      style.backgroundColor = 'white';
    }

    if (colIndex == 0) {
      style.maxWidth = 80;
      style.minWidth = 80;
      style.width = 80;
      style.left = 0;
      style.zIndex = 4;
    }
    return style;
  };

  const headerFormatter = (column, colIndex) => {
    const { rawHeader } = column;
    const { RoomFocuses = [], VirtualRoomURL } = rawHeader || {};
    const { VirtualMeetingMode } = dContext.department;
    const { StandardPassingDurationInMinutes } = scheduleData.metaData || {};

    if (colIndex == 0) {
      const visibility = showHeaderPopOver['timeSlotHeader'] || {};
      return (
        <div style={{ ...style.headerStyle, width: 80 }}>
          {column.text}{' '}
          {StandardPassingDurationInMinutes == 0 ? (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ marginLeft: 5 }}
                id={`passing_period_warning_time_header`}
              />
              <UncontrolledTooltip target={`passing_period_warning_time_header`}>
                Warning: No passing period - This may affect some of your notifications.
              </UncontrolledTooltip>
            </>
          ) : null}
          <FontAwesomeIcon icon={visibility.self ? faChevronLeft : faChevronRight} style={{ marginLeft: 10 }} />
        </div>
      );
    }

    if (column.text === 'Flex Events') {
      return (
        <div style={style.headerStyle}>
          <div
            style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}
            onClick={async (e) => {
              if (generatedScheduleData) {
                e.stopPropagation();
                return;
              }
              e.stopPropagation();
              setShowFlexEventDetailModal(true);
            }}
          >
            <div style={{ marginRight: 10 }}> {column.text || ''}</div>
            {countFlexEvents() <= 0 ? (
              <FontAwesomeIcon
                icon={faTimes}
                style={style.clickableIcon}
                onClick={async (e) => {
                  if (dateIsInThePast && !willEditThePast) {
                    const continueProcess = await fireConfirmationForEditingPastSchedule();
                    if (!continueProcess) {
                      return;
                    }
                  }
                  e.stopPropagation();
                  setHasFlexColumn(false);
                }}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      const key = column.dataField.toString();
      const candidateCount = columnTracker[key] ? columnTracker[key].length : 0;

      return (
        <div style={style.headerStyle}>
          {/* {column.dataField} */}
          <div
            id={`header_${rawHeader.pk_ScheduleAssignment != null ? rawHeader.pk_ScheduleAssignment : colIndex}`}
            style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}
          >
            <div style={style.simpleColumn} id={`header_text_${rawHeader.pk_ScheduleAssignment}`}>
              {`${column.text} ${candidateCount > 0 ? `(${candidateCount})` : ''} `}
              <div style={{ fontSize: 10, minHeight: 15 }}> {column.label || ''}</div>
              {generatedScheduleData ? null : renderHeaderPopOver(column)}
            </div>
            {VirtualMeetingMode === 'ExternalColumn' && !VirtualRoomURL ? (
              <div
                style={{ ...style.simpleColumn, width: 25, color: 'red', paddingLeft: 10, marginBottom: 15 }}
                id={`missing_column_url_${rawHeader.pk_ScheduleAssignment}`}
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </div>
            ) : null}
          </div>

          {rawHeader && rawHeader.pk_ScheduleAssignment != null ? (
            <UncontrolledTooltip target={`header_text_${rawHeader.pk_ScheduleAssignment}`}>
              {RoomFocuses && RoomFocuses.length > 0 ? 'Focus: \n' : ''}
              {RoomFocuses && RoomFocuses.length > 0
                ? RoomFocuses.map((f) => {
                    return <div style={{ width: 120 }}>{f.Name}</div>;
                  })
                : 'No Focuses'}
            </UncontrolledTooltip>
          ) : null}

          {VirtualMeetingMode === 'ExternalColumn' && !VirtualRoomURL ? (
            <UncontrolledTooltip target={`missing_column_url_${rawHeader.pk_ScheduleAssignment}`}>
              Virtual Meeting Mode is set to "Coordinator Managed Breakout Rooms (Multiple URL)" but this room is
              missing it's own Meeting URL!
            </UncontrolledTooltip>
          ) : null}
        </div>
      );
    }
  };

  const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { StandardPassingDurationInMinutes } = scheduleData.metaData || {};
    const { CustomPassingDurationInMinutes, pk_Timeslot, slot, slotEnd } = row;

    // For Time Slot column, just render entire cell( which is a string)
    if (formatExtraData && formatExtraData.isTime) {
      const isMainRoomAndNoLink =
        row.CustomMeetingTitle && row.VirtualRoomType === 'AllDay' && !scheduleData.metaData.MainRoomURL;
      const isCustomAndNoLink = row.CustomMeetingTitle && row.VirtualRoomType === 'Custom' && !row.CustomMeetingUrl;
      const urlIssue = VirtualMeetingMode !== 'Disabled' && (isMainRoomAndNoLink || isCustomAndNoLink);

      return (
        <div style={{ width: 130 }} id={`${row.pk_Timeslot !== 'END' ? 'capsule_time_' : ''}${row.pk_Timeslot}`}>
          {/* {row.pk_Timeslot} */}
          {moment.tz(row.slot, null).format('hh:mm A')}
          {generatedScheduleData || row.pk_Timeslot === 'END' ? null : renderTimeSlotPopover(row)}
          {(!StandardPassingDurationInMinutes && !CustomPassingDurationInMinutes) ||
          CustomPassingDurationInMinutes == 0 ? (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ color: 'black', marginLeft: 5 }}
                id={`passing_period_warning_${pk_Timeslot}`}
              />
              <UncontrolledTooltip target={`passing_period_warning_${pk_Timeslot}`}>
                Warning: No passing period - This may affect some of your notifications.
              </UncontrolledTooltip>
            </>
          ) : null}
          {urlIssue ? (
            <>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                style={{ color: 'red', marginLeft: 5 }}
                id={`url_warning_${pk_Timeslot}`}
              />
              <UncontrolledTooltip target={`url_warning_${pk_Timeslot}`}>
                Warning: {isCustomAndNoLink ? `Custom Event URL is missing!` : `Main Room URL is missing!`}
              </UncontrolledTooltip>
            </>
          ) : null}
        </div>
      );
    }

    if (row.pk_Timeslot === 'END') {
      return (
        <div>
          <b>{row.endTimesText}</b>
        </div>
      );
    }

    const {
      ScheduleBlockEntries,
      isFlexEvent,
      isUnavailable,
      cellState,
      unavailableEvaluators,
      availableEvaluators,
      CustomMeetingTitle,
      flexEvents = [],
      pk_ScheduleAssignment,
    } = cell;

    const { metaData } = scheduleData;

    let cellHasCandidates = false;
    const momentStart = moment.tz(slot, null);
    const momentEnd = moment.tz(slotEnd, null);
    const now = moment(`${momentStart.format('MMM DD, YYYY')} ${moment().format('hh:mm A')}`);

    ScheduleBlockEntries.forEach((block) => {
      if (block.Candidates && block.Candidates.length > 0) {
        cellHasCandidates = true;
      }
    });

    let cellContent = '';
    const pendingKeysInThisCell =
      Object.keys(pendingCandidates).filter((key) => {
        return key.indexOf(`${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}_`) >= 0;
      }) || [];

    const pendingItemsInThisCell = pendingKeysInThisCell.map((key) => {
      return pendingCandidates[key];
    });

    const renderContent = (candidateList = [], scheduleBlock) => {
      const candidatesToRender = [];
      const { flexEventEvaluators = [], FlexEvents = [] } = scheduleBlock;
      const candidatesInFlexEvent = [];

      let flexEventsForCell = 0;
      FlexEvents.forEach((flexEvent) => {
        const { AttendingUsers = [], AttendingCandidates = [] } = flexEvent;
        const flexEventHasEvaluatorForColumn = AttendingUsers.filter((u) => {
          return flexEventEvaluators.find((e) => {
            return e.pk_User == u.pk_User;
          });
        });

        if (flexEventHasEvaluatorForColumn.length > 0) {
          flexEventsForCell++;
        }

        AttendingCandidates.forEach((c) => {
          candidatesInFlexEvent.push(c.pk_Candidate);
        });
      });

      const pendingItemKey = `${pk_Timeslot}_${pk_ScheduleAssignment}${
        scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''
      }`;

      const isPendingItem = pendingCandidates[pendingItemKey] != null; // TO DO: Move to per-scheduleBlock
      const candidateAttendanceObject = {};
      let attendanceObject = {};

      if (attendanceData) {
        if (attendanceData.ScheduleBlocks && attendanceData.ScheduleBlocks[scheduleBlock.pk_ScheduleBlock]) {
          attendanceObject = attendanceData.ScheduleBlocks[scheduleBlock.pk_ScheduleBlock];
        } else if (attendanceData.TimeSlots && attendanceData.TimeSlots[row.pk_Timeslot]) {
          attendanceObject = attendanceData.TimeSlots[row.pk_Timeslot];
        }
      }

      if (attendanceObject.Candidates) {
        attendanceObject.Candidates.forEach((c) => {
          candidateAttendanceObject[c.fk_Candidate] = c;
        });
      }

      let evaluatorsPresent = 0;

      if (attendanceObject.Evaluators) {
        const { availableEvaluators = [] } = cell;

        attendanceObject.Evaluators.forEach((e) => {
          // To fix Admin appearing in Cell
          const evaluatorIsInColumn = availableEvaluators.find((ae) => {
            return ae.pk_User == e.fk_User;
          });

          if (evaluatorIsInColumn) {
            if (e.EnableActivelyAttending) {
              evaluatorsPresent++;
            }
          }
        });
      }

      candidateList.forEach((candidate, i) => {
        if (candidate == null) {
          return;
        }

        const candidateIsInFlexEvent = candidatesInFlexEvent.indexOf(candidate.pk_Candidate) >= 0;
        const candidateIsPresent =
          attendanceObject.Candidates && attendanceObject.Candidates.length
            ? attendanceObject.Candidates[0].IsActive && attendanceObject.Candidates[0].EnableActivelyAttending
            : 0;

        const key = `${pk_Timeslot}_${pk_ScheduleAssignment}${scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''}`;
        const backgroundColor =
          candidates && Object.keys(candidates).length > 0 && candidates[candidate.pk_Candidate]
            ? candidates[candidate.pk_Candidate].color
            : null;

        const color = backgroundColor ? (tinycolor(backgroundColor).isLight() ? 'black' : 'white') : 'black';
        const border =
          isPendingItem && candidate.pk_Candidate != null
            ? '5px solid #a1e3a5 '
            : `5px solid ${backgroundColor || '#d4d4d4'}`;
        const candidateInterviewCount =
          generatedScheduleData &&
          generatedScheduleData.interviewCountTracker &&
          generatedScheduleData.interviewCountTracker[candidate.pk_Candidate]
            ? generatedScheduleData.interviewCountTracker[candidate.pk_Candidate]
            : 0;

        const flexEventEvaluatorWarningID = `cell_flexEvent_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}_${
          scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''
        }`;
        const infoInterviewCountID = `info_${key}_interviewCounter`;
        const infoEvaluatorAttendanceID = `info_${key}_evaluatorAttendance`;
        const infoCandidateAttendanceID = `info_${key}_candidateAttendance`;
        const problematicCandidateID = `problematic_candidate_${pk_Timeslot}_${pk_ScheduleAssignment}_${candidate.pk_Candidate}`;
        const flexEventCandidateWarningID = `cell_flexEvent_candidate_warning_${row.pk_Timeslot}_${
          cell.pk_ScheduleAssignment
        }_${scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''}`;

        const capsuleID = `capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`;
        const capsuleLabelID = `capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}_label`;
        const capsuleLabelInnerID = `capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}_label_inner`;

        candidatesToRender.push(
          <>
            <div
              id={capsuleID}
              key={`${pk_Timeslot}_${pk_ScheduleAssignment}${scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''}`}
              style={{
                ...style.candidateLabel,
                color,
                backgroundColor: backgroundColor || '#d4d4d4',
                border,
                marginBottom: candidateList.length > 0 && i < candidateList.length - 1 ? 5 : 0,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={(e) => {
                if (
                  scheduleBlock &&
                  /*backgroundColor &&*/ (!isEmbedded || allowScheduleInteraction) &&
                  !isPendingItem
                ) {
                  setSelectedScheduleBlock(scheduleBlock);
                } else {
                  e.stopPropagation();
                }
              }}
            >
              {evaluatorsPresent ? (
                <div style={{ ...style.simpleColumn, alignItems: 'center', justifyContent: 'center', minWidth: 10 }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <div
                      id={infoEvaluatorAttendanceID}
                      // icon={faDotCircle}
                      style={{
                        border: '2px solid #f2f2f2',
                        borderRadius: 20,
                        color: '#f2f2f2',
                        backgroundColor: evaluatorsPresent > 0 ? '#298dff' : 'grey',
                        fontWeight: 'bold',
                        fontSize: 10,
                        marginRight: 2,
                        minWidth: 17,
                        minHeight: 17,
                        textAlign: 'center',
                      }}
                    >
                      {evaluatorsPresent ? evaluatorsPresent : ''}
                    </div>
                    <UncontrolledTooltip target={infoEvaluatorAttendanceID}>
                      {evaluatorsPresent} evaluator{evaluatorsPresent > 1 ? 's' : ''} present:
                      <br />
                      {attendanceObject.Evaluators
                        ? attendanceObject.Evaluators.map((evaluator) => {
                            const { EvaluatorFirstName, EvaluatorLastName, EnableActivelyAttending } = evaluator;

                            const evaluatorIsInColumn = availableEvaluators.find((ae) => {
                              return ae.pk_User == evaluator.fk_User;
                            });

                            if (evaluatorIsInColumn && EnableActivelyAttending) {
                              return (
                                <>
                                  {EvaluatorFirstName || ''} {EvaluatorLastName || ''}
                                  <br />
                                </>
                              );
                            }
                          })
                        : ''}
                    </UncontrolledTooltip>
                  </div>
                </div>
              ) : null}

              {candidateIsPresent ? (
                <div style={{ ...style.simpleColumn, alignItems: 'center', justifyContent: 'center', marginRight: 2 }}>
                  <FontAwesomeIcon
                    id={infoCandidateAttendanceID}
                    icon={faUserCircle}
                    style={{
                      color: candidateIsPresent ? '#35911f' : 'grey',
                      fontWeight: 'bold',
                      fontSize: 17,
                      border: '2px solid #f2f2f2',
                      borderRadius: 20,
                      backgroundColor: '#f2f2f2',
                    }}
                  />
                  <UncontrolledTooltip
                    target={infoCandidateAttendanceID}
                    style={{ color: candidateIsPresent ? '#4cd12b' : '#ff3d3d' }}
                  >
                    Candidate is present!
                  </UncontrolledTooltip>
                </div>
              ) : null}
              <div
                id={capsuleLabelID}
                style={{ ...style.simpleColumn, alignItems: 'center', justifyContent: 'center' }}
              >
                <div
                  id={capsuleLabelInnerID}
                  style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}
                >
                  {candidate.LastName || ''}
                  {candidate.FirstName ? `, ${candidate.FirstName}` : ''}
                </div>
              </div>

              {backgroundColor ? null : (
                <div style={{ ...style.simpleColumn, alignItems: 'center', justifyContent: 'center' }}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{ marginLeft: 5, color: '#ff3d3d' }}
                    id={problematicCandidateID}
                  />
                  <UncontrolledTooltip target={problematicCandidateID}>
                    Candidate has no Data within the current Department! This may be because this candidate was
                    scheduled and then deleted some time after, without unscheduling before.
                  </UncontrolledTooltip>
                </div>
              )}

              {candidateIsInFlexEvent ? (
                <div style={{ ...style.simpleColumn, alignItems: 'center', justifyContent: 'center' }}>
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{ marginLeft: 5 }}
                    id={flexEventCandidateWarningID}
                  />
                  <UncontrolledTooltip target={flexEventCandidateWarningID}>
                    Candidate is in a Flex Event at this time!
                  </UncontrolledTooltip>
                </div>
              ) : null}

              {generatedScheduleData != null ? (
                <div
                  style={{
                    ...style.simpleColumn,
                    alignItems: 'center',
                    justifyContent: 'center',
                    minWidth: 10,
                    marginLeft: 5,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'relative',
                    }}
                  >
                    <div
                      id={infoInterviewCountID}
                      style={{
                        border: '2px solid #f2f2f2',
                        borderRadius: 20,
                        color: 'black',
                        backgroundColor: '#a1e3a5',
                        fontWeight: 'bold',
                        fontSize: 10,
                        marginRight: 2,
                        minWidth: 17,
                        minHeight: 17,
                        textAlign: 'center',
                      }}
                    >
                      {candidateInterviewCount}
                    </div>
                    <UncontrolledTooltip target={infoInterviewCountID}>
                      Candidate currently has {candidateInterviewCount} interviews on this Generated Schedule.
                    </UncontrolledTooltip>
                  </div>
                </div>
              ) : null}
              {cellState === 'Flex Event' && flexEventEvaluators && flexEventEvaluators.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    id={flexEventEvaluatorWarningID}
                    icon={faExclamationTriangle}
                    style={{ color: 'black', marginLeft: 10 }}
                  />
                  <UncontrolledTooltip target={flexEventEvaluatorWarningID}>
                    <div>
                      <div>
                        Some Evaluators might be in a Flex Event and be unavailable during this time. (
                        {flexEventEvaluators.length} of {availableEvaluators.length + unavailableEvaluators.length}
                        ).
                      </div>
                      <div style={{ textAlign: 'justify', marginTop: 15 }}>
                        <div style={{ ...style.ellipsis, width: 180 }}>
                          Unavailable ({flexEventsForCell > 1 ? 'Several' : FlexEvents[0].FlexEventName}):
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {flexEventEvaluators.map((evaluator) => {
                            if (evaluator == null) {
                            }
                            return <div>{`${evaluator ? evaluator.UserLast : 'Null Evaluator'}\n`}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          </>,
        );
      });

      // If no Candidates to render, check pending list. This Pending is for individual additions,
      // NOT Generate Schedule
      if (candidatesToRender.length == 0) {
        if (isPendingItem) {
          const cellPendingCandidates = pendingCandidates[pendingItemKey] || [];
          cellPendingCandidates.forEach((candidate, i) => {
            const candidateActual = candidates[candidate.pk_Candidate];
            const backgroundColor =
              candidates && Object.keys(candidates).length > 0 && candidateActual
                ? candidateActual.color
                : cellState === 'Warning'
                ? '#ffc180'
                : '#d4d4d4';
            const color =
              candidate.pk_Candidate == null
                ? backgroundColor
                : backgroundColor
                ? tinycolor(backgroundColor).isLight()
                  ? 'black'
                  : 'white'
                : 'black';
            const border = candidate.pk_Candidate == null ? `5px solid ${backgroundColor}` : '5px solid #a1e3a5 ';
            //generatedScheduleData && generatedScheduleData.interviewCountTracker[candidate.pk_Candidate]
            // ? '5px solid #a1e3a5 '
            // : `5px solid ${backgroundColor}`;

            const pendingItemKey = `${pk_Timeslot}_${pk_ScheduleAssignment}${
              scheduleBlock ? `_${scheduleBlock.SortOrder}` : '_0'
            }`;

            const candidateInterviewCount =
              generatedScheduleData &&
              generatedScheduleData.interviewCountTracker &&
              generatedScheduleData.interviewCountTracker[candidate.pk_Candidate]
                ? generatedScheduleData.interviewCountTracker[candidate.pk_Candidate]
                : 0;

            candidatesToRender.push(
              <>
                <div
                  id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                  key={pendingItemKey}
                  style={{
                    ...style.candidateLabel,
                    color,
                    backgroundColor,
                    border,
                    marginBottom: cellPendingCandidates.length > 0 && i < cellPendingCandidates.length - 1 ? 5 : 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    // if (scheduleBlock) setSelectedScheduleBlock(scheduleBlock);
                  }}
                >
                  {candidate.LastName || ' '} {candidate.FirstName ? `, ${candidate.FirstName}` : ' '}
                  {/**Add Interview counter here */}
                  {generatedScheduleData != null &&
                  generatedScheduleData.interviewCountTracker[candidate.pk_Candidate] ? (
                    <div
                      style={{
                        ...style.simpleColumn,
                        alignItems: 'center',
                        justifyContent: 'center',
                        minWidth: 10,
                        marginLeft: 5,
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}
                      >
                        <div
                          id={`info_${pendingItemKey}_interviewCounter`}
                          style={{
                            border: '2px solid #f2f2f2',
                            borderRadius: 20,
                            color: 'black',
                            backgroundColor: '#a1e3a5',
                            fontWeight: 'bold',
                            fontSize: 10,
                            marginRight: 2,
                            minWidth: 17,
                            minHeight: 17,
                            textAlign: 'center',
                          }}
                        >
                          {candidateInterviewCount}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {candidate.pk_Candidate == null ? '|' : null}
                  {cellState === 'Flex Event' && flexEventEvaluators && flexEventEvaluators.length > 0 ? (
                    <>
                      <FontAwesomeIcon
                        id={`cell_flexEvent_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                        icon={faExclamationTriangle}
                        style={{ color: 'black', marginLeft: 10 }}
                      />
                      <UncontrolledTooltip
                        target={`cell_flexEvent_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                      >
                        <div>
                          <div>
                            Some Evaluators might be in a Flex Event and be unavailable during this time. (
                            {flexEventEvaluators.length} of {availableEvaluators.length + unavailableEvaluators.length}
                            ).
                          </div>
                          <div style={{ textAlign: 'justify', marginTop: 15 }}>
                            <div style={{ ...style.ellipsis, width: 180 }}>
                              Unavailable ({flexEventsForCell > 1 ? 'Several' : FlexEvents[0].FlexEventName}):
                            </div>
                            <div style={{ paddingLeft: 20 }}>
                              {flexEventEvaluators.map((evaluator) => {
                                if (evaluator == null) {
                                }
                                return <div>{`${evaluator ? evaluator.UserLast : 'Null Evaluator'}\n`}</div>;
                              })}
                            </div>
                          </div>
                        </div>
                      </UncontrolledTooltip>
                    </>
                  ) : null}
                </div>
              </>,
            );
          });
        } else {
          // EMPTY CELL!
          candidatesToRender.push(
            <div
              id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
              key={
                scheduleBlock && scheduleBlock.pk_ScheduleBlock != null
                  ? scheduleBlock.pk_ScheduleBlock
                  : `${rowIndex}_${cell.dataField}_pending`
              }
              style={{
                ...style.candidateLabel,
                backgroundColor: '#d4d4d4',
                color: row.CustomMeetingTitle ? 'black' : '#d4d4d4',
                border: '5px solid #d4d4d4',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={(e) => {
                if (generatedScheduleData != null) {
                  e.stopPropagation();
                }
                if (scheduleBlock && (!isEmbedded || allowScheduleInteraction)) {
                  setSelectedScheduleBlock(clone(scheduleBlock));
                }
              }}
            >
              {row.CustomMeetingTitle ? (
                row.CustomMeetingTitle
              ) : cellState === 'Flex Event' ? (
                <div style={{ visibility: 'hidden' }}>|</div>
              ) : (
                `|`
              )}

              {cellState === 'Flex Event' && flexEventEvaluators && flexEventEvaluators.length > 0 ? (
                <>
                  <FontAwesomeIcon
                    id={`cell_flexEvent_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                    icon={faExclamationTriangle}
                    style={{ color: 'black', marginLeft: row.CustomMeetingTitle ? 10 : 0 }}
                  />
                  <UncontrolledTooltip
                    target={`cell_flexEvent_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                  >
                    <div>
                      <div>
                        Some Evaluators might be in a Flex Event and be unavailable during this time. (
                        {flexEventEvaluators.length} of {availableEvaluators.length + unavailableEvaluators.length}).
                      </div>
                      <div style={{ textAlign: 'justify', marginTop: 15 }}>
                        <div style={{ ...style.ellipsis, width: 180 }}>
                          Unavailable ({flexEventsForCell > 1 ? 'Several' : FlexEvents[0].FlexEventName}):
                        </div>
                        <div style={{ paddingLeft: 20 }}>
                          {flexEventEvaluators.map((evaluator) => {
                            if (evaluator == null) {
                            }
                            return <div>{`${evaluator ? evaluator.UserLast : 'Null Evaluator'}\n`}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>,
          );
        }
      }
      return candidatesToRender;
    };

    if (isFlexEvent) {
      let backgroundColor = '#d4d4d4';
      if (cellState === 'Unavailable') {
        backgroundColor = '#ff3d3d';
      } else if (cellState === 'Warning') {
        backgroundColor = '#ffc180';
      }

      const color = backgroundColor ? (tinycolor(backgroundColor).isLight() ? 'black' : 'white') : 'black';
      const border = `5px solid ${backgroundColor}`;

      if (cellState === 'Warning' || (cellState === 'Unavailable' && cellHasCandidates)) {
        const renderContentForWarning = (candidateList = [], scheduleBlock) => {
          const { pk_Timeslot } = row;
          const { pk_ScheduleAssignment } = cell;

          const candidatesToRender = [];
          const pendingItemKey = `${pk_Timeslot}_${pk_ScheduleAssignment}${
            scheduleBlock ? `_${scheduleBlock.SortOrder}` : '_0'
          }`;
          const isPendingItem = pendingCandidates[pendingItemKey] != null; // TO DO: Move to per-scheduleBlock
          candidateList.forEach((candidate, i) => {
            if (candidates && Object.keys(candidates).length > 0 && candidates[candidate.pk_Candidate]) {
              backgroundColor = candidates[candidate.pk_Candidate].color;
            } else {
              backgroundColor = '#ffc180';
            }

            const color = backgroundColor ? (tinycolor(backgroundColor).isLight() ? 'black' : 'white') : 'black';
            const border = isPendingItem ? '5px solid #a1e3a5 ' : `5px solid ${backgroundColor || '#d4d4d4'}`;
            candidatesToRender.push(
              <>
                <div
                  id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                  key={`${pk_Timeslot}_${pk_ScheduleAssignment}${scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''}`}
                  // key={candidate.pk_Candidate}
                  style={{
                    ...style.candidateLabel,
                    color,
                    backgroundColor: backgroundColor || '#d4d4d4',
                    border,
                    marginBottom: candidateList.length > 0 && i < candidateList.length - 1 ? 5 : 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    if (scheduleBlock && backgroundColor && (!isEmbedded || allowScheduleInteraction)) {
                      setSelectedScheduleBlock(scheduleBlock);
                    } else {
                      e.stopPropagation();
                    }
                  }}
                >
                  {candidate.LastName || ''} {candidate.FirstName ? `, ${candidate.FirstName}` : ''}
                  <FontAwesomeIcon
                    id={`cell_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                    icon={faExclamationCircle}
                    style={{ marginLeft: candidateList.length > 0 ? 10 : 0 }}
                  />
                  <UncontrolledTooltip target={`cell_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}>
                    <div>
                      <div>
                        Some Evaluators are Unavailable during this time ({unavailableEvaluators.length} of{' '}
                        {availableEvaluators.length + unavailableEvaluators.length}).
                      </div>
                      <div style={{ textAlign: 'justify', marginTop: 15 }}>
                        <div>Unavailable:</div>
                        <div style={{ paddingLeft: 20 }}>
                          {unavailableEvaluators.map((evaluator) => {
                            if (evaluator == null) {
                            }
                            return <div>{`${evaluator ? evaluator.UserLast : 'Null Evaluator'}\n`}</div>;
                          })}
                        </div>
                        Available:
                        <div style={{ paddingLeft: 20 }}>
                          {availableEvaluators.map((evaluator) => {
                            return <div>{`${evaluator.UserLast}\n`}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  </UncontrolledTooltip>
                </div>
              </>,
            );
          });

          // If no Candidates to render, check pending list
          if (candidatesToRender.length == 0) {
            if (isPendingItem) {
              const cellPendingCandidates = pendingCandidates[pendingItemKey] || [];
              cellPendingCandidates.forEach((candidate) => {
                const backgroundColor =
                  candidates && Object.keys(candidates).length > 0 ? candidates[candidate.pk_Candidate].color : 'black';
                const color = backgroundColor ? (tinycolor(backgroundColor).isLight() ? 'black' : 'white') : 'black';
                const border =
                  isPendingItem && candidate.pk_Candidate != null
                    ? '5px solid #a1e3a5 '
                    : `5px solid ${backgroundColor}`;

                candidatesToRender.push(
                  <div
                    id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                    key={`${pk_Timeslot}_${pk_ScheduleAssignment}${scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''}`}
                    style={{
                      ...style.candidateLabel,
                      color,
                      backgroundColor,
                      border,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      // if (scheduleBlock) setSelectedScheduleBlock(scheduleBlock);
                    }}
                  >
                    {candidate.LastName || ''} {candidate.FirstName ? `, ${candidate.FirstName}` : ''}
                    <FontAwesomeIcon
                      id={`cell_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                      icon={faExclamationCircle}
                      style={{ color: 'black', marginLeft: 10 }}
                    />
                    <UncontrolledTooltip target={`cell_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}>
                      <div>
                        <div>
                          Some Evaluators are Unavailable during this time ({unavailableEvaluators.length} of{' '}
                          {availableEvaluators.length + unavailableEvaluators.length}).
                        </div>
                        <div style={{ textAlign: 'justify', marginTop: 15 }}>
                          <div>Unavailable:</div>
                          <div style={{ paddingLeft: 20 }}>
                            {unavailableEvaluators.map((evaluator) => {
                              if (evaluator == null) {
                              }
                              return <div>{`${evaluator ? evaluator.UserLast : 'Null Evaluator'}\n`}</div>;
                            })}
                          </div>
                          Available:
                          <div style={{ paddingLeft: 20 }}>
                            {availableEvaluators.map((evaluator) => {
                              return <div>{`${evaluator.UserLast}\n`}</div>;
                            })}
                          </div>
                        </div>
                      </div>
                    </UncontrolledTooltip>
                  </div>,
                );
              });
            } else {
              candidatesToRender.push(
                <div
                  id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                  key={
                    scheduleBlock && scheduleBlock.pk_ScheduleBlock != null
                      ? scheduleBlock.pk_ScheduleBlock
                      : `${rowIndex}_${cell.dataField}_pending`
                  }
                  style={{
                    ...style.candidateLabel,
                    backgroundColor: '#ffc180',
                    color: '#ffc180',
                    border: `5px solid #ffc180`,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    if (scheduleBlock && (!isEmbedded || allowScheduleInteraction))
                      setSelectedScheduleBlock(scheduleBlock);
                  }}
                >
                  |
                  <FontAwesomeIcon
                    id={`cell_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}
                    icon={faExclamationCircle}
                    style={{ color: 'black' }}
                  />
                  <UncontrolledTooltip target={`cell_warning_${row.pk_Timeslot}_${cell.pk_ScheduleAssignment}`}>
                    <div>
                      <div>
                        Some Evaluators are Unavailable during this time ({unavailableEvaluators.length} of{' '}
                        {availableEvaluators.length + unavailableEvaluators.length}).
                      </div>
                      <div style={{ textAlign: 'justify', marginTop: 15 }}>
                        <div>Unavailable:</div>
                        <div style={{ paddingLeft: 20 }}>
                          {unavailableEvaluators.map((evaluator) => {
                            if (evaluator == null) {
                            }
                            return <div>{`${evaluator ? evaluator.UserLast : 'Null Evaluator'}\n`}</div>;
                          })}
                        </div>
                        Available:
                        <div style={{ paddingLeft: 20 }}>
                          {availableEvaluators.map((evaluator) => {
                            return <div>{`${evaluator.UserLast}\n`}</div>;
                          })}
                        </div>
                      </div>
                    </div>
                  </UncontrolledTooltip>
                </div>,
              );
            }
          }
          return candidatesToRender;
        };

        return (
          <div>
            {isTableLoaded
              ? ScheduleBlockEntries.map((scheduleBlock, i) => {
                  const { Candidates = [], slot, slotEnd } = scheduleBlock;
                  // const rowStartTime = moment.tz(slot, null).format('MMM DD, YYYY hh:mm A');
                  // const rowEndTime = moment
                  //   .tz(slotEnd, null)
                  //   .subtract(5, 'seconds')
                  //   .format('MMM DD, YYYY hh:mm A');
                  const cTime = moment.tz(metaData.TimeZone).format('MMM DD, YYYY hh:mm A');

                  const isCurrentTime = isTimeRangeOverlap([slot, slotEnd], [cTime, cTime]);
                  cellContent = renderContentForWarning(Candidates, scheduleBlock);
                  return (
                    <div
                      style={
                        i > 0
                          ? style.splitCell(isCurrentTime)
                          : { border: isCurrentTime && i > 0 ? '3px solid green' : null }
                      }
                    >
                      {cellContent}
                    </div>
                  );
                })
              : null}
          </div>
        );
      } else {
        return (
          <div>
            {isTableLoaded
              ? ScheduleBlockEntries.map((scheduleBlock, i) => {
                  let blockIsUnavailable = false; // Checks for Overlap
                  const { Candidates = [], slot, slotEnd } = scheduleBlock;
                  const rowStartTime = moment.tz(slot, null).format('MMM DD, YYYY hh:mm A');
                  const rowEndTime = moment
                    .tz(slotEnd, null)
                    .subtract(5, 'seconds')
                    .format('MMM DD, YYYY hh:mm A');
                  const cTime = moment.tz(metaData.TimeZone).format('MMM DD, YYYY hh:mm A');

                  const isCurrentTime = isTimeRangeOverlap([slot, slotEnd], [cTime, cTime]);

                  if (/*blockIsUnavailable && */ isUnavailable) {
                    return (
                      <div
                        id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                        style={
                          i > 0
                            ? style.splitCell(isCurrentTime)
                            : { border: isCurrentTime && i > 0 ? '3px solid green' : null }
                        }
                        onClick={(e) => {
                          if (
                            scheduleBlock &&
                            /*backgroundColor &&*/ (!isEmbedded || allowScheduleInteraction) &&
                            generatedScheduleData == null
                          ) {
                            setSelectedScheduleBlock(scheduleBlock);
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        <div
                          id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}_label`}
                          style={{
                            ...style.candidateLabel,
                            color,
                            backgroundColor,
                            border,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={(e) => {
                            if (generatedScheduleData) {
                              e.stopPropagation();
                            }
                          }}
                        >
                          {isUnavailable
                            ? `UNAVAILABLE`
                            : cell.flexEventName
                            ? cell.flexEventName
                            : 'UNKNOWN FLEX EVENT'}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={
                          i > 0
                            ? style.splitCell(isCurrentTime)
                            : { border: isCurrentTime && i > 0 ? '3px solid green' : null }
                        }
                      >
                        {renderContent(Candidates, scheduleBlock)}
                      </div>
                    );
                  }
                })
              : null}
          </div>
        );
      }
    } else if (row.CustomMeetingTitle && !cellHasCandidates && ScheduleBlockEntries) {
      return (
        <div>
          {isTableLoaded
            ? ScheduleBlockEntries.map((scheduleBlock, i) => {
                const pendingItemKey = `${pk_Timeslot}_${pk_ScheduleAssignment}${
                  scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''
                }`;

                const { Candidates = [], slot, slotEnd } = scheduleBlock;
                const rowStartTime = moment.tz(slot, null).format('MMM DD, YYYY hh:mm A');
                const rowEndTime = moment
                  .tz(slotEnd, null)
                  .subtract(5, 'seconds')
                  .format('MMM DD, YYYY hh:mm A');
                const cTime = moment.tz(metaData.TimeZone).format('MMM DD, YYYY hh:mm A');

                const isCurrentTime = isTimeRangeOverlap([slot, slotEnd], [cTime, cTime]);

                const isPendingItem = pendingCandidates[pendingItemKey] != null; // TO DO: Move to per-scheduleBlock
                if (Candidates.length > 0) {
                  cellContent = renderContent(Candidates, scheduleBlock);
                  return (
                    <div
                      style={
                        i > 0
                          ? style.splitCell(isCurrentTime)
                          : { border: isCurrentTime && i > 0 ? '3px solid green' : null }
                      }
                    >
                      {cellContent}
                    </div>
                  );
                } else if (isPendingItem) {
                  const cellPendingCandidates = pendingCandidates[pendingItemKey] || [];
                  const candidate = cellPendingCandidates[0];
                  const backgroundColor =
                    candidates && Object.keys(candidates).length > 0 ? candidates[candidate.pk_Candidate].color : null;
                  const color = backgroundColor ? (tinycolor(backgroundColor).isLight() ? 'black' : 'white') : 'black';
                  const border = isPendingItem ? '5px solid #a1e3a5 ' : `5px solid ${backgroundColor}`;

                  return (
                    <div
                      style={
                        i > 0
                          ? style.splitCell(isCurrentTime)
                          : { border: isCurrentTime && i > 0 ? '3px solid red' : null }
                      }
                    >
                      <div
                        id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                        key={`${pk_Timeslot}_${pk_ScheduleAssignment}${
                          scheduleBlock ? `_${scheduleBlock.SortOrder}` : ''
                        }`}
                        style={{
                          ...style.candidateLabel,
                          color,
                          backgroundColor,
                          border,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          // if (scheduleBlock) setSelectedScheduleBlock(scheduleBlock);
                        }}
                      >
                        {candidate.LastName || ''} {candidate.FirstName ? `, ${candidate.FirstName}` : ''}
                      </div>
                    </div>
                  );
                } else {
                  let backgroundColor = '#d4d4d4';
                  const color = backgroundColor ? (tinycolor(backgroundColor).isLight() ? 'black' : 'white') : 'black';
                  const border = `5px solid ${backgroundColor}`;
                  return (
                    <div
                      id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}`}
                      style={
                        i > 0
                          ? style.splitCell(isCurrentTime)
                          : { border: isCurrentTime && i > 0 ? '3px solid green' : null }
                      }
                      onClick={(e) => {
                        setSelectedScheduleBlock(scheduleBlock);
                      }}
                    >
                      <div
                        id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}_${scheduleBlock.SortOrder}_label`}
                        style={{
                          ...style.candidateLabel,
                          color,
                          backgroundColor,
                          border,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        {row.CustomMeetingTitle}
                      </div>
                    </div>
                  );
                }
              })
            : null}
        </div>
      );
    } else if (
      (ScheduleBlockEntries && ScheduleBlockEntries.length > 0) ||
      (pendingItemsInThisCell && pendingItemsInThisCell.length > 0)
    ) {
      // REGULAR SPLIT CELL
      const { metaData } = scheduleData;
      return (
        <div>
          {isTableLoaded
            ? // ScheduleBlockEntries ? (
              ScheduleBlockEntries.map((scheduleBlock, i) => {
                const { Candidates = [], slot, slotEnd } = scheduleBlock;

                const cTime = moment.tz(metaData.TimeZone).format('MMM DD, YYYY hh:mm A');
                const isCurrentTime = isTimeRangeOverlap([slot, slotEnd], [cTime, cTime]);
                cellContent = renderContent(Candidates, scheduleBlock);

                return (
                  <div
                    style={
                      i > 0
                        ? style.splitCell(isCurrentTime)
                        : { border: isCurrentTime && i > 0 ? '3px solid green' : null }
                    }
                  >
                    {cellContent}
                  </div>
                );
              })
            : null}
        </div>
      );
    } else {
      return (
        <div
          key={`empty_${row.pk_Timeslot}_${Math.random() * 900}`}
          id={`capsule_${pk_ScheduleAssignment}_${pk_Timeslot}`}
          style={{
            ...style.candidateLabel,
            // visibility: 'hidden',
            padding: 5,
          }}
          onClick={() => {}}
        >
          NO VALUE
        </div>
      );
    }
  };

  const flexColumnCellFormatter = (cell, row, rowIndex, formatExtraData) => {
    let flexEvents = row.FlexEvents || [];

    let hasFlexEventMainRoomAndNoLink = false;
    let hasFlexEventCustomAndNoLink = false;
    let hasFlexEventUrlIssue = false;

    let flexEventWithUrlIssues = [];
    flexEvents = flexEvents.filter((fE) => {
      return (
        (fE.EventName && fE.EventName.toLowerCase() !== 'unavailable') ||
        (fE.FlexEventName && fE.FlexEventName.toLowerCase() !== 'unavailable')
      );
    });

    flexEvents.forEach((f) => {
      const { FlexEventName, CustomUrl, UrlType } = f;
      const isMainRoomAndNoLink = FlexEventName && UrlType === 'AllDay' && !scheduleData.metaData.MainRoomURL;
      const isCustomAndNoLink = FlexEventName && UrlType === 'Custom' && !CustomUrl;
      const urlIssue = VirtualMeetingMode !== 'Disabled' && (isMainRoomAndNoLink || isCustomAndNoLink);

      if (urlIssue) {
        hasFlexEventUrlIssue = true;
        flexEventWithUrlIssues.push(f);
      }

      if (isMainRoomAndNoLink) {
        hasFlexEventMainRoomAndNoLink = true;
      }

      if (isCustomAndNoLink) {
        hasFlexEventCustomAndNoLink = true;
      }
    });

    if (row.pk_Timeslot === 'END') {
      return (
        <div>
          <b>{row.endTimesText}</b>
        </div>
      );
    }

    const isOverlap = flexEvents && flexEvents.length > 1;

    return (
      <div
        id={`capsule_flexEvent_${rowIndex}`}
        style={{
          ...style.candidateLabel,
          color: flexEvents.length <= 0 ? 'white' : 'black',
          backgroundColor: isOverlap ? '#ff9703' : 'white',
          border: `5px solid ${isOverlap ? '#ff9703' : 'white'}`,
          textAlign: 'center',
          fontWeight: 'bold',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isOverlap
          ? `${flexEvents.length} Events`
          : flexEvents && flexEvents.length > 0
          ? flexEvents[0].FlexEventName || flexEvents[0].EventName
          : '|'}

        {isOverlap ? (
          <>
            <FontAwesomeIcon id={`row_${rowIndex}_flexEvent`} icon={faInfoCircle} style={{ marginLeft: 10 }} />
            <UncontrolledTooltip target={`row_${rowIndex}_flexEvent`}>
              {flexEvents.map((flexEvent) => {
                if (flexEvent.EventName) {
                  return <div>{flexEvent.EventName}</div>;
                } else if (flexEvent.FlexEventName) {
                  return <div>{flexEvent.FlexEventName}</div>;
                }
              })}
            </UncontrolledTooltip>
          </>
        ) : null}
        {hasFlexEventUrlIssue ? (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ marginLeft: 5 }}
              id={`row_${rowIndex}_flexEvent_urlIssueIcon`}
            />

            <UncontrolledTooltip target={`row_${rowIndex}_flexEvent_urlIssueIcon`}>
              The following Flex Events have URL issues:
              {flexEventWithUrlIssues.map((flexEvent) => {
                if (flexEvent.EventName) {
                  return <div>{flexEvent.EventName}</div>;
                } else if (flexEvent.FlexEventName) {
                  return <div>{flexEvent.FlexEventName}</div>;
                }
              })}
            </UncontrolledTooltip>
          </>
        ) : null}
        {/* {flexEvents.length > 0 && isOverlap ? (
          <UncontrolledTooltip target={`row_${rowIndex}_flexEvent`}>
            {flexEvents.map((flexEvent) => {
              if (flexEvent.EventName) {
                return <div>{flexEvent.EventName}</div>;
              } else if (flexEvent.FlexEventName) {
                return <div>{flexEvent.FlexEventName}</div>;
              }
            })}
          </UncontrolledTooltip>
        ) : null} */}
      </div>
    );
  };

  const sortHeaders = (scheduleData) => {
    const headers = scheduleData.headers || [];
    let newHeaders = [];
    const columnsWithRooms = [];
    const columnsWithoutRooms = [];

    headers.forEach((header) => {
      const listToAddTo = header.RoomLabel ? columnsWithRooms : columnsWithoutRooms;
      let name = '';
      let evaluators = header && header.Evaluators && header.Evaluators.Evaluators ? header.Evaluators.Evaluators : [];
      const evaluatorNames = [];
      evaluators.forEach((evaluator, i) => {
        const { UserFirst = ' ', UserLast = ' ' } = evaluator;
        evaluatorNames.push(`${UserFirst[0]}. ${UserLast}`);
      });
      evaluatorNames.sort();
      evaluatorNames.forEach((evaluatorName, i) => {
        name += `${evaluatorName}${evaluatorNames.length > 1 && i < evaluatorNames.length - 1 ? ', ' : ' '}`;
      });

      listToAddTo.push({
        ...header,
        text: name || 'No Header Name!',
        label: header.RoomLabel,
      });
    });

    columnsWithRooms.sort((a, b) => {
      const textA = a.label ? a.label.toUpperCase() : '';
      const textB = b.label ? b.label.toUpperCase() : '';
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });

    columnsWithoutRooms.sort((a, b) => {
      const textA = a.text ? a.text.toUpperCase() : '';
      const textB = b.text ? b.text.toUpperCase() : '';
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    newHeaders = [...columnsWithRooms, ...columnsWithoutRooms];

    return newHeaders;
  };

  const makeColumns = (data) => {
    const headers = data.headers;
    let newColumns = [];

    headers.forEach((header) => {
      let name = '';
      let evaluators = header && header.Evaluators && header.Evaluators.Evaluators ? header.Evaluators.Evaluators : [];
      const evaluatorNames = [];
      evaluators.forEach((evaluator, i) => {
        const { UserLast = ' ', UserFirst = ' ' } = evaluator;

        evaluatorNames.push(`${UserFirst[0]}. ${UserLast}`);
      });
      evaluatorNames.sort();
      evaluatorNames.forEach((evaluatorName, i) => {
        name += `${evaluatorName}${evaluatorNames.length > 1 && i < evaluatorNames.length - 1 ? ', ' : ' '}`;
      });

      newColumns.push({
        dataField: header.pk_ScheduleAssignment.toString(),
        text: name || 'No Header Name!',
        label: header.RoomLabel,
        formatter: cellFormatter,
        headerFormatter: headerFormatter,
        headerStyle: headerProcessStyle,
        style: processStyle,
        rawHeader: header,
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            if (generatedScheduleData || e.target.id.indexOf('capsule_') < 0 || row.pk_Timeslot === 'END') {
              return;
            }

            if (isInPreviewMode({ column, row })) {
              setShowUnsavedChangeModal(!isSubmitting);
              return;
            }

            if (isEmbedded && !allowScheduleInteraction) {
              return;
            }

            const cellActual = row[parseInt(column.dataField)];

            setSelectedCell({ column, row, columnIndex, rowIndex });
            setCellSplitCount(
              cellActual.ScheduleBlockTotalNumberOfSplits != null
                ? cellActual.ScheduleBlockTotalNumberOfSplits
                : cellActual.ColumnTotalNumberOfSplits
                ? cellActual.ColumnTotalNumberOfSplits
                : 1,
            );
            setShowScheduleDetailModal(true);
            hideHeaderPopoversExcept();
            hideTimeSlotPopoversExcept();
            setShowAddColumnPopover(false);
          },
          onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
            if (isInPreviewMode()) {
              return;
            }
            setCellHoveredOn({ rowIndex, columnIndex });
          },
          onMouseLeave: (e, column, columnIndex, row, rowIndex) => {
            if (isInPreviewMode()) {
              return;
            }
            setCellHoveredOn(null);
          },
        },
        headerEvents: {
          onClick: async (e, column, columnIndex) => {
            const { rawHeader } = column;
            const { RoomFocuses = [], VirtualRoomURL } = rawHeader || {};
            const { VirtualMeetingMode } = dContext.department;

            e.stopPropagation();
            // if (dateIsInThePast && !willEditThePast) {
            //   const continueProcess = await fireConfirmationForEditingPastSchedule();
            //   if (!continueProcess) {
            //     return;
            //   }
            // }
            if (isInPreviewMode()) {
              setShowUnsavedChangeModal(!isSubmitting);
              return;
            }
            hideTimeSlotPopoversExcept();
            // hideHeaderPopoversExcept(parseInt(column.dataField));
            setShowAddColumnPopover(false);
          },
          onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
            setHeaderHoveredOn({ column, columnIndex });
          },
          onMouseLeave: () => {
            setHeaderHoveredOn(null);
          },
        },
        headerAttrs: {
          id: `room_${header.pk_ScheduleAssignment}_header`,
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          if (row.pk_Timeslot === 'END') {
            if (colIndex == 1) {
              return { colSpan: hasFlexColumn ? scheduleData.headers.length + 1 : scheduleData.headers.length };
            } else {
              return { hidden: true };
            }
          }
        },
      });
    });

    newColumns.splice(0, 0, {
      dataField: 'slot',
      text: 'Time',
      formatter: cellFormatter,
      headerFormatter: headerFormatter,
      classes: 'timeSlot',
      style: processStyle,
      headerStyle: headerProcessStyle,
      isTime: true,
      formatExtraData: { metaData: data.metaData, isTime: true },
      events: {
        onClick: async (e, column, columnIndex, row, rowIndex) => {
          if ((isEmbedded && !allowScheduleInteraction) || row.pk_Timeslot === 'END') {
            return;
          }

          if (columnIndex == 0) {
            if (isInPreviewMode()) {
              setShowUnsavedChangeModal(!isSubmitting);
              return;
            }
            hideTimeSlotPopoversExcept(row.pk_Timeslot);
            hideHeaderPopoversExcept();
            setShowAddColumnPopover(false);
          }
        },
      },
      headerEvents: {
        onClick: async (e, column, columnIndex) => {
          e.stopPropagation();
          if (isEmbedded) {
            return;
          }

          if (isInPreviewMode()) {
            setShowUnsavedChangeModal(!isSubmitting);
            return;
          }
          hideTimeSlotPopoversExcept();
          hideHeaderPopoversExcept('timeSlotHeader');
          setShowAddColumnPopover(false);
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setHeaderHoveredOn({ column, columnIndex });
        },
        onMouseLeave: () => {
          setHeaderHoveredOn(null);
        },
      },
      headerAttrs: {
        id: 'timeSlotHeader',
      },
      attrs: (cell, row, rowIndex, colIndex) => {
        return { id: `timeSlot_${row.pk_Timeslot}` };
      },
    });

    if (hasFlexColumn) {
      newColumns.push({
        dataField: 'flexColumn',
        text: 'Flex Events',
        isDummyField: true,
        formatter: flexColumnCellFormatter,
        headerStyle: {
          alignItems: 'center',
          color: 'black',
          cursor: 'pointer',
          maxWidth: 280,
          minWidth: 180,
          position: 'sticky',
          textAlign: 'center',
          top: -5,
          verticalAlign: 'middle',
          backgroundColor: '#afafaf',
        },
        style: processFlexCellStyle,
        headerFormatter: headerFormatter,
        events: {
          onClick: async (e, column, columnIndex, row, rowIndex) => {
            if (isFetching || isSubmitting || generatedScheduleData != null || e.target.id.indexOf('capsule_') < 0) {
              e.stopPropagation();
              return;
            }
            if (isInPreviewMode({ column, row })) {
              setShowUnsavedChangeModal(!isSubmitting);
              return;
            }
            // if (dateIsInThePast && !willEditThePast) {
            //   const continueProcess = await fireConfirmationForEditingPastSchedule();
            //   if (!continueProcess) {
            //     return;
            //   }
            // }
            if (!allowScheduleInteraction) {
              return;
            }
            setSelectedCell({ column, row, columnIndex, rowIndex });
            setShowFlexEventDetailModal(true);
          },
          onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
            if (isInPreviewMode()) {
              return;
            }
            setCellHoveredOn({ rowIndex, columnIndex });
          },
          onMouseLeave: (e, column, columnIndex, row, rowIndex) => {
            if (isInPreviewMode()) {
              return;
            }
            setCellHoveredOn(null);
          },
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          if (row.pk_Timeslot === 'END') {
            if (colIndex == 1) {
              return { colSpan: hasFlexColumn ? scheduleData.headers.length + 1 : scheduleData.headers.length };
            } else {
              return { hidden: true };
            }
          }
        },
      });
    }

    const newHeaders = [];
    newColumns.forEach((column) => {
      const originalHeader = headers.find((h) => {
        return parseInt(h.pk_ScheduleAssignment) == parseInt(column.dataField);
      });

      if (originalHeader) {
        newHeaders.push(clone(originalHeader));
      }
    });

    const newScheduleData = clone(scheduleData);
    newScheduleData.headers = newHeaders;
    return newColumns;
  };

  const onToggleScheduleDetailModal = () => {
    if (showScheduleDetailModal) {
      setSelectedCell(null);
      setSelectedScheduleBlock(null);
    }
    setShowScheduleDetailModal(!showScheduleDetailModal);
  };

  const updateEvaluators = (data, callback) => {
    const {
      evaluatorsToAdd = [],
      evaluatorsToDelete = [],
      column,
      TotalNumberOfSplits,
      pk_MeetingRoom,
      ignoreEvaluatorFocuses,
      RoomLabel,
      pk_ScheduleAssignment,
      RoomFocuses = [],
      VirtualRoomDescription,
      VirtualRoomURL,
    } = data;

    const { rawHeader = {} } = column || {};
    const { Evaluators = {} } = rawHeader || {};
    if (
      Evaluators &&
      Evaluators.Evaluators &&
      evaluatorsToDelete &&
      evaluatorsToAdd &&
      evaluatorsToDelete.length - evaluatorsToAdd.length >= Evaluators.Evaluators.length
    ) {
      Swal.fire(
        'Oops...',
        'You must select at least one evaluator or delete this column if it is no longer needed.',
        'error',
      );
    }

    const payload = {
      writeEvaluatorPrimaryKeys: evaluatorsToAdd || [],
      deleteEvaluatorPrimaryKeys: evaluatorsToDelete || [],
      TotalNumberOfSplits,
      VirtualRoomDescription,
      EnableIgnoreEvaluatorFocus: ignoreEvaluatorFocuses, // TODO
      VirtualRoomURL: VirtualRoomURL ? urlWithHttpProtocol(VirtualRoomURL) : null,
      Focuses: RoomFocuses.map((f) => {
        return f.pk_Focus;
      }),
    };

    if (RoomLabel != null) {
      payload.RoomLabel = RoomLabel;
    }

    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/schedule/evaluator',
          {
            pk_Department: dContext.department.pk_Department,
            pk_ScheduleAssignment: parseInt(pk_ScheduleAssignment),
            pk_InterviewDate: pk_InterviewDate || Number(scheduleID),
          },
          payload,
          formatBearerToken(token),
        )
          .then((res) => {
            const newShowHeaderPopOver = { ...showHeaderPopOver };
            newShowHeaderPopOver[pk_ScheduleAssignment].self = false;
            if (callback) {
              callback();
            }
            setIsSubmitting(false);
            setShowHeaderPopOver(newShowHeaderPopOver);
            fetchSchedule(token);
          })
          .catch((err) => {
            if (callback) {
              callback(false);
            }
            setIsSubmitting(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const setSaveHook = (newSaveHook, key) => {
    const newSaveHooks = { ...saveHooks };
    if (newSaveHooks[key] == null) {
      newSaveHooks[key] = {};
    }
    newSaveHooks[key] = newSaveHook;
    setSaveHooks(newSaveHooks);
  };

  const setIsChanged = (newIsChanged, key) => {
    const newSaveHooks = { ...saveHooks };

    if (newSaveHooks[key] == null) {
      newSaveHooks[key] = {};
    }
    newSaveHooks[key].isChanged = newIsChanged;
    setSaveHooks(newSaveHooks);
  };

  const countFlexEvents = (customEvaluators) => {
    let count = 0;

    Object.values(indexedFlexEvents).forEach((flexEvent) => {
      if (flexEvent.FlexEventName && flexEvent.FlexEventName.toLowerCase() !== 'unavailable') {
        count++;
      }
    });

    // Object.values(customEvaluators || evaluators).forEach((e) => {
    //   if (e.FlexEvents) {
    //     e.FlexEvents.forEach((flexEvent) => {
    //       const flexEventStartDate = moment(flexEvent.FlexStartTime).format('MMM DD YYYY');
    //       const flexEventEndDate = moment(flexEvent.FlexTimeEnd).format('MMM DD, YYYY');
    //       const { metaData = {} } = scheduleData || {};
    //       if (
    //         (flexEventStartDate === metaData.DateOfInterview || flexEventEndDate === metaData.DateOfInterview) &&
    //         flexEvent.FlexEventName &&
    //         flexEvent.FlexEventName.toLowerCase() !== 'unavailable'
    //       ) {
    //         count++;
    //       }
    //     });
    //   }
    // });
    return count;
  };

  const renderHeaderPopOver = (header) => {
    const visibility = showHeaderPopOver[header.dataField] || {};
    let hasChildOpen = false;

    Object.keys(visibility).forEach((key) => {
      if (key !== 'self') {
        if (visibility[key]) {
          hasChildOpen = true;
        }
      }
    });

    if (document.getElementById(`room_${header.dataField}_header`) == null) {
      return;
    }
    return (
      <Popover
        placementPrefix="bs-popover headerDetailPopover"
        isOpen={visibility && visibility.self} // Change with room id later
        target={`room_${header.dataField}_header`} // Change with room id later
        trigger={`${hasChildOpen ? 'click' : 'legacy'}`}
        placement="right"
        modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={async () => {
          const newShowHeaderPopOver = { ...showHeaderPopOver };
          const visibility = newShowHeaderPopOver[header.dataField] || {};

          if (!allowScheduleInteraction) {
            return;
          }

          if (visibility.showFocusPopover) {
            visibility.showFocusPopover = false;
            newShowHeaderPopOver[header.dataField] = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }
          if (visibility.showUnavailable) {
            visibility.showUnavailable = false;
            newShowHeaderPopOver[header.dataField] = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }
          if (visibility.showRoomNumberPopover) {
            visibility.showRoomNumberPopover = false;
            newShowHeaderPopOver[header.dataField] = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }

          if (visibility.showSplitPopover) {
            visibility.showSplitPopover = false;
            newShowHeaderPopOver[header.dataField] = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }

          if (visibility.showConfirmClearPopover) {
            visibility.showConfirmClearPopover = false;
            newShowHeaderPopOver[header.dataField] = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }

          // if (dateIsInThePast && !willEditThePast) {
          //   const continueProcess = await fireConfirmationForEditingPastSchedule();
          //   if (!continueProcess) {
          //     return;
          //   }
          // }
          if (isInPreviewMode() && !visibility.self) {
            setShowUnsavedChangeModal(!isSubmitting);
            return;
          }

          visibility.self = !visibility.self;

          if (!visibility.self) {
            visibility.showHeaderPopOver = false;
            visibility.showFocusPopover = false;

            hideHeaderPopoversExcept();
          } else {
            hideHeaderPopoversExcept(header.dataField);
          }

          // LAST RESORT, Manually false ALL other header keys
          // Object.keys(newShowHeaderPopOver).forEach((key)=>{

          //   newShowHeaderPopOver
          // })
          // newShowHeaderPopOver[header.dataField] = visibility;

          // setShowHeaderPopOver(newShowHeaderPopOver);
          // hideHeaderPopoversExcept(header.dataField);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.simpleColumn}>
              {' '}
              <div style={{ textAlign: 'left' }}>{header.text || ''}</div>
            </div>
            <div style={style.simpleColumn}>
              <div style={style.flexEndRow}>
                {/* {saveHooks[header.dataField] && saveHooks[header.dataField].isChanged ? (
                  <FontAwesomeIcon
                    icon={faCheck}
                    style={{ ...style.clickableIcon, marginRight: 10 }}
                    onClick={(e) => {
                      // headerPopoverRefs[header.dataField].onSave();
                      if (saveHooks[header.dataField]) {
                        saveHooks[header.dataField].onSave();
                      }
                    }}
                  />
                ) : null}
                {saveHooks[header.dataField] && saveHooks[header.dataField].isChanged ? (
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ ...style.clickableIcon, marginRight: 10 }}
                    onClick={(e) => {
                      if (saveHooks[header.dataField] && saveHooks[header.dataField].onClear) {
                        saveHooks[header.dataField].onClear();
                      }
                    }}
                  />
                ) : null} */}
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={style.clickableIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    const newShowHeaderPopOver = { ...showHeaderPopOver };
                    const visibility = newShowHeaderPopOver[header.dataField] || {};
                    visibility.self = false;
                    visibility.showRoomNumberPopover = false;
                    Object.keys(visibility).forEach((key) => {
                      visibility[key] = false;
                    });
                    newShowHeaderPopOver[header.dataField] = { ...visibility };
                    setShowHeaderPopOver(newShowHeaderPopOver);
                  }}
                />
              </div>
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <HeaderDetailDropDown
            addUnavailableSlot={addUnavailableSlot}
            addUnavailableSlots={addUnavailableSlots}
            updateFlexEvents={updateFlexEvents}
            fetchSchedule={() => {
              fetchSchedule(token);
            }}
            columnTracker={columnTracker}
            dContext={dContext}
            deleteFlexEvent={deleteFlexEvent}
            evaluators={evaluators}
            generateScheduleForColumn={generateScheduleForColumn}
            headers={scheduleData ? scheduleData.headers : []}
            isSubmitting={isSubmitting}
            onClearColumn={onClearColumn}
            onDeleteColumn={onDeleteColumn}
            onUpdateEvaluators={updateEvaluators}
            scheduleData={scheduleData}
            selectedHeader={header}
            setColumnSplit={setColumnSplit}
            setIsChanged={setIsChanged}
            setSaveHook={setSaveHook}
            setShowHeaderPopOver={setShowHeaderPopOver}
            setShowSplitCellConfirmModal={setShowSplitCellConfirmModal}
            showHeaderPopOver={showHeaderPopOver}
            splitCountTracker={splitCountTracker}
            token={token}
            updateFlexEvent={updateFlexEvent}
            fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
            dateIsInThePast={dateIsInThePast}
            willEditThePast={willEditThePast}
            scheduleID={scheduleID}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderTimeSlotHeaderPopOver = () => {
    const visibility = showHeaderPopOver['timeSlotHeader'] || {};
    let hasChildOpen = false;
    Object.keys(visibility).forEach((key) => {
      if (key !== 'self') {
        if (visibility[key]) {
          hasChildOpen = true;
        }
      }
    });
    return (
      <Popover
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        placementPrefix="bs-popover headerDetailPopover"
        placement="right"
        flip={false}
        isOpen={visibility && visibility.self} // Change with room id later
        target={`timeSlotHeader`} // Change with room id later
        trigger={`${hasChildOpen ? 'click' : 'legacy'}`}
        toggle={async () => {
          if (generatedScheduleData || showPastScheduleWarning) {
            return;
          }

          const newShowHeaderPopOver = { ...showHeaderPopOver };
          const visibility = newShowHeaderPopOver['timeSlotHeader'] || {};
          if (visibility.showAddTimeSlotPopover) {
            visibility.showAddTimeSlotPopover = false;
            newShowHeaderPopOver.timeSlotHeader = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }

          if (visibility.showConfirmModal) {
            visibility.showConfirmModal = false;
            newShowHeaderPopOver.timeSlotHeader = visibility;
            setShowHeaderPopOver(newShowHeaderPopOver);
            return;
          }

          // if (dateIsInThePast && !willEditThePast) {
          //   const continueProcess = await fireConfirmationForEditingPastSchedule();
          //   if (!continueProcess) {
          //     return;
          //   }
          // }
          if (isInPreviewMode() && !visibility.self) {
            setShowUnsavedChangeModal(!isSubmitting);
            return;
          }

          visibility.self = !visibility.self;
          if (!visibility.self) {
            visibility.showHeaderPopOver = false;
            visibility.showFocusPopover = false;
          }

          newShowHeaderPopOver['timeSlotHeader'] = visibility;

          setShowHeaderPopOver(newShowHeaderPopOver);
        }}
      >
        <PopoverHeader>
          <div style={style.flexEndRow}>
            <div style={{ width: '100%', textAlign: 'center' }}>Manage Time</div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={() => {
                const newShowHeaderPopOver = { ...showHeaderPopOver };
                const visibility = newShowHeaderPopOver['timeSlotHeader'] || {};
                visibility.self = false;
                visibility.showRoomNumberPopover = false;
                newShowHeaderPopOver['timeSlotHeader'] = visibility;
                setShowHeaderPopOver(newShowHeaderPopOver);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <TimeSlotHeaderPopover
            pk_InterviewDate={pk_InterviewDate}
            forcedContext={forcedContext}
            showHeaderPopOver={showHeaderPopOver}
            setShowHeaderPopOver={setShowHeaderPopOver}
            updateAllData={updateAllData}
            scheduleData={scheduleData}
            fetchSchedule={fetchSchedule}
            dateIsInThePast={dateIsInThePast}
            willEditThePast={willEditThePast}
            fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
            hideHeaderPopover={async () => {
              const newShowHeaderPopOver = { ...showHeaderPopOver };
              const visibility = newShowHeaderPopOver['timeSlotHeader'] || {};

              if (dateIsInThePast && !willEditThePast) {
                const continueProcess = await fireConfirmationForEditingPastSchedule();
                if (!continueProcess) {
                  return;
                }
              }
              if (isInPreviewMode() && !visibility.self) {
                setShowUnsavedChangeModal(!isSubmitting);
                return;
              }

              visibility.self = false;
              visibility.showHeaderPopOver = false;
              visibility.showFocusPopover = false;

              newShowHeaderPopOver['timeSlotHeader'] = visibility;

              setShowHeaderPopOver(newShowHeaderPopOver);
            }}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderTimeSlotPopover = (row) => {
    // WARNING: Weird bug for Popover.
    // Add column with key x, delete column, add column with
    // key x again, crash.
    const { pk_Timeslot } = row;
    const visibility = showTimeSlotPopover[pk_Timeslot] || {};
    const { metaData } = scheduleData;
    const slotTime = row.slot; //.format('hh:mm A');
    const slotEndTime = row.slotEnd; //.format('hh:mm A');
    let hasChildOpen = false;
    Object.keys(visibility).forEach((key) => {
      if (key !== 'self') {
        if (visibility[key]) {
          hasChildOpen = true;
        }
      }
    });

    return (
      <Popover
        // placementPrefix="headerDetailPopover bs-popover "
        popperClassName="headerDetailPopover"
        target={`timeSlot_${pk_Timeslot}`} // Change with room id later
        trigger={`${hasChildOpen ? 'click' : 'legacy'}`}
        isOpen={visibility && visibility.self}
        flip={false}
        placement="right"
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={async () => {
          return;
          const newShowTimeSlotPopover = { ...showTimeSlotPopover };
          const visibility = newShowTimeSlotPopover[pk_Timeslot] || {};
          if (visibility.showAddTimeSlotPopover) {
            visibility.showAddTimeSlotPopover = false;
            newShowTimeSlotPopover[pk_Timeslot] = visibility;
            setShowTimeSlotPopover(newShowTimeSlotPopover);
            return;
          }

          if (visibility.showAddCustomPopover) {
            visibility.showAddCustomPopover = false;
            newShowTimeSlotPopover[pk_Timeslot] = visibility;
            setShowTimeSlotPopover(newShowTimeSlotPopover);
            return;
          }

          if (visibility.showCustomDurationPopover) {
            visibility.showCustomDurationPopover = false;
            newShowTimeSlotPopover[pk_Timeslot] = visibility;
            setShowTimeSlotPopover(newShowTimeSlotPopover);
            return;
          }

          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }

          if (isInPreviewMode()) {
            setShowUnsavedChangeModal(!isSubmitting);
            return;
          }

          visibility.self = !visibility.self;

          newShowTimeSlotPopover[pk_Timeslot] = visibility;

          setShowTimeSlotPopover(newShowTimeSlotPopover);
          // hideTimeSlotPopoversExcept(pk_Timeslot);
          hideHeaderPopoversExcept();
        }}
      >
        <PopoverHeader>
          <div style={style.flexEndRow}>
            <div style={style.centeredLabel}>{`${moment.tz(slotTime, null).format('hh:mm A')} - ${moment
              .tz(slotEndTime, null)
              .format('hh:mm A')}`}</div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={(e) => {
                e.stopPropagation();
                const newShowTimeSlotPopover = { ...showTimeSlotPopover };

                newShowTimeSlotPopover[pk_Timeslot] = { self: false };
                setShowTimeSlotPopover(newShowTimeSlotPopover);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <TimeSlotPopover
            forcedContext={forcedContext}
            row={row}
            fetchSchedule={fetchSchedule}
            pk_InterviewDate={pk_InterviewDate || Number(scheduleID)}
            scheduleData={scheduleData}
            addRowCustomEvent={addRowCustomEvent}
            showTimeSlotPopover={showTimeSlotPopover}
            setShowTimeSlotPopover={setShowTimeSlotPopover}
            fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
            dateIsInThePast={dateIsInThePast}
            willEditThePast={willEditThePast}
            showPastScheduleWarning={showPastScheduleWarning}
            hideHeaderPopover={() => {
              const newShowTimeSlotPopover = { ...showTimeSlotPopover };
              newShowTimeSlotPopover[pk_Timeslot] = { self: false };
              setShowTimeSlotPopover(newShowTimeSlotPopover);
            }}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderAddColumnPopover = () => {
    return (
      <Popover
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        flip={false}
        placementPrefix="addColumnPopover bs-popover"
        target={`addButton`}
        trigger={'legacy'}
        isOpen={showAddColumnPopover}
        toggle={async () => {
          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
          if (isInPreviewMode()) {
            setShowUnsavedChangeModal(!isSubmitting);
            return;
          }
          setShowAddColumnPopover(!showAddColumnPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.flexEndRow}>
            <div style={style.centeredLabel}>Add Column</div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.clickableIcon}
              onClick={() => {
                setShowAddColumnPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <AddColumnPopover
            headers={scheduleData && scheduleData.headers ? scheduleData.headers : []}
            evaluators={evaluators}
            isAddingColumn={isAddingColumn}
            onAddColumn={addColumn}
            onAddColumns={addColumns}
            onAddFlexColumn={addFlexColumn}
            hasFlexColumn={hasFlexColumn}
            onRemoveFlexColumn={removeFlexColumn}
            setShowAddColumnPopover={setShowAddColumnPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderCloneModal = () => {
    return (
      <CloneModal
        scheduleID={scheduleID}
        setWillEditThePast={setWillEditThePast}
        resetCopyValues={resetCopyValues}
        showCloneModal={showCloneModal}
        setShowCloneModal={setShowCloneModal}
        setInterviewDateToClone={setInterviewDateToClone}
        willCopyHeaders={willCopyHeaders}
        setWillCopyHeaders={setWillCopyHeaders}
        willCopyStartTime={willCopyStartTime}
        setWillCopyStartTime={setWillCopyStartTime}
        willCopyInterviewDuration={willCopyInterviewDuration}
        setWillCopyInterviewDuration={setWillCopyInterviewDuration}
        willCopyPassingDuration={willCopyPassingDuration}
        setWillCopyPassingDuration={setWillCopyPassingDuration}
        willCopyCandidateAssignments={willCopyCandidateAssignments}
        setWillCopyCandidateAssignments={setWillCopyCandidateAssignments}
        willCopyCustomEvents={willCopyCustomEvents}
        setWillCopyCustomEvents={setWillCopyCustomEvents}
        willCopyTimeSlots={willCopyTimeSlots}
        setWillCopyTimeSlots={setWillCopyTimeSlots}
        willCopyFlexEventEvaluators={willCopyFlexEventEvaluators}
        setWillCopyFlexEventEvaluators={setWillCopyFlexEventEvaluators}
        willCopyCustomDurations={willCopyCustomDurations}
        setWillCopyCustomDurations={setWillCopyCustomDurations}
        willCopyFlexEvents={willCopyFlexEvents}
        setWillCopyFlexEvents={setWillCopyFlexEvents}
        willCopyEvaluators={willCopyEvaluators}
        setWillCopyEvaluators={setWillCopyEvaluators}
        willCopyFocuses={willCopyFocuses}
        setWillCopyFocuses={setWillCopyFocuses}
        interviewDateToClone={interviewDateToClone}
        isCloning={isCloning}
        cloneFromExistingSchedule={cloneFromExistingSchedule}
        isSubmitting={isSubmitting}
        interviewDates={interviewDates}
      />
    );
  };

  const attachFlexEvents = (scheduleData, newEvaluators, indexedData) => {
    const newScheduleData = clone(scheduleData);
    const { body, headers, metaData } = newScheduleData;
    const { FlexEvents = [] } = metaData;
    const newBody = clone(body);
    let flexEventName = null;
    const newHeaders = clone(headers);
    const headerFlexEvents = {};
    const { newIndexedFlexEventEvaluators, newIndexedFlexEvents, newIndexedEvaluatorFlexEvents } = indexedData || {};

    newBody.forEach((row, i) => {
      const rowStartTime = moment.tz(row.slot, null);
      const rowEndTime = moment.tz(row.slotEnd, null);

      // Attaches Flex Events by looking through evaluators. Processes Unavailables
      headers.forEach((header, i) => {
        const { pk_ScheduleAssignment } = header;
        let isFlexEvent = false;
        let isUnavailable = false;
        let cellState = 'Normal'; // Normal, Flex Event, Warning, Unavailable
        let flexEventActual = null;

        const cellFlexEvents = [];
        let cellEvaluators = [];
        let cellEvaluatorKeys = [];

        // debugger ============================
        // isFlexEvent = Math.random() * 100 < 20;
        // isUnavailable = Math.random() * 100 < 20;
        // ======================================

        const Evaluators = header.Evaluators && header.Evaluators.Evaluators ? header.Evaluators.Evaluators : [];
        cellEvaluators = [...Evaluators];
        cellEvaluators.forEach((evaluator) => {
          cellEvaluatorKeys.push(evaluator.pk_User);
        });

        cellEvaluators = [];

        cellEvaluatorKeys.forEach((pk_User) => {
          if (newEvaluators[pk_User]) {
            cellEvaluators.push(newEvaluators[pk_User]);
          }
        });

        const availableEvaluators = [...cellEvaluators];
        const unavailableEvaluators = [];

        Evaluators.forEach((headerEvaluator) => {
          const pk_User = Object.keys(newEvaluators).find((key) => {
            return key.toString() === headerEvaluator.pk_User.toString();
          });

          const evaluator = newEvaluators[pk_User];

          if (evaluator) {
            if (evaluator.FlexEvents) {
              const evaluatorFlexEvents = newIndexedEvaluatorFlexEvents[pk_User] || [];

              evaluatorFlexEvents.forEach((f) => {
                const flexEvent = newIndexedFlexEvents[f.pk_FlexEvent];
                const { FlexTimeStart, FlexDateStart, FlexTimeEnd, FlexDateEnd } = flexEvent;
                const flexEventStart = moment.tz(`${FlexDateStart} ${FlexTimeStart}`, null);
                const flexEventEnd = moment.tz(`${FlexDateEnd} ${FlexTimeEnd}`, null);

                if (
                  (flexEventStart.isSameOrAfter(rowStartTime) && flexEventStart.isBefore(rowEndTime)) || // Event start is between rowstart and rowend
                  (flexEventEnd.isAfter(rowStartTime) && flexEventEnd.isBefore(rowEndTime)) || // event end is between rowstart and row end
                  (flexEventStart.isSameOrBefore(rowStartTime) && flexEventEnd.isSameOrAfter(rowEndTime)) // rowstart and row end is within event start and end
                ) {
                  isFlexEvent = true; // flexEvent.FlexEventName.toLowerCase() === 'unavailable';
                  flexEventName = flexEvent.FlexEventName;
                  flexEventActual = flexEvent;

                  // if Evaluator has UNAVAILABLE event, means evaluator would
                  // be unavailable for this cell
                  if (flexEventName === 'UNAVAILABLE') {
                    const indexToRemove = availableEvaluators.findIndex((item) => {
                      return item.pk_User == evaluator.pk_User;
                    });
                    if (indexToRemove >= 0) {
                      unavailableEvaluators.push(clone(availableEvaluators[indexToRemove]));
                      availableEvaluators.splice(indexToRemove, 1);
                    }
                  } else {
                    // Block for Non-UNAVAILABLE flex events
                    const newRowFlexEvents = row.FlexEvents ? row.FlexEvents.slice() : [];

                    if (
                      !newRowFlexEvents.find((f) => {
                        return f.pk_FlexEvent == flexEvent.pk_FlexEvent;
                      })
                    ) {
                      newRowFlexEvents.push(flexEvent);
                      row.FlexEvents = newRowFlexEvents;
                    }

                    row[`${header.pk_ScheduleAssignment.toString()}`].flexEvents = newRowFlexEvents;
                    row[`${header.pk_ScheduleAssignment.toString()}`].FlexEvents = newRowFlexEvents;
                  }

                  if (
                    flexEvent.FlexEventName.toLowerCase() === 'unavailable' &&
                    !cellFlexEvents.find((f) => {
                      return f.pk_FlexEvent === flexEvent.pk_FlexEvent;
                    })
                  ) {
                    cellFlexEvents.push(flexEvent);
                    const { ScheduleBlockEntries = [] } = row[pk_ScheduleAssignment];

                    ScheduleBlockEntries.forEach((scheduleBlockEntry) => {
                      const { slot, slotEnd } = scheduleBlockEntry;
                      const { FlexStartTime, FlexTimeEnd } = flexEvent;

                      if (isTimeRangeOverlap([slot, slotEnd], [FlexStartTime, FlexTimeEnd])) {
                        if (!scheduleBlockEntry.flexEvents) {
                          scheduleBlockEntry.flexEvents = [];
                        }
                        if (!scheduleBlockEntry.FlexEvents) {
                          scheduleBlockEntry.FlexEvents = [];
                        }
                        scheduleBlockEntry.isFlexEvent = true;
                        scheduleBlockEntry.flexEvents.push(flexEvent);
                        // scheduleBlockEntry.FlexEvents.push(flexEvent);
                      }
                    });
                  }
                }
              });
            }
          }
        });

        // Check if there would be evaluators left
        if (cellEvaluators.length > 0 && availableEvaluators.length == 0) {
          isUnavailable = true;
          cellState = 'Unavailable';
        } else if (
          cellEvaluators.length > 0 &&
          availableEvaluators.length > 0 &&
          availableEvaluators.length < cellEvaluators.length
        ) {
          cellState = 'Warning';
        } else if (isFlexEvent) {
          cellState = 'Flex Event';
        } else {
          cellState = 'Normal';
        }

        row[pk_ScheduleAssignment].isFlexEvent = isFlexEvent;
        row[pk_ScheduleAssignment].isUnavailable = isUnavailable;
        row[pk_ScheduleAssignment].flexEvents = cellFlexEvents;
        row[pk_ScheduleAssignment].cellState = cellState;
        row[pk_ScheduleAssignment].availableEvaluators = availableEvaluators;
        row[pk_ScheduleAssignment].unavailableEvaluators = unavailableEvaluators;

        if (headerFlexEvents[pk_ScheduleAssignment] == null) {
          headerFlexEvents[pk_ScheduleAssignment] = [];
        }
        cellFlexEvents.forEach((flexEvent) => {
          if (flexEvent.FlexEventName !== 'UNAVAILABLE') return;

          const { pk_FlexEvent } = flexEvent;
          const eventIndex = headerFlexEvents[pk_ScheduleAssignment].findIndex((f) => {
            return f.pk_FlexEvent === pk_FlexEvent;
          });
          if (eventIndex < 0) {
            const evaluatorIDs = newIndexedFlexEventEvaluators
              ? newIndexedFlexEventEvaluators[flexEvent.pk_FlexEvent]
              : indexedFlexEventEvaluators[flexEvent.pk_FlexEvent] || [];
            const evaluatorsActual = [];

            evaluatorIDs.forEach((id) => {
              const evaluatorToAdd = clone(newEvaluators[parseInt(id)]);

              if (evaluatorToAdd) {
                delete evaluatorToAdd.FlexEvents;

                evaluatorsActual.push(evaluatorToAdd);
              }
            });

            flexEvent.evaluators = evaluatorsActual;
            headerFlexEvents[pk_ScheduleAssignment].push(flexEvent);
          }
        });

        if (isFlexEvent) {
          row[pk_ScheduleAssignment].flexEventName = flexEventName;
        }
      });

      //  Attaches Flex EVents by looping through Flex Events. Should have been used isntead of the above headers loop, but a lot of existing code already uses the shape from headers loop :'(
      // Runs through Flex Events list off of metadata and checks if overlaps with row.
      FlexEvents.forEach((flexEvent) => {
        const { FlexTimeStart, FlexDateStart, FlexTimeEnd, FlexDateEnd, pk_FlexEvent } = flexEvent;
        const flexEventStart = moment.tz(`${FlexDateStart} ${FlexTimeStart}`, null);
        const flexEventEnd = moment.tz(`${FlexDateEnd} ${FlexTimeEnd}`, null);
        const newRowFlexEvents = row.FlexEvents || [];

        if (
          (flexEventStart.isSameOrAfter(rowStartTime) && flexEventStart.isBefore(rowEndTime)) || // Event start is between rowstart and rowend
          (flexEventEnd.isAfter(rowStartTime) && flexEventEnd.isBefore(rowEndTime)) || // event end is between rowstart and row end
          (flexEventStart.isSameOrBefore(rowStartTime) && flexEventEnd.isSameOrAfter(rowEndTime)) // rowstart and row end is within event start and end
        ) {
          if (
            !newRowFlexEvents.find((f) => {
              return f.pk_FlexEvent === pk_FlexEvent;
            })
          ) {
            newRowFlexEvents.push(flexEvent);
            row.FlexEvents = newRowFlexEvents;
          }

          if (flexEvent.AttendingCandidates && flexEvent.AttendingCandidates.length > 0) {
            flexEvent.AttendingCandidates.forEach((candidate) => {
              headers.forEach((header, i) => {
                const { pk_ScheduleAssignment } = header;

                const Evaluators = header.Evaluators ? header.Evaluators.Evaluators : [];

                const { ScheduleBlockEntries = [] } = row[pk_ScheduleAssignment] || {};
                const newScheduleBlockEntries = [];
                const oldFlexEvents = row[pk_ScheduleAssignment].FlexEvents || [];
                const flexEventAlreadyExistsInCell = oldFlexEvents.find((fe) => {
                  return fe.pk_FlexEvent == flexEvent.pk_FlexEvent;
                });

                if (!flexEventAlreadyExistsInCell) {
                  oldFlexEvents.push(flexEvent);
                  row[pk_ScheduleAssignment].FlexEvents = oldFlexEvents;
                }

                const flexEventHasEvaluatorForColumn = flexEvent.AttendingUsers.filter((u) => {
                  return Evaluators.find((e) => {
                    return e.pk_User == u.pk_User;
                  });
                });

                ScheduleBlockEntries.forEach((scheduleBlock) => {
                  const { slot, slotEnd } = scheduleBlock;
                  const oldBlockEvents = scheduleBlock.FlexEvents ? clone(scheduleBlock.FlexEvents) : [];
                  const { FlexTimeStart, FlexTimeEnd } = flexEvent;
                  const flexStart = moment.tz(
                    `${moment.tz(FlexDateStart, null).format('MMM DD, YYYY')} ${FlexTimeStart}`,
                    null,
                  );

                  const flexEventAlreadyExistsInBlock = oldBlockEvents.find((fe) => {
                    return fe.pk_FlexEvent == flexEvent.pk_FlexEvent;
                  });
                  if (
                    isTimeRangeOverlap(
                      [slot, slotEnd],
                      [flexStart.format('MMM DD, YYYY hh:mm A'), `${FlexDateEnd} ${FlexTimeEnd}`],
                    )
                  ) {
                    const flexEventCandidates = scheduleBlock.flexEventCandidates || [];
                    const candidateExists = flexEventCandidates.find((c) => {
                      return c.pk_Candidate == candidate.pk_Candidate;
                    });

                    if (!candidateExists) {
                      flexEventCandidates.push(candidate);
                    }

                    if (!flexEventAlreadyExistsInBlock) {
                      oldBlockEvents.push(flexEvent);
                    }

                    if (flexEventHasEvaluatorForColumn) {
                      scheduleBlock.FlexEvents = oldBlockEvents;
                    }

                    scheduleBlock.flexEventCandidates = flexEventCandidates;
                  }

                  newScheduleBlockEntries.push(scheduleBlock);
                  row[pk_ScheduleAssignment].ScheduleBlockEntries = newScheduleBlockEntries;
                });
              });
            });
          }

          if (flexEvent.AttendingUsers) {
            flexEvent.AttendingUsers.forEach((evaluator) => {
              headers.forEach((header, i) => {
                const { pk_ScheduleAssignment } = header;
                const Evaluators = header.Evaluators ? header.Evaluators.Evaluators : [];

                const { ScheduleBlockEntries = [] } = row[pk_ScheduleAssignment] || {};
                const newScheduleBlockEntries = [];
                const oldFlexEvents = row[pk_ScheduleAssignment].FlexEvents || [];
                const flexEventAlreadyExistsInCell = oldFlexEvents.find((fe) => {
                  return fe.pk_FlexEvent == flexEvent.pk_FlexEvent;
                });
                const evaluatorIsInColumn = Evaluators.find((e) => {
                  return evaluator.pk_User == e.pk_User;
                });

                if (!evaluatorIsInColumn) {
                  return;
                }
                if (!flexEventAlreadyExistsInCell) {
                  oldFlexEvents.push(flexEvent);
                  row[pk_ScheduleAssignment].FlexEvents = oldFlexEvents;
                }
                ScheduleBlockEntries.forEach((scheduleBlock) => {
                  const { slot, slotEnd } = scheduleBlock;
                  const oldBlockEvents = scheduleBlock.FlexEvents ? clone(scheduleBlock.FlexEvents) : [];
                  const { FlexTimeStart, FlexTimeEnd } = flexEvent;
                  const flexStart = moment.tz(
                    `${moment.tz(FlexDateStart, null).format('MMM DD, YYYY')} ${FlexTimeStart}`,
                    null,
                  );

                  const flexEventAlreadyExistsInBlock = oldBlockEvents.find((fe) => {
                    return fe.pk_FlexEvent == flexEvent.pk_FlexEvent;
                  });

                  if (
                    isTimeRangeOverlap(
                      [slot, slotEnd],
                      [`${flexStart.format('MMM DD, YYYY hh:mm A')}`, `${FlexDateEnd} ${FlexTimeEnd}`],
                    )
                  ) {
                    const flexEventEvaluators = scheduleBlock.flexEventEvaluators || [];
                    const evaluatorExists = flexEventEvaluators.find((e) => {
                      return e.pk_User == evaluator.pk_User;
                    });

                    if (!evaluatorExists) {
                      flexEventEvaluators.push(evaluator);
                    }

                    if (!flexEventAlreadyExistsInBlock) {
                      oldBlockEvents.push(flexEvent);
                    }
                    scheduleBlock.FlexEvents = oldBlockEvents;
                    scheduleBlock.flexEventEvaluators = flexEventEvaluators;
                  }

                  newScheduleBlockEntries.push(scheduleBlock);
                  row[pk_ScheduleAssignment].ScheduleBlockEntries = newScheduleBlockEntries;
                });
              });
            });
          }
        }
      });
    });

    newHeaders.forEach((h) => {
      h.flexEvents = headerFlexEvents[h.pk_ScheduleAssignment];
    });

    newScheduleData.body = newBody;
    newScheduleData.headers = newHeaders;
    return newScheduleData;
  };

  // If cell is highlighted green.
  const isInPreviewMode = (options = {}) => {
    const { column, row } = options;
    let isPending = false;
    if (column && row) {
      isPending = pendingCandidates[`${row.pk_Timeslot}_${column.pk_ScheduleAssignment}`];
    }
    return isPending;
  };

  const updateAllData = (options) => {
    const parent = document.getElementById('schedule_proper').parentElement;
    setWindowWidth(parent.clientWidth - 40);

    const getScheduleData = new Promise((resolve, reject) => {
      fetchSchedule(token, { ...options, resolve, reject });
    });

    getScheduleData.then((newScheduleData) => {
      const getEvaluators = new Promise((resolve, reject) => {
        fetchEvaluators(token, newScheduleData, { ...options, resolve, reject });
      });

      const getCandidates = new Promise((resolve, reject) => {
        fetchCandidates(token, { ...options, resolve, reject });
      });

      Promise.all([getEvaluators, getCandidates])
        .then((values) => {
          const { pendingItemKey } = options || {};
          const [newEvaluators, newCandidates] = values;
          if (pendingItemKey) {
            if (pendingItemKey === 'all') {
              setPendingCandidates({});
            } else {
              const newPendingCandidates = clone(pendingCandidatesStateRef.current);
              delete newPendingCandidates[pendingItemKey];
              setPendingCandidates(newPendingCandidates);
              pendingCandidatesStateRef.current = clone(newPendingCandidates);
            }
          }

          const indexedData = indexFlexEvents(newScheduleData, newEvaluators);
          const scheduleDataWithFlexEvents = attachFlexEvents(newScheduleData, newEvaluators, indexedData);

          if (selectedCell && selectedScheduleBlock) {
            updateSelectedCellAndBlockFromScheduleData(scheduleDataWithFlexEvents);
          }
          setScheduleData(scheduleDataWithFlexEvents);
          if (setParentScheduleData) {
            setParentScheduleData(scheduleDataWithFlexEvents);
          }

          setIsGeneratingSchedule(false);
          setGeneratedScheduleData(null);
          setupTrackers(newScheduleData);
          setEvaluators(newEvaluators);
          setCandidates(newCandidates);

          setIsTableLoaded(true);
          if (setParentCandidates) {
            setParentCandidates(newCandidates);
          }
          const { headers, body, metaData } = newScheduleData;
          const { StartTime, EndTime } = getScheduleStartAndEndTime(newScheduleData);
          const now = moment();
          if (headers.length <= 0 && body.length <= 1 && !isEmbedded) {
            setShowScheduleWizard(true);
          } else {
            setShowScheduleWizard(false);
          }

          setCandidateLimit(metaData.CandidateLimit || 1);
          setIsFetching(false);

          const isMeetingInProgress = now.isSameOrAfter(StartTime) && now.isSameOrBefore(EndTime);
          if (headers.length > 0 && body.length > 1) {
            fetchAttendanceData(false);
          }
          const interval = 60000;
          // const interval = 5000;
          clearInterval(attendanceChecker);
          attendanceChecker = setInterval(() => {
            if (scheduleDataRef.current) {
              const { StartTime, EndTime } = getScheduleStartAndEndTime(scheduleDataRef.current);
              const { headers, body } = scheduleDataRef.current;
              const now = moment(); //.format('MMM DD, YYYY hh:mm A');
              const isMeetingInProgress = now.isSameOrAfter(StartTime) && now.isSameOrBefore(EndTime); //isTimeRangeOverlap([StartTime, now], [now, EndTime]);

              if (isMeetingInProgress && headers.length > 0 && body.length > 1) {
                fetchAttendanceData();
              }
            }
          }, interval);
        })
        .catch((err) => {
          console.log('updateAll err: ', err);
        });
    });
  };

  const tableData = generatedScheduleData ? generatedScheduleData.body : scheduleData ? scheduleData.body : [];
  const tableColumns = scheduleData ? makeColumns(scheduleData) : [];

  const renderUnsavedChangesConfirmModal = () => {
    return (
      <ConfirmModal
        isOpen={showUnsavedChangeModal}
        title={'Alert - Unsaved changes!'}
        message="Do you want to accept or discard the generated schedule?"
        buttonChoices={
          <>
            <Button
              color="secondary"
              onClick={() => {
                setShowUnsavedChangeModal(false);
              }}
            >
              <div style={style.buttonLabel}>Cancel</div>
            </Button>
            <Button
              color="danger"
              onClick={() => {
                setGeneratedScheduleData(null);
                setShowUnsavedChangeModal(false);
                setShowGenerateDataPanel(false);
              }}
            >
              <div style={style.buttonLabel}>Discard</div>
            </Button>
            <Button
              color="success"
              onClick={() => {
                if (generatedScheduleData) {
                  submitGeneratedSchedule(generatedScheduleData.body);
                }
                setShowUnsavedChangeModal(false);
                setShowGenerateDataPanel(false);
              }}
            >
              <div style={style.buttonLabel}>Accept</div>
            </Button>
          </>
        }
      />
    );
  };

  const getMaxSplitCount = () => {
    if (selectedCell == null) {
      return 1;
    }
    const col = parseInt(selectedCell.column.dataField);
    const { StandardDurationInMinutes, StandardPassingDurationInMinutes } = scheduleData.metaData;
    let lowestSplitCount = null;
    scheduleData.body.forEach((row, i) => {
      if (i == scheduleData.body.length - 1) {
        return;
      }
      const { CustomDurationInMinutes = 0, CustomPassingDurationInMinutes = 0 } = row[col];
      const duration =
        CustomDurationInMinutes != null ? parseInt(CustomDurationInMinutes) : parseInt(StandardDurationInMinutes);
      const passingDuration = CustomPassingDurationInMinutes
        ? parseInt(CustomPassingDurationInMinutes)
        : parseInt(StandardPassingDurationInMinutes);

      if (lowestSplitCount == null || duration + passingDuration < lowestSplitCount) {
        lowestSplitCount = duration + passingDuration;
      }
    });
    return lowestSplitCount;
  };

  const renderSplitCellModal = () => {
    return (
      <Modal isOpen={showSplitCellModal} zIndex={9999} centered={true}>
        <ModalHeader
          closeButton
          toggle={() => {
            setShowSplitCellModal(false);
          }}
        >
          Set Split Count
        </ModalHeader>
        <ModalBody>
          <div>
            <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
              <Input
                style={{ textAlign: 'center', width: '50%' }}
                type="number"
                value={cellSplitCount}
                placeholder="Enter number of splits"
                onChange={(e) => {
                  let newValue = parseInt(e.target.value);
                  if (newValue < 1) {
                    newValue = 1;
                  }
                  const maxSplitCount = getMaxSplitCount();

                  if (newValue > maxSplitCount) {
                    newValue = maxSplitCount;
                  }

                  setCellSplitCount(newValue);
                }}
              />
            </div>

            <div style={style.splitCellButtonRow}>
              <Button
                color="danger"
                style={{ ...style.simpleColumn, ...style.genButton, backgroundColor: null, justifyContent: 'center' }}
                onClick={() => {
                  setShowSplitCellModal(false);
                }}
              >
                Cancel
              </Button>
              <Button
                color="danger"
                style={{ ...style.simpleColumn, ...style.genButton, backgroundColor: null, justifyContent: 'center' }}
                onClick={() => {
                  const { row, column } = selectedCell;
                  const { dataField } = column;
                  const { pk_Timeslot } = row;

                  const data = {
                    pk_ScheduleAssignment: parseInt(dataField),
                    pk_Timeslot,
                  };
                  setSplitData(data);

                  const willCandidateBeUnscheduled = isCandidateWillBeUnscheduled(splitCountTracker, {
                    timeSlots: [pk_Timeslot],
                    headers: [parseInt(dataField)],
                    splitValue: 1,
                  });

                  if (willCandidateBeUnscheduled) {
                    setShowSplitCellConfirmModal(true);
                    return;
                  }
                  removeCellSplits(data, () => {
                    setShowScheduleDetailModal(false);
                    setShowSplitCellModal(false);
                  });
                }}
              >
                Remove
              </Button>
              <Button
                style={{ ...style.simpleColumn, ...style.genButton, justifyContent: 'center' }}
                onClick={() => {
                  const { row, column } = selectedCell;
                  const { dataField } = column;
                  const { pk_Timeslot } = row;

                  const data = {
                    pk_ScheduleAssignment: parseInt(dataField),
                    TotalNumberOfSplits: cellSplitCount,
                    pk_Timeslot,
                  };

                  setSplitData(data);

                  const willCandidateBeUnscheduled = isCandidateWillBeUnscheduled(splitCountTracker, {
                    timeSlots: [pk_Timeslot],
                    headers: [parseInt(dataField)],
                    splitValue: cellSplitCount,
                  });

                  if (willCandidateBeUnscheduled) {
                    setShowSplitCellConfirmModal(true);
                    return;
                  }

                  setCellSplit(data, (err) => {
                    if (!err) {
                      setShowScheduleDetailModal(false);
                      setShowSplitCellModal(false);
                    }
                  });
                }}
              >
                Set
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderSplitCellConfirmModal = () => {
    return (
      <ConfirmModal
        isOpen={showSplitCellConfirmModal}
        title={'Alert - Candidates to be Unscheduled!'}
        message="One or more candidates will be unscheduled because the schedule block they are in will be removed. Manually Clear them first, or set a higher split count."
        buttonChoices={
          <Button
            style={style.genButton}
            onClick={() => {
              if (selectedCell) {
                const { row, column } = selectedCell;
                const selectedCellActual = row[parseInt(column.dataField)];
                const { ScheduleBlockTotalNumberOfSplits, ColumnTotalNumberOfSplits } = selectedCellActual;
                setCellSplitCount(ScheduleBlockTotalNumberOfSplits || ColumnTotalNumberOfSplits || 1);
              } else {
                // console.log("selectedCell doesn't exist");
              }

              setShowSplitCellConfirmModal(false);
            }}
          >
            <div style={style.buttonLabel}>Okay</div>
          </Button>
        }
      />
    );
  };

  const renderFlexEventColumnDetailModal = () => {
    return (
      <FlexEventDetailModal
        candidates={candidatesArray}
        columnTracker={columnTracker}
        dateIsInThePast={dateIsInThePast}
        fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
        forcedContext={forcedContext}
        indexedFlexEventEvaluators={indexedFlexEventEvaluators}
        isOpen={showFlexEventDetailModal}
        pk_InterviewDate={pk_InterviewDate}
        rowTracker={rowTracker}
        scheduleData={scheduleData}
        selectedCell={selectedCell}
        setSelectedCell={setSelectedCell}
        setShowFlexEventDetailModal={setShowFlexEventDetailModal}
        showPastScheduleWarning={showPastScheduleWarning}
        updateAllData={updateAllData}
        VirtualMeetingMode={VirtualMeetingMode}
        willEditThePast={willEditThePast}
        evaluators={
          evaluators && Object.values(evaluators).length > 0
            ? Object.values(evaluators).sort((a, b) => {
                const aString = `${a.UserLast}, ${a.UserFirst}`.toUpperCase();
                const bString = `${b.UserLast}, ${b.UserFirst} `.toUpperCase();

                if (aString < bString) {
                  return -1;
                }
                if (aString > bString) {
                  return 1;
                }
                return 0;
              })
            : []
        }
      />
    );
  };

  const renderScheduleDetailModal = () => {
    return (
      <ScheduleDetailModal
        addCandidateToSchedule={addCandidateToSchedule}
        addUnavailableSlot={addUnavailableSlot}
        addUnavailableSlots={addUnavailableSlots}
        allowInterviewLimit={allowInterviewLimit}
        attendanceData={attendanceData}
        candidateLimit={candidateLimit}
        candidates={candidates}
        clearScheduleBlock={clearScheduleBlock}
        columnTracker={columnTracker}
        dateIsInThePast={dateIsInThePast}
        dContext={dContext}
        deleteFlexEvent={deleteFlexEvent}
        evaluators={evaluators}
        fetchSchedule={fetchSchedule}
        fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
        indexedEvaluatorFlexEvents={indexedEvaluatorFlexEvents}
        isAddingCandidate={isAddingCandidate}
        isSubmitting={isSubmitting}
        isFetching={isFetching}
        onToggle={onToggleScheduleDetailModal}
        pendingCandidates={pendingCandidates}
        rowTracker={rowTracker}
        scheduleData={scheduleData}
        scheduleID={scheduleID}
        selectedCell={selectedCell}
        selectedScheduleBlock={selectedScheduleBlock}
        setAllowInterviewLimit={setAllowInterviewLimit}
        setShowSplitCellModal={setShowSplitCellModal}
        showScheduleDetailModal={showScheduleDetailModal}
        setShowScheduleDetailModal={setShowScheduleDetailModal}
        splitBlockCandidateTracker={splitBlockCandidateTracker}
        updateFlexEvent={updateFlexEvent}
        updateFlexEvents={updateFlexEvents}
        updateAllData={updateAllData}
        willEditThePast={willEditThePast}
      />
    );
  };

  const fireConfirmationForEditingPastSchedule = () => {
    // if the interview date is the same day then let them continue - no need to ask about editting
    if (interviewDateIsToday) {
      return true;
    }
    setShowPastScheduleWarning(true);
    return Swal.fire({
      title: 'Warning!',
      text: `This schedule is in the past or already in progress. Are you sure you want to modify?`,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonColor: 'red',
      confirmButtonText: `Yes`,
    }).then((result) => {
      setTimeout(() => {
        setShowPastScheduleWarning(false);
      }, 1000);

      if (result.value) {
        setWillEditThePast(true);

        return true;
      }
      return false;
    });
  };

  const getZoneFromOffset = () => {
    if (scheduleData) {
      const offset = moment.tz(scheduleData.metaData.TimeZone).format('Z');

      const zoneNames = moment.tz.names().filter((tz) => moment.tz(tz).format('Z') === offset);
      const guessedName = moment.tz.guess();

      const matchingName = zoneNames.find((n) => {
        return n === guessedName;
      });
      if (matchingName) {
        return matchingName;
      }
    }

    return scheduleData.metaData.TimeZone;
  };

  const checkInactivity = (forceLogout) => {
    const now = moment();
    const lastActivity = localStorage.getItem('lastActivity');

    const lastActivityMoment = lastActivity ? moment(lastActivity) : null;
    const currentInactivityLength = now.diff(lastActivityMoment, 'minutes');
    const { StartTime, EndTime } = getScheduleStartAndEndTime(scheduleDataRef.current);
    const isMeetingInProgress = now.isSameOrAfter(StartTime) && now.isSameOrBefore(EndTime);

    if (
      !isMeetingInProgress &&
      (!isEmbedded || isSuperUserPanel) &&
      (!lastActivity || (lastActivity && currentInactivityLength > inactivityWait && !hasFiredInactivityPopup))
    ) {
      hasFiredInactivityPopup = true;
      // const autoLogoutTimeout = setTimeout(() => {
      //   logout();
      // }, 40000);

      if (forceLogout) {
        sessionStorage.removeItem('roleChoice');
        logout();
      } else {
        Swal.fire({
          title: 'Inactivity Detected X',
          text: 'Are you still there?',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No, Log me out',
          denyButtonColor: 'red',
          timer: 30000,
          timerProgressBar: true,
        }).then((res) => {
          hasFiredInactivityPopup = false;
          if (res.dismiss === Swal.DismissReason.timer) {
            logout();
            sessionStorage.removeItem('roleChoice');
          } else if (res.isConfirmed) {
            // YES STILL HERE
            // clearTimeout(autoLogoutTimeout);
          } else {
            // logout
            logout();
            sessionStorage.removeItem('roleChoice');
          }
        });
      }
    } else if (isMeetingInProgress) {
      localStorage.setItem('lastActivity', now.toISOString());
    }
  };

  return (
    <div id="schedule_proper">
      {isScheduleDataError ? (
        <div style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '20vh' }}>Error fetching Schedule Data</div>
      ) : (
        <>
          {showGenerateDataPanel ? (
            <Alert>
              <div>
                <div style={style.previewButtonPanel}>
                  <Button
                    color="danger"
                    style={{ ...style.genButton, backgroundColor: null }}
                    onClick={() => {
                      setGeneratedScheduleData(null);
                      setShowGenerateDataPanel(false);
                      setPendingCandidates({});
                      setupTrackers(scheduleData);
                    }}
                  >
                    <div style={style.buttonLabel}>Discard New Schedule</div>
                  </Button>
                  <Button
                    style={{ ...style.genButton, backgroundColor: null }}
                    color="success"
                    onClick={() => {
                      submitGeneratedSchedule(generatedScheduleData.body);
                      setShowGenerateDataPanel(false);
                      setGenerateMode('ordered');
                    }}
                  >
                    <div style={style.buttonLabel}>Accept New Schedule</div>
                  </Button>

                  <Button
                    style={{ ...style.genButton }}
                    onClick={() => {
                      if (generatedScheduleData && generatedScheduleData.headersToFillUp) {
                        generateScheduleForColumn(generatedScheduleData.headersToFillUp);
                      } else {
                        setGenerateMode('ordered');
                        generateScheduleForEntireTable();
                      }
                    }}
                  >
                    <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
                      <FontAwesomeIcon icon={generatedScheduleData ? faRedo : faMagic} style={style.buttonIcon} />
                      <div style={style.buttonLabel}>Regenerate Schedule</div>
                    </div>
                  </Button>
                </div>
                <div style={style.buttonLabel}>
                  Accepting the new schedule will overwrite the previous schedule!{' '}
                  {allowInterviewLimit
                    ? `(Candidate Interview Limit is set to ${interviewLimit})`
                    : scheduleData && scheduleData.headers
                    ? `(Maximum ${scheduleData.headers.length} candidate interviews)`
                    : 1}{' '}
                </div>
              </div>
            </Alert>
          ) : !isEmbedded || allowScheduleInteraction || showTopBar ? (
            <TopBar
              addRowCustomEvent={addRowCustomEvent}
              allowInterviewLimit={allowInterviewLimit}
              candidates={candidates}
              columnTracker={columnTracker}
              dContext={dContext}
              evaluators={evaluators}
              fetchSchedule={fetchSchedule}
              fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
              generatedScheduleData={generatedScheduleData}
              generateMode={generateMode}
              interviewLimit={interviewLimit}
              isAddingCandidate={isAddingCandidate}
              isCloning={isCloning}
              isEmbedded={isEmbedded}
              isFetching={isFetching}
              isGeneratingSchedule={isGeneratingSchedule}
              isInPreviewMode={isInPreviewMode}
              isSubmitting={isSubmitting}
              rowTracker={rowTracker}
              scheduleData={scheduleData}
              scheduleID={scheduleID}
              setAllowInterviewLimit={setAllowInterviewLimit}
              setCandidateLimit={setCandidateLimit}
              setGeneratedScheduleData={setGeneratedScheduleData}
              setInterviewLimit={setInterviewLimit}
              setPendingCandidates={setPendingCandidates}
              setShowCloneModal={setShowCloneModal}
              setShowGenerateDataPanel={setShowGenerateDataPanel}
              setShowUnsavedChangeModal={setShowUnsavedChangeModal}
              setupTrackers={setupTrackers}
              showCloneModal={showCloneModal}
              submitGeneratedSchedule={submitGeneratedSchedule}
              willEditThePast={willEditThePast}
              toggleScheduleWizard={() => {
                setShowScheduleWizard(!showScheduleWizard);
              }}
            />
          ) : null}
          {(!isEmbedded || allowScheduleInteraction) && !generatedScheduleData ? (
            <div style={style.spacedBetweenRow}>
              <div style={{ fontStyle: 'italic' }}>
                {scheduleData ? (
                  <>
                    {' '}
                    <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
                    This schedule displayed is for "
                    <b>
                      {scheduleData && scheduleData.metaData ? getZoneFromOffset() : 'N/A'} (
                      {moment.tz(getZoneFromOffset()).format('hh:mm A')})
                    </b>
                    ". You are currently in Timezone "<b>{moment.tz.guess()}</b>".
                  </>
                ) : (
                  ''
                )}
              </div>

              <div style={{ ...style.simpleColumn, minWidth: 340 }}>
                <div style={{ ...style.simpleRow, justifyContent: 'right}' }}>
                  {scheduleData ? (
                    <div style={{ ...style.simpleColumn, minWidth: 160 }}>
                      <div style={style.simpleRow}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'relative',
                          }}
                        >
                          <div
                            style={{
                              border: '2px solid #f2f2f2',
                              borderRadius: 20,
                              color: '#f2f2f2',
                              backgroundColor: '#298dff',
                              fontWeight: 'bold',
                              fontSize: 10,
                              marginRight: 2,
                              minWidth: 17,
                              minHeight: 17,
                              textAlign: 'center',
                            }}
                          >
                            1
                          </div>
                        </div>
                        <div style={{ fontStyle: 'italic' }}>- Evaluators present</div>
                      </div>
                      <div style={style.simpleRow}>
                        <FontAwesomeIcon
                          icon={faUserCircle}
                          style={{
                            color: '#35911f',
                            fontWeight: 'bold',
                            fontSize: 17,
                            border: '2px solid #f2f2f2',
                            borderRadius: 20,
                            backgroundColor: '#f2f2f2',
                          }}
                        />
                        <div style={{ fontStyle: 'italic' }}>- Candidate present</div>
                      </div>
                    </div>
                  ) : null}

                  {!hasFlexColumn && allowScheduleInteraction && !generatedScheduleData ? (
                    <div style={{ ...style.simpleColumn, minWidth: 100 }}>
                      <Button
                        size="sm"
                        disabled={isAddingColumn}
                        color="primary"
                        onClick={() => {
                          addFlexColumn();
                          setShowAddColumnPopover(false);
                        }}
                        style={{ marginRight: 10 }}
                      >
                        <div style={style.spacedBetweenRow}>
                          <div>
                            <FontAwesomeIcon icon={faPlusSquare} id="addButton" style={style.addColumnIcon} />
                          </div>
                          {<div style={{ marginLeft: 10 }}>Add Flex Column</div>}
                        </div>
                      </Button>
                    </div>
                  ) : null}
                  <div style={{ ...style.simpleColumn, minWidth: 100 }}>
                    <Button
                      size="sm"
                      onClick={async () => {
                        if (generatedScheduleData) {
                          return;
                        }

                        if (dateIsInThePast && !willEditThePast) {
                          const continueProcess = await fireConfirmationForEditingPastSchedule();
                          if (!continueProcess) {
                            return;
                          }
                        }

                        if (isInPreviewMode()) {
                          setShowUnsavedChangeModal(!isSubmitting);
                          return;
                        }

                        if (!showAddColumnPopover) {
                          hideHeaderPopoversExcept();
                          hideTimeSlotPopoversExcept();
                        }
                        setShowAddColumnPopover(!showAddColumnPopover);
                      }}
                    >
                      <div style={style.spacedBetweenRow}>
                        <div>
                          <FontAwesomeIcon icon={faPlusSquare} id="addButton" style={style.addColumnIcon} />
                        </div>
                        {<div style={{ marginLeft: 10 }}>Add Evaluators</div>}
                      </div>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div style={style.tableContainer(scheduleData, windowWidth, hasFlexColumn)}>
            <div style={{ ...style.simpleColumn, width: '100%' }} id="scheduleContainer">
              {scheduleData ? (
                <BootstrapTable
                  bordered={true}
                  columns={tableColumns}
                  data={tableData}
                  keyField="slot"
                  data-pdfmake={{ headerRows: 1 }}
                  bodyClasses={'tableClassName'}
                  noDataIndication={() => {
                    return <div style={{ textAlign: 'center', fontWeight: 'bold' }}>No TimeSlots</div>;
                  }}
                />
              ) : (
                <div style={style.loadingContainer}>
                  {/* <Spinner color="primary" /> */}
                  <div style={{ display: 'flex', width: 100, height: 100 }}>
                    <Loading />
                  </div>
                </div>
              )}
              {/* <div>Footer of deathhh</div> */}
            </div>
          </div>

          {(!isEmbedded || allowScheduleInteraction) && !generatedScheduleData
            ? scheduleData //&& scheduleData.headers && scheduleData.headers.length > 0
              ? renderTimeSlotHeaderPopOver()
              : null
            : null}

          {!isEmbedded || allowScheduleInteraction ? renderAddColumnPopover() : null}

          {renderScheduleDetailModal()}
          {renderFlexEventColumnDetailModal()}
          {renderUnsavedChangesConfirmModal()}
          {renderSplitCellModal()}
          {renderSplitCellConfirmModal()}
          {renderCloneModal()}
          <ScheduleWizard
            forcedContext={forcedContext}
            pk_InterviewDate={pk_InterviewDate}
            isOpen={showScheduleWizard}
            setShowScheduleWizard={setShowScheduleWizard}
            columnTracker={columnTracker}
            evaluators={evaluators}
            candidates={candidates}
            scheduleData={scheduleData}
            updateAllData={updateAllData}
          />
        </>
      )}
    </div>
  );
};

export default Schedule;
