import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, CustomInput, Input, Label, UncontrolledTooltip } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, sanitizeWithOptions } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { fetchDataAgnostic, fetchDataAuth0, putData } from '../../../../../Services/dataApi';
import { clone } from '../../../../../Services/schedule';
import AdminEmailPreview from '../AdminCommunicationHistory/AdminEmailPreview/AdminEmailPreview';
import style from './AdminCommunicationSettings.style';

const AdminCommunicationSettings = ({ updateChanges }) => {
  const [templates, setTemplates] = useState([]);

  const [newConfig, setNewConfig] = useState({
    textColor: '#575860',
    backgroundColor: '#54ADD9',
    congratulatoryTemplate: null,
    waitlistTemplate: null,
  });
  const [oldConfig, setOldConfig] = useState({
    textColor: '#575860',
    backgroundColor: '#54ADD9',
    congratulatoryTemplate: null,
    waitlistTemplate: null,
  }); // Compare values to this one for comparison

  const [cTemplate, setCTemplate] = useState(null);
  const [wNTemplate, setWNTemplate] = useState(null);
  const [haveSettings, setHaveSettings] = useState(false);
  const [gettingTemplateID, setIsGettingTemplateID] = useState(false);

  // const [selectedCongratulatoryTemplate, setSelectedCongratulatoryTemplate] = useState();
  // const [selectedWaitlistTempalate, setSelectedWaitlistTemplate] = useState();
  const [token, setToken] = useState();
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);
  const activeDepartment = dContext ? dContext.department : {};

  useEffect(() => {
    if (haveSettings && templates && templates.length > 0 && newConfig) {
      if (cTemplate === null && newConfig.congratulatoryTemplate !== null) {
        templates.forEach((item) => {
          if (item.pk_CommunicationTemplate === newConfig.congratulatoryTemplate) {
            getTemplateById(item.pk_CommunicationTemplate, setCTemplate);
          }
        });
      }
      if (wNTemplate === null && newConfig.waitlistTemplate !== null) {
        templates.forEach((item) => {
          if (item.pk_CommunicationTemplate === newConfig.waitlistTemplate) {
            getTemplateById(item.pk_CommunicationTemplate, setWNTemplate);
          }
        });
      }
    }
  }, [haveSettings, templates, newConfig, cTemplate, wNTemplate]);

  useEffect(() => {
    getTokenSilently()
      .then((token) => {
        setToken(token);
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });

    getTemplates();
    getSettings();
  }, []);

  useEffect(() => {
    if (oldConfig && Object.keys(oldConfig).length > 0) {
      setNewConfig(clone(oldConfig));
    }
  }, [oldConfig]);

  const updateConfig = (key, value) => {
    const newNewConfig = clone(newConfig);
    newNewConfig[key] = value;

    setNewConfig(newNewConfig);
    if (oldConfig[key] != newNewConfig[key]) {
      updateChanges('settings', true);
    }
  };

  const compareConfigs = () => {
    let areEqual = true;
    Object.keys(newConfig).forEach((key) => {
      if (newConfig[key] !== oldConfig[key]) {
        areEqual = false;
      }
    });

    return areEqual;
  };

  const getTemplates = (id = null) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAuth0(
          'department/readCommunicationTemplates',
          { pk_Department: dContext.department.pk_Department, automated: true },
          formatBearerToken(token),
        )
          .then((result) => {
            setTemplates(result.data);
            // updateConfig('congratulatoryTemplate', result.data[0]);
            // updateConfig('waitlistTemplate', result.data[0]);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getSettings = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/communication/settings',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            const {
              HeaderBackgroundColor,
              HeaderTextColor,
              DoNotAutoSendCongratulationsEmail,
              DoNotAutoSendWaitlistEmail,
              EnableAutoSlotState,
              CongratulatoryTemplate,
              WaitlistNotificationTemplate,
            } = res.data;
            const newNewConfig = clone(oldConfig);
            newNewConfig.textColor = HeaderTextColor || newConfig.textColor;
            newNewConfig.backgroundColor = HeaderBackgroundColor || newConfig.backgroundColor;
            newNewConfig.automatedSlotting = EnableAutoSlotState;
            newNewConfig.congratulatoryTemplate = CongratulatoryTemplate;
            newNewConfig.waitlistTemplate = WaitlistNotificationTemplate;
            newNewConfig.doNotAutoSendCongratulationsEmail = DoNotAutoSendCongratulationsEmail;
            newNewConfig.doNotAutoSendWaitlistEmail = DoNotAutoSendWaitlistEmail;

            setNewConfig(newNewConfig);
            setOldConfig(newNewConfig);
            setHaveSettings(true);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderOptions = () => {
    const options = [<option value="do_not_send">Don't Send</option>];

    templates.forEach((template) => {
      options.push(<option value={template.pk_CommunicationTemplate}>{template.Title}</option>);
    });
    if (options.length > 0) {
      options.splice(
        0,
        0,
        <option value={null} selected disabled>
          {' '}
          Choose Template
        </option>,
      );
    } else {
      options.splice(
        0,
        0,
        <option value={null} selected disabled>
          {' '}
          No Options
        </option>,
      );
    }
    return options;
  };

  const getTemplateById = (value, callback) => {
    // if they choose the placeholder option just set the selected template to null
    if (value === 'placeholder') {
      return;
    }
    setIsGettingTemplateID(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAuth0(
          'department/readCommunicationTemplate',
          { pk_Department: activeDepartment.pk_Department, pk_CommunicationTemplate: value },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsGettingTemplateID(false);
            callback(res.data);
          })
          .catch((err) => {
            setIsGettingTemplateID(false);
            callback(null);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const createMarkUp = (html) => {
    return { __html: sanitizeWithOptions(html) };
  };

  const renderPreview = (template) => {
    const { Body = '' } = template || {};
    return <div dangerouslySetInnerHTML={createMarkUp(Body)}></div>;
    // return <AdminEmailPreview itemToPreview={template} mode="template" />;
  };

  const saveSettings = () => {
    getTokenSilently()
      .then((token) => {
        const commObject = {
          HeaderTextColor: newConfig.textColor,
          HeaderBackgroundColor: newConfig.backgroundColor,
          EnableAutoSlotState: newConfig.automatedSlotting,
          DoNotAutoSendCongratulationsEmail: newConfig.doNotAutoSendCongratulationsEmail,
          DoNotAutoSendWaitlistEmail: newConfig.doNotAutoSendWaitlistEmail,
        };

        // add templates if they're chosen
        if (cTemplate && cTemplate.pk_CommunicationTemplate) {
          commObject.cTemplatePk = cTemplate.pk_CommunicationTemplate;
        }

        if (wNTemplate && wNTemplate.pk_CommunicationTemplate) {
          commObject.wNTemplatePk = wNTemplate.pk_CommunicationTemplate;
        }

        putData(
          'department/communication/settings',
          { pk_Department: activeDepartment.pk_Department },
          {
            commObject,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setOldConfig(newConfig);
            updateChanges('settings', false);
            Swal.fire({
              title: 'Success!',
              text: 'Settings updated',
              type: 'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: `Ok`,
            });
          })
          .catch((err) => {
            Swal.fire(
              'Error...',
              'Something broke while trying to save your settings, please try again later or constact an administrator',
              'error',
            );
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const {
    textColor,
    backgroundColor,
    congratulatoryTemplate,
    waitlistTemplate,
    automatedSlotting,
    doNotAutoSendCongratulationsEmail,
    doNotAutoSendWaitlistEmail,
  } = newConfig;
  const disableSaveButton = compareConfigs();
  const { department } = dContext;
  const { DepartmentName, DepartmentLogo } = department;
  return (
    <div style={{ height: 'calc(100vh - 300px)', overflowY: 'scroll', overflowX: 'hidden' }}>
      {/* <RouterPrompt isOpen={!disableSaveButton} message="You have unsaved changes, are you sure you want to leave?" /> */}
      <div
        style={{
          ...style.simpleRow,
          alignItems: 'center',
          justifyContent: 'flex-end',
          marginTop: 20,
          marginBottom: 20,
          position: 'sticky',
          zIndex: 999,
          top: 0,
          backgroundColor: 'white',
          padding: 10,
        }}
      >
        <Button
          disabled={disableSaveButton || gettingTemplateID}
          size="sm"
          color={disableSaveButton ? 'secondary' : 'success'}
          style={{ minWidth: 150 }}
          onClick={() => {
            saveSettings();
          }}
        >
          Save Communication Settings
        </Button>
      </div>

      <Card style={{ marginBottom: 30 }}>
        <CardHeader>
          <div style={style.simpleRow}>
            <div style={style.titleHeader}>Customize Header</div>
          </div>
        </CardHeader>
        <CardBody>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '19%' }}>
              <div style={style.simpleRow}>
                <div style={style.inputContainer}>
                  <Label for="bgColorPicker">{`Background Color (${backgroundColor})`}</Label>
                  <Input
                    id="bgColorPicker"
                    value={backgroundColor}
                    type="color"
                    onChange={(e) => {
                      updateConfig('backgroundColor', e.target.value);
                    }}
                  />
                </div>
              </div>

              <div style={style.simpleRow}>
                <div style={style.inputContainer}>
                  <Label for="colorPicker">{`Text Color (${textColor})`}</Label>
                  <Input
                    id="colorPicker"
                    type="color"
                    value={textColor}
                    onChange={(e) => {
                      updateConfig('textColor', e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                ...style.simpleColumn,
                width: '78%',
                alignItems: 'center',
                verticalAlign: 'middle',
                justifyContent: 'center',
                backgroundColor,
                marginLeft: 20,
              }}
            >
              <div style={{ ...style.spacedBetweenRow, width: '90%' }}>
                <div>
                  <img src={DepartmentLogo} style={{ height: 80 }} />
                </div>
                <div
                  style={{
                    ...style.previewHeader,
                    color: textColor,
                    textAlign: 'left',
                    width: '100%',
                    paddingLeft: 20,
                  }}
                >
                  {DepartmentName || ''}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.templatesSection}>
              <div style={style.titleHeader}>Assign Automated Notification Templates</div>
            </div>
            <div id="automatedSlotting">
              <CustomInput
                id="automatedSlottingSwitch"
                type="switch"
                checked={automatedSlotting}
                // value={automatedSlotting}
                label={`Enable Automated Candidate Registration (Slotting)`}
                onChange={(e) => {
                  updateConfig('automatedSlotting', e.target.checked);
                  // setAutomatedSlotting(e.target.checked);
                }}
              />
              <UncontrolledTooltip target="automatedSlotting">
                <div style={{ textAlign: 'left' }}>
                  When enabled, candidates will be registered:
                  <br />
                  <br />
                  1. First come first serve. <br />
                  2. Based on their preference order. <br />
                  3. Based on session limits.
                </div>
              </UncontrolledTooltip>
            </div>
          </div>
        </CardHeader>

        <div style={{ ...style.simpleRow, justifyContent: 'space-between' }}>
          <div style={style.templatesSectionSubcontainers}>
            <Card>
              <CardHeader>
                <div style={style.subTitleHeader}>Congratulatory Template</div>
              </CardHeader>
              <CardBody>
                <div style={{ ...style.inputContainer, marginTop: 0 }}>
                  <div style={{ textAlign: 'center' }}>
                    This template will be sent automatically when a candidate successfully registers for an interview
                    session.
                  </div>
                  {/* <CustomInput
                    type="switch"
                    id="doNotAutoSendCongratulationsEmail"
                    checked={!doNotAutoSendCongratulationsEmail}
                    // value={automatedSlotting}
                    label={`Enable Automated Congratulatory Notifications`}
                    onChange={(e) => {
                      updateConfig('doNotAutoSendCongratulationsEmail', !e.target.checked);
                      // setAutomatedSlotting(e.target.checked);
                    }}
                  /> */}
                </div>
                <div style={style.previewContainer}>{renderPreview(cTemplate)}</div>
              </CardBody>
            </Card>
          </div>
          <div style={style.templatesSectionSubcontainers}>
            <Card>
              <CardHeader>
                <div style={style.subTitleHeader}>Waitlist Notification Template</div>
              </CardHeader>
              <CardBody>
                <div style={{ ...style.inputContainer, marginTop: 0 }}>
                  <div style={{ textAlign: 'center' }}>
                    This template will be sent automatically when a candidate is waitlisted for one or more interview
                    sessions.
                  </div>

                  {/* <CustomInput
                    type="switch"
                    id="doNotAutoSendWaitlistEmail"
                    checked={!doNotAutoSendWaitlistEmail}
                    // value={automatedSlotting}
                    label={`Enable Automated Waitlist Notifications`}
                    onChange={(e) => {
                      updateConfig('doNotAutoSendWaitlistEmail', !e.target.checked);
                      // setAutomatedSlotting(e.target.checked);
                    }}
                  /> */}
                </div>
                <div style={style.previewContainer}>{renderPreview(wNTemplate)}</div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AdminCommunicationSettings;
