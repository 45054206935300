import { faRedo } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import style from './style.js';

const ConfirmModal = ({ title, message, isOpen, onAccept, onCancel, buttonChoices, modalStyle, buttonStyle }) => {
  const [pressedOkButton, setPressedButton] = useState(false);

  useEffect(() => {
    if (!isOpen) {
      setPressedButton(false);
    }
  }, [isOpen]);

  const renderDefaultButtons = () => {
    const { cancelText, cancelBtnColor, acceptText, acceptBtnColor } = buttonStyle || {};
    return (
      <>
        <Button
          disabled={pressedOkButton}
          color={cancelBtnColor ? cancelBtnColor : 'danger'}
          style={{ ...style.genButton, backgroundColor: null }}
        >
          <div style={style.buttonLabel} onClick={onCancel}>
            {cancelText ? cancelText : 'Cancel'}
          </div>
        </Button>
        <Button
          disabled={pressedOkButton}
          color={acceptBtnColor ? acceptBtnColor : 'primary'}
          style={{ ...style.genButton, backgroundColor: null }}
          onClick={() => {
            setPressedButton(true);
            onAccept();
          }}
        >
          <div style={style.buttonLabel}>{acceptText ? acceptText : 'Yes'}</div>
        </Button>
      </>
    );
  };

  return (
    <Modal isOpen={isOpen} centered={true} style={modalStyle} zIndex={9999}>
      <ModalHeader>
        <div style={{ ...style.labelText }}>{title ? title : ''}</div>
      </ModalHeader>
      <ModalBody>
        <div style={style.modalBody}>
          <div style={{ ...style.labelText }}>{message ? message : 'Processing . . .'}</div>
        </div>
      </ModalBody>
      <ModalFooter style={style.centeredRow}>{buttonChoices ? buttonChoices : renderDefaultButtons()}</ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
