import {
  faCheckSquare,
  faCircle,
  faClipboardList,
  faDotCircle,
  faDownload,
  faPrint,
  faSquare,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Button, Input, Label, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import style from './PrintMaterials.style';
import { fetchCandidates } from '../../../../../Services/CandidatesAPI';
import './PrintMaterials.style.css';
import { fetchDataAgnostic, postDataAgnostic } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { clone, set } from 'lodash';
import Loading from 'Body/Statuses/Loading';
import Swal from 'sweetalert2';
import { processExportCandidates } from 'Services/CSVExports';
import { createPDFForCandidateEvaluationsWithAnswers } from 'Services/PDFMakeTemplates/CandidateEvaluations/CandidateEvaluationsAnswered';
import { createPDFForMockOrals } from 'Services/PDFMakeTemplates/MockOrals/MockOrals';

const PrintMaterials = () => {
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showCandidatePopover, setShowCandidatePopover] = useState(false);

  const [datesToProcess, setDatesToProcess] = useState(0);
  const [datesProcessed, setDatesProcessed] = useState(0);
  const [downloadType, setDownloadType] = useState('evaluation'); // evaluation or prescreen
  const [isGettingCandidateResults, setIsGettingCandidateResults] = useState(false);
  const [isProcessingDates, setIsProcessingDates] = useState();
  const [isLoadingCandidates, setIsLoadingCandidates] = useState();
  const [isProcessingMockOrals, setIsProcessingMockOrals] = useState();

  const [CSVData, setCSVData] = useState([]);
  const [scaleData, setScaleData] = useState(null);

  const datesProcessedRef = useRef(0);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    getCandidates();
    getScale();
  }, []);

  useEffect(() => {
    if (CSVData && CSVData.length > 0) {
      // CSVData.splice(0, 1);
      document.getElementById('download_actual_candidates').click();
      setShowCandidatePopover(false);
      setSelectedCandidates([]);
      setSelectAll(false);
    }
  }, [CSVData]);

  useEffect(() => {
    if (selectedCandidates) {
      if (candidates.length > 0 && selectedCandidates.length == candidates.length && !selectAll) {
        setSelectAll(true);
      } else if (selectedCandidates.length < candidates.length && selectAll) {
        setSelectAll(false);
      }
    }
  }, [selectedCandidates]);

  const getCandidates = () => {
    if (dContext.department) {
      setIsLoadingCandidates(true);
      getTokenSilently()
        .then((token) => {
          fetchCandidates(token, {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            callback: (newCandidates) => {
              setIsLoadingCandidates(false);
              setCandidates(
                Object.values(newCandidates).filter((c) => {
                  return !c.isRemoved && !c.isDeleted;
                }),
              );
            },
          });
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    } else {
      setTimeout(getCandidates);
    }
  };

  const getScale = (scale) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setScaleData(res.data);
          })
          .catch((err) => {
            console.log('getScale err: ', err);
          })
          .finally(() => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidateResults = (options) => {
    const { callback } = options;
    setIsGettingCandidateResults(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/mockorals/responses/all',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            pk_Candidates: selectedCandidates || [],
            type: 'evaluation',
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (callback) {
              callback(res.data);
              return;
            }

            setIsGettingCandidateResults(false);
          })
          .catch((err) => {
            if (callback) {
              callback();
            }

            setIsGettingCandidateResults(false);
          });
      })
      .catch((err) => {
        if (callback) {
          callback();
        }
        setIsGettingCandidateResults(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getUserEvaluations = (type, callback) => {
    setIsGettingCandidateResults(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/evaluation/responses/all',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            type,
            groupBy: 'user',
            pk_Candidates: selectedCandidates || [],
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsGettingCandidateResults(false);
            if (callback) {
              callback({ items: res.data || [], type });
              return;
            }
            const newData = processExportCandidates({ items: res.data, type });

            if (newData) {
              setCSVData(newData);
            }
          })
          .catch((err) => {
            console.log('getUserEvaluations err: ', err);
            setIsGettingCandidateResults(false);
            if (callback) {
              return;
              callback({ items: [], type });
            }
          });
      })
      .catch((err) => {
        setIsGettingCandidateResults(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const processExportPDF = (options) => {
    const { items, type } = options;
    const { department } = dContext;
    if (items.length <= 0) {
      Swal.fire('Error', 'There are no reports available for any of the selected candidates!', 'error');
      return;
    }

    if (!scaleData) {
      Swal.fire('Error', 'There is no Question data to print!', 'error');
      return;
    }
    createPDFForCandidateEvaluationsWithAnswers({
      department,
      evaluators: items,
      type,
      scaleData,
      callback: () => {
        setIsGettingCandidateResults(false);
      },
    });
  };

  const renderCandidateEvaluations = () => {
    const { AllowPrescreen = false } = dContext && dContext.season ? dContext.season : {};

    return (
      <div style={style.simpleColumn}>
        <div
          style={{
            ...style.simpleRow,
            padding: 10,
            paddingTop: 0,
            marginTop: 20,
            cursor: 'pointer',
            justifyContent: 'center',
          }}
        >
          <div
            style={{ ...style.simpleColumn, width: '80%' }}
            onClick={() => {
              if (!selectAll) {
                setSelectedCandidates(
                  candidates.map((c) => {
                    return c.pk_Candidate;
                  }),
                );
              } else if (candidates.length > 0 && selectedCandidates.length === candidates.length) {
                setSelectedCandidates([]);
              }
              setSelectAll(!selectAll);
            }}
          >
            <div style={style.simpleRow}>
              <div style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={selectAll ? faCheckSquare : faSquare} />
              </div>
              <div style={{ width: '100%', textAlign: 'left' }}>Select All</div>
            </div>
          </div>
        </div>

        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <div
            style={{
              ...style.simpleColumn,
              overflowY: 'scroll',
              height: `calc(100vh - 380px)`,
              width: '80%',
              minWidth: 300,
            }}
          >
            {isLoadingCandidates ? (
              <div style={{ fontWeight: 'bold', width: '100%', marginTop: 100, textAlign: 'center' }}>Loading. . .</div>
            ) : candidates && candidates.length > 0 ? (
              candidates
                .sort((a, b) => {
                  const stringA = `${a.LastName || ''} ${a.LastName ? ', ' : ''}${a.FirstName}`;
                  const stringB = `${b.LastName || ''} ${b.LastName ? ', ' : ''}${b.FirstName}`;
                  if (stringA < stringB) {
                    return -1;
                  }
                  if (stringA > stringB) {
                    return 1;
                  }
                  return 0;
                })
                .map((c, i) => {
                  const isSelected = selectedCandidates.includes(c.pk_Candidate);
                  return (
                    <div
                      style={{
                        ...style.simpleRow,
                        padding: 10,
                        cursor: 'pointer',
                        backgroundColor: isSelected ? '#d9f5ff' : i % 2 == 0 ? '#c7cbd1' : 'white',
                      }}
                      onClick={() => {
                        const newCandidates = selectedCandidates.slice();
                        if (isSelected) {
                          newCandidates.splice(newCandidates.indexOf(c.pk_Candidate), 1);
                        } else {
                          newCandidates.push(c.pk_Candidate);
                        }
                        setSelectedCandidates(newCandidates);
                      }}
                    >
                      <div style={{ marginRight: 10 }}>
                        <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
                      </div>
                      <div style={{ width: '100%', textAlign: 'left' }}>{`${c.LastName || ''}${
                        c.LastName ? ', ' : ''
                      }${c.FirstName || ''}`}</div>
                    </div>
                  );
                })
            ) : (
              <div style={{ fontWeight: 'bold', width: '100%', marginTop: 100, textAlign: 'center' }}>
                No Candidates
              </div>
            )}
          </div>
        </div>
        <div style={{ ...style.simpleRow, justifyContent: 'center', marginTop: 10 }}>
          <div style={{ ...style.simpleColumn, width: '80%' }}>
            <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
              {AllowPrescreen ? (
                <>
                  <Button
                    color="primary"
                    size="sm"
                    disabled={selectedCandidates.length <= 0 || isGettingCandidateResults}
                    style={{ width: '100%', marginRight: 10 }}
                    onClick={() => {
                      setDownloadType('prescreen');
                      getUserEvaluations('prescreen');
                    }}
                  >
                    <div style={style.spacedBetweenRow} id="exportCompleted">
                      <div>
                        <FontAwesomeIcon icon={faDownload} />
                      </div>
                      <div style={{ ...style.centeredText, fontSize: 15 }}>Prescreen Data (CSV)</div>
                    </div>
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    disabled={selectedCandidates.length <= 0 || isGettingCandidateResults}
                    style={{ width: '100%', marginRight: 10 }}
                    onClick={() => {
                      setDownloadType('prescreen');
                      getUserEvaluations('prescreen', processExportPDF);
                    }}
                  >
                    <div style={style.spacedBetweenRow} id="exportCompleted">
                      <div>
                        <FontAwesomeIcon icon={faDownload} />
                      </div>
                      <div style={{ ...style.centeredText, fontSize: 15 }}>Prescreen PDF</div>
                    </div>
                  </Button>
                </>
              ) : null}

              <Button
                color="primary"
                size="sm"
                disabled={selectedCandidates.length <= 0 || isGettingCandidateResults}
                style={{ width: '100%', marginRight: 10 }}
                onClick={() => {
                  setDownloadType('evaluation');
                  getUserEvaluations('evaluation');
                }}
              >
                <div style={style.spacedBetweenRow} id="exportCompleted">
                  <div>
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div style={{ ...style.centeredText, fontSize: 15 }}>Evaluation Data (CSV)</div>
                </div>
              </Button>

              <Button
                color="primary"
                size="sm"
                disabled={selectedCandidates.length <= 0 || isGettingCandidateResults}
                style={{ width: '100%', marginRight: 10 }}
                onClick={() => {
                  setDownloadType('evaluation');
                  getUserEvaluations('evaluation', processExportPDF);
                }}
              >
                <div style={style.spacedBetweenRow} id="exportCompleted">
                  <div>
                    <FontAwesomeIcon icon={faDownload} />
                  </div>
                  <div style={{ ...style.centeredText, fontSize: 15 }}>Evaluation PDF</div>
                </div>
              </Button>

              {dContext.department && dContext.department.ProgramType === 'Mock Oral Board' ? (
                <Button
                  color="primary"
                  size="sm"
                  disabled={selectedCandidates.length <= 0 || isGettingCandidateResults}
                  style={{ width: '100%', marginRight: 10 }}
                  onClick={() => {
                    setDownloadType('evaluation');
                    setIsGettingCandidateResults(true);
                    getCandidateResults({
                      callback: (rawData) => {
                        setIsGettingCandidateResults(false);

                        createPDFForMockOrals({
                          rawData,
                          scaleData,
                          department: dContext.department,
                          candidates: selectedCandidates.map((c) => {
                            const matchingCandidate = candidates.find((c2) => {
                              return c2.pk_Candidate === c;
                            });
                            if (matchingCandidate) {
                              return matchingCandidate;
                            }
                          }),
                          callback: () => {
                            setIsGettingCandidateResults(false);
                          },
                        });
                      },
                    });
                  }}
                >
                  <div style={style.spacedBetweenRow} id="exportCompleted">
                    <div>
                      <FontAwesomeIcon icon={faDownload} />
                    </div>
                    <div style={{ ...style.centeredText, fontSize: 15 }}> Mock Orals PDF</div>
                  </div>
                </Button>
              ) : null}
            </div>
          </div>

          {/* <Button
            disabled={selectedCandidates.length <= 0 || isGettingCandidateResults}
            color="primary"
            style={{ width: 150, marginTop: 15 }}
            onClick={() => {
              getCandidateResults();
            }}
          >
            Print
          </Button> */}
        </div>
      </div>
    );
  };

  const renderLoadingModal = () => {
    return (
      <Modal isOpen={isProcessingDates} centered={true}>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Loading
              options={{
                labelText: `Processing interview dates: ${
                  datesToProcess ? `${datesProcessedRef.current} / ${datesToProcess}` : '. . .'
                }`,
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderMockOralLoadingModal = () => {
    return (
      <Modal isOpen={isGettingCandidateResults} centered={true}>
        <ModalBody>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <Loading
              options={{
                labelText: `Processing candidates. . .`,
                labelStyle: { fontSize: 9, textAlign: 'center', fontWeight: 'bold' },
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div>
      <div style={style.simpleRow}>
        {renderCandidateEvaluations()}
        {renderLoadingModal()}
        {renderMockOralLoadingModal()}
        <div style={{ visibility: 'hidden' }}>
          <CSVLink
            data={CSVData}
            target="_blank"
            id={`download_actual_candidates`}
            filename={`Export_Candidates_Evaluations_(${downloadType})_${moment().format('MMM DD, YYYY hhmm A')}`}
          />
        </div>
      </div>
    </div>
  );
};

export default PrintMaterials;
