import React, { createContext, useEffect, useState } from 'react';
import { useAuth0 } from './auth0/reactAuth0Spa';
import { useHistory } from 'react-router';
import { formatBearerToken } from './Common.functions';
import { fetchDataAgnostic } from './Services/dataApi';
import { clone } from './Services/schedule';

const themes = {
  light: {
    foreground: '#000000',
    background: '#eeeeee',
  },
  dark: {
    foreground: '#ffffff',
    background: '#222222',
  },
};

export const DepartmentContext = createContext({ department: {}, season: {} });
export const DepartmentWrapper = ({ children, location }) => {
  const history = useHistory();

  const [department, setDepartment] = useState(null);
  const [season, setSeason] = useState(null);
  const [userIsNotAssociated, setUserIsNotAssociated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  // NOTE: as of the time this was written, default department isn't being used as a feature.
  // commenitng this out becuase it might be added later.

  // // get Default department and season
  // useEffect(() => {
  //   getTokenSilently().then((token) => {
  //     // if no token just set loading to false
  //     if (!token) {
  //       setLoading(false);
  //       return;
  //     }

  //     fetchDataAgnostic('department/season/active', null, formatBearerToken(token))
  //       .then((result) => {
  //         // if user isn't associated with a department inform children components
  //         if (result && result.data && result.data.department === 'none') {
  //           // setUserIsNotAssociated(true);
  //         } else if (result.data && result.data.department) {
  //           setDepartment(result.data.department);
  //           setSeason(result.data.season);
  //         }
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   });
  // }, []);

  const getDepartmentFromSession = (pk_Department) => {
    return getTokenSilently()
      .then((token) => {
        return fetchDataAgnostic('department', { pk_Department }, formatBearerToken(token))
          .then((result) => {
            return result.data;
          })
          .catch((err) => {
            sessionStorage.removeItem('department');
            return false;
          });
      })
      .catch((err) => {});
  };

  const handleRefresh = async (legacyPathname, department) => {
    const isAtRoot = window.location.pathname === '/';
    const atOrgCreation = window.location.pathname === '/organizations';
    const isAtLogoutRoute = window.location.pathname === '/logout';

    const hasDepartmentAndSeason = department != null && season != null;
    const { search, pathname } = window.location;

    // if user is at root or has a deparmtent no need to redirect them back to the login
    if (isAtRoot || hasDepartmentAndSeason || atOrgCreation || isAtLogoutRoute) {
      setIsLoading(false);
      return;
    }

    let pk_Department = sessionStorage.getItem('department');

    if ((pk_Department && !department) || (department && pk_Department != department.pk_Department)) {
      const departmentInfo = await getDepartmentFromSession(Number(pk_Department));

      if (!departmentInfo) {
        history.push({
          pathname: '/',
          state: { path: pathname + search },
        });
        setIsLoading(false);
      } else {
        const activeSeason = departmentInfo.season.find((season) => season.Active);
        setDepartment(departmentInfo.department);
        setSeason(activeSeason);
      }
    } else {
      // return;
      history.replace({
        pathname: '/',
        state: { path: pathname + search },
      });
      setIsLoading(false);
    }
    // setIsLoading(false);
  };

  useEffect(() => {
    if (department && season) {
      setIsLoading(false);
    }
  }, [department, season]);

  useEffect(() => {
    handleRefresh(window.location.pathname, department);
  }, [window.location.pathname, department ? department.pk_Department : null]);

  return (
    <DepartmentContext.Provider
      value={{
        department,
        season,
        userIsNotAssociated,
        getDepartmentAndSeason: (...p) => {
          handleRefresh();
        },
        setSeason: (...p) => setSeason(...p),
        setDepartment: (...p) => setDepartment(...p),
      }}
      style={{ display: 'flex', height: '100%', width: '100%' }}
    >
      {isLoading ? <div></div> : children}
    </DepartmentContext.Provider>
  );
};
