import React, { useEffect, useContext, useState } from 'react';
import Wrapper from './Wrapper';
import { fetchDataAgnostic } from '../../Services/dataApi';
import { useAuth0 } from '../../auth0/reactAuth0Spa.js';
import { DepartmentContext } from '../../DepartmentWrapper';
import { formatBearerToken } from '../../Common.functions';

const RezRATE = (props) => {
  const { date, match, isAdmin, selectedEvaluator, evaluator, setEvaluatorInterviewDates, isEmbedded } = props;
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const { setDepartment, department, season } = dContext;
  const [enablePrescreenMode, setEnablePrescreenMode] = useState(false);

  useEffect(() => {
    const type = 'evaluator';
    if (setDepartment && department && !isAdmin && (!department.roleType || department.roleType !== type)) {
      setDepartment((prevState) => {
        // eslint-disable-next-line
        let clone = structuredClone(prevState);
        clone.roleType = type;
        return clone;
      });
    }
  }, [setDepartment, department]);

  useEffect(() => {
    // if is admin and no evaluator is selected don't make the call
    const isAdminAndNoSelectedEvaluator =
      isAdmin && (!selectedEvaluator || Object.keys(selectedEvaluator).length === 0);
    if (isAdminAndNoSelectedEvaluator || !season) {
      return;
    }

    // setTimeout(() => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/evaluator/interviewDates',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            isAdmin,
            pk_User: selectedEvaluator ? selectedEvaluator.pk_User : null,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setEvaluatorInterviewDates(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
    // }, 15000);
  }, [isAdmin, selectedEvaluator, season]);

  return <Wrapper {...props}></Wrapper>;
};

export default RezRATE;
