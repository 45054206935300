import { faPortrait, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState, Fragment, useRef, useCallback } from 'react';
import { NotificationManager } from 'react-notifications';
import { useLocation, useHistory } from 'react-router-dom';
import { Button, Col, Input, InputGroup, InputGroupAddon, Row, FormGroup, Label, CustomInput } from 'reactstrap';
import SyntheticEvaluation from './SyntheticEvaluation/SyntheticEvaluation.js';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, userExistsInSystem, getDefaultInterviewDate } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import Swal from 'sweetalert2';
import moment from 'moment';
import { fetchData, fetchDataAgnostic, postDataAgnostic } from '../../../../Services/dataApi';
import { clone } from '../../../../Services/schedule';
import AddUser from '../AdminTeam/AddUser/AddUser';
import style from './AdminEvaluate.style';

const radioButtonOptions = {
  showAllUsers: 'showAllUsers',
  showScheduledUsers: 'showScheduledUsers',
};

const AdminEvaluate = ({ date = {}, setDate, location }) => {
  const [users, setUsers] = useState([]);
  const [usersWithPrescreenAssignments, setUsersWithPrescreenAssignments] = useState([]);
  const [dateArray, setDateArray] = useState([]);

  const [selectedUser, setSelectedUser] = useState({});
  const [searchString, setSearchString] = useState('');
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [emailThatExists, setEmailThatExists] = useState('');
  const [genericUserCreationError, setGenericUserCreationError] = useState(false);
  const [myDate, setMyDate] = useState({});
  const [adjacentDates, setAdjacentDates] = useState([]);
  const [closestDates, setClosestDates] = useState([]);

  const [isFetchingUsers, setIsFetchingUsers] = useState(false);
  const [isFetchingUsersWithCandidates, setIsFetchingUsersWithCandidates] = useState(false);
  const [allowPrescreen, setAllowPrescreen] = useState(false);
  const [enablePrescreenMode, setEnablePrescreenMode] = useState();
  const [test, setTest] = useState('test beginning');

  const [showAddUser, setShowAddUser] = useState();
  const [isPosting, setIsPosting] = useState(false);
  const [user, setUser] = useState({});
  const [URLUser, setURLUser] = useState(null);

  const [pk_InterviewDate, setpk_InterviewDate] = useState(null);
  const [pk_User, setpk_User] = useState(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  const [loading, setLoading] = useState(true);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const query = new URLSearchParams(window.location.search);
  const history = useHistory();
  const selectedRef = useRef(null);

  const onRefChange = useCallback(
    (node) => {
      // if the user is coming from another page with a pre-selection we need to make sure to scroll down to the apporpirate selection.
      // We should only do this once on mount
      if (node !== null && !hasScrolled) {
        node.scrollIntoView();
        setHasScrolled(true);
      }
    },
    [hasScrolled],
  );

  useEffect(() => {
    if (dateArray && myDate !== 'Prescreen') {
      const qpk_User = query.get('pk_User');
      const qpk_InterviewDate = query.get('pk_InterviewDate');

      if (qpk_InterviewDate) {
        setpk_InterviewDate(qpk_InterviewDate);
      }
      if (qpk_User) {
        setpk_User(qpk_User);
      }

      if (pk_InterviewDate != null) {
        const dateFromArray = dateArray.find((d) => {
          return parseInt(d.pk_InterviewDate) == parseInt(pk_InterviewDate);
        });

        if (dateFromArray) {
          setMyDate(dateFromArray);
        }
      } else {
        if (dateArray && dateArray.length > 0) {
          const defaultDate = getDefaultInterviewDate(dateArray, dContext.season);
          setMyDate(defaultDate);
        }
      }
    }
    setLoading(false);
  }, [dateArray]);

  useEffect(() => {
    if (dContext && dContext.season) {
      setAllowPrescreen(dContext.season.AllowPrescreen);
      setEnablePrescreenMode(dContext.season.EnablePrescreenMode);
      if (dContext.season.EnablePrescreenMode) {
        setMyDate('Prescreen');
      }
    }
  }, [dContext]);

  useEffect(() => {
    if (myDate && myDate.pk_InterviewDate) {
      getUsers();
      setDate(myDate);
      if (myDate.pk_InterviewDate === 'Prescreen') {
        getEvaluatorsWithAssignedCandidates();
      }
    }

    if (myDate === 'Prescreen') {
      setDate({ pk_InterviewDate: 'Prescreen' });
      getUsers();
      getEvaluatorsWithAssignedCandidates();
    }
  }, [myDate]);

  useEffect(() => {
    getUsers(showAllUsers);
  }, [showAllUsers]);

  useEffect(() => {
    filterBySearch(searchString);
  }, [searchString, users]);

  useEffect(() => {
    getSeason();
    getActiveInterviewDates();
  }, []);

  useEffect(() => {
    if (
      !isFetchingUsers &&
      !isFetchingUsersWithCandidates &&
      (myDate === 'Prescreen' || myDate.pk_InterviewDate === 'Prescreen')
    ) {
      const newUsers = [];
      usersWithPrescreenAssignments.forEach((user) => {
        const counterpart = users.find((u) => {
          return u.pk_User === user.pk_User;
        });
        if (counterpart && counterpart.EnableEvaluator && !counterpart.Deleted) {
          newUsers.push(counterpart);
        }
      });

      if (JSON.stringify(users) !== JSON.stringify(newUsers)) {
        setUsers(newUsers);
      }
    }
  }, [users, usersWithPrescreenAssignments]);

  const getActiveInterviewDates = (callback) => {
    getTokenSilently()
      .then((t) => {
        fetchDataAgnostic(
          'department/interviewDates',
          // 'department/season/user/scheduledates',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            // isAdmin: myDate === 'Prescreen' ? 1 : null,
            // pk_User: myDate === 'Prescreen' && selectedUser ? selectedUser.pk_User : null,
          },

          formatBearerToken(t),
        )
          .then((res) => {
            const datesSortedByEarliest = res.data.sort((a, b) => {
              const firstMillis = moment(`${a.DateOfInterview} ${a.StartTime}`).valueOf();
              const secondMillis = moment(`${b.DateOfInterview} ${b.StartTime}`).valueOf();

              if (firstMillis < secondMillis) {
                return -1;
              }
              if (firstMillis > secondMillis) {
                return 1;
              }
              // a must be equal to b
              return 0;
            });

            const scheduleLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
            let placeholderLabelCount = 0;
            let oldDate = null;
            datesSortedByEarliest.forEach((d) => {
              if (oldDate != d.DateOfInterview) {
                placeholderLabelCount = 0;
                oldDate = d.DateOfInterview;
              }
              if (!d.Label) {
                if (placeholderLabelCount < scheduleLetters.length) {
                  d.Label = `Schedule ${scheduleLetters[placeholderLabelCount]}`;
                } else {
                  d.Label = `Schedule ${placeholderLabelCount - scheduleLetters.length}`;
                }
              }
              placeholderLabelCount++;
            });
            setDateArray(datesSortedByEarliest);

            if (dContext.season.EnablePrescreenMode) {
              setMyDate('Prescreen');
            } else {
              console.log();
            }
            if (callback) {
              callback();
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleChange = (key, value) => {
    const newUser = clone(user);
    newUser[key] = value;
    setUser(newUser);
  };

  const filterBySearch = (str) => {
    const newFiltered = users.filter((user) => {
      const name = `${user.UserFirst} ${user.UserLast}`;
      return str == '' || !str || name.toLowerCase().indexOf(str.toLowerCase()) >= 0;
    });
    setFilteredUsers(newFiltered);
  };

  // Get evalautors with assigned candidates for prescreen
  const getEvaluatorsWithAssignedCandidates = () => {
    setIsFetchingUsersWithCandidates(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/users/prescreen/all',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsFetchingUsersWithCandidates(false);
            const rawUsers = Object.values(res.data)
              // .filter((user) => {
              //   return !user.Deleted && user.EnableEvaluator;
              // })
              .sort((a, b) => {
                const textA = `${a.UserLast ? `${a.UserLast}, ` : ''}${a.UserFirst || ''}`.toUpperCase();
                const textB = `${b.UserLast ? `${b.UserLast}, ` : ''} ${b.UserFirst}`.toUpperCase();

                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });

            setUsersWithPrescreenAssignments(rawUsers);
          })
          .catch((err) => {
            console.log('err getting evaluators with assigned candidates', err);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getUsers = () => {
    const isPrescreen = myDate === 'Prescreen' || myDate.pk_InterviewDate === 'Prescreen';

    setIsFetchingUsers(true);
    getTokenSilently()
      .then((token) => {
        return fetchDataAgnostic(
          'department/users',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: isPrescreen ? dContext.season.pk_Season : null,
            pk_InterviewDate: showAllUsers || isPrescreen ? null : pk_InterviewDate || myDate.pk_InterviewDate,
            mode: isPrescreen ? 'Prescreen' : 'Evaluation',
          },
          formatBearerToken(token),
        )
          .then((res) => {
            // should only use this variable on load (passed in through query param), so set it as null for any subsequant calls
            setpk_InterviewDate(null);
            setSelectedUser(null);
            setIsFetchingUsers(false);
            const rawUsers = Object.values(res.data)
              .filter((user) => {
                return !user.Deleted && user.EnableEvaluator;
              })
              .sort((a, b) => {
                const textA = `${a.UserLast ? `${a.UserLast}, ` : ''}${a.UserFirst || ''}`.toUpperCase();
                const textB = `${b.UserLast ? `${b.UserLast}, ` : ''} ${b.UserFirst}`.toUpperCase();

                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });
            setUsers(rawUsers);
            const qpk_User = pk_User || query.get('pk_User');

            if (qpk_User) {
              setpk_User(null);
            }
            if (qpk_User != null) {
              const userToSelect = rawUsers.find((user) => {
                return user.pk_User == qpk_User;
              });
              // setSelectedUser(userToSelect || rawUsers[0]);
            } else if (selectedUser) {
              const userToSelect = rawUsers.find((user) => {
                return user.pk_User == selectedUser.pk_User;
              });

              // setSelectedUser(userToSelect || rawUsers[0]);
            } else {
              // setSelectedUser(rawUsers[0]);
            }
          })
          .catch((err) => {
            setIsFetchingUsers(false);
            const rawUsers = users.slice();
            if (selectedUser) {
              const userToSelect = rawUsers.find((user) => {
                return user.pk_User == selectedUser.pk_User;
              });
              // setSelectedUser(userToSelect || rawUsers[0]);
            } else {
              // setSelectedUser(rawUsers[0]);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getSeason = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data) {
              setAllowPrescreen(res.data.AllowPrescreen);
              setEnablePrescreenMode(res.data.EnablePrescreenMode);
              if (res.data.EnablePrescreenMode) {
                setMyDate('Prescreen');
              }
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleDateChange = (e) => {
    const value = Number(e.target.value);
    if (e.target.value === 'Prescreen') {
      setShowAllUsers(false);
      setMyDate('Prescreen');

      return;
    } else {
      dateArray.forEach((item) => {
        if (item.pk_InterviewDate === value) {
          setMyDate(item);
          getAdjacentSchedule(dateArray, item);
        }
      });
    }
  };

  const saveNewUser = (userObject) => {
    userObject.Auth0Email = userObject.UserEmail;
    const users = [userObject];
    setIsPosting(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/users',
          { pk_Department: dContext.department.pk_Department, isSingleUser: true },
          { users },
          formatBearerToken(token),
        )
          .then(async (result) => {
            if (result.data.userExists) {
              setEmailThatExists(users[0].UserEmail);
              NotificationManager.error('A user with that email already exists in this department.');
            } else if (result.data.error) {
              setGenericUserCreationError(true);
              NotificationManager.error('Something went wrong...');
            } else {
              if (result.data.userExistInSystem) {
                userExistsInSystem(result.data.userObject);
              } else {
                NotificationManager.success('Success!', 'User Saved');
              }

              await getUsers();
              setShowAddUser(false);
              setUser({});
              // TODO: Find a better way to get current state
              setUsers((prevState) => {
                const selectedUser = prevState.find((user) => {
                  return result.data.pk_User === user.pk_User;
                });
                setSelectedUser(selectedUser);
                return prevState;
              });
            }
          })
          .catch((err) => {
            setGenericUserCreationError(true);
          })
          .finally(() => {
            setIsPosting(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getClosestDate = (dateArray) => {
    const now = moment();
    const datesSortedByEarliest = dateArray.sort((a, b) => {
      const firstMillis = moment(`${a.DateOfInterview} ${a.StartTime}`).valueOf();
      const secondMillis = moment(`${b.DateOfInterview} ${b.StartTime}`).valueOf();

      if (firstMillis < secondMillis) {
        return -1;
      }
      if (firstMillis > secondMillis) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });

    return datesSortedByEarliest.filter((date) => {
      return now.isSameOrBefore(moment(`${date.DateOfInterview} ${date.StartTime}`));
    });
  };

  const getAdjacentSchedule = (dates, currentDate) => {
    const newAdjacentDates =
      dates.filter((d) => {
        return d.DateOfInterview === currentDate.DateOfInterview;
        // && d.pk_InterviewDate != currentDate.pk_InterviewDate;
      }) || [];

    setAdjacentDates(newAdjacentDates);
  };

  const changeEvaluatorScope = (e) => {
    if (e.target.value === radioButtonOptions.showScheduledUsers) {
      setShowAllUsers(false);
    } else if ((e.target.value = radioButtonOptions.showAllUsers)) {
      setShowAllUsers(true);
    }
    // setShowAllUsers(value);
  };

  const renderUserExistsError = () => {
    if (emailThatExists.trim() !== '') {
      if (user && user.UserEmail === emailThatExists) {
        return true;
      }
    }
    return false;
  };

  if (loading) {
    return <div />;
  }

  const renderDateDropdown = () => {
    return (
      <Fragment>
        <FormGroup>
          <Label for="exampleCustomSelect">Interview Date</Label>
          <CustomInput
            type="select"
            id="exampleCustomSelect"
            name="customSelect"
            value={myDate.pk_InterviewDate || myDate}
            onChange={handleDateChange}
          >
            {allowPrescreen ? <option value={'Prescreen'}>Prescreen</option> : null}
            {dateArray.map((item) => {
              const { DateOfInterview, pk_InterviewDate, Label } = item;
              return (
                <option key={pk_InterviewDate} value={pk_InterviewDate}>
                  {moment(DateOfInterview).format('MMM DD, YYYY (ddd)')}
                  {`${Label ? `: ${Label}` : ''}`}
                </option>
              );
            })}
          </CustomInput>
        </FormGroup>

        {myDate !== 'Prescreen' ? (
          <>
            <FormGroup check style={{ marginRight: '15px', marginBottom: '10px' }}>
              <Label check>
                <Input
                  checked={!showAllUsers}
                  value={radioButtonOptions.showScheduledUsers}
                  type="radio"
                  name="radio2"
                  onChange={changeEvaluatorScope}
                />{' '}
                Scheduled Evaluators
              </Label>
            </FormGroup>
            <FormGroup check style={{ marginBottom: '10px' }}>
              <Label check>
                <Input
                  checked={showAllUsers}
                  value={radioButtonOptions.showAllUsers}
                  type="radio"
                  name="radio2"
                  onChange={changeEvaluatorScope}
                />{' '}
                All Evaluators
              </Label>
            </FormGroup>
          </>
        ) : null}
      </Fragment>
    );
  };

  const renderSearchInput = () => {
    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Button
            onClick={() => {
              setSearchString('');
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroupAddon>
        <Input
          value={searchString}
          type="text"
          placeholder="Search User. . ."
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
        />
        <InputGroupAddon addonType="append">
          <Button color="success">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    );
  };

  const renderUserList = () => {
    return (
      <div style={style.userListContainer}>
        {/* <div> {filteredUsers.length}</div> */}
        {!isFetchingUsers ? (
          filteredUsers.length > 0 ? (
            filteredUsers.map((user, index) => {
              const { UserPhotoUrl, UserFirst, UserLast, LastLoginDate, Role, EnableEvaluator, Deleted } = user;
              let itemProps = {};
              let backgroundColor = null;
              if (selectedUser && selectedUser.pk_User == user.pk_User) {
                itemProps = { ref: onRefChange };
                backgroundColor = '#d9f5ff';
              } else if (index % 2 == 0) {
                backgroundColor = '#c7cbd1';
              }

              if (Deleted || !EnableEvaluator) {
                // backgroundColor = '#f8d7da';
              }
              return (
                <div
                  style={{ ...style.itemRow, backgroundColor }}
                  {...itemProps}
                  onClick={() => {
                    setSelectedUser(user);
                  }}
                >
                  <div>
                    {UserPhotoUrl ? (
                      <img src={UserPhotoUrl} height={50} style={style.imgPlaceHolder} />
                    ) : (
                      <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
                    )}
                  </div>
                  <div style={style.candidateInfo}>
                    <div>{`${UserLast}, ${UserFirst}`}</div>
                    <div style={{ fontSize: 12, fontWeight: 'bold', height: !Role ? 20 : null }}>{Role || ' '}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ textAlign: 'center' }}>No Users</div>
          )
        ) : (
          <div style={{ textAlign: 'center' }}>Loading . . .</div>
        )}
      </div>
    );
  };

  const renderAddUserModal = () => {
    return (
      <AddUser
        isOpen={showAddUser}
        user={user}
        setUser={setUser}
        setIsOpen={setShowAddUser}
        saveNewUser={saveNewUser}
        isPosting={isPosting}
        handleChange={handleChange}
        renderUserExistsError={renderUserExistsError}
      />
    );
  };

  return (
    <Row>
      <Col md={3}>
        <Row style={{ padding: 10, marginRight: 2, marginLeft: 5 }} className="card shadow bg-light">
          <Col md={12}>
            <Row>{renderDateDropdown()}</Row>
            <Row>{renderSearchInput()}</Row>
            <Row> {renderUserList()}</Row>
          </Col>
        </Row>
      </Col>
      <Col md={9}>
        <Row style={{ padding: 10, marginRight: 2 }} className="card shadow bg-light">
          <Col md={12}>
            <div style={{ height: 'calc(100vh - 300px)' }}>
              <SyntheticEvaluation
                selectedEvaluator={selectedUser}
                isAdmin={true}
                date={myDate}
                adjacentDates={adjacentDates}
                handleDateChange={handleDateChange}
                dateArray={dateArray}
              />
            </div>
            {/* EVALUATE SHEET for {selectedUser && selectedUser.UserFirst ? selectedUser.UserFirst : ''}{' '}
            {selectedUser && selectedUser.UserLast ? selectedUser.UserLast : ''} */}
          </Col>
        </Row>
      </Col>
      {renderAddUserModal()}
    </Row>
  );
};

export default AdminEvaluate;
