import DragAndDropFiles from 'Body/DragAndDropFiles/DragAndDropFiles';
import { fetchCandidates } from 'Services/CandidatesAPI';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Dropzone, { useDropzone } from 'react-dropzone';
import style from '../style';
import Swal from 'sweetalert2';
import { DepartmentContext } from 'DepartmentWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faFile,
  faFileImage,
  faFilePdf,
  faInfoCircle,
  faRedo,
  faRedoAlt,
  faSortDown,
  faSpinner,
  faTimesCircle,
  faUndoAlt,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { constructUrl, fetchDataAgnostic, postData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import {
  Button,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  UncontrolledTooltip,
} from 'reactstrap';
import { post } from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DocumentTypesPopover from '../DocumentTypesPopover';
import { getImagesFromPDFFileAndResize, resizeImage } from 'Services/candidates';
import { popoverModifiers } from 'enums';

const DocumentBulkImporter = (props) => {
  const { getCandidates, allCandidatesDetails = [], changes, setChanges } = props;
  const [files, setFiles] = useState(null);
  const [fileData, setFileData] = useState(null); // parsed data for rendering
  const [candidates, setCandidates] = useState([]); // updated list of candidates
  const [documentTypes, setDocumentTypes] = useState([]); // list of document types
  const [selectedDocumentType, setSelectedDocumentType] = useState(''); // selected document type
  const [unmatchedFiles, setUnamtchedFiles] = useState([]); // files that do not match any candidate
  const [matchedFiles, setMatchedFiles] = useState([]); // files that match a candidate
  const [failedImageExtractionFiles, setFailedImageExtractionFiles] = useState([]); // files that failed to extract images
  const [acceptedFiles, setAcceptedFiles] = useState([]); // files that are accepted

  const [uploadedFiles, setUploadedFiles] = useState([]); // files that are uploaded
  const [failedFiles, setFailedFiles] = useState([]); // files that failed to upload
  const [fileUploadStatusKeys, setFileUploadStatusKeys] = useState({}); // keys for file upload statuses. Keys are pk_Candidate keys, and values are true/false depending on success or fail.This is for UI updates. Use ref below for actual tracking.

  const [showModifyDocumentTypesModal, setShowModifyDocumentTypesModal] = useState(false);
  const [showDocumentTypeOptionsPopover, setShowDocumentTypeOptionsPopover] = useState(false);

  const [isUploadingFiles, setUploadingFiles] = useState(false);

  const [isLoadingCandidates, setIsLoadingCandidates] = useState(true);
  const [isFetchingDocumentTypes, setIsFetchingDocumentTypes] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);

  const fileUploadStatusKeysRef = useRef(fileUploadStatusKeys);
  const history = useHistory();

  useEffect(() => {
    getDocumentTypes();
  }, []);

  useEffect(() => {
    setFileUploadStatusKeys(fileUploadStatusKeysRef.current);
  }, [fileUploadStatusKeysRef]);

  useEffect(() => {}, [fileUploadStatusKeys]);

  const getDocumentTypes = () => {
    setIsFetchingDocumentTypes(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'documentTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsFetchingDocumentTypes(false);
            setDocumentTypes([
              {
                DocumentTypeName: 'Candidate Photo',
                pk_DocumentType: 'candidate_photo',
                lineAfter: true,
                isBeta: true,
                tooltip:
                  "This is a special document type for candidate photos. Choosing this and uploading images will set those as any matching candidate's profile photo. When uploading PDFs, RezRATE will attempt to extract the biggest image across the entire file, and use that as the profile image instead. Images are capped at 1000x1000 pixels ",
              },
              ...res.data,
            ]);
          })
          .catch((err) => {
            setIsFetchingDocumentTypes(false);
          });
      })
      .catch((err) => {
        setIsFetchingDocumentTypes(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const matchFilesToCandidates = (files) => {
    const newUnmatchedFiles = [];
    const newMatchedFiles = [];
    const candidateMatchStatus = {};
    files.forEach((file) => {
      const fileNameStrings = file.name.split('_');
      let isMatched = false;

      allCandidatesDetails.forEach((candidate) => {
        const { SFMatchID, AAMCID } = candidate;

        if (isMatched || candidateMatchStatus[candidate.pk_Candidate]) {
          return;
        }
        fileNameStrings.forEach((string) => {
          if (isMatched || candidateMatchStatus[candidate.pk_Candidate]) {
            return;
          }
          if (string.includes('-' || string.length > 10)) {
            return;
          }

          const hasSFMatchID = SFMatchID && string.includes(SFMatchID);
          const hasAAMCID = AAMCID && string.includes(AAMCID);
          const hasID = hasSFMatchID || hasAAMCID;

          if (!isMatched && hasID) {
            const idToCheck = hasSFMatchID ? SFMatchID : AAMCID;
            const numericSubStrings = string.split(/\D+/); // split all numbers.

            numericSubStrings.forEach((numericSubstring) => {
              if (numericSubstring === idToCheck) {
                const matchedFile = { file };
                matchedFile.LastName = candidate.LastName;
                matchedFile.FirstName = candidate.FirstName;
                matchedFile.pk_Candidate = candidate.pk_Candidate;
                newMatchedFiles.push(matchedFile);
                isMatched = true;
                candidateMatchStatus[candidate.pk_Candidate] = true;
              }
            });
          }
        });
      });

      if (!isMatched) {
        const unmatchedFile = { file };
        newUnmatchedFiles.push(unmatchedFile);
      }
    });

    const promises = [];
    setAcceptedFiles(files);
    setUnamtchedFiles(newUnmatchedFiles);

    if (selectedDocumentType === 'candidate_photo') {
      newMatchedFiles.forEach((matchedFile, fileIndex) => {
        const file = matchedFile.file;
        const fileType = file.type;

        if (fileType === 'application/pdf') {
          promises.push(
            new Promise((resolve, reject) => {
              getImagesFromPDFFileAndResize(file, (image) => {
                // document.getElementById('debugger_img').src = image;
                resolve({ image, fileIndex });
              });
            }),
          );
        } else {
          // Handle other file types if necessary
          console.log('This is not a PDF file:', file.name);
        }
      });

      if (promises.length > 0) {
        Promise.all(promises).then((images) => {
          const newNewMatchedFiles = newMatchedFiles.slice();
          const newFailedImageExtractionFiles = failedImageExtractionFiles.slice();

          images.forEach((imageFile) => {
            const { image, fileIndex } = imageFile;
            const newFileName = newMatchedFiles[fileIndex].file.name.replace('.pdf', '.jpg');
            console.log('image: ', image);
            // document.getElementById('debugger_img').src = image;

            const isFailedImageExtraction = image ? false : true;

            const newFile = {
              ...newMatchedFiles[fileIndex],
              file: new File([image], newFileName, {
                type: 'image/jpeg',
              }),
              isFailedImageExtraction,
            };

            if (isFailedImageExtraction) {
              newNewMatchedFiles[fileIndex] = null;
              newFailedImageExtractionFiles.push(newFile);
            } else {
              newNewMatchedFiles[fileIndex] = newFile;
            }
          });

          setMatchedFiles(newNewMatchedFiles.filter((file) => file));
          setFailedImageExtractionFiles(newFailedImageExtractionFiles);
        });

        return;
      }
    }

    setMatchedFiles(newMatchedFiles);
  };

  const repackFilesForUpload = (files, isProfilePhoto) => {
    const formData = new FormData();

    files.forEach((file, i) => {
      const currentFile = file.file;

      const payload = {
        name: currentFile.name,
        pk_Candidate: file.pk_Candidate,
        pk_Department: dContext.department.pk_Department,
        pk_DocumentType: isProfilePhoto ? 0 : selectedDocumentType, // set to 0 if isProfilePhoto true
        isProfilePhoto,
      };

      fileUploadStatusKeysRef.current[file.pk_Candidate] = 'uploading';
      formData.append(`files`, currentFile, JSON.stringify(payload));
    });
    setFileUploadStatusKeys(fileUploadStatusKeysRef.current);
    return formData;
  };

  const chunkUpload = async (formData, callback) => {
    return getTokenSilently()
      .then((token) => {
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            ...formatBearerToken(token),
          },
        };

        return post(constructUrl('files/candidate/bulk-document'), formData, config)
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return false;
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const fileUploadBulk = async (files, isBulkUploader) => {
    let filesAsArray = files;

    const chunkSize = 5;

    setUploadingFiles(true);
    const newMatchedFiles = matchedFiles.slice();
    Object.keys(fileUploadStatusKeysRef.current).forEach((pk_Candidate) => {
      const matchingFile = newMatchedFiles.find((file) => file.pk_Candidate === pk_Candidate);
      if (matchingFile) {
        matchingFile.status = fileUploadStatusKeysRef.current[pk_Candidate];
      }
    });
    setMatchedFiles(newMatchedFiles);
    const isProfilePhoto = selectedDocumentType === 'candidate_photo';
    for (let i = 0; i < filesAsArray.length; i += chunkSize) {
      const filesChunk = filesAsArray.slice(i, i + chunkSize);

      const formData = repackFilesForUpload(filesChunk, isProfilePhoto);
      // console.log('formData: ', formData);

      const chunkResult = await chunkUpload(formData);

      if (!chunkResult) {
        filesChunk.forEach((file) => {
          failedFiles.push(file);
          fileUploadStatusKeysRef.current[file.pk_Candidate] = 'failed';
        });
      } else {
        filesChunk.forEach((file) => {
          failedFiles.push(file);
          fileUploadStatusKeysRef.current[file.pk_Candidate] = 'success';
        });
      }
      const newMatchedFiles = matchedFiles.slice();
      Object.keys(fileUploadStatusKeysRef.current).forEach((pk_Candidate) => {
        const matchingFile = newMatchedFiles.find((file) => file.pk_Candidate === pk_Candidate);
        if (matchingFile) {
          matchingFile.status = fileUploadStatusKeysRef.current[pk_Candidate];
        }
      });
      setMatchedFiles(newMatchedFiles);
      setFileUploadStatusKeys(fileUploadStatusKeysRef.current);
    }

    const { successCount, totalCount } = countFailedOrUpdatingFiles();
    if (totalCount === successCount) {
      setChanges({ ...changes, import_data: false });
    }
    getCandidates();
    setUploadingFiles(false);
  };

  const countFailedOrUpdatingFiles = () => {
    let successCount = 0;
    let totalCount = 0;
    Object.keys(fileUploadStatusKeysRef.current).forEach((key) => {
      totalCount++;
      if (fileUploadStatusKeysRef.current[key] === 'success') {
        successCount++;
      }
    });

    return { successCount, totalCount };
  };

  const resetBulkImports = () => {
    fileUploadStatusKeysRef.current = {};
    setFileUploadStatusKeys({});
    setMatchedFiles([]);
    setUnamtchedFiles([]);
    setFailedImageExtractionFiles([]);
    setAcceptedFiles([]);
    setSelectedDocumentType('');
    setChanges({ ...changes, import_data: false });
  };

  const onDrop = (acceptedFiles) => {
    const isDisabled = isFetchingDocumentTypes || !selectedDocumentType;

    if (isDisabled) {
      return;
    }

    let hasNonZeroFile = 0; // if false. we pop a swal

    acceptedFiles.forEach((file) => {
      if (file.size > 0) {
        hasNonZeroFile++;
      }
    });

    if (!acceptedFiles || acceptedFiles.length <= 0) {
      Swal.fire({
        title: 'Invalid File Type',
        icon: 'error',
        type: 'error',
        text: 'Please Use PDF, JPG, JPEG or PNG',
      });
    } else if (hasNonZeroFile == 0) {
      Swal.fire({
        title: 'Unable to read file.' + acceptedFiles.length > 1 ? 's' : '',
        text: 'Make sure you have Extracted/Unzipped your files before uploading.1',
        icon: 'error',
      });
      return;
    } else if (hasNonZeroFile < acceptedFiles.length) {
      Swal.fire({
        title: 'Some files are empty',
        text: 'Make sure you have Extracted/Unzipped your files before uploading.2',
        icon: 'warning',
      });
    } else {
      setChanges({ ...changes, import_data: true });
      matchFilesToCandidates(acceptedFiles);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'],
    onDrop,
  });

  const renderModifyDocumentTypesModal = () => {
    return (
      <Modal
        style={{ minWidth: '70vw' }}
        centered
        isOpen={showModifyDocumentTypesModal}
        toggle={() => {
          if (showModifyDocumentTypesModal) {
            getDocumentTypes();
          }
          setShowModifyDocumentTypesModal(!showModifyDocumentTypesModal);
        }}
      >
        <ModalHeader
          toggle={() => {
            if (showModifyDocumentTypesModal) {
              getDocumentTypes();
            }
            setShowModifyDocumentTypesModal(!showModifyDocumentTypesModal);
          }}
        >
          Modify Document Types
        </ModalHeader>
        <ModalBody style={{ height: '60vh' }}>
          <div style={{ ...style.simpleRow, overflowY: 'auto', height: '56vh' }}>
            <DocumentTypesPopover />
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderUploadStatus = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '80%',
          paddingTop: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 10,
            height: 'calc(100vh - 550px)',
            overflowY: 'auto',
          }}
        >
          {matchedFiles.map((item) => {
            return (
              <FileToUploadItem
                item={item}
                fileUploadStatusKeys={fileUploadStatusKeys}
                fileUploadBulk={fileUploadBulk}
              />
            );
          })}
        </div>
      </div>
    );
  };

  const renderUploadPreview = () => {
    const renderFileItem = (item) => {
      const { file, LastName, FirstName, isFailedImageExtraction } = item;
      const { name } = file;
      const nameToDisplay = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;

      return (
        <div style={{ display: 'flex', flexDirection: 'row', padding: 10, borderBottom: '1px solid #D0D0D0' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginRight: 10 }}>
            {file.type.includes('image/') && isFailedImageExtraction == false ? (
              <img src={URL.createObjectURL(file)} style={{ width: 70 }} />
            ) : file.type.includes('image/') ? (
              <FontAwesomeIcon icon={faFileImage} style={{ fontSize: 70 }} />
            ) : (
              <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 70 }} />
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 80px)' }}>
            <div style={{ fontSize: 15, fontWeight: 'bold' }}>{nameToDisplay || '---'}</div>
            <div style={{ fontSize: 13 }}> {name || '---'}</div>
          </div>
        </div>
      );
    };

    let totalColumns = 0;
    if (unmatchedFiles.length > 0) {
      totalColumns++;
    }

    if (failedImageExtractionFiles.length > 0) {
      totalColumns++;
    }

    if (matchedFiles.length > 0) {
      totalColumns++;
    }

    let columnWidth = '90%';

    return (
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', paddingTop: 0 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              borderTop: '1px solid black',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {unmatchedFiles.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: columnWidth,
                  padding: 10,
                  fontWeight: 'bold',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#FF0000',
                  fontSize: 17,
                }}
              >
                {`${unmatchedFiles.length} / ${acceptedFiles.length} Unmatched`}
              </div>
            ) : null}

            {failedImageExtractionFiles.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: columnWidth,
                  padding: 10,
                  fontWeight: 'bold',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#FF0000',
                  fontSize: 17,
                }}
              >
                {`${failedImageExtractionFiles.length} / ${acceptedFiles.length} Failed Image${
                  failedImageExtractionFiles.length > 1 ? 's' : ''
                }`}
              </div>
            ) : null}

            {matchedFiles.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: columnWidth,
                  padding: 10,
                  fontWeight: 'bold',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#00AE28',
                  fontSize: 17,
                }}
              >
                {`${matchedFiles.length} / ${acceptedFiles.length} Matches`}
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {unmatchedFiles.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: columnWidth,
                  height: 'calc(100vh - 550px)',
                  overflowY: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  backgroundColor: '#FFD2D2',
                }}
              >
                {unmatchedFiles.length > 0 ? (
                  unmatchedFiles.map((item) => renderFileItem(item))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                  >
                    No Unmatched Files
                  </div>
                )}
              </div>
            ) : null}

            {failedImageExtractionFiles.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: columnWidth,
                  height: 'calc(100vh - 550px)',
                  overflowY: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  backgroundColor: '#FFD2D2',
                }}
              >
                {failedImageExtractionFiles.length > 0 ? (
                  failedImageExtractionFiles.map((item) => renderFileItem(item))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                  >
                    No Failed Image Extraction Files
                  </div>
                )}
              </div>
            ) : null}

            {matchedFiles.length > 0 ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: columnWidth,
                  height: 'calc(100vh - 550px)',
                  overflowY: 'auto',
                  marginRight: 10,
                  marginLeft: 10,
                  backgroundColor: '#CEFFDA',
                }}
              >
                {matchedFiles.length > 0 ? (
                  matchedFiles.map((item) => renderFileItem(item))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      padding: 10,
                    }}
                  >
                    No Matching Files
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderDragAndDropArea = () => {
    const isDisabled = isFetchingDocumentTypes || !selectedDocumentType;

    const selectedDocumentTypeObject = documentTypes.find((d) => {
      return d.pk_DocumentType === selectedDocumentType;
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={style.simpleColumn}>
          <div style={{ ...style.simpleRow, paddingLeft: 20, paddingRight: 20, justifyContent: 'center' }}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Button
                outline={selectedDocumentTypeObject ? false : true}
                id={'document_type_btn'}
                style={{ fontWeight: 'bold', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                color={selectedDocumentTypeObject ? 'secondary' : 'danger'}
                onClick={() => {
                  setShowDocumentTypeOptionsPopover(!showDocumentTypeOptionsPopover);
                }}
              >
                <span style={{ flex: 1, textAlign: 'center' }}>
                  {selectedDocumentTypeObject
                    ? selectedDocumentTypeObject.DocumentTypeName || 'Unnamed Document Type'
                    : 'Select Document Type'}
                  {selectedDocumentTypeObject ? null : (
                    <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginLeft: 5 }} />
                  )}
                </span>
                <FontAwesomeIcon icon={faSortDown} style={{ marginLeft: 'auto' }} />
              </Button>
              {renderDocumentTypeOptionsPopover()}
            </div>
            <div
              style={{
                ...style.simpleColumn,
                width: 230,
                paddingLeft: 5,
                paddingRight: 5,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                color="primary"
                style={{ fontWeight: 'bold', width: 220 }}
                onClick={() => {
                  setShowModifyDocumentTypesModal(true);
                  // history.push('/administrator/settings/candidateSettings/document_types');
                }}
              >
                Modify Document Types
              </Button>
            </div>
          </div>
          <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
            <div
              disabled={isDisabled}
              {...getRootProps()}
              style={{
                height: 'min-content',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderRadius: 15,
              }}
              className="hover_blue"
            >
              <input disabled={isDisabled} {...getInputProps()} />
              <div
                style={{
                  height: 'calc(70vh - 250px',
                  width: 'calc(70vw - 250px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  margin: 10,
                  padding: 10,
                  backgroundColor: isDisabled ? '#94908f' : '#cfd0d1',
                  border: '3px solid black',
                  borderRadius: 15,
                  cursor: isDisabled ? 'not-allowed' : 'pointer',
                }}
              >
                {isFetchingDocumentTypes || !selectedDocumentType ? (
                  // <Loading />
                  <FontAwesomeIcon icon={faExclamationTriangle} size="10x" style={{ paddingBottom: '15px' }} />
                ) : (
                  <FontAwesomeIcon icon={faUpload} size="10x" style={{ paddingBottom: '15px' }} />
                )}
                {isFetchingDocumentTypes ? (
                  <h4>Please wait. . .</h4>
                ) : !selectedDocumentType ? (
                  'Please select a document type to upload'
                ) : isDragActive ? (
                  <h4>Drop the files here ...</h4>
                ) : (
                  <h4>
                    {' '}
                    {isFetchingDocumentTypes
                      ? 'Fetching document types. . .'
                      : 'Click to browse or drag and drop files here'}
                  </h4>
                )}
                {selectedDocumentType ? (
                  <h4>{`(${
                    documentTypes.find((docType) => {
                      return (
                        docType.pk_DocumentType === parseInt(selectedDocumentType) ||
                        docType.pk_DocumentType === selectedDocumentType
                      );
                    }).DocumentTypeName
                  })`}</h4>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDocumentTypeOptionsPopover = () => {
    return (
      <Popover
        isOpen={showDocumentTypeOptionsPopover}
        toggle={() => {
          setShowDocumentTypeOptionsPopover(!showDocumentTypeOptionsPopover);
        }}
        flip={false}
        modifiers={{
          preventOverflow: { boundariesElement: 'viewport' },
        }}
        placement="bottom"
        target="document_type_btn"
        placementPrefix="filterPopover bs-popover"
        trigger="legacy"
      >
        <PopoverBody>
          <div style={{ ...style.simpleColumn, width: '100%', height: 420, overflowY: 'auto' }}>
            {!isFetchingDocumentTypes ? (
              documentTypes.map((item) => {
                const { lineAfter, tooltip, isBeta } = item;
                return (
                  <>
                    <div
                      style={{ ...style.simpleRow, justifyContent: 'space-between', padding: 5 }}
                      onClick={() => {
                        setSelectedDocumentType(item.pk_DocumentType);
                        setShowDocumentTypeOptionsPopover(false);
                      }}
                    >
                      <Button
                        outline
                        color={selectedDocumentType == item.pk_DocumentType ? 'primary' : 'secondary'}
                        style={{ width: '100%', fontWeight: 'bold' }}
                      >
                        {item.DocumentTypeName || `Unnamed Document Type`}{' '}
                        {isBeta ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'purple' }}>BETA</span> : ' '}
                        {tooltip ? (
                          <FontAwesomeIcon id={`tooltip_${item.pk_DocumentType}`} icon={faInfoCircle} />
                        ) : null}
                        {tooltip ? (
                          <UncontrolledTooltip
                            target={`tooltip_${item.pk_DocumentType}`}
                            placement="right"
                            modifiers={popoverModifiers}
                          >
                            {' '}
                            {tooltip}
                          </UncontrolledTooltip>
                        ) : null}
                      </Button>
                    </div>
                    {lineAfter ? (
                      <div
                        style={{
                          height: 2,
                          width: '100%',
                          marginTop: 15,
                          marginBottom: 10,
                          borderTop: '1px lightgray solid',
                        }}
                      >
                        {' '}
                      </div>
                    ) : null}
                  </>
                );
              })
            ) : (
              <div style={{ ...style.simpleRow, justifyContent: 'center', padding: 5 }}>
                Loading document types. . .
              </div>
            )}
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderContent = () => {
    if (Object.keys(fileUploadStatusKeys).length > 0 || isUploadingFiles) {
      return renderUploadStatus();
    } else if (matchedFiles.length > 0 || unmatchedFiles.length > 0 || failedImageExtractionFiles.length > 0) {
      return renderUploadPreview();
    } else {
      return renderDragAndDropArea();
    }
  };

  const renderTopMessage = () => {
    if (matchedFiles.length > 0 || unmatchedFiles.length > 0) {
      return (
        <div style={{ ...style.simpleColumn, justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ fontSize: 15, textAlign: 'center', marginBottom: 5 }}>
            Associate documents (such as CV or LoR) with the appropriate candidate. The <b>candidate must exist</b>{' '}
            before the document can be paired.
          </div>
          <div>
            <div style={{ fontSize: 17, textAlign: 'center', fontWeight: 'bold', color: '#8383f7' }}>
              {selectedDocumentType
                ? `${
                    documentTypes.find((docType) => {
                      return (
                        docType.pk_DocumentType === parseInt(selectedDocumentType) ||
                        docType.pk_DocumentType === selectedDocumentType
                      );
                    }).DocumentTypeName
                  }`
                : null}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderBottomBar = () => {
    if (Object.keys(fileUploadStatusKeys).length > 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            width: '100%',
            padding: 10,
          }}
        >
          <Button
            disabled={matchedFiles.length <= 0}
            color={'success'}
            style={{ width: 250, fontWeight: 'bold' }}
            onClick={() => {
              const { totalCount, successCount } = countFailedOrUpdatingFiles();
              if (isUploadingFiles) {
                Swal.fire({
                  title: 'Please Wait',
                  icon: 'warning',
                  text: 'Some files are still uploading or failed to upload. Are you sure you want to ignore them?',
                  showCancelButton: true,
                  confirmButtonText: 'Yes',
                  confirmButtonColor: '#FF0000',
                }).then((result) => {
                  if (result.isConfirmed) {
                    resetBulkImports();
                  }
                });
              } else if (totalCount != successCount) {
                Swal.fire({
                  title: 'Please Wait',
                  icon: 'warning',
                  text: 'Some files have failed to upload. Are you sure you want to ignore them?',
                  showCancelButton: true,
                  confirmButtonText: 'Yes',
                  confirmButtonColor: '#FF0000',
                }).then((result) => {
                  if (result.isConfirmed) {
                    resetBulkImports();
                  }
                });
              } else {
                resetBulkImports();
              }
            }}
          >
            Upload more files
          </Button>
        </div>
      );
    } else if (matchedFiles.length > 0 || unmatchedFiles.length > 0 || failedImageExtractionFiles.length > 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            padding: 10,
          }}
        >
          <Button
            color={'secondary'}
            style={{ width: 250, fontWeight: 'bold' }}
            onClick={() => {
              resetBulkImports();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={matchedFiles.length <= 0}
            color={'success'}
            style={{ width: 250, fontWeight: 'bold' }}
            onClick={() => {
              fileUploadStatusKeysRef.current = {};
              fileUploadBulk(matchedFiles);
            }}
          >
            Import {matchedFiles.length} Document{matchedFiles.length > 1 ? 's' : ''}
          </Button>
        </div>
      );
    }
  };

  return (
    <div style={{ ...style.simpleRow, margin: 10, backgroundColor: 'white', width: '98%', minHeight: '60vh' }}>
      <div style={{ ...style.simpleColumn, justifyContent: 'flex-start', alignItems: 'center', width: '100%' }}>
        {/* <img id="debugger_img" src={null} /> */}
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
            <div style={{ fontSize: 17, fontWeight: 'bold', marginBottom: 5, padding: 5, textAlign: 'center' }}>
              Bulk Document Import {isUploadingFiles ? 'Uploading. . .' : null}
            </div>
            <div style={{ ...style.simpleRow, width: '80%', justifyContent: 'center' }}>{renderTopMessage()}</div>
          </div>
        </div>
        {renderContent()}
        {renderBottomBar()}
        {renderModifyDocumentTypesModal()}
      </div>
    </div>
  );
};

const FileToUploadItem = (props) => {
  const { item, fileUploadStatusKeys, fileUploadBulk } = props;
  const { file, LastName, FirstName, pk_Candidate, isFailedImageExtraction } = item;

  const { name, type } = file;
  const nameToDisplay = `${LastName || ''}${LastName && FirstName ? ', ' : ''}${FirstName || ''}`;
  const status = fileUploadStatusKeys[pk_Candidate] || 'default';

  let icons = {
    success: faCheckCircle,
    failed: faRedoAlt,
    uploading: faSpinner,
    default: faClock,
  };

  let backgroundColors = {
    success: '#CEFFDA',
    failed: '#FFD2D2',
    uploading: '#C0C0C0',
    default: '#ffa459',
  };

  let messages = {
    success: 'Succesfully Uploaded',
    failed: 'Failed to Upload',
    uploading: 'Uploading. . .',
    default: 'Waiting to Upload',
  };
  let backgroundColor = backgroundColors[status] || backgroundColors.default;
  let icon = icons[status] || icons.default;
  let message = messages[status] || messages.default;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 10,
        borderBottom: '1px solid #D0D0D0',
        backgroundColor,
        width: '100%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', width: 80, marginRight: 10, justifyContent: 'center' }}>
        {file.type.includes('image/') && isFailedImageExtraction == false ? (
          <img src={URL.createObjectURL(file)} style={{ width: 70 }} />
        ) : file.type.includes('image/') ? (
          <FontAwesomeIcon icon={faFileImage} style={{ fontSize: 70 }} />
        ) : (
          <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 70 }} />
        )}
        {/* <FontAwesomeIcon icon={type.includes('image/') ? faFileImage : faFilePdf} style={{ fontSize: 30 }} /> */}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{ fontSize: 15, fontWeight: 'bold' }}
          onClick={() => {
            console.log('item: ', item);

            // window
            // .open(`/viewFile/${selectedCandidate.pk_Candidate}/${pk_File}/${pk_DocumentType}/${Name}`, '_blank')
            // .focus();
          }}
        >
          {nameToDisplay || '---'}
        </div>
        <div style={{ fontSize: 13 }}> {name || '---'}</div>
      </div>
      <div
        style={{ display: 'flex', flexDirection: 'column', width: 250, justifyContent: 'center', fontWeight: 'bold' }}
      >
        {message}
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: 80, justifyContent: 'center' }}>
        <FontAwesomeIcon
          id={`icon_${pk_Candidate}`}
          icon={icon}
          spin={status === 'uploading'}
          style={{
            fontSize: 30,
            cursor: status === 'failed' ? 'pointer' : 'default',
          }}
          onClick={() => {
            if (status === 'failed') {
              fileUploadBulk([item]);
            }
          }}
        />
        {status === 'failed' && (
          <UncontrolledTooltip placement="top" target={`icon_${pk_Candidate}`}>
            Click to Retry Upload of this file
          </UncontrolledTooltip>
        )}
      </div>
    </div>
  );
};

export default DocumentBulkImporter;
