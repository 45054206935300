import { faCheck, faCheckSquare, faPencilAlt, faPlus, faSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, CustomInput, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchDataAgnostic, putData } from '../../../../Services/dataApi';
import { useAlert } from 'react-alert';
import { clone } from '../../../../Services/schedule';
import style from './SeasonPopover.style';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const SeasonPopover = ({ seasons = [], selectedSeason, setSelectedSeason, fetchSeasons, settings }) => {
  const [seasonChoices, setSeasonChoices] = useState([]); // Actual use

  const [isUpdating, setIsUpdating] = useState();
  const [lastUpdatedRow, setLastUpdatedRow] = useState();
  const [allowPreScreen, setAllowPreScreen] = useState(false);
  const [token, setToken] = useState();

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const { department = {} } = dContext;
  const alert = useAlert();
  const history = useHistory();

  useEffect(() => {
    if (seasons) {
      seasons.forEach((s, i) => {
        s.index = i;
        s.editMode = false;
      });

      if (selectedSeason) {
        let sSeason = seasons.find((s) => {
          return selectedSeason.pk_Season == s.pk_Season; //s.Active;
        });

        if (!sSeason) {
          sSeason = seasons.find((s) => {
            return s.Active;
          });
        }

        if (sSeason) {
          setSelectedSeason(sSeason);
        }
      }

      setSeasonChoices(
        clone(
          seasons.sort((a, b) => {
            // Sort by date
            const textA = a.UserLast;
            const textB = b.UserLast;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
            //  return new Date(b[key]) - new Date(a[key]);
          }),
        ),
      );
    }
  }, [JSON.stringify(seasons)]);

  useEffect(() => {
    getSeasonSettings();
  }, [JSON.stringify(selectedSeason)]);

  useEffect(() => {
    getTokenSilently()
      .then((token) => {
        setToken(token);
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  }, []);

  const getSeasonSettings = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setAllowPreScreen(res.data.AllowPrescreen);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const handleChange = (index, key, season, value) => {
    const newSeasons = clone(seasonChoices);
    const newSeason = clone(season);
    newSeason[key] = value;
    setLastUpdatedRow(newSeason);
    if (key != 'SeasonName') {
      newSeasons[index] = newSeason;
      setLastUpdatedRow(newSeason);
      setSeasonChoices(newSeasons);
    }

    if (key === 'editMode') {
      newSeasons[index] = newSeason;
      newSeasons.forEach((s, i) => {
        if (i != index) {
          s.editMode = false;
        }
      });
      setSeasonChoices(newSeasons);
    }
    if (key === 'Active' || key === 'EnablePrescreenMode') {
      setLastUpdatedRow(null);
      saveChanges(newSeason);
    }
  };

  const saveChanges = (seasonToSave, cb) => {
    const pk_Season = seasonToSave.pk_Season;
    delete seasonToSave.editMode;
    delete seasonToSave.fk_Department;
    delete seasonToSave.index;
    delete seasonToSave.pk_UUID_FM;
    delete seasonToSave.pk_Season;

    setIsUpdating(true);

    getTokenSilently()
      .then((token) => {
        putData(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: pk_Season },
          { seasonObject: seasonToSave },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Saved Season!');
            setIsUpdating(false);
            if (fetchSeasons) {
              fetchSeasons();
            }

            if (cb) {
              cb();
            }
          })
          .catch((err) => {
            alert.error('Error saving Season!');
            if (cb) {
              cb();
            }
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        alert.error('Error saving Season!');
        if (cb) {
          cb();
        }
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const headerFormatter = (column, colIndex) => {
    return <div style={style.headerStyle}>{column.text}</div>;
  };

  const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    switch (formatExtraData.SeasonName) {
      case 'Active':
        return <div style={style.headerStyle}></div>;
        break;

      case '':
        return (
          <div style={style.headerStyle}>
            <InputGroup>
              {department.EnableSuperUser ? (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <CustomInput
                    disabled={isUpdating || !department.EnableSuperUser}
                    type="switch"
                    id={`active_${rowIndex}`}
                    name={`active_${rowIndex}`}
                    checked={row.Active}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(rowIndex, 'Active', row, row.Active ? 0 : 1);
                    }}
                  />
                </div>
              ) : null}

              <Input
                style={{ textAlign: 'center' }}
                disabled={!row.editMode || isUpdating}
                type="text"
                defaultValue={row.SeasonName}
                value={row.editMode ? lastUpdatedRow.SeasonName : row.SeasonName}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  handleChange(rowIndex, 'SeasonName', row, e.target.value);
                }}
              />
              {!row.editMode || isUpdating ? (
                <div
                  style={{
                    // backgroundColor: 'red',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%',
                  }}
                  onClick={(e) => {
                    console.log('onClick 2');
                  }}
                ></div>
              ) : null}
              <InputGroupAddon addonType="append">
                <Button
                  color={
                    row.editMode
                      ? lastUpdatedRow && lastUpdatedRow.SeasonName !== row.SeasonName && lastUpdatedRow.SeasonName
                        ? 'success'
                        : 'danger'
                      : 'warning'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    if (row.editMode) {
                      if (lastUpdatedRow && lastUpdatedRow.SeasonName !== row.SeasonName) {
                        if (lastUpdatedRow.SeasonName) {
                          saveChanges(lastUpdatedRow, () => {
                            handleChange(rowIndex, 'editMode', row, false);
                            setLastUpdatedRow(null);
                          });
                        } else {
                          handleChange(rowIndex, 'editMode', row, false);
                        }
                      } else {
                        handleChange(rowIndex, 'editMode', row, false);
                      }
                    } else {
                      setLastUpdatedRow(row);
                      handleChange(rowIndex, 'editMode', row, true);
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={
                      row.editMode
                        ? lastUpdatedRow && lastUpdatedRow.SeasonName !== row.SeasonName && lastUpdatedRow.SeasonName
                          ? faCheck
                          : faTimes
                        : faPencilAlt
                    }
                  />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </div>
        );

      case 'Modify':
        return (
          <div style={style.headerStyle}>
            <Button
              disabled={isUpdating || (lastUpdatedRow != null && lastUpdatedRow.pk_Season != row.pk_Season)}
              color={row.editMode ? 'success' : 'warning'}
              onClick={(e) => {
                e.stopPropagation();
                if (row.editMode) {
                  handleChange(rowIndex, 'editMode', row, !row.editMode);
                  saveChanges(lastUpdatedRow);
                  setLastUpdatedRow(null);
                } else {
                  setLastUpdatedRow(row);
                  handleChange(rowIndex, 'editMode', row, !row.editMode);
                }
              }}
            >
              <FontAwesomeIcon icon={row.editMode ? faCheck : faPencilAlt} />
            </Button>
          </div>
        );
        break;
      default:
        return <div style={style.headerStyle}>{cell}</div>;
        break;
    }
  };

  const makeColumns = () => {
    const newColumns = [];
    const headers = [{ SeasonName: '', dataField: 'SeasonName' }];

    headers.forEach((header) => {
      newColumns.push({
        dataField: header.dataField,
        text: header.SeasonName,
        SeasonName: header.SeasonName,
        formatter: cellFormatter,
        headerFormatter: headerFormatter,
        // classes: 'timeSlot',
        style: (cell, row, rowIndex, colIndex) => {
          const cStyle = { verticalAlign: 'middle', cursor: 'pointer' };
          const backgroundColor = row.pk_Season == selectedSeason.pk_Season ? '#d9f5ff' : null;
          return { ...cStyle, backgroundColor };
        },
        headerStyle: (column, colIndex) => {
          const hStyle = { verticalAlign: 'middle' };
          switch (column.text) {
            case 'Active':
              hStyle.width = 50;
              break;
            case 'Modify':
              hStyle.width = 50;
              break;
          }
          return hStyle;
        },
        // isTime: true,
        formatExtraData: { SeasonName: header.SeasonName },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {
            console.log('click!!');
            dContext.setSeason(row);
            setSelectedSeason(row);
            if (window.location.pathname.includes('administrator/schedule')) {
              history.push('/administrator');
            }
          },
        },
        headerEvents: {
          onClick: (e, column, columnIndex) => {},
        },
        headerAttrs: {
          // id: 'timeSlotHeader',
        },
        attrs: (cell, row, rowIndex, colIndex) => {
          // return { id: `timeSlot_${row.pk_Timeslot}` };
        },
      });
    });
    return newColumns;
  };

  return (
    <div>
      <div style={style.tableContainer}>
        <BootstrapTable
          bordered={true}
          columns={makeColumns()}
          data={seasonChoices.sort((s) => {
            return s.pk_Season;
          })}
          keyField="slot"
        />
      </div>
      {/* <div style={{ ...style.simpleRow, marginBottom: 10 }}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          {' '}
          <Button color="success">
            <div style={style.spacedBetweenRow}>
              <div>
                <FontAwesomeIcon icon={faPlus} />
              </div>
              <div style={{ textAlign: 'center', width: '100%' }}>Start New Season</div>
            </div>
          </Button>
        </div>
      </div> */}
      {/* <div style={{ borderBottom: '1px solid gray', marginBottom: 10 }} /> */}
      {/* <div style={{ marginTop: 10, marginBottom: 10, fontWeight: 'bold' }}>Default Questionnaire: </div>
      <div style={style.simpleRow}>
        <div style={{ ...style.simpleColumn, width: '50%', marginRight: 5 }}>
          <Button
            disabled={isUpdating || !allowPreScreen}
            color={allowPreScreen && selectedSeason.EnablePrescreenMode == 1 ? 'success' : 'secondary'}
            onClick={() => {
              handleChange(selectedSeason.index, 'EnablePrescreenMode', selectedSeason, 1);
            }}
          >
            <div style={style.spacedBetweenRow}>
              <div style={{ marginRight: 5 }}>
                <FontAwesomeIcon
                  icon={allowPreScreen && selectedSeason.EnablePrescreenMode == 1 ? faCheckSquare : faSquare}
                />
              </div>
              <div>Prescreen</div>
            </div>
          </Button>
        </div>
        <div style={{ ...style.simpleColumn, width: '50%', marginLeft: 5 }}>
          <Button
            disabled={isUpdating}
            color={allowPreScreen && selectedSeason.EnablePrescreenMode == 1 ? 'secondary' : 'success'}
            onClick={() => {
              handleChange(selectedSeason.index, 'EnablePrescreenMode', selectedSeason, 0);
            }}
          >
            <div style={style.spacedBetweenRow}>
              <div style={{ marginRight: 5 }}>
                <FontAwesomeIcon
                  icon={allowPreScreen && selectedSeason.EnablePrescreenMode == 1 ? faSquare : faCheckSquare}
                />
              </div>
              <div>Evaluation</div>
            </div>
          </Button>
        </div>
      </div> */}
    </div>
  );
};

export default SeasonPopover;
