import React, { useState, useEffect, useContext } from 'react';
import { faPlusCircle, faSearch, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table,
} from 'reactstrap';
import style from './Tags.style';

// import FocustItems from './FocusItems/FocustItems';
import { clone } from '../../../../../Services/schedule';
import TagItems from './TagItems/TagItems';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from 'DepartmentWrapper';

const Tags = ({ allowEdit = false }) => {
  const [newTag, setNewTag] = useState({ name: '' }); // DocumentTypeName, UIIcon
  const [nameToSearch, setNameToSearch] = useState('');
  const [tags, setTags] = useState({});
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    getTags();
  }, []);

  const getTags = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/tags',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((results) => {
            setTags(results.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const filterTags = (tags) => {
    const newFilters = { ...tags };
    Object.keys(tags).forEach((key) => {
      const tag = tags[key];
      if (tag.Tag.toLowerCase().indexOf(nameToSearch.toLowerCase()) < 0) {
        delete newFilters[key];
      }
    });
    return newFilters;
  };

  const addNewTag = () => {
    const tagTrimmed = newTag.name.trim();
    if (tagTrimmed.length === 0) return;

    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/tag',
          { pk_Department: dContext.department.pk_Department },
          { Tag: tagTrimmed },
          formatBearerToken(token),
        ).then((results) => {
          getTags();
          setNewTag({ name: '' });
        });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateTags = (tag) => {
    getTokenSilently()
      .then((token) => {
        putData(
          'department/tag',
          { pk_Department: dContext.department.pk_Department, pk_Tag: tag.pk_Tag },
          { Tag: tag.Tag },
          formatBearerToken(token),
        )
          .then((results) => {})
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onSave = (tag) => {
    const newTags = JSON.parse(JSON.stringify(tags));
    newTags[tag.index] = tag;
    setTags(newTags);
    updateTags(tag);
  };

  const deleteDatabaseTag = (pk_Tag) => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/tag',
          { pk_Department: dContext.department.pk_Department, pk_Tag: pk_Tag },
          null,
          formatBearerToken(token),
        )
          .then((result) => {})
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onDeleteTag = (id) => {
    const newTags = tags.filter((tag, i) => {
      return tag.pk_Tag !== id;
    });
    deleteDatabaseTag(id);
    setTags(newTags);
  };

  const filteredTags = filterTags(tags);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <div style={style.mainContainer}>
        <Card style={{ width: '60%', height: '60vh' }}>
          <CardHeader>
            <div style={style.centeredText}>
              <b>Tags</b>
            </div>
          </CardHeader>
          <CardBody>
            <InputGroup>
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon
                    icon={faTimes}
                    style={{ ...style.clickable }}
                    onClick={() => {
                      setNameToSearch('');
                    }}
                  />
                </InputGroupText>
              </InputGroupAddon>

              <Input
                value={nameToSearch}
                type="text"
                placeholder="Search Tag. . ."
                onChange={(e) => {
                  setNameToSearch(e.target.value);
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginRight: '20px',
              }}
            >
              <div
                style={{
                  alignSelf: 'flex-end',
                  padding: '5px',
                  verticalAlign: 'bottom',
                  width: '45px',
                }}
              >
                <strong> Tags</strong>
              </div>
              <div clasnName="editAndTagWrappper" style={{ display: 'flex', flexDirection: 'row' }}>
                <div
                  style={{
                    alignSelf: 'flex-end',
                    marginLeft: '3px',
                    padding: '5px',
                    textAlign: 'center',
                    verticalAlign: 'bottom',
                    width: '45px',
                  }}
                >
                  <strong>Edit</strong>
                </div>
                <div
                  style={{
                    alignSelf: 'flex-end',
                    marginLeft: '3px',
                    padding: '5px',
                    textAlign: 'center',
                    verticalAlign: 'bottom',
                    width: '60px',
                  }}
                >
                  <strong>Delete </strong>
                </div>
              </div>
            </div>
            <div style={{ overflowY: 'scroll', height: '30vh' }}>
              {Object.keys(filteredTags).map((key, i) => {
                const tag = tags[key];
                return <TagItems key={key} tag={tag} index={i} onDeleteTag={onDeleteTag} onSave={onSave} />;
              })}
            </div>
            <div style={style.inputAndAddButtonContainer}>
              <div style={style.manageFieldsNewName}>
                <Input
                  style={{ textAlign: 'left' }}
                  placeholder="Add Tag. . ."
                  value={newTag.name}
                  onChange={(e) => {
                    const newName = e.target.value;
                    setNewTag((newTag) => {
                      const newNewTag = clone(newTag);
                      return { ...newNewTag, name: newName };
                    });
                  }}
                  maxLength="20"
                />
              </div>
              <div>
                <Button
                  color="success"
                  onClick={() => {
                    addNewTag();
                  }}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Tags;
