import _ from 'lodash';
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { NotificationManager } from 'react-notifications';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, informUserEvalLocked } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { deleteData, postDataAgnostic, putData } from '../../../../Services/dataApi';
import BinaryQuestion from './BinaryQuestion';
import LikertQuestion from './LikertQuestion';
import TextQuestion from './TextQuestion';

let timeoutTime = 10000;

var timeoutHandle;

var MOUSE_MOVE_THRESHOLD = 1000,
  lastMouseMoveTime = -100;

const TimeoutOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  button {
    position: fixed;
  }
`;

const Evaluation = ({
  activeTab,
  candidateEvaluation,
  candidateEvaluationStatus,
  candidateInfo,
  candidateSortTypes,
  checkIfStatusNeedsChangingToModify,
  currentCandidateSortType,
  currentlyGettingEval,
  DNRStatus,
  evalStatus,
  evalTab,
  getCandidateEvaluation,
  getCandidateStatus,
  getInfo,
  isAdmin,
  pk_User,
  reduxDate,
  schedule,
  selectedCandidateId,
  selectedCandidate,
  setCandidateEvaluation,
  setCandidateEvaluationStatus,
  setDisableScreen,
  setDNRStatus,
  setEvalStatus,
  Status4SelectedDNR,
  timeoutDurationInMinutes,
  toggleToSchedule,
  toggleToPreviousTab,
  token,
  typeOfEvent,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [showPreventionOverlay, setShowPreventionOverlay] = useState(false);
  const [AnswersAnswerNumberScore, setAnswersAnswerNumberScore] = useState(null);
  const [questionsNeedingAnswers, setQuestionsNeedingAnswers] = useState({});
  const [count, setCount] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [questionaireDisabled, setQuestionaireDisabled] = useState(false);
  const [submitQuestions, setSubmitQuestions] = useState(false);
  const [promisesToResolve, setPromisesToResolve] = useState({});
  const [promisesResolving, setPromisesResolving] = useState(false);
  let questionnaireWidth = '100%';
  const activeTabRef = useRef(activeTab);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const alert = useAlert();
  const dContext = useContext(DepartmentContext);

  const { season } = dContext;
  const { EnableEvaluationQuestionnaire, EnablePrescreenQuestionnaire, EnablePrescreenVisibleToAllEvaluators } = season;

  const mode = reduxDate ? (reduxDate.pk_InterviewDate === 'Prescreen' ? 'Prescreen' : 'Evaluation') : null;

  useEffect(() => {
    if (candidateEvaluationStatus === 'Complete' || candidateEvaluationStatus === 'Unable To Evaluate') {
      setShowPreventionOverlay(true);
    } else {
      setShowPreventionOverlay(false);
    }
  }, [candidateEvaluationStatus]);

  useEffect(() => {
    if (mode === 'Prescreen' && !EnablePrescreenQuestionnaire) {
      setQuestionaireDisabled(true);
      return;
    }
    if (mode === 'Evaluation' && !EnableEvaluationQuestionnaire) {
      setQuestionaireDisabled(true);
      return;
    }
    setQuestionaireDisabled(false);
  }, [mode, EnableEvaluationQuestionnaire, EnablePrescreenQuestionnaire]);

  // statechange (overriding) orange
  // modification blue
  // creation green
  // destruction red

  // continue evaluation - blue
  // begin - blue
  // modify evlaution - orange
  // zoom - green
  // submit - green
  // unable to evaluate - orange

  // fluid container - darker color

  // no bottom border
  // no schedule or candidates returned - "No Items available for this date"
  // spelled out in date picker

  // change style of document viewer - change icon

  useEffect(() => {
    setDNRStatus(Status4SelectedDNR);
  }, [Status4SelectedDNR]);

  useEffect(() => {
    let readyToSubmit = true;
    for (const property in promisesToResolve) {
      if (promisesToResolve[property] == true) readyToSubmit = false;
    }
    if (readyToSubmit == false && promisesResolving === false) {
      setPromisesResolving(true);
    }

    if (readyToSubmit == true && promisesResolving === true) {
      setPromisesResolving(false);
    }
  }, [promisesToResolve]);

  const submitForm = () => {
    const allRequiredQuestionsAnswered = checkForRequiredAnswers(candidateEvaluation.evaluation[0].Questions);

    const changeEvalStatusToSubmitted = () => {
      changeEvalStatus('Complete', null)
        .then((res) => {
          if (res !== false) {
            const { Status4SelectedStatus } = res;
            const { FirstName, LastName } = selectedCandidate;
            alert.success(`${FirstName} ${LastName}'s evaluation has been submitted`);
            // Swal.fire('Submitted!', `${FirstName} ${LastName}'s evaluation has been submitted`);
            getInfo(true);
            toggleToPreviousTab();
          } else {
            informUserEvalLocked();
          }
        })
        .catch((err) => {
          console.log('err: ', err);

          // alert.error('Evaluation submission failed!');
          Swal.fire('Error', 'Something went wrong...', 'error');
        })
        .finally(() => {
          setDisableScreen(false);
          getInfo(true, true, false, null, null, true);
          setSubmitQuestions(false);
        });
    };

    if (!allRequiredQuestionsAnswered && DNRStatus !== true) {
      Swal.fire(
        'Error',
        `<div>Please answer all required questions (<span class="color_red">*</span>).</div>`,
        'error',
      );
      setDisableScreen(false);
    } else {
      if (DNRStatus === true && !allRequiredQuestionsAnswered) {
        Swal.fire({
          title: 'Warning',
          text: `You have unanswered questions, are you sure you want to submit an incomplete evaluation?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: 'rgb(48, 133, 214)',
          confirmButtonText: `Submit`,
          cancelButtonColor: 'rgb(221, 51, 51)',
        }).then((result) => {
          if (result.value) {
            changeEvalStatusToSubmitted();

            // setQuestionScale(questionScaleKeys.likert5);
            // saveScaleType(questionScaleKeys.likert5);
            // setHasChange(true);
          }
          setDisableScreen(false);
        });
      } else {
        changeEvalStatusToSubmitted();
      }
    }
  };

  const setEvaluationStatusToInProgress = () => {
    const inProgressStatus = 'In Progress';

    setCandidateEvaluationStatus((prevState) => {
      // if the status is already set to in progress don't do anything
      if (prevState.Status === inProgressStatus) return prevState;

      let prevStateCopy = _.cloneDeep(prevState);
      prevStateCopy.Status = inProgressStatus;
      return prevStateCopy;
    });
  };

  const updateEval = (DisplayValue, pk_EvaluationQuestion, type, callback) => {
    getTokenSilently()
      .then(async (token) => {
        // if Display value is null delete the existing answer
        if (!DisplayValue) {
          await deleteData(
            'department/season/candidate/answer',
            {
              DisplayValue,
              pk_EvaluationQuestion,
              pk_Department: dContext.department.pk_Department,
              pk_Season: dContext.season.pk_Season,
              pk_EvaluationStatus: candidateEvaluation.Status.pk_EvaluationStatus,
              type,
            },
            null,
            formatBearerToken(token),
          )
            .then((result) => {
              const { pk_EvaluationQuestion } = result.data;
              setEvaluationStatusToInProgress();
              // need to update object
              setCandidateEvaluation((prevState) => {
                const prevStateCopy = _.cloneDeep(prevState);
                prevStateCopy.evaluation[0].Questions.forEach((item) => {
                  if (item.pk_EvaluationQuestion === Number(pk_EvaluationQuestion)) {
                    item.AnswerText = null;
                    item.AnswerNumber = null;
                  }
                });
                return prevStateCopy;
              });
            })
            .catch((e) => {
              if (e.message.indexOf('403') >= 0) {
                //TODO: ONLY ALERT IF 403, not 400

                alert.error('User does not have permission to update this evaluation');
              }

              if (callback) {
                callback({ error: true });
              }
            });
        } else {
          await postDataAgnostic(
            'department/season/candidate/answer',
            {
              DisplayValue,
              pk_EvaluationQuestion,
              pk_Department: dContext.department.pk_Department,
              pk_Season: dContext.season.pk_Season,
              pk_EvaluationStatus: candidateEvaluation.Status.pk_EvaluationStatus,
              type,
            },
            null,
            formatBearerToken(token),
          )
            .then((result) => {
              console.log('result: ', result);
              if (result.error) {
                NotificationManager.error('Error!', 'Unable to save');
                // TODO: ONLY ALERT IF 403, not 400
                if (result.message && result.message.indexOf('403') >= 0) {
                  alert.error('User does not have permission to update this evaluation');
                }
                if (callback) {
                  callback({ error: true });
                }
                return;
              }
              const {
                AnswerNumber,
                AnswerText,
                AnswerTypeNumber1OrText2,
                fk_EvaluationQuestion,
                pk_EvaluationAnswer,
              } = result.data;
              setEvaluationStatusToInProgress();
              // NotificationManager.success('Success!', 'Answer Saved');
              // need to update the object
              setCandidateEvaluation((prevState) => {
                const prevStateCopy = _.cloneDeep(prevState);
                prevStateCopy.evaluation[0].Questions.forEach((item) => {
                  if (item.pk_EvaluationQuestion === fk_EvaluationQuestion) {
                    if (AnswerTypeNumber1OrText2 === 1) {
                      item.AnswerNumber = AnswerNumber;
                    } else {
                      item.AnswerText = AnswerText;
                    }
                    item.pk_EvaluationAnswer = pk_EvaluationAnswer;
                    item.AnswerTypeNumber1OrText2 = AnswerTypeNumber1OrText2;
                  }
                });
                return prevStateCopy;
              });
            })

            .catch((e) => {
              NotificationManager.error('Error!', 'Unable to save');
              // TODO: ONLY ALERT IF 403, not 400
              if (e.message && e.message.indexOf('403') >= 0) {
                alert.error('User does not have permission to update this evaluation');
              }
              if (callback) {
                callback({ error: true });
              }
            });
        }
        setIsTyping(false);
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });

    // setPromisesToResolve((prevState) => {
    //   const promisesToResolveCopy = cloneDeep(prevState);
    //   promisesToResolveCopy[answerid] = true;
    //   return promisesToResolveCopy;
    // });
    // // return;
    // const formData = new FormData();
    // formData.append('evaluation', 1);
    // formData.append('candidateid', selectedCandidateId);
    // // If user is evaluating candidates through the prescreen make sure
    // // to mark the mode as prescreen when they change answers
    // formData.append('mode', date === 'Prescreen' ? 'Prescreen' : 'Evaluation');
    // formData.append('answervalue', answervalue);
    // formData.append('answerid', answerid);
    // const url = determineUrl(token);
    // post(url, formData)
    //   .then((res) => {
    //     if (res.data) setCandidateEvaluation(res.data);
    //   })
    //   .catch((err) => {})
    //   .finally(() => {
    //     setIsTyping(false);
    //     setPromisesToResolve((prevState) => {
    //       const promisesToResolveCopy = cloneDeep(prevState);
    //       promisesToResolveCopy[answerid] = false;
    //       return promisesToResolveCopy;
    //     });
    //   });
  };

  const changeEvalStatus = (status, enableDoNotRank) => {
    console.log('selectedCandidateId: ', selectedCandidateId);

    if (!selectedCandidateId) return Promise.reject(new Error('No selected candidate ID'));
    setDisableScreen(true);
    return getTokenSilently(token)
      .then((token) => {
        setDisableButton(false);
        return putData(
          'department/season/candidate/evaluation/status',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_User,
            enableDoNotRank,
            selectedCandidateId: selectedCandidateId,
            isAdmin,
            status,
            mode,
          },
          null,
          formatBearerToken(token),
        );
      })
      .then((result) => {
        if (result.data && result.data.locked && result.data.locked === true) {
          return false;
        } else {
          getTokenSilently(token)
            .then((token) => {
              getCandidateStatus(selectedCandidateId, token);
            })
            .catch((err) => {
              if (err.message === 'Login required') {
                loginWithRedirect();
              }
            });
          return result;
        }
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
        return false;
      })
      .finally(() => {
        setDisableScreen(false);
      });
  };

  const handleMouseMove = useCallback((event) => {
    if (activeTabRef.current === evalTab) {
      var now = +new Date();
      if (now - lastMouseMoveTime < MOUSE_MOVE_THRESHOLD) return;
      lastMouseMoveTime = now;

      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function() {
        setShowOverlay(true);
      }, timeoutDurationInMinutes * 60000);
    }
  }, []);

  const checkForRequiredAnswers = (Questions) => {
    setQuestionsNeedingAnswers({});
    let allRequiredAnswered = true;
    const questionsNeedToAnswer = {};
    Questions.forEach((question, i) => {
      const { AnswerNumber, AnswerText, AnswerTypeNumber1OrText2, EnableRequired, pk_EvaluationQuestion } = question;
      // check to see if question has any answer in them for all types
      let isRequiredAndHasValue = true;
      if (EnableRequired) {
        if (AnswerTypeNumber1OrText2 === 1) {
          if (AnswerNumber === null || AnswerNumber === undefined) {
            isRequiredAndHasValue = false;
          }
        } else {
          if (AnswerText === null || AnswerText === undefined || (AnswerText && AnswerText.trim() === '')) {
            isRequiredAndHasValue = false;
          }
        }
      }
      if (!isRequiredAndHasValue) {
        questionsNeedToAnswer[pk_EvaluationQuestion] = true;
        allRequiredAnswered = false;
      }
    });
    setQuestionsNeedingAnswers(questionsNeedToAnswer);
    return allRequiredAnswered;
  };

  // check for any answers. This will be necessary if the user wants to wipe the evaluation for any reason
  const checkForAnyAnswer = (questions) => {
    let hasAnswers = false;
    questions.forEach((question) => {
      if (question.AnswerTypeNumber1OrText2 === 1 && question.AnswerNumber !== null) {
        hasAnswers = true;
      } else if (question.AnswerText != null || (question.AnswerText && question.AnswerText.trim() !== '')) {
        hasAnswers = true;
      }
    });
    return hasAnswers;
  };

  const handleTouchStart = useCallback((event) => {
    if (activeTabRef.current === evalTab) {
      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function() {
        setShowOverlay(true);
      }, timeoutDurationInMinutes * 60000);
    }
  }, []);

  useEffect(() => {
    activeTabRef.current = activeTab;
    // if user is on eval tab start timeout
    if (activeTab === evalTab) {
      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function() {
        setShowOverlay(true);
      }, timeoutTime);
      return;
    }
    window.clearTimeout(timeoutHandle);
  }, [activeTab]);

  const updateAnswersInDatabase = (index) => {
    //candidateEvaluation
  };

  useEffect(() => {
    if (typeOfEvent === 'mousemove') {
      window.addEventListener(typeOfEvent, handleMouseMove);

      return () => {
        window.removeEventListener(typeOfEvent, handleMouseMove);
      };
    }
    if (typeOfEvent === 'touchstart') {
      window.addEventListener(typeOfEvent, handleTouchStart);

      return () => {
        window.removeEventListener(typeOfEvent, handleTouchStart);
      };
    }
  }, [handleMouseMove, handleTouchStart]);

  const handleEvalChange = () => {
    changeEvalStatus('UnableToEvaluate', null)
      .then((res) => {
        if (res !== false) {
          const { FirstName, LastName } = selectedCandidate;
          alert.success(`${FirstName} ${LastName}'s evaluation has been submitted`);
          // Swal.fire('Submitted!', `${FirstName} ${LastName}'s evaluation has been submitted`);
          getInfo(true, true, true);
          toggleToPreviousTab();
        } else {
          informUserEvalLocked();
        }
        setDisableScreen(false);
      })
      .catch((err) => {
        setDisableScreen(false);
        Swal.fire('Error', 'Something went wrong...', 'error');
      });
  };

  const alertUserDisabled = () => {
    Swal.fire(
      'Questionnaire is Disabled',
      'Your Coordinator can enable: Settings → Evaluation → Evaluation Questionnaire → Enable for Evaluators.',
      'warning',
    );
  };

  const alertUserForChange = () => {
    Swal.fire({
      title: 'Do you want to modify this evaluation?',
      text: `Status will be set to "In progress."`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(48, 133, 214)',
      confirmButtonText: `Modify`,
      cancelButtonColor: 'rgb(221, 51, 51)',
    }).then((result) => {
      if (result.value) {
        setShowPreventionOverlay(false);
        changeEvalStatus('InProgress', false).then((result) => {
          if (result === false) {
            informUserEvalLocked();
          }
        });
        // setQuestionScale(questionScaleKeys.likert5);
        // saveScaleType(questionScaleKeys.likert5);
        // setHasChange(true);
      }
    });
  };

  const addPrescreenComments = (question) => {
    if (question.PrescreenCombinedComments !== null && mode === 'Evaluation' && EnablePrescreenVisibleToAllEvaluators) {
      const prescreenComments = question.PrescreenComments || [];
      let loggedInEvaluatorComment = '';

      prescreenComments.sort((a, b) => {
        const nameA = a.EvaluatorName.toUpperCase();
        const nameB = b.EvaluatorName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });

      let concatenatedPrescreenComment = loggedInEvaluatorComment;

      for (const comment of prescreenComments) {
        if (comment['AnswerText'].search('bold') === -1) concatenatedPrescreenComment += comment.AnswerText;
        else concatenatedPrescreenComment = comment.AnswerText + concatenatedPrescreenComment;
      }

      return (
        <>
          <h6 style={{ fontSize: '14px', fontWeight: 'bold' }}>Prescreen Comments:</h6>
          <div dangerouslySetInnerHTML={{ __html: concatenatedPrescreenComment || '---' }}></div>
        </>
      );
    } else if (question.UserPrescreenComment && mode === 'Evaluation') {
      return (
        <>
          <span style={{ fontWeight: 'bold', marginTop: 5 }}>Your Prescreen Comment: </span>
          <span>{question.UserPrescreenComment || '---'} </span>
        </>
      );
    }
  };

  const addPrescreenAvg = (question) => {
    if (question.PrescreenAverageScore && mode === 'Evaluation' && EnablePrescreenVisibleToAllEvaluators) {
      return (
        <>
          <span style={{ fontWeight: 'bold', marginTop: 5 }}>Prescreen Average Score:</span>{' '}
          <span>{question.PrescreenAverageScore} </span>
          {question.UserPrescreenScore ? (
            <>
              <br />
              <span style={{ marginTop: 4 }}>Your Prescreen Score: </span>
              <span>{question.UserPrescreenScore} </span>
            </>
          ) : null}
        </>
      );
    } else if (question.UserPrescreenScore && mode === 'Evaluation') {
      return (
        <>
          <span style={{ fontWeight: 'bold', marginTop: 5 }}>Your Prescreen Score: </span>
          <span>{question.UserPrescreenScore} </span>
        </>
      );
    }
  };

  const determineQuestion = (question, i, questionOptions) => {
    const { CustomQuestionOptions } = question;
    const { pk_EvaluationQuestion } = question;
    const { QuestionType } = question;

    // if (CustomQuestionOptions) {
    //   questionnaireWidth = 'calc(100vw - 60%)';
    // }

    const flagAsUnanswered = questionsNeedingAnswers[pk_EvaluationQuestion];
    const alternatingColor = i % 2 ? '#f2f2f2' : '#fff';
    const backgroundColor = flagAsUnanswered ? '#ffe5e5' : alternatingColor;

    let type = '';

    const questionTypeCapitalized = QuestionType.toUpperCase();

    //at one point type was defined numerically but got changed to string.
    if (QuestionType === '0' || questionTypeCapitalized === 'TEXT') {
      type = 'text';
    }

    if (QuestionType === '1' || questionTypeCapitalized === 'SCALE') {
      type = 'scale';
    }

    if (QuestionType === '2' || questionTypeCapitalized === 'YES/NO') {
      type = 'yes/no';
    }

    if (type === 'scale') {
      return (
        <div
          key={question.recordId}
          style={{ backgroundColor: backgroundColor, padding: '20px', width: questionnaireWidth }}
        >
          <LikertQuestion
            question={question}
            questionOptions={CustomQuestionOptions || questionOptions}
            i={i}
            updateEval={updateEval}
            updateAnswersInDatabase={updateAnswersInDatabase}
            Answers4QuestionsAnswerNumberScore={question.Answers4QuestionsAnswerNumberScore}
            checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
            questionaireDisabled={questionaireDisabled}
          />
          {addPrescreenAvg(question)}
        </div>
      );
    }

    if (type === 'yes/no') {
      return (
        <div
          key={question.recordId}
          style={{ backgroundColor: backgroundColor, padding: '20px', width: questionnaireWidth }}
        >
          <BinaryQuestion
            question={question}
            i={i}
            updateAnswersInDatabase={updateAnswersInDatabase}
            updateEval={updateEval}
            checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
            questionaireDisabled={questionaireDisabled}
          />
        </div>
      );
    }

    if (type === 'text') {
      return (
        <div
          key={question.recordId}
          style={{ backgroundColor: backgroundColor, padding: '20px', width: questionnaireWidth }}
        >
          <TextQuestion
            questionaireDisabled={questionaireDisabled}
            question={question}
            updateAnswersInDatabase={updateAnswersInDatabase}
            setIsTyping={setIsTyping}
            i={i}
            updateEval={updateEval}
            answerText={question.AnswerText}
            checkIfStatusNeedsChangingToModify={checkIfStatusNeedsChangingToModify}
          />
          {addPrescreenComments(question)}
        </div>
      );
    }

    return <div></div>;
  };

  if (!candidateEvaluation) return <div />;

  if (candidateEvaluation === 'error') return <div />;

  return (
    <Fragment>
      <div style={{ padding: '10px', width: '100%', height: 'calc(100vh - 500px)', overflow: 'auto' }}>
        {(showPreventionOverlay || questionaireDisabled) && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (questionaireDisabled) {
                alertUserDisabled();
              } else {
                alertUserForChange();
              }
            }}
            style={{
              height: '100%',
              width: 'calc(100% - 20px)',
              position: 'absolute',
              cursor: 'pointer',
              top: '0',
              zIndex: '1',
            }}
          ></div>
        )}
        {candidateEvaluation && candidateEvaluation.evaluation && candidateEvaluation.evaluation.length > 0 ? (
          candidateEvaluation.evaluation.map((questionObject) => {
            return questionObject.Questions.map((question, i) => {
              return determineQuestion(question, i, candidateEvaluation.questionOptions);
            });
          })
        ) : (
          <div
            style={{
              height: '40vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
            }}
          >
            No Evaluation is available
          </div>
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around', padding: 15 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <label className="switch">
            <input
              tabIndex={questionaireDisabled ? -1 : 0}
              type="checkbox"
              id={`DNR_${selectedCandidateId}`}
              disabled={disableButton}
              checked={DNRStatus || false}
              onClick={async () => {
                const continueWorkflow = await checkIfStatusNeedsChangingToModify();
                console.log('continueWorkflow: ', continueWorkflow);
                console.log('DNRStatus: ', DNRStatus);
                if (!continueWorkflow) {
                  return;
                }
                setDisableButton(true);

                if (DNRStatus) {
                  setDNRStatus(false);
                  changeEvalStatus(null, false).then((result) => {
                    if (result === false) {
                      informUserEvalLocked();
                      setDNRStatus(true);
                    }
                  });
                } else {
                  setDNRStatus(true);
                  changeEvalStatus(null, true).then((result) => {
                    if (result === false) {
                      informUserEvalLocked();
                      setDNRStatus(false);
                    }
                  });
                }
              }}
            />
            <span className="slider round"></span>
          </label>
          Do Not Rank
        </div>
        <div
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <Button
            tabIndex={questionaireDisabled ? -1 : 0}
            disabled={questionaireDisabled || promisesResolving || isTyping}
            onClick={async (e) => {
              const continueWorkflow = await checkIfStatusNeedsChangingToModify('Unable To Evaluate');
              if (continueWorkflow === false) {
                return;
              }

              const hasAnswers = checkForAnyAnswer(candidateEvaluation.evaluation[0].Questions);
              e.preventDefault();
              if (hasAnswers) {
                const { FirstName, LastName } = selectedCandidate;
                return Swal.fire({
                  title: 'Warning',
                  text: `This will delete any feedback you have already entered for ${FirstName ||
                    'Candidate'} ${LastName || ''}.`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Discard Evaluation',
                }).then((result) => {
                  if (result.value === true) {
                    setDisableScreen(true);
                    handleEvalChange();
                  }
                });
              } else {
                setDisableScreen(true);
                handleEvalChange();
              }
            }}
          >
            Unable To Evaluate
          </Button>
        </div>
        <div
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <p style={{ margin: 0, padding: 0 }}>
            Questions marked with "<span style={{ color: '#ff0000' }}>*</span>" are required
          </p>
        </div>
        <div
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
        >
          <Button
            tabIndex={questionaireDisabled ? -1 : 0}
            disabled={promisesResolving || isTyping || questionaireDisabled}
            onClick={async (e) => {
              e.preventDefault();
              const continueWorkflow = await checkIfStatusNeedsChangingToModify();

              if (continueWorkflow) {
                setDisableScreen(true);
                submitForm();
              }
            }}
          >
            {promisesResolving || isTyping ? 'Saving...' : 'Submit'}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Evaluation;
