import {
  faChartBar,
  faListOl,
  faUser,
  faFilter,
  faHistory,
  faUserAltSlash,
  faCog,
  faTimesCircle,
  faGrip,
  faDownload,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import {
  applyCSVProtection,
  formatBearerToken,
  roundToDecimalPlace,
  scrollToElement,
  splitArray,
  swalErrorMessage,
} from '../../../../Common.functions';
import { PopupWithCloseButton } from '../../../../Common.jsx';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { fetchDataAgnostic, putData } from '../../../../Services/dataApi';
import AdminRankDetails from './AdminRankDetails/AdminRankDetails';
import AdminRankHistory from './AdminRankHistory';
import AdminRankList from './AdminRankList/AdminRankList';
import AdminRankRemovedCandidates from './AdminRankRemovedCandidates/AdminRankRemovedCandidates';
import AdminRanksSort from './AdminRanks/AdminRanksSort';
import AdminRankSettings from './AdminRankSettings/AdminRankSettings';
import style from './AdminRankHistoryLanding.style';
import {
  Button,
  ButtonGroup,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import { CSVLink } from 'react-csv';
import { useAlert } from 'react-alert';

import './AdminRankHistoryLanding.style.css';
import TagFilter from './TagFilter/TagFilter';
import { separateDataTypesByAvailability } from '../../../../Services/candidates';
import AdminRankChart from './AdminRankChart/AdminRankChart';
import { createPDFForRankList } from '../../../../Services/PDFMakeTemplates/Rank/RankList';
import { useHistory } from 'react-router-dom';
import { RouterPrompt } from 'Body/RezRATE/Common/RouterPrompt/RouterPrompt';
import { clone } from 'lodash';
import Loading from 'Body/Statuses/Loading';
import moment from 'moment';

const defaultModes = {
  evaluation: 1,
  prescreen: 2,
};

const determineMode = (nodeAsInt) => {
  return nodeAsInt === defaultModes.prescreen ? 'prescreen' : 'evaluation';
};

const AdminRankHistoryLanding = () => {
  const [candidates, setCandidates] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showTagFilter, setShowTagFilter] = useState();
  const [selectedTagFilter, setSelectedTagFilter] = useState();
  const [dataTypes, setDataTypes] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState();
  const [candidateRanks, setCandidateRanks] = useState([]);
  const [removedCandidates, setRemovedCandidates] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [detailNotesHaveChange, setDetailNotesHaveChange] = useState();

  const [mode, setMode] = useState(null);
  const [openRankSettings, setOpenRankSettings] = useState(false);

  const [sliderValue, setSliderValue] = useState(2);
  const [openRemovedCandidatesList, setOpenRemovedCandidatesList] = useState(false);
  const [openRankHistory, setOpenRankHistory] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  const [isUpdatingSeason, setIsUpdatingSeason] = useState(false);
  const [isFetchingCandidates, setIsFetchingCandidates] = useState(false);
  const [isGettingCandidateDetails, setIsGettingCandidateDetails] = useState(false);
  const [isShowingChartPrinterFriendlyVersion, setIsShowingChartPrinterFriendlyVersion] = useState(false);

  const [selectedSeason, setSelectedSeason] = useState(null);
  const [lastJumpTo, setLastJumpTo] = useState(1);
  const [viewMode, setviewMode] = useState('List'); //'List', 'Grid'
  const dContext = useContext(DepartmentContext);

  const csvDownload = useRef();

  const selectedCandidateRef = useRef(null);
  const history = useHistory();
  const alert = useAlert();
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    window.addEventListener('keyup', (event) => {
      if (event.key !== 'Enter' || (activeTab != 1 && candidateRanks.length <= 0)) {
        return;
      }
      const jump_btn = document.getElementById('jump_btn');
      const activeElement = document.activeElemen;
      if (jump_btn && activeElement && activeElement.id === 'jump_to_field') {
        jump_btn.click();
      }

      // const scrollToCandidate = candidateRanks[jump_to_field.value - 1];
    });
  }, []);

  useEffect(() => {
    if (mode) {
      const typeToFetch = mode === defaultModes.evaluation ? 'evaluation' : 'prescreen';
      getActiveCandidates(typeToFetch);
    }
  }, [selectedTagFilter, mode]);

  useEffect(() => {
    if (candidates) {
      sortByRankPosition(candidates);
    }
  }, [candidates]);

  useEffect(() => {
    if (dContext && dContext.season) {
      getDepartmentDataTypes();
      if (dContext.season.EnablePrescreenMode && dContext.season.AllowPrescreen) {
        setMode(defaultModes.prescreen);
      } else {
        setMode(defaultModes.evaluation);
      }
      setIsLoading(false);
      setSelectedSeason(dContext.season);
    }
  }, [JSON.stringify(dContext)]);

  useEffect(() => {
    if (history.location.pathname.indexOf('/administrator/rank/chart_print_preview') >= 0) {
      setIsShowingChartPrinterFriendlyVersion(true);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    selectedCandidateRef.current = clone(selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (candidates) {
      processCSVData(candidates);
    }
  }, [candidates]);

  const processCSVData = (newCandidates) => {
    // AAMCID
    // Email
    // SFMatchID
    // NRMPID
    // CellPhone
    // PreferredPhone
    // Tags
    // ProgramSignal
    // InterviewDate
    // InterviewLabel(Coming back from API as “Label” here already)
    // Final Score (Remember to multiply by 100 and round to 2 decimal places)
    // Note that we have a “CandidateSubHeading” field that contains Medical School of Graduation if it’s a Residency or contains “Last Residency” if it’s a fellowship. so the heading needs to be dynamic based on which program type is in settings and we’ll display that heading for this column.
    // RankPositionBasedOnScore
    // DNR Count (count of how many people marked as DNR – should be an int 0 if none)

    const { department } = dContext;
    const { ProgramType } = department;

    const dataOrder = [
      { label: 'Rank', value: 'EvalPosition' },
      { label: 'AAMC ID', value: 'AAMCID' },
      { label: 'First Name', value: 'FirstName' },
      { label: 'Last Name', value: 'LastName' },
      { label: 'Email Address', value: 'Email' },
      { label: 'SFMatch ID', value: 'SFMatchID' },
      {
        label: ProgramType === 'Residency' ? 'Medical School of Graduation' : 'Residency',
        value: 'CandidateSubHeading',
      },
      { label: 'Tags', value: 'Tags' },
      { label: 'Program Signal', value: 'ProgramSignal' },
      { label: 'Interview Date', value: 'DateOfInterview' },
      { label: 'Interview Label', value: 'Label' },
      { label: 'Final Score', value: 'CandidateFinalScoreAsDecimal' },
      { label: 'Candidate Sub Heading', value: 'CandidateSubHeading' },
      { label: 'Rank Position Based On Score', value: 'RankPositionBasedOnScore' },
      { label: 'DNR Count', value: 'UsersWhoMarkedDoNotRank' },
    ];

    const newCSVData = [
      dataOrder.map((item) => {
        return item.label || '';
      }),
    ];

    newCandidates.forEach((candidate, i) => {
      if (candidate.EnableRemoveFromList) {
        return;
      }

      const newRow = dataOrder.map((item) => {
        if (item.value === 'Tags') {
          return applyCSVProtection(candidate[item.value].join('\n'));
        }

        if (item.value === 'UsersWhoMarkedDoNotRank') {
          return candidate[item.value] ? candidate[item.value].length : 0;
        }

        if (item.value === 'CandidateFinalScoreAsDecimal') {
          return candidate[item.value] ? roundToDecimalPlace(candidate[item.value] * 100, 3) : 0;
        }

        if (item.label === 'Rank') {
          return `${i + 1}\,`;
        }
        return applyCSVProtection(candidate[item.value]) || '';
      });
      newCSVData.push(newRow);
    });

    setCsvData(newCSVData);
  };

  const updateSeason = (newSeason) => {
    const typeForFetch = newSeason.EnablePrescreenMode ? 'prescreen' : 'evaluation';
    const pk_Tag = selectedTagFilter ? selectedTagFilter.pk_Tag : null;

    getActiveCandidates(typeForFetch);
    setIsUpdatingSeason(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { seasonObject: newSeason },
          formatBearerToken(token),
        )
          .then((res) => {
            fetchSeasons();
          })
          .catch((err) => {
            setIsUpdatingSeason(false);
          });
      })
      .catch((err) => {
        setIsUpdatingSeason(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const fetchSeasons = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/seasons',
          {
            pk_Department: dContext.department.pk_Department,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdatingSeason(false);
            if (res.data) {
              const newSeason = res.data.find((s) => {
                return s.pk_Season === dContext.season.pk_Season;
              });
              if (newSeason) {
                setSelectedSeason(newSeason);
                getActiveCandidates(mode === defaultModes.prescreen ? 'prescreen' : 'evaluation');
              }
            }
            // if (res.data && res.data.error == null) {
            //   setSeasons(res.data);
            // }
          })
          .catch((err) => {
            setIsUpdatingSeason(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidateDetails = (candidate, callback) => {
    if (candidate) {
      setIsGettingCandidateDetails(true);
      const { pk_Candidate } = candidate;
      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/candidate',
            { pk_Candidate, pk_Department: dContext.department.pk_Department },
            formatBearerToken(token),
          )
            .then((result) => {
              if (
                selectedCandidateRef.current &&
                selectedCandidateRef.current.pk_Candidate !== candidate.pk_Candidate
              ) {
                return;
              }
              setIsGettingCandidateDetails(false);
              const newCandidateDetails = result.data;
              const newSelectedCandidate = { ...candidate, ...newCandidateDetails }; //
              newSelectedCandidate.DateOfInterview = candidate.DateOfInterview;
              newSelectedCandidate.Tags = candidate.Tags;
              newSelectedCandidate.CandidateFinalScoreAsDecimal = candidate.CandidateFinalScoreAsDecimal;
              setSelectedUser(newSelectedCandidate);
              if (result.data && result.data.error) {
                swalErrorMessage();
                return;
              }
            })
            .catch((err) => {
              console.log('err: ', err);
              setIsGettingCandidateDetails(false);
              swalErrorMessage();
            });
        })
        .catch((err) => {
          setIsGettingCandidateDetails(false);
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    } else {
      setSelectedUser(null);
    }
  };

  const getDepartmentDataTypes = () => {
    // setIsUpdatingDataType(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            // setIsUpdatingDataType(false);
            setDataTypes(
              Object.values(res.data).filter((dataType) => {
                return dataType.Enabled && dataType.DisplayOnRank;
              }),
            );
            // setDataTypesRaw(res.data);
          })
          .catch((err) => {
            // setIsUpdatingDataType(false);
          });
      })
      .catch((err) => {
        // setIsUpdatingDataType(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const sliderChange = (event) => {
    setSliderValue(event.target.value);
  };

  const sortByRankPosition = (candidates) => {
    let candidatesSorted = candidates.sort(function(a, b) {
      return a.EvalPosition - b.EvalPosition;
    });

    const [array1, array2] = splitArray(candidatesSorted, 'EnableRemoveFromList');
    setRemovedCandidates(array1);
    setCandidateRanks(array2);

    if (selectedUser && selectedUser.pk_Candidate) {
      const newSelectedUser = array2.find((c) => {
        return c.pk_Candidate === selectedUser.pk_Candidate;
      });
      if (newSelectedUser) {
        setSelectedUser(newSelectedUser);
        getCandidateDetails(newSelectedUser);
      } else {
        setSelectedUser(array2[0]);
        getCandidateDetails(array2[0]);
      }
    } else {
      if (array2.length > 0) {
        setLastJumpTo(1);
      }
      setSelectedUser(array2[0]);
      getCandidateDetails(array2[0]);
      if (array2[0]) {
        scrollToElement(`row_${array2[0].pk_Candidate}_${2}`);
      }
    }
  };

  const getActiveCandidates = (typeForFetch = null, options) => {
    const { callback } = options || {};

    setIsFetchingCandidates(true);
    const pk_Tag = selectedTagFilter ? selectedTagFilter.pk_Tag : null;
    const type = typeForFetch ? typeForFetch : dContext.season.EnablePrescreenMode ? 'prescreen' : 'evaluation';

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/candidates/ranklist',
          {
            pk_Season: dContext.season.pk_Season,
            pk_Department: dContext.department.pk_Department,
            type,
            pk_Tag,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsFetchingCandidates(false);
            if (result.data && result.data.error) {
              // setError(true);
            } else {
              if (callback) {
                callback(result.data);
              }
              setCandidates(result.data);
            }
          })
          .catch((err) => {
            Swal.fire('Error', 'Error on fetching rank list!', 'error');

            setCandidates([]);
            setIsFetchingCandidates(false);
            // setError(true);
          })
          .finally(() => {
            setIsFetchingCandidates(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const findDifferences = (newOrder, oldOrder, originalIndex, newIndex) => {
    // need to record the position before stripping out the others
    const mappedWithRankValue = newOrder.map((item, i) => {
      const { pk_Candidate, CandidateConsideration } = item;
      return {
        EvalPosition: i + 1,
        pk_Candidate: pk_Candidate,
        CandidateConsideration: CandidateConsideration || null,
      };
    });
    return mappedWithRankValue;

    // TODO: restrict array to only items needing update

    // if the pk's don't match the position has changed.  If they don't have a pk_rank yet need to insert them

    return mappedWithRankValue.slice(originalIndex, newIndex + 1);
  };

  const confirmRemoval = () => {
    const { FirstName, LastName } = selectedUser;
    Swal.fire({
      title: 'Warning!',
      text: `Are you sure you want to exclude ${FirstName} ${LastName} from your rank list?`,
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: `Exclude`,
    }).then((result) => {
      if (result.value) {
        removeCandidateFromRank();
      }
    });
  };

  const removeCandidateFromRank = () => {
    const newCandidateRanks = candidateRanks.slice();
    const { pk_Candidate, FirstName, LastName } = selectedUser;
    const selectedUserIndex = newCandidateRanks.findIndex((r) => {
      return r.pk_Rank == selectedUser.pk_Rank;
    });
    if (selectedUserIndex < 0) {
      // ALERT FOR USER NOT FOUND
      return;
    }

    // newCandidateRanks.splice(selectedUserIndex, 1);
    newCandidateRanks[selectedUserIndex].EnableRemoveFromlist = 1;
    const changeMessage = `Excluded ${FirstName} ${LastName} from Rank List `;

    newCandidateRanks.forEach((r, i) => {
      r.EvalPosition = i + 1;
    });

    const { pk_Tag } = selectedTagFilter || {};

    getTokenSilently()
      .then((token) => {
        putData(
          'department/rankList',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_Tag,
          },
          {
            newCandidateRanks,
            mode: determineMode(mode),
            changeMessage,
            pk_Candidate,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success(`Successfully excluded ${FirstName} ${LastName}!`);
          })
          .catch((err) => {
            alert.error(`Failed to exclude ${FirstName} ${LastName}!`);
          })
          .finally(() => {
            getActiveCandidates(mode === defaultModes.prescreen ? 'prescreen' : 'evaluation');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const postChange = (newOrder, oldOrder, changeMessage, pk_Candidate, originalIndex, newIndex, cb) => {
    const { pk_Tag } = selectedTagFilter || {};

    getTokenSilently()
      .then((token) => {
        const newCandidateRanks = findDifferences(newOrder, oldOrder, originalIndex, newIndex);

        putData(
          'department/rankList',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season, pk_Tag },
          {
            newCandidateRanks,
            changeMessage,
            pk_Candidate,
            mode: determineMode(mode),
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Changes saved!');
            getActiveCandidates(mode === defaultModes.prescreen ? 'prescreen' : 'evaluation', { callback: cb });
          })
          .catch((err) => {
            alert.error('Error on saving!');
          });
      })
      .catch((err) => {
        alert.success('Error on saving!');
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addCandidateToRank = (candidate) => {
    const newCandidateRanks = removedCandidates.slice();
    const { pk_Candidate, FirstName, LastName } = candidate;
    const { pk_Tag } = selectedTagFilter || {};

    const selectedUserIndex = newCandidateRanks.findIndex((r) => {
      return r.pk_Candidate == candidate.pk_Candidate;
    });
    if (selectedUserIndex < 0) {
      // ALERT FOR USER NOT FOUND
      return;
    }
    newCandidateRanks[selectedUserIndex].EnableRemoveFromlist = 0;
    const changeMessage = `Added ${FirstName} ${LastName} to Rank List `;

    getTokenSilently()
      .then((token) => {
        putData(
          'department/rankList',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season, pk_Tag },
          {
            newCandidateRanks,
            changeMessage,
            pk_Candidate,
            mode: determineMode(mode),
          },
          formatBearerToken(token),
        )
          .then((result) => {
            alert.success(`Successfully returned ${FirstName} ${LastName}!`);
            getActiveCandidates(mode === defaultModes.prescreen ? 'prescreen' : 'evaluation', {
              callback: (newCandidates) => {
                const candidateToScrollTo = newCandidates.find((c) => c.pk_Candidate == pk_Candidate);
                setSelectedUser(candidateToScrollTo);

                // scrollToElement(`row_${pk_Candidate}_${2}`, null, {
                //   elementToListenScroll: 'rankListContainer',
                // });

                // Fix at a later date. Must scroll AFTER populated by new data.
                // Possible solutions: useEffect watching selectedUser.
                setTimeout(() => {
                  const jump_btn = document.getElementById('jump_btn');
                  scrollToElement(`row_${pk_Candidate}_${2}`, null, {
                    elementToListenScroll: 'rankListContainer',
                    fieldToBlur: 'jump_to_field',
                    selectBlurInput: true,
                    callback: () => {
                      jump_btn.disabled = false;
                    },
                  });
                }, 200);
              },
            });
          })
          .catch((err) => {
            alert.error(`Failed to return ${FirstName} ${LastName}!`);
          })
          .finally(() => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const processButtonColors = (btnValue) => {
    const isSelected = activeTab == btnValue;
    return {
      backgroundColor: isSelected ? '#d9f5ff' : null,
      color: isSelected ? 'black' : null,
    };
  };

  const jumpToIndex = () => {
    let jumpToValue = lastJumpTo;

    if (jumpToValue < 1) {
      jumpToValue = 1;
    }

    if (jumpToValue > candidateRanks.length) {
      jumpToValue = candidateRanks.length;
    }

    let jump_to_actual = jumpToValue;

    const newSelected = candidateRanks[jumpToValue - 1];
    const scrollToCandidate = candidateRanks[jump_to_actual - 1]; // actual candidate item to scroll to. This is different to the highlighted, selected cnadidate.

    const activeElement = document.activeElement;

    const jump_btn = document.getElementById('jump_btn');

    if (activeElement && activeElement.id === 'jump_to_field' && jump_btn) {
      jump_btn.disabled = true;
    }

    scrollToElement(`row_${scrollToCandidate.pk_Candidate}_${2}`, null, {
      elementToListenScroll: `rankListContainer_${viewMode}`,
      fieldToBlur: 'jump_to_field',
      selectBlurInput: true,
      callback: () => {
        jump_btn.disabled = false;
      },
    });

    setLastJumpTo(selectedUser.EvalPosition);
    getCandidateDetails(newSelected);
    setSelectedUser(newSelected);
  };

  const orderRanksByFinalScore = (candidateRanks) => {
    candidateRanks.sort((a, b) => {
      // if they have qual ranks just sort by the date in which they were inserted
      if (a.CandidateFinalScoreAsDecimal === b.CandidateFinalScoreAsDecimal) {
        return new Date(b.rankCreatedAt) - new Date(a.rankCreatedAt);
      }
      return b.CandidateFinalScoreAsDecimal - a.CandidateFinalScoreAsDecimal;
    });

    // after sorting them set each EvaluationPosition to it's new valuethan
    candidateRanks.forEach((item, i) => {
      item.EvalPosition = i + 1;
    });

    // setLastJumpTo();
    return candidateRanks;
  };

  const resetRankHistory = () => {
    // eslint-disable-next-line
    let clone = structuredClone(candidateRanks);
    clone = orderRanksByFinalScore(clone);
    postChange(clone, candidateRanks, 'Reset rank by final scores.', null, null, null, (newCandidates) => {
      setSelectedUser(null);
      setOpenRankHistory(false);
    });
  };

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <AdminRankList
            viewMode={'List'}
            detailNotesHaveChange={detailNotesHaveChange}
            sliderValue={parseInt(sliderValue)}
            candidateRanks={candidateRanks}
            postChange={postChange}
            isFetchingCandidates={isFetchingCandidates}
            setCandidateRanks={setCandidateRanks}
            setSelectedUser={(candidate, isDrag) => {
              getCandidateDetails(candidate);
              setSelectedUser((prev) => {
                if (prev && !isDrag) {
                  setLastJumpTo(prev.EvalPosition);
                }

                return candidate;
              });
            }}
            selectedTagFilter={selectedTagFilter}
            selectedUser={selectedUser}
            defaultModes={defaultModes}
            mode={mode}
            setLastJumpTo={setLastJumpTo}
          />
        );

      case 2:
        return (
          <AdminRankList
            viewMode={'Grid'}
            detailNotesHaveChange={detailNotesHaveChange}
            sliderValue={parseInt(sliderValue)}
            candidateRanks={candidateRanks}
            postChange={postChange}
            isFetchingCandidates={isFetchingCandidates}
            setCandidateRanks={setCandidateRanks}
            setSelectedUser={(candidate, isDrag) => {
              getCandidateDetails(candidate);
              setSelectedUser((prev) => {
                if (prev && !isDrag) {
                  setLastJumpTo(prev.EvalPosition);
                }

                return candidate;
              });
            }}
            selectedTagFilter={selectedTagFilter}
            selectedUser={selectedUser}
            defaultModes={defaultModes}
            mode={mode}
            setLastJumpTo={setLastJumpTo}
          />
        );

      case 3:
        return (
          <AdminRankChart
            rawData={candidateRanks.slice()}
            mode={mode}
            setSelectedUser={(candidate) => {
              getCandidateDetails(candidate);
              setSelectedUser(candidate);
            }}
            isFetchingCandidates={isFetchingCandidates}
          />
        );
    }
  };

  const renderTagFilter = () => {
    return (
      <Popover
        flip={false}
        placementPrefix="tagFilter bs-popover"
        isOpen={showTagFilter}
        target={`tagFilterBtn`}
        trigger="legacy"
        toggle={() => {
          setShowTagFilter(!showTagFilter);
        }}
      >
        <PopoverHeader>
          <div style={style.spaceBetweenRow}>
            Filter by Tag{' '}
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowTagFilter(!showTagFilter);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <TagFilter
            tags={tags}
            setSelectedTagFilter={setSelectedTagFilter}
            selectedFilter={selectedTagFilter}
            updateSeason={updateSeason}
            defaultModes={defaultModes}
            isUpdating={isUpdatingSeason}
            mode={mode}
            setMode={setMode}
            selectedSeason={selectedSeason}
          />
        </PopoverBody>
      </Popover>
    );
  };

  if (isLoading) {
    return <div />;
  }

  if (isShowingChartPrinterFriendlyVersion) {
    return (
      <div style={{ paddingTop: 20, paddingLeft: 30, paddingRight: 30, paddingBottom: 30 }}>
        <AdminRankChart
          isShowingChartPrinterFriendlyVersion={isShowingChartPrinterFriendlyVersion}
          rawData={candidateRanks.slice()}
          mode={mode}
          setSelectedUser={(candidate) => {
            getCandidateDetails(candidate);
            setSelectedUser(candidate);
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        <>
          <PopupWithCloseButton
            open={openRemovedCandidatesList}
            closeOnDocumentClick={true}
            closeFunction={setOpenRemovedCandidatesList}
          >
            <AdminRankRemovedCandidates
              removedCandidates={removedCandidates}
              addCandidateToRank={addCandidateToRank}
              setOpenRemovedCandidatesList={setOpenRemovedCandidatesList}
              getActiveCandidates={getActiveCandidates}
            />
          </PopupWithCloseButton>
          <PopupWithCloseButton open={openRankHistory} closeOnDocumentClick={true} closeFunction={setOpenRankHistory}>
            <AdminRankHistory
              candidateRanks={candidateRanks}
              candidates={candidates}
              selectedTagFilter={selectedTagFilter}
              determineMode={determineMode}
              mode={mode}
              getActiveCandidates={getActiveCandidates}
              orderRanksByFinalScore={orderRanksByFinalScore}
              postChange={postChange}
              setOpenRankHistory={setOpenRankHistory}
              setSelectedUser={setSelectedUser}
            />
          </PopupWithCloseButton>
          <PopupWithCloseButton closeOnDocumentClick={true} open={openRankSettings} closeFunction={setOpenRankSettings}>
            <AdminRankSettings />
          </PopupWithCloseButton>
          <div
            style={{
              ...style.simpleRow,
              flexWrap: 'wrap',
              justifyContent: 'center',
              minHeight: 600,
              height: 'calc(100vh - 300px)',
            }}
          >
            <div className="card shadow bg-light col-lg-8 col-md-12 col-sm-12">
              <div className="card-header" style={{ padding: '0px' }}>
                <div className="row">
                  <div className="col">
                    <div style={style.simpleRow}>
                      <ButtonGroup style={{ width: '100%' }}>
                        <Button
                          style={{ width: '33.33%', ...processButtonColors(1) }}
                          onClick={() => {
                            setActiveTab(1);
                          }}
                        >
                          <FontAwesomeIcon icon={faListOl} /> {selectedTagFilter ? selectedTagFilter.Tag : ''} Rank List
                        </Button>
                        <Button
                          style={{ width: '33.33%', ...processButtonColors(2) }}
                          onClick={() => {
                            setActiveTab(2);
                          }}
                        >
                          <FontAwesomeIcon icon={faGrip} /> {selectedTagFilter ? selectedTagFilter.Tag : ''} Rank Grid
                        </Button>
                        <Button
                          style={{ width: '33.33%', ...processButtonColors(3) }}
                          onClick={() => {
                            setActiveTab(3);
                          }}
                        >
                          <FontAwesomeIcon icon={faChartBar} /> Chart Candidate Scores
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="card-body"
                style={{
                  // display: 'flex',
                  // flexDirection: 'row',
                  padding: '0px',
                  paddingBottom: 0,
                  minHeight: 455,
                  height: 'calc(100vh - 410px)',
                  overflowY: 'clip',
                }}
              >
                {renderContent()}
              </div>
              <div
                className="card-footer"
                style={{ padding: 0, paddingTop: 2, paddingBottom: 2, backgroundColor: 'white' }}
              >
                <div
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'top',
                    width: '100%',
                    height: 40,
                    flexDirection: 'row',
                    display: 'flex',
                    // opacity: 0.6,
                  }}
                >
                  <div>
                    {activeTab != 2 ? (
                      <Button
                        disabled={isPrinting}
                        color="success"
                        style={{ minWidth: 100, marginLeft: 0 }}
                        onClick={() => {
                          // setIsPrinting(true);
                          if (activeTab == 1) {
                            createPDFForRankList({
                              candidates: candidateRanks,
                              department: dContext.department,
                              season: dContext.season,
                              callback: (res) => {
                                setIsPrinting(false);
                              },
                            });
                          } else {
                            window.open(`/administrator/rank/chart_print_preview`, '_blank');
                          }
                        }}
                      >
                        {activeTab == 1 ? 'Print' : 'Open Printer-Friendly Version'}
                      </Button>
                    ) : null}
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: 280,
                      visibility: activeTab == 1 || activeTab == 2 ? null : 'hidden',
                      justifyContent: 'right',
                    }}
                  >
                    {' '}
                    <FormGroup>
                      <div>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <Button
                              style={{ width: 120 }}
                              onClick={() => {
                                const jumpToInput = document.getElementById('jump_to_field');
                                if (jumpToInput) {
                                  jumpToInput.select();
                                }
                              }}
                            >
                              Jump To #
                            </Button>
                          </InputGroupAddon>
                          <div style={{ width: 80 }}>
                            <Input
                              id="jump_to_field"
                              type={'number'}
                              style={{ textAlign: 'center' }}
                              value={lastJumpTo}
                              disabled={candidateRanks.length <= 0}
                              onChange={(e) => {
                                let newValue = parseInt(e.target.value);
                                if (newValue < 1) {
                                  newValue = 1;
                                }
                                setLastJumpTo(newValue);
                              }}
                            />
                          </div>

                          <InputGroupAddon addonType="append">
                            <Button
                              disabled={candidateRanks.length <= 0}
                              style={{ width: 80 }}
                              id="jump_btn"
                              size="sm"
                              color="success"
                              onClick={(e) => {
                                if (detailNotesHaveChange) {
                                  Swal.fire({
                                    title: 'Unsaved Changes!',
                                    text:
                                      'You have unsaved Note changes on the candidate details panel. Jumping now would discard those changes. Are you sure you want to proceed? This action cannot be undone.',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: '#d33',
                                    cancelButtonColor: 'gray',
                                    confirmButtonText: 'Discard',
                                    cancelButtonText: 'Cancel',
                                  }).then((result) => {
                                    if (result.value) {
                                      jumpToIndex();
                                    }
                                  });
                                } else {
                                  jumpToIndex();
                                }
                              }}
                            >
                              Go
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </div>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 xcol-md-12 col-sm-12">
              <AdminRankDetails
                setDetailNotesHaveChange={setDetailNotesHaveChange}
                isGettingCandidateDetails={isGettingCandidateDetails}
                selectedUser={selectedUser || {}}
                candidateCount={candidates.length}
                confirmRemoval={confirmRemoval}
                getActiveCandidates={() => {
                  getActiveCandidates(mode === defaultModes.prescreen ? 'prescreen' : 'evaluation');
                }}
                removeCandidateFromRank={removeCandidateFromRank}
                dataTypes={dataTypes}
                dContext={dContext}
                token={token}
                mode={mode}
              />
            </div>
          </div>
          <div style={style.bottomButtonPanels}>
            <div style={{ ...style.simpleColumn, width: 230 }}>
              <div style={{ ...style.simpleRow }}>
                <Button
                  size="sm"
                  className="btn btn sm btn-info fas fa-xs fa-user"
                  style={{ width: '40px' }}
                  onClick={() => {
                    if (parseInt(sliderValue) > 1) {
                      setSliderValue(parseInt(sliderValue) - 1);
                    }
                  }}
                >
                  {' '}
                  <FontAwesomeIcon icon={faUser} size="xs" />{' '}
                </Button>
                <div
                  className="slidecontainer"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '10px',
                  }}
                >
                  <input type="range" min="1" max="3" value={sliderValue} onChange={sliderChange} />
                  {/* <!-- 
                This slider will resize the photo and text on the candidate column to allow more or less records to fit vertically on screen.
                The default setting is medium sized. The buttons to left and right are to make smaller or larger as well as to indicate which way to slide the dot.
                It would be nice to reecord this setting in the database as a department preference.
              --> */}
                </div>
                <Button
                  size="sm"
                  className="btn btn-sm btn-info fas fa-lg fa-user"
                  style={{ width: '40px' }}
                  onClick={() => {
                    if (parseInt(sliderValue) < 3) {
                      setSliderValue(parseInt(sliderValue) + 1);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faUser} size="lg" />
                </Button>
              </div>
            </div>
            <div style={{ ...style.simpleColumn, width: 'calc(100% - 250px)' }}>
              <div style={{ ...style.simpleRow, flexWrap: 'wrap', justifyContent: 'center' }}>
                <Button
                  size="sm"
                  style={style.bottomButton}
                  color="success"
                  onClick={() => {
                    csvDownload.current.link.click();
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} style={{ marginRight: 10 }} />
                  Download File
                </Button>
                <div style={{ visibility: 'hidden', display: 'none' }}>
                  <CSVLink
                    data={csvData}
                    ref={csvDownload}
                    target="_blank"
                    filename={`Rank History ${moment().format('MM-DD-YYYY')}.csv`}
                  >
                    Download File
                  </CSVLink>
                </div>
                <Button
                  size="sm"
                  style={style.bottomButton}
                  color="danger"
                  onClick={() => {
                    Swal.fire({
                      title: 'Are you sure you want to reset the rank based on final scores?',
                      showCancelButton: true,
                      confirmButtonText: 'Reset',

                      confirmButtonColor: '#d33',
                      cancelButtonColor: 'gray',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        resetRankHistory();
                      }
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faUndoAlt} style={{ marginRight: 10 }} />
                  Reset Rank Order
                </Button>

                <Button size="sm" id="tagFilterBtn" style={{ ...style.bottomButton, justifyContent: 'space-between' }}>
                  <FontAwesomeIcon icon={faFilter} style={{ marginRight: 10 }} />
                  {selectedTagFilter
                    ? `${selectedTagFilter.Tag} ${mode === defaultModes.prescreen ? ' (Prescreen)' : ' (Evaluation)'}`
                    : `Show All (${mode === defaultModes.prescreen ? 'Prescreen' : 'Evaluation'}) `}
                </Button>

                <Button
                  size="sm"
                  style={style.bottomButton}
                  onClick={() => {
                    setOpenRankHistory(true);
                  }}
                >
                  <FontAwesomeIcon icon={faHistory} style={{ marginRight: 10 }} />
                  Rank Version History
                </Button>
                <Button
                  size="sm"
                  style={style.bottomButton}
                  onClick={(e) => {
                    setOpenRemovedCandidatesList(true);
                  }}
                >
                  <FontAwesomeIcon icon={faUserAltSlash} /> Excluded Candidate{removedCandidates.length > 1 ? 's' : ''}{' '}
                  ({removedCandidates.length})
                </Button>

                {renderTagFilter()}
              </div>
            </div>
          </div>
        </>
        <RouterPrompt
          hasChanges={detailNotesHaveChange}
          okLabel={'Discard Changes'}
          onOk={() => {
            setDetailNotesHaveChange(false);

            return true;
          }}
        />
      </>
    );
  }
};

export default AdminRankHistoryLanding;

// below ranks that would allow to narrow down to given date
// add tags
