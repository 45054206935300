import React, { useState, useEffect } from 'react';
import { faPlusCircle, faSearch, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, CardBody, CardHeader, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

import style from './Focuses.style';

import FocustItems from './FocusItems/FocustItems';
import { clone } from '../../../../../Services/schedule';
import { deleteData, fetchDataAgnostic, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { useAlert } from 'react-alert';

function Focuses({ dContext }) {
  const [newFocus, setNewFocus] = useState({ name: '' });
  const [nameToSearch, setNameToSearch] = useState('');
  const [focuses, setFocuses] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const alert = useAlert();

  useEffect(() => {
    getFocuses();
  }, []);

  const getFocuses = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/focuses',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((results) => {
            setFocuses(results.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const filterFocuses = (focuses) => {
    const newFilters = { ...focuses };
    Object.keys(focuses).forEach((key) => {
      const focus = focuses[key];
      //ASK JEFF HOW HE WANTS NULL VALUES TO BE HANDLED IN FOCUS.NAME KEY
      if (focus && focus.Name === null) {
        focus.Name = '';
      }

      if (focus.Name.toLowerCase().indexOf(nameToSearch.toLowerCase()) < 0) {
        delete newFilters[key];
      }
    });
    return newFilters;
  };

  const addNewFocus = () => {
    const focusTrimmed = newFocus.name.trim();
    if (focusTrimmed.length === 0) return;

    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/focus',
          { pk_Department: dContext.department.pk_Department },
          { Name: focusTrimmed },
          formatBearerToken(token),
        )
          .then((results) => {
            alert.success('Focus Added!');
            getFocuses();
            setNewFocus({ name: '' });
            setIsUpdating(false);
          })
          .catch((err) => {
            setIsUpdating(false);
            alert.error('Focus Failed to Add!');
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateFocuses = (focus, cb) => {
    getTokenSilently()
      .then((token) => {
        putData(
          'department/focus',
          { pk_Department: dContext.department.pk_Department, pk_Focus: focus.pk_Focus },
          { Name: focus.Name },
          formatBearerToken(token),
        )
          .then((results) => {
            alert.success('Focus Updated!');
            getFocuses();
            if (cb) {
              cb();
            }
          })
          .catch((err) => {
            cb(err);
            alert.error('Focuses Failed to Update!');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onSave = (focus, cb) => {
    const newFocuses = JSON.parse(JSON.stringify(focuses));
    newFocuses[focus.index] = focus;
    setFocuses(newFocuses);
    updateFocuses(focus, cb);
  };

  const deleteDatabaseFocus = (pk_Focus) => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/focus',
          { pk_Department: dContext.department.pk_Department, pk_Focus: pk_Focus },
          null,
          formatBearerToken(token),
        )
          .then((results) => {
            alert.success('Focus Deleted!');
          })
          .catch((err) => {
            alert.error('Focus Delete failed!');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onDeleteFocus = (id) => {
    const newFocuses = focuses.filter((focus) => {
      return focus.pk_Focus !== id;
    });
    deleteDatabaseFocus(id);
    setFocuses(newFocuses);
  };
  const filteredFocuses = filterFocuses(focuses);
  /**********************************************/
  return (
    <div style={style.mainContainer}>
      <Card style={{ width: '60%', height: 'calc(30vh + 220px' }}>
        <CardHeader>
          <div style={style.centeredText}>
            <b>Focuses</b>
          </div>
        </CardHeader>
        <CardBody>
          <InputGroup>
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FontAwesomeIcon
                  icon={faTimes}
                  style={{ ...style.clickable }}
                  onClick={() => {
                    setNameToSearch('');
                  }}
                />
              </InputGroupText>
            </InputGroupAddon>

            <Input
              value={nameToSearch}
              type="text"
              placeholder="Search Focus. . ."
              onChange={(e) => {
                setNameToSearch(e.target.value);
              }}
            />
            <InputGroupAddon addonType="append">
              <InputGroupText>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroupAddon>
          </InputGroup>
          {/* <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginRight: '20px',
            }}
          >
            <div
              style={{
                alignSelf: 'flex-end',
                padding: '5px',
                verticalAlign: 'bottom',
                width: '65px',
                flexDirection: 'column',
              }}
            >
              <strong> Focuses </strong>
            </div>
            <div clasnName="editAndTagWrappper" style={{ display: 'flex', flexDirection: 'row' }}>
              <div
                style={{
                  alignSelf: 'flex-end',
                  marginLeft: '3px',
                  padding: '5px',
                  textAlign: 'center',
                  verticalAlign: 'bottom',
                  width: '45px',
                }}
              >
                <strong>Edit</strong>
              </div>
              <div
                style={{
                  alignSelf: 'flex-end',
                  marginLeft: '3px',
                  padding: '5px',
                  textAlign: 'center',
                  verticalAlign: 'bottom',
                  width: '60px',
                }}
              >
                <strong>Delete </strong>
              </div>
            </div>
          </div> */}
          <div style={{ overflowY: 'scroll', height: '30vh', marginTop: 20 }}>
            {Object.keys(filteredFocuses).map((key, i) => {
              const focus = focuses[key];
              return <FocustItems key={key} focus={focus} index={i} onDeleteFocus={onDeleteFocus} onSave={onSave} />;
            })}
          </div>

          <div style={style.inputAndAddButtonContainer}>
            <div style={style.manageFieldsNewName}>
              <Input
                style={{ textAlign: 'left' }}
                placeholder="Add Focus. . ."
                value={newFocus.name}
                onChange={(e) => {
                  const newName = e.target.value;
                  setNewFocus((newFocus) => {
                    const newNewFocus = clone(newFocus);
                    return { ...newNewFocus, name: newName };
                  });
                }}
                maxLength="55"
              />
            </div>
            <div>
              <Button
                color="success"
                disabled={isUpdating || newFocus.name.trim().length === 0}
                onClick={() => {
                  addNewFocus();
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default Focuses;
