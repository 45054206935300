import { faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { deleteData, fetchDataAgnostic, postDataAgnostic } from '../../../../../Services/dataApi';
import { clone } from '../../../../../Services/schedule';
import ConfirmModal from '../../../Coordinator/Schedule/ConfirmModal';
import style from './style';
import TagItem from './TagItem';
import { useAlert } from 'react-alert';

const TagPopover = ({
  token,
  selectedCandidates = [],
  getCandidates,
  getAllCandidatesData,
  setShowPopover,
  setParentTags,
}) => {
  const [searchString, setSearchString] = useState('');

  const [selectedTags, setSelectedTags] = useState([]);
  const [oldTags, setOldTags] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isAddingTab, setIsAddingTab] = useState(false);

  const [tagChoices, setTagChoices] = useState([]);
  const [showTagConfirmModal, setShowTagConfirmModal] = useState(false);
  const [tagAction, setTagAction] = useState();

  const alert = useAlert();
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    initializeSelectedTags();
  }, [JSON.stringify(selectedCandidates), JSON.stringify(tagChoices)]);

  useEffect(() => {
    getTags();
  }, []);

  const initializeSelectedTags = () => {
    const allTags = {};
    const newSelectedTags = [];
    selectedCandidates.forEach((candidate) => {
      if (candidate && candidate.Tags) {
        candidate.Tags.forEach((tag) => {
          if (allTags[tag.Tag]) {
            allTags[tag.Tag] = { Tag: tag.Tag, count: allTags[tag.Tag].count + 1 };
          } else {
            allTags[tag.Tag] = { Tag: tag.Tag, count: 1 };
          }
        });
      }
    });

    if (selectedCandidates.length == 1) {
      if (Object.keys(allTags) == null || Object.keys(allTags).length <= 0) {
        setSelectedTags([]);
      } else {
        Object.values(allTags).forEach((tag, i) => {
          if (tag.count == selectedCandidates.length) {
            const tagActual = tagChoices.find((tagChoice) => {
              return tagChoice.Tag == tag.Tag;
            });
            if (tagActual) {
              newSelectedTags.push(tagActual);
            }
          }
        });
      }
    }
    setSelectedTags(newSelectedTags);
    setOldTags(newSelectedTags);
  };

  const getTags = () => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/tags',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setTagChoices(res.data);
            setParentTags(res.data);
            setIsLoading(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onClickTag = (clickedTag, isInSelectedTags) => {
    const newSelectedTags = clone(selectedTags);

    if (isInSelectedTags) {
      newSelectedTags.splice(
        newSelectedTags.findIndex((tag) => {
          return tag.Tag === clickedTag.Tag;
        }),
        1,
      );
    } else {
      newSelectedTags.push(clickedTag);
    }
    setSelectedTags(newSelectedTags);
  };

  const addNewTag = (newTag) => {
    setIsAddingTab(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/tag',
          {
            pk_Department: dContext.department.pk_Department,
          },
          {
            Tag: newTag,
            pk_TagType: 3,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            getTags();
            setIsAddingTab(false);
            return;
          })
          .catch((err) => {
            setIsAddingTab(false);
            return;
          });
      })
      .catch((err) => {
        setIsAddingTab(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
        return;
      });
  };

  const addTagsToCandidates = (tagList, candidateList, promiseDetails) => {
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/candidate/tags',
          {
            pk_Department: dContext.department.pk_Department,
          },
          {
            tagPks: tagList,
            candidatePks: candidateList,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
              getCandidates();
              if (getAllCandidatesData) {
                getAllCandidatesData();
              }
            }
          })
          .catch((err) => {
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const deleteTagsFromCandidates = (tagList, candidateList, promiseDetails) => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/candidate/tags',
          {
            pk_Department: dContext.department.pk_Department,
          },
          {
            tagPks: tagList,
            candidatePks: candidateList,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
              getCandidates();
              if (getAllCandidatesData) {
                getAllCandidatesData();
              }
            }
          })
          .catch((err) => {
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const assignTagToCandidates = () => {
    const tagsToDelete = [];
    const tagsToAdd = [];

    selectedTags.forEach((tag) => {
      const tagExists = oldTags.find((cTag) => {
        return cTag.Tag === tag.Tag;
      });

      if (!tagExists) {
        const tagActual = tagChoices.find((tagChoice) => {
          return tagChoice.Tag === tag.Tag;
        });
        if (tagActual) {
          tagsToAdd.push(tagActual.pk_Tag);
        }
      }
    });

    oldTags.forEach((tag) => {
      const tagExists = selectedTags.find((sTag) => {
        return sTag.Tag === tag.Tag;
      });
      if (!tagExists) {
        const tagActual = tagChoices.find((tagChoice) => {
          return tagChoice.Tag === tag.Tag;
        });
        if (tagActual) {
          tagsToDelete.push(tagActual.pk_Tag);
        }
      }
    });

    const candidateList = [];
    selectedCandidates.forEach((candidate) => {
      candidateList.push(candidate.pk_Candidate);
    });
    // assignTagToCandidates(tagsToAdd, tagsToDelete, candidatePKs);
    const addToCandidates = new Promise((resolve, reject) => {
      addTagsToCandidates(tagsToAdd, candidateList, { resolve, reject });
    });

    const deleteFromCandidates = new Promise((resolve, reject) => {
      deleteTagsFromCandidates(tagsToDelete, candidateList, { resolve, reject });
    });

    Promise.all([addToCandidates, deleteFromCandidates])
      .then((res) => {
        alert.success('Tags Updated!');
        if (selectedCandidates.length == 1) {
          getCandidates(null, { forcedSelectedCandidateId: selectedCandidates[0].pk_Candidate });
        } else {
          getCandidates();
        }
        if (getAllCandidatesData) {
          getAllCandidatesData();
        }

        if (setShowPopover) {
          setShowPopover();
        }
      })
      .catch((err) => {
        console.log('err: ', err);
        alert.error('Error Updating Tags!');
      });
  };

  const renderTagsConfirm = () => {
    return (
      <ConfirmModal
        title={'Multiple Selected Candidates'}
        message={`Are you sure you want to apply tag changes to ${selectedCandidates.length} candidates?`}
        onAccept={() => {
          const tagList = [];
          const candidateList = [];

          selectedTags.forEach((tag) => {
            tagList.push(tag.pk_Tag);
          });
          selectedCandidates.forEach((c) => {
            candidateList.push(c.pk_Candidate);
          });
          if (tagAction === 'delete') {
            deleteTagsFromCandidates(tagList, candidateList);
          } else {
            addTagsToCandidates(tagList, candidateList);
          }
          setTagAction(null);
          setShowTagConfirmModal(false);
        }}
        onCancel={() => {
          setTagAction(null);
          setShowTagConfirmModal(false);
        }}
        isOpen={showTagConfirmModal}
      />
    );
  };

  const filteredTagChoices = tagChoices
    .sort((a, b) => {
      return a.Tag > b.Tag ? 1 : a.Tag < b.Tag ? -1 : 0;
    })
    .filter((tag) => {
      return tag.Tag.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    });

  const hasSearchValues = filteredTagChoices.length > 0;

  return (
    <div>
      <div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button
              onClick={() => {
                setSearchString('');
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Search. . ."
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
          <InputGroupAddon addonType="append">
            <Button
              color={hasSearchValues ? 'secondary' : 'success'}
              onClick={(e) => {
                if (!hasSearchValues) {
                  addNewTag(searchString);
                }
              }}
            >
              <FontAwesomeIcon icon={hasSearchValues ? faSearch : faPlus} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <div style={style.tagList}>
        {filteredTagChoices && filteredTagChoices.length > 0 ? (
          filteredTagChoices.map((tag, i) => {
            return (
              <TagItem tag={tag} index={i} selectedTags={selectedTags} oldTags={oldTags} onClickTag={onClickTag} />
            );
          })
        ) : !isLoading ? (
          <div style={style.centeredText}>No Tags</div>
        ) : (
          <div style={style.centeredText}>Loading. . .</div>
        )}
      </div>
      <Button
        color="success"
        style={style.actionButtons}
        onClick={() => {
          Swal.fire({
            title: 'Enter name of new tag!',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Add Tag',
            confirmButtonColor: 'green',
            showLoaderOnConfirm: true,
            preConfirm: (newTagName) => {
              return new Promise((resolve, reject) => {
                getTokenSilently()
                  .then((token) => {
                    postDataAgnostic(
                      'department/tag',
                      {
                        pk_Department: dContext.department.pk_Department,
                      },
                      {
                        Tag: newTagName,
                        pk_TagType: 3,
                      },
                      formatBearerToken(token),
                    )
                      .then((res) => {
                        getTags();
                        setIsAddingTab(false);
                        resolve();
                      })
                      .catch((err) => {
                        setIsAddingTab(false);
                        reject();
                      });
                  })
                  .catch((err) => {
                    setIsAddingTab(false);
                    if (err.message === 'Login required') {
                      loginWithRedirect();
                    }
                    reject();
                  });
              });
            },
            allowOutsideClick: () => !isAddingTab,
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire('Added Tag!', 'success');
            }
          });
        }}
      >
        New Tag
      </Button>
      {selectedCandidates && selectedCandidates.length == 1 ? (
        <Button
          disabled={
            JSON.stringify(selectedTags) === JSON.stringify(oldTags) ||
            (oldTags.length == 0 && selectedTags.length == 0)
          }
          style={style.actionButtons}
          onClick={() => {
            if (selectedCandidates.length > 1) {
              setShowTagConfirmModal(true);
              return;
            }
            assignTagToCandidates();
          }}
          color="success"
        >
          Save
        </Button>
      ) : null}
      {selectedCandidates && selectedCandidates.length > 1 ? (
        <Button
          disabled={
            JSON.stringify(selectedTags) === JSON.stringify(oldTags) ||
            (oldTags.length == 0 && selectedTags.length == 0)
          }
          style={style.actionButtons}
          onClick={() => {
            if (selectedCandidates.length > 1) {
              setShowTagConfirmModal(true);
              return;
            }

            const tagList = [];
            const candidateList = [];

            selectedTags.forEach((tag) => {
              tagList.push(tag.pk_Tag);
            });
            selectedCandidates.forEach((c) => {
              candidateList.push(c.pk_Candidate);
            });

            addTagsToCandidates(tagList, candidateList);
          }}
          color="success"
        >
          Add Tags
        </Button>
      ) : null}
      {selectedCandidates && selectedCandidates.length > 1 ? (
        <Button
          disabled={
            JSON.stringify(selectedTags) === JSON.stringify(oldTags) ||
            (oldTags.length == 0 && selectedTags.length == 0)
          }
          style={style.actionButtons}
          onClick={() => {
            if (selectedCandidates.length > 1) {
              setTagAction('delete');
              setShowTagConfirmModal(true);
              return;
            }
          }}
          color="danger"
        >
          Remove Tags
        </Button>
      ) : null}
      <Button
        style={style.actionButtons}
        color="secondary"
        onClick={() => {
          setSelectedTags(clone(oldTags));
        }}
      >
        {selectedCandidates.length > 1 ? 'Clear Tag Selection' : 'Reset'}
      </Button>
      {renderTagsConfirm()}
    </div>
  );
};

export default TagPopover;
