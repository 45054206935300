import { faPlus, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { deleteData, fetchDataAgnostic, postDataAgnostic } from '../../../../../Services/dataApi';
import { clone } from '../../../../../Services/schedule';
import ConfirmModal from '../../../Coordinator/Schedule/ConfirmModal';
import style from './style';
import { useAlert } from 'react-alert';
import GroupItem from './GroupItem/GroupItem';

const GroupsPopover = ({
  token,
  selectedCandidates = [],
  candidate,
  getCandidates,
  getAllCandidatesData,
  setShowPopover,
  setParentTags,
}) => {
  const [searchString, setSearchString] = useState('');

  const [selectedGroup, setSelectedGroup] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isAddingGroup, setIsAddingGroup] = useState(false);

  const [groupChoices, setGroupChoices] = useState([]);
  const [showTagConfirmModal, setShowTagConfirmModal] = useState(false);
  const [tagAction, setTagAction] = useState();

  const alert = useAlert();
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    initializeSelectedGroups();
  }, [JSON.stringify(selectedCandidates), JSON.stringify(groupChoices)]);

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (candidate) {
      setSelectedGroup(candidate.Group || {});
    }
  }, [candidate]);

  const initializeSelectedGroups = () => {
    // const allTags = {};
    // const newSelectedTags = [];
    // selectedCandidates.forEach((candidate) => {
    //   if (candidate && candidate.Tags) {
    //     candidate.Tags.forEach((tag) => {
    //       if (allTags[tag.Tag]) {
    //         allTags[tag.Tag] = { Tag: tag.Tag, count: allTags[tag.Tag].count + 1 };
    //       } else {
    //         allTags[tag.Tag] = { Tag: tag.Tag, count: 1 };
    //       }
    //     });
    //   }
    // });
    // if (selectedCandidates.length == 1) {
    //   if (Object.keys(allTags) == null || Object.keys(allTags).length <= 0) {
    //     setSelectedTags([]);
    //   } else {
    //     Object.values(allTags).forEach((tag, i) => {
    //       if (tag.count == selectedCandidates.length) {
    //         const tagActual = groupChoices.find((tagChoice) => {
    //           return tagChoice.Tag == tag.Tag;
    //         });
    //         if (tagActual) {
    //           newSelectedTags.push(tagActual);
    //         }
    //       }
    //     });
    //   }
    // }
    // setSelectedTags(newSelectedTags);
    // setOldTags(newSelectedTags);
  };

  const getGroups = () => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'candidate/groups',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setGroupChoices(res.data);
            setIsLoading(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const onClickGroup = (clickedGroup, isInSelectedTags) => {
    if (selectedGroup.pk_Group === clickedGroup.pk_Group) {
      setSelectedGroup({});
    } else {
      setSelectedGroup(clickedGroup);
    }
  };

  const addNewGroup = (newGroup, promiseDetails) => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'candidate/group',
          null,
          {
            Name: newGroup,
            Description: '',
            fk_Department: dContext.department.pk_Department,
            fk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsLoading(false);
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
              getCandidates();
              if (getAllCandidatesData) {
                getAllCandidatesData();
              }
            }
          })
          .catch((err) => {
            Swal.fire('Error', 'Error deleting group', 'error');
            setIsLoading(false);
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
          })
          .finally(() => {
            getGroups();
          });
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire('Error', 'Error deleting group', 'error');
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const addGroupToCandidates = (tagList, candidateList, promiseDetails) => {
    // console.log('addGroupToCandidates firing');
  };

  const deleteGroupFromCandidates = (groupToDelete, promiseDetails) => {
    // console.log('deleteGroupFromCandidates firing');
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'candidate/group',
          null,
          //   {
          //     pk_Department: dContext.department.pk_Department,
          //   },
          {
            fk_Department: dContext.department.pk_Department,
            fk_Season: dContext.season.pk_Season,
            pk_Group: groupToDelete.pk_Group,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsLoading(false);
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
              getCandidates();
              if (getAllCandidatesData) {
                getAllCandidatesData();
              }
            }
          })
          .catch((err) => {
            Swal.fire('Error', 'Error creating new group', 'error');
            setIsLoading(false);
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
          });
      })
      .catch((err) => {
        Swal.fire('Error', 'Error creating new group', 'error');
        setIsLoading(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const assignGroupToCandidates = (promiseDetails) => {
    setIsLoading(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'candidate/group/assign',
          null,
          {
            pk_Candidate: candidate.pk_Candidate,
            pk_Group: selectedGroup && selectedGroup.pk_Group != null ? selectedGroup.pk_Group : null,
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsLoading(false);
            if (promiseDetails) {
              const { resolve } = promiseDetails;
              resolve('true');
            } else {
              getCandidates();
              if (getAllCandidatesData) {
                getAllCandidatesData();
              }
            }
          })
          .catch((err) => {
            console.log('err: ', err);
            Swal.fire('Error', 'Error assigning group', 'error');
            setIsLoading(false);
            if (promiseDetails) {
              const { reject } = promiseDetails;
              reject();
            }
          })
          .finally(() => {
            getGroups();
          });
      })
      .catch((err) => {
        setIsLoading(false);
        Swal.fire('Error', 'Error assigning group', 'error');
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const filteredGroupChoices = groupChoices
    .sort((a, b) => {
      return a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0;
    })
    .filter((Group) => {
      return Group.Name.toLowerCase().indexOf(searchString.toLowerCase()) >= 0;
    });

  const hasSearchValues = filteredGroupChoices.length > 0;

  return (
    <div>
      <div>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <Button
              onClick={() => {
                setSearchString('');
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </InputGroupAddon>
          <Input
            type="text"
            placeholder="Search. . ."
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
          />
          <InputGroupAddon addonType="append">
            <Button
              color={hasSearchValues ? 'secondary' : 'success'}
              onClick={(e) => {
                if (!hasSearchValues) {
                  addNewGroup(searchString);
                }
              }}
            >
              <FontAwesomeIcon icon={hasSearchValues ? faSearch : faPlus} />
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
      <div style={style.tagList}>
        {filteredGroupChoices && filteredGroupChoices.length > 0 ? (
          filteredGroupChoices.map((Group, i) => {
            return (
              <GroupItem
                Group={Group}
                index={i}
                selectedGroup={selectedGroup}
                onClickGroup={onClickGroup}
                candidate={candidate}
              />
            );
          })
        ) : !isLoading ? (
          <div style={style.centeredText}>No Groups</div>
        ) : (
          <div style={style.centeredText}>Loading. . .</div>
        )}
      </div>
      <Button
        disabled={isLoading}
        color="success"
        style={style.actionButtons}
        onClick={() => {
          Swal.fire({
            title: 'Enter name of new Group!',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off',
            },
            showCancelButton: true,
            confirmButtonText: 'Add Group',
            confirmButtonColor: 'green',
            showLoaderOnConfirm: true,
            preConfirm: (newGroupName) => {
              return new Promise((resolve, reject) => {
                addNewGroup(newGroupName, { resolve, reject });
              });
            },
            allowOutsideClick: () => {
              return !isAddingGroup;
            },
          }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire('Added Tag!', 'success');
            }
          });
        }}
      >
        New Group
      </Button>

      <Button
        disabled={
          isLoading ||
          (candidate &&
            ((selectedGroup && selectedGroup.pk_Group === candidate.Group.pk_Group) ||
              selectedGroup.pk_Group === candidate.Group.pk_Group))
        }
        style={style.actionButtons}
        onClick={() => {
          assignGroupToCandidates();
        }}
        color="success"
      >
        Save
      </Button>

      <Button
        disabled={isLoading}
        style={style.actionButtons}
        onClick={() => {
          addGroupToCandidates(selectedGroup);
        }}
        color="success"
      >
        Add Group
      </Button>
    </div>
  );
};

export default GroupsPopover;
