import { faCalendarPlus, faChevronDown, faPortrait, faTags, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Button, CustomInput, Popover, PopoverBody, PopoverHeader, UncontrolledTooltip } from 'reactstrap';
import style from './AdminWaitlistedCandidates.style';
import './AdminWaitlistedCandidates.style.css';
import ManagePopover from './ManagePopover/ManagePopover';
import Loading from 'Body/Statuses/Loading';

const AdminWaitlistedCandidates = ({
  candidates = [],
  interviewDate,
  getCandidates,
  showRegistered,
  setShowRegistered,
  isFetchingCandidates,
  setActiveTab,
}) => {
  const [showFilterPopover, setShowFilterPopover] = useState({});
  const [date, setDate] = useState();
  const [myShowRegistered, setMyShowRegistered] = useState(false);

  const [candidatesToDisplay, setCandidatesToDisplay] = useState([]);

  useEffect(() => {
    if (interviewDate) {
      setDate(interviewDate);
    }
  }, [interviewDate]);

  useEffect(() => {
    setMyShowRegistered(showRegistered);
    setCandidatesToDisplay(
      candidates.filter((candidate) => {
        const { InterviewDatepk_InterviewDate } = candidate;
        return (!showRegistered && InterviewDatepk_InterviewDate == null) || showRegistered;
      }),
    );
  }, [showRegistered, candidates]);

  const renderManagePopover = (row) => {
    return (
      <Popover
        flip={false}
        popperClassName="commsManagePopover"
        isOpen={showFilterPopover[row.pk_Candidate]}
        trigger="legacy"
        target={`manage_btn_${row.pk_Candidate}`}
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={() => {
          const newShowFilterPopover = { ...showFilterPopover };
          const newValue = !newShowFilterPopover[row.pk_Candidate];
          newShowFilterPopover[row.pk_Candidate] = newValue;

          setShowFilterPopover(newShowFilterPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={{ width: '100%' }}>Waitlist Actions</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={(e) => {
                  e.stopPropagation();
                  const newShowFilterPopover = { ...showFilterPopover };
                  newShowFilterPopover[row.pk_Candidate] = false;

                  setShowFilterPopover(newShowFilterPopover);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <ManagePopover
            setActiveTab={setActiveTab}
            candidate={row}
            hidePopover={() => {
              const newShowFilterPopover = { ...showFilterPopover };
              newShowFilterPopover[row.pk_Candidate] = false;

              setShowFilterPopover(newShowFilterPopover);
            }}
            interviewDate={date}
            getCandidates={getCandidates}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const getDatesStrings = (c) => {
    const { interviewDates = [], InterviewDatepk_InterviewDate } = c;

    const dateStrings = [];

    interviewDates.forEach((d) => {
      const { DateOfInterview, Label, StartTime, pk_InterviewDate } = d;
      dateStrings.push(
        `${moment.tz(`${DateOfInterview} ${StartTime}`, null).format('MMM DD, YYYY')} ${Label ? `(${Label})` : ''} ${
          pk_InterviewDate === InterviewDatepk_InterviewDate ? '[REGISTERED]' : ''
        }`,
      );
    });

    return dateStrings;
  };

  return (
    <div style={{ marginTop: 10 }}>
      <div style={style.title}>Waitlisted Candidates: {candidatesToDisplay.length}</div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {' '}
        <CustomInput
          id="showRegisterSwitch"
          type="switch"
          checked={showRegistered}
          label="Show Registered"
          onClick={(e) => {
            console.log('onCLick!!');
            setShowRegistered(!showRegistered);
            // e.stopPropagation();
            // setShowRegistered(!e.target.checked);
          }}
          onChange={(e) => {}}
        />
      </div>
      <div style={style.mainContainer}>
        <div style={{ ...style.column, width: '100%', width: 500 }}>
          {candidatesToDisplay && !isFetchingCandidates ? (
            candidatesToDisplay.map((candidate, i) => {
              let backgroundColor = '#c97200';
              if (i % 2 === 0) {
                backgroundColor = '#fd9801';
              }

              const {
                FirstName,
                InterviewDateDateOfInterview,
                InterviewDateLabel,
                LastName,
                CandidateSubHeading,
                NumberOfWaitlistedDates,
                PhotoUrl,
                Tags = [],
                InterviewDatepk_InterviewDate,
                WaitlistPreferenceOrder,
              } = candidate;

              let tags = 'None';
              if (Tags) {
                Tags.forEach((tag) => {
                  if (tag.Tag) {
                    if (tags === 'None') {
                      tags = `${tag.Tag}`;
                    } else {
                      tags += `, ${tag.Tag}`;
                    }
                  }
                });
              }

              let candidateName = `---`;
              if (candidate && LastName && FirstName) {
                candidateName = `${candidate.LastName}, ${candidate.FirstName}`;
              } else if (candidate && candidate.LastName) {
                candidateName = `${candidate.LastName}`;
              } else if (candidate && candidate.FirstName) {
                candidateName = ` ${candidate.FirstName}`;
              }
              return (
                <div style={{ ...style.itemRow, backgroundColor, color: 'white' }}>
                  {PhotoUrl ? (
                    <img src={PhotoUrl} width={80} style={style.imgPlaceHolder} />
                  ) : (
                    <FontAwesomeIcon style={style.imgPlaceHolder} icon={faPortrait} />
                  )}
                  <div style={{ ...style.column, width: '100%' }}>
                    <div style={style.candidateName}>{candidateName} </div>
                    <div style={{ ...style.medicalSchool, fontWeight: 'bold' }}>
                      {' '}
                      {InterviewDateDateOfInterview
                        ? `Current Interview Session: ${moment(InterviewDateDateOfInterview).format('MMM DD, YYYY')}`
                        : ''}
                      {`${InterviewDateLabel ? ` (${InterviewDateLabel})` : ''}`}
                    </div>
                    <div style={style.medicalSchool}>{CandidateSubHeading || '---'}</div>
                    <div style={style.medicalSchool} id={`waitlisted_${candidate.pk_Candidate}`}>
                      #
                      {WaitlistPreferenceOrder != null
                        ? `${WaitlistPreferenceOrder + 1} Choice`
                        : 'Registered by Admin'}{' '}
                      | {NumberOfWaitlistedDates != null ? NumberOfWaitlistedDates : '0'} Selected
                      {NumberOfWaitlistedDates > 0 ? (
                        <UncontrolledTooltip
                          placement="top"
                          flip={false}
                          target={`waitlisted_${candidate.pk_Candidate}`}
                          modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                        >
                          {getDatesStrings(candidate).map((d, i) => {
                            return (
                              <div style={{ textAlign: 'left', width: '100%' }}>
                                {i + 1}. {d}
                              </div>
                            );
                          })}
                        </UncontrolledTooltip>
                      ) : null}
                    </div>
                    <div style={style.medicalSchool}>
                      <FontAwesomeIcon icon={faTags} style={{ marginRight: 10 }} />
                      <span>{tags}</span>
                    </div>
                  </div>

                  <div style={{ ...style.column, width: 80 }}>
                    <Button id={`manage_btn_${candidate.pk_Candidate}`} style={{ color: 'white' }}>
                      <FontAwesomeIcon icon={faChevronDown} />
                      <UncontrolledTooltip
                        placement="top"
                        flip={false}
                        target={`manage_btn_${candidate.pk_Candidate}`}
                        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                      >
                        Actions
                      </UncontrolledTooltip>
                    </Button>
                    {renderManagePopover(candidate)}
                  </div>
                </div>
              );
            })
          ) : isFetchingCandidates ? (
            <div style={{ marginTop: 100 }}>
              <Loading options={{ Label: 'Fetching candidates. .  .' }} />
            </div>
          ) : (
            <div>No Candidates</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminWaitlistedCandidates;
