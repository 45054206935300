import { useAuth0 } from 'auth0/reactAuth0Spa';
import Loading from 'Body/Statuses/Loading';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AdminLogout = () => {
  const { logout, isAuthneticated } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    // sessionStorage.removeItem('department');
    // sessionStorage.removeItem('roleChoice');
    clearCacheAndRefresh();

    setTimeout(() => {
      try {
        logout();
      } catch (error) {
        history.push('/');
      } finally {
      }
      //   if (isAuthneticated) {
      //     logout();
      //   } else {
      //     console.log('No auth');
      //     history.push('/');
      //   }
      //   if (auth0 && auth0.logout) {
      //     auth0.logout();
      //   } else {
      //     console.log('No auth');
      //     history.push('/');
      //   }
    }, 3000);
  }, []);

  const clearCacheAndRefresh = () => {
    if (caches) {
      caches.keys().then((res) => {
        res.forEach((name) => {
          caches.delete(name);
        });
      });
    }
    const now = moment();
    sessionStorage.removeItem('department');
    sessionStorage.removeItem('roleChoice');
    localStorage.clear();
    localStorage.setItem('lastBusted', now.format('MMM DD, YYYY HH:mm'));
    // delete browser cache and hard reload
    //   window.location.reload(true);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '85vh',
          }}
        >
          <Loading options={{ labelText: 'Logging you out. . .' }} />
        </div>
      </div>
    </div>
  );
};

export default AdminLogout;
