import React, { useState, useRef, useEffect, useContext } from 'react';
import { Button, Input, Label, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';
import {
  faAddressCard,
  faCalendarAlt,
  faIdCardAlt,
  faUserAlt,
  faUserEdit,
  faUsers,
  faCalendarDay,
  faUserCheck,
  faCoffee,
  faListUl,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import style from './style';
import { fetchDataAgnostic } from '../../../../../../Services/dataApi';
import { CSVLink } from 'react-csv';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { formatBearerToken } from '../../../../../../Common.functions';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';

import { createPDFForSchedule } from '../../../../../../Services/PDFMakeTemplates/MasterSchedule';

import Swal from 'sweetalert2';
import Schedule from '../..';
import moment from 'moment-timezone';
import { createPDFForCandidateBadges } from '../../../../../../Services/PDFMakeTemplates/CandidateBadges/CandidateBadges';
import { createPDFForCandidateBadges2 } from '../../../../../../Services/PDFMakeTemplates/CandidateBadges/CandidateBadges_avery_5384';
import { createPDFForCandidateFactCards } from '../../../../../../Services/PDFMakeTemplates/CandidateBadges/CandidateFactCards';
import { createPDFForCandidateFaces } from '../../../../../../Services/PDFMakeTemplates/CandidateBadges/CandidateFaces';
import { createPDFForEvaluatorSchedule } from '../../../../../../Services/PDFMakeTemplates/EvaluatorSchedule/EvaluatorSchedule';
import { createPDFForCandidateSchedule } from '../../../../../../Services/PDFMakeTemplates/CandidateSchedule/CandidateSchedule';
import { createPDFForCandidateFacesWithNotes } from '../../../../../../Services/PDFMakeTemplates/CandidateBadges/CandidateFacesWithNotes';
import { separateDataTypesByAvailability } from '../../../../../../Services/candidates';
import { createPDFForCandidatesBreakReport } from '../../../../../../Services/PDFMakeTemplates/CandidateBreakReports/CandidateBreakReport';
import { createPDFForCandidateEvaluations } from '../../../../../../Services/PDFMakeTemplates/CandidateEvaluations/CandidateEvaluations';
import Loading from 'Body/Statuses/Loading';
import { clone } from 'lodash';

const PrintDropDown = ({
  scheduleID,
  row = {},
  setShowSettings,
  department,
  setIsShowingSchedulePreview,
  forcedScheduleData,
}) => {
  const candidateCSVDownload = useRef();
  const scheduleRef = useRef();

  const [candidateCSVData, setCandidateCSVData] = useState([]);
  const [scheduleData, setScheduleData] = useState();
  const [columnTracker, setColumnTracker] = useState();
  const [rowTracker, setRowTracker] = useState();
  const [candidates, setCandidates] = useState();

  const [isDownloadingCandidates, setIsDownlaodingCandidates] = useState(false);
  const [isProcessingPDF, setIsProcessingPDF] = useState(false);

  const [showSchedulePreview, setShowSchedulePreview] = useState(false);
  const [schedulePreviewDataURL, setSchedulePreviewDataURL] = useState();

  const [PDFOrientation, setPDFOrientation] = useState('landscape');
  const [PDFFontSize, setPDFFontSize] = useState(4);
  const [dataTypes, setDataTypes] = useState([]);
  const [questionsData, setQuestionsData] = useState({});
  const [isPreparingScheduleData, setIsPreparingScheduleData] = useState(true);

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (candidateCSVData && candidateCSVData.length > 0) {
      setTimeout(() => {
        candidateCSVDownload.current.link.click();
      }, 1000);

      setTimeout(() => {
        setCandidateCSVData([]);
        if (setShowSettings) {
          setShowSettings(false);
        }
      }, 2000);
    }
  }, [candidateCSVData]);

  useEffect(() => {
    getDepartmentDataTypes((newDataTypes) => {
      const separatedDataTypes = separateDataTypesByAvailability(newDataTypes);
      setDataTypes(separatedDataTypes.enabled);
    });
  }, []);

  useEffect(() => {
    if (forcedScheduleData) {
      // Breaks the system
      // setScheduleData(forcedScheduleData);
    }
  }, [forcedScheduleData]);

  useEffect(() => {
    if (scheduleData && showSchedulePreview) {
      let interviewDateRow = {};
      if (scheduleData) {
        const { metaData } = scheduleData;
        const { DateOfInterview, StartTime, TimeZone, Label } = metaData;
        interviewDateRow.scheduleName = metaData.Label;
        interviewDateRow.interviewDate = moment.tz(`${DateOfInterview || ''} ${StartTime}`, null);
      } else if (row) {
        interviewDateRow.scheduleName = row.Label;
        interviewDateRow.interviewDate = row.MomentDateOfInterview;
      }
      setIsProcessingPDF(true);
      createPDFForSchedule({
        interviewDateRow: interviewDateRow,
        scheduleData,
        columnTracker,
        candidates,
        department: dContext.department,
        PDFOrientation,
        PDFFontSize,
        willGetDataUrl: true,
        callback: (dataURL) => {
          if (dataURL) {
            setSchedulePreviewDataURL(dataURL);
          }
          setIsProcessingPDF(false);
        },
      });
    }
  }, [PDFOrientation, JSON.stringify(scheduleData || {}), PDFFontSize, showSchedulePreview]);

  useEffect(() => {
    if (setIsShowingSchedulePreview) {
      setIsShowingSchedulePreview(showSchedulePreview);
      if (showSchedulePreview) {
        setIsProcessingPDF(true);
      }
    }
  }, [showSchedulePreview]);

  useEffect(() => {
    if (row && row.pk_InterviewDate != null) {
      // getScheduleData();
    }
  }, [row]);

  useEffect(() => {
    if (scheduleData && scheduleData.body && scheduleData.body.length > 0) {
      if (isPreparingScheduleData) {
        setIsPreparingScheduleData(!scheduleData.body[0].slot);
      }
    }
  }, [scheduleData]);

  const getQuestions = (callback) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((result) => {
            if (result.data) {
              setQuestionsData(result.data);
            }
            if (result.data && result.data) {
              callback(result.data);
            } else {
              callback([]);
            }
          })
          .catch((err) => {
            callback([]);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidatesForDate = (pk_InterviewDate, callback) => {
    getTokenSilently().then((token) => {
      fetchDataAgnostic(
        'department/season/user/scheduledate/candidates',
        {
          pk_Department: department ? department.pk_Department : dContext.department.pk_Department,
          pk_Season: dContext.season.pk_Season,
          pk_InterviewDate,
          allCandidatesForDate: 1,
          mode: 'Evaluation',
        },
        formatBearerToken(token),
      )
        .then((res) => {
          callback(Object.values(res.data));
        })
        .catch((err) => {
          if (err.message === 'Login required') {
            loginWithRedirect();
          }
        });
    });
  };

  const getScheduleData = (callback) => {
    setIsPreparingScheduleData(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/interviewDate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate: scheduleID != null ? parseInt(scheduleID) : row.pk_InterviewDate,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data.metaData == null || res.data.metaData.StartDateAndTime == null) {
              setSchedulePreviewDataURL(null);
            } else {
              setScheduleData(res.data);
            }

            if (callback) {
              callback(res.data);
            }
          })
          .catch((e) => {
            if (callback) {
              callback(null);
            }
            setSchedulePreviewDataURL(null);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidatesWithSchedules = (pk_InterviewDate, callback) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'schedule/season/interviewDate/candidates',
          {
            pk_Department: department ? department.pk_Department : dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
            pk_InterviewDate,
            // allCandidatesForDate: 1,
            // mode: dContext.season.EnablePrescreenMode ? 'Prescreen' : 'Evaluation',
          },
          formatBearerToken(token),
        )
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getUsersWithSchedules = (pk_InterviewDate, callback) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/interviewDate/user/schedules',
          {
            pk_Department: department ? department.pk_Department : dContext.department.pk_Department,
            pk_InterviewDate,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getDepartmentDataTypes = (callback) => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/dataTypes',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            callback(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const downloadCandidatesCSV = () => {
    setIsDownlaodingCandidates(true);

    getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (validCandidates) => {
      setIsDownlaodingCandidates(false);
      const newCSVData = [
        [
          'AAMC ID',
          'SFMatch ID',
          'NRMP ID',
          'Last Name',
          'Middle Name',
          'First Name',
          'Medical School of Graduation',
          'Phone Number',
          'Email Address',
        ],
      ];

      validCandidates.forEach((candidate) => {
        const {
          AAMCID,
          SFMatchID,
          NRMPID,
          pk_InterviewDate,
          LastName,
          MiddleName,
          FirstName,
          MedicalSchoolOfGraduation,
          Phone,
          Email,
        } = candidate;

        // if (scheduleID != pk_InterviewDate) {
        //   return;
        // }
        const newRow = [
          AAMCID || '',
          SFMatchID || '',
          NRMPID || '',
          LastName || '',
          MiddleName || '',
          FirstName || '',
          MedicalSchoolOfGraduation || '',
          Phone || '',
          Email || '',
        ];

        newCSVData.push(newRow);
      });
      if (newCSVData.length <= 1) {
        Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
        return;
      }

      setCandidateCSVData(newCSVData);
      // setIsProcessingPDF(false);
    });
  };

  const downloadSchedulePDF = () => {
    const containerElement = document.getElementById('scheduleContainer');
    const html = containerElement ? containerElement.outerHTML : null;
    let dateToUse = moment();
    if (scheduleData) {
      const { metaData } = scheduleData;
      const { DateOfInterview, StartTime, TimeZone, Label } = metaData;
      dateToUse = moment.tz(`${DateOfInterview || ''} ${StartTime}`, null);
    } else if (row && row.MomentDateOfInterview) {
      dateToUse = row.MomentDateOfInterview;
    }

    if (html) {
      createPDFForSchedule({
        interviewDateRow: { scheduleName: row.Label, interviewDate: dateToUse },
        scheduleData,
        columnTracker,
        candidates,
        department: dContext.department,
        PDFOrientation,
        PDFFontSize,
      });
    }
  };

  const renderPreviewModal = () => {
    let dateLabel = '';
    if (scheduleData) {
      const { metaData } = scheduleData;
      const { DateOfInterview, StartTime, TimeZone, Label } = metaData;
      dateLabel = moment.tz(`${DateOfInterview || ''} ${StartTime}`, null).format('MMM DD, YYYY');
    } else if (row && row.MomentDateOfInterview) {
      dateLabel = row.MomentDateOfInterview.format('MMM DD, YYYY');
    }

    return (
      <Modal
        isOpen={showSchedulePreview}
        style={{ maxWidth: '90%', maxHeight: '90%' }}
        zIndex={9999}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <ModalHeader
          closeButton
          toggle={() => {
            setShowSchedulePreview(!showSchedulePreview);
          }}
        >
          <div style={{ ...style.spacedBetweenRow, width: '85vw' }}>
            <div>
              <div style={{ ...style.simpleRow, alignItems: 'center' }}>
                <div style={{ fontSize: 22, marginTop: 20, fontWeight: 'bold' }}>
                  Schedule - {row.Label} ({dateLabel})
                </div>
                <div style={{ marginLeft: 20 }}>
                  <Label for="pdf_orientation_btn" style={{ fontSize: 12, marginBottom: 0 }}>
                    Orientation
                  </Label>
                  <Input
                    type="select"
                    id="pdf_orientation_btn"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      setPDFOrientation(e.target.value);
                    }}
                  >
                    <option value="landscape">Landscape</option>
                    <option value="portrait">Portrait</option>
                  </Input>
                </div>
                <div style={{ marginLeft: 20, width: 100 }}>
                  <Label for="pdf_font_size_btn" style={{ fontSize: 12, marginBottom: 0 }}>
                    Font Size
                  </Label>
                  <Input
                    id="pdf_font_size_btn"
                    type="number"
                    value={PDFFontSize}
                    onChange={(e) => {
                      const newValue = parseInt(e.target.value);
                      if (newValue > 0) setPDFFontSize(newValue);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button
                disabled={scheduleData == null}
                color="success"
                id="pdf_download_btn"
                onClick={() => {
                  downloadSchedulePDF();
                }}
              >
                Download
              </Button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              // display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              overflow: 'scroll',
              height: '80vh',
            }}
          >
            {isProcessingPDF ? (
              // <Spinner color="primary" style={{ marginTop: '30vh', marginLeft: '49%' }} />
              <div style={{ marginTop: '30vh', height: 100 }}>
                {' '}
                <Loading />
              </div>
            ) : schedulePreviewDataURL ? (
              <iframe src={schedulePreviewDataURL} style={{ width: '100%', height: '100%' }} />
            ) : (
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                {'No Schedule PDF Data'}
              </div>
            )}

            <div style={{ visibility: 'hidden' }}>
              <Schedule
                isEmbedded={true}
                pk_InterviewDate={scheduleID ? parseInt(scheduleID) : row.pk_InterviewDate}
                embeddedRef={scheduleRef}
                setParentScheduleData={setScheduleData}
                setParentColumnTracker={setColumnTracker}
                setParentCandidates={setCandidates}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderCandidateCSVDownload = () => {
    if (candidateCSVData && candidateCSVData.length > 0) {
      const dateToUse =
        scheduleData && scheduleData.metaData
          ? moment.tz(`${scheduleData.metaData.DateOfInterview} ${scheduleData.metaData.StartTime}`, null)
          : row.MomentDateOfInterview;
      return (
        <div style={{ visibility: 'hidden', display: 'none' }}>
          <CSVLink
            data={candidateCSVData}
            ref={candidateCSVDownload}
            target="_blank"
            filename={`${dateToUse.format('MMM. DD YYYY (ddd)')} Candidates.csv`}
          >
            Download
          </CSVLink>
        </div>
      );
    } else {
      return null;
    }
  };

  const isDisabledButtons = isDownloadingCandidates || isProcessingPDF || isPreparingScheduleData; //|| row.CountOfCandidates <= 0 || row.CountOfCandidates == null;

  const { TimeZone } = dContext.department || {};
  return (
    <div style={style.simpleRow}>
      <div style={{ ...style.simpleColumn, flex: 1 }}>
        <div style={style.simpleRow}>
          <Button
            disabled={isDisabledButtons}
            style={{ ...style.genButton, width: '100%' }}
            onClick={() => {
              setShowSchedulePreview(true);
              // downloadSchedulePDF();
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faCalendarAlt} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Master Schedule</div>
            </div>
          </Button>
        </div>
        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              setIsProcessingPDF(true);
              console.log('dContext: ', clone(dContext));
              getUsersWithSchedules(
                scheduleID ? scheduleID : row ? row.pk_InterviewDate : null,
                (usersWithSchedules) => {
                  console.log('usersWithSchedules: ', usersWithSchedules);

                  if (usersWithSchedules && usersWithSchedules.length > 0) {
                    const user0 = usersWithSchedules[0];
                    if (user0.body && user0.body[0]) {
                      const StartTime = user0.body[0].StartTime;
                      const startFormatted = moment.tz(StartTime, TimeZone).format('hh:mm A');
                      console.log("user 0's first schedule slot's StartTime: ", StartTime);
                      console.log(
                        `user 0's first schedule slot's StartTime formatted in timezone=${TimeZone}: ${startFormatted}`,
                      );
                    }
                  }
                  createPDFForEvaluatorSchedule({
                    department: dContext.department,
                    evaluators: usersWithSchedules || [],
                    interviewDate: scheduleData || row,
                    callback: () => {
                      setIsProcessingPDF(false);
                    },
                  });
                },
              );
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faUserEdit} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Evaluator Schedules</div>
            </div>
          </Button>
        </div>

        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              setIsProcessingPDF(true);
              getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (candidatesForDate) => {
                getQuestions((newQuestions) => {
                  createPDFForCandidateEvaluations({
                    department: dContext.department,
                    candidates: candidatesForDate,
                    questions: newQuestions,
                    interviewDate: scheduleData || row,
                    callback: (res) => {
                      setIsProcessingPDF(false);
                    },
                  });
                });
              });
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faListUl} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Candidate Evaluations</div>
            </div>
          </Button>
        </div>

        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              if (scheduleData) {
                setIsProcessingPDF(true);
                getCandidatesForDate(
                  scheduleID ? scheduleID : row ? row.pk_InterviewDate : null,
                  (candidatesForDate) => {
                    createPDFForCandidatesBreakReport({
                      department: dContext.department,
                      candidates: candidatesForDate,
                      interviewDate: scheduleData || row,
                      scheduleData,
                      columnTracker,
                      rowTracker,
                      callback: (res) => {
                        setIsProcessingPDF(false);
                      },
                    });
                  },
                );
              } else {
                setIsProcessingPDF(true);
                getScheduleData((newScheduleData) => {
                  if (newScheduleData) {
                    getCandidatesForDate(
                      scheduleID ? scheduleID : row ? row.pk_InterviewDate : null,
                      (candidatesForDate) => {
                        createPDFForCandidatesBreakReport({
                          department: dContext.department,
                          candidates: candidatesForDate,
                          interviewDate: scheduleData || row,
                          scheduleData: newScheduleData,
                          columnTracker,
                          rowTracker,
                          callback: (res) => {
                            setIsProcessingPDF(false);
                          },
                        });
                      },
                    );
                  } else {
                    setIsProcessingPDF(false);
                  }
                });
              }
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faCoffee} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Candidates Break Report</div>
            </div>
          </Button>

          <div style={{ visibility: 'hidden', width: 0, height: 0 }}>
            <Schedule
              isEmbedded={true}
              pk_InterviewDate={scheduleID ? parseInt(scheduleID) : row.pk_InterviewDate}
              embeddedRef={scheduleRef}
              setParentScheduleData={(newScheduleData) => {
                setScheduleData(newScheduleData);
              }}
              setParentColumnTracker={setColumnTracker}
              setParentRowTracker={setRowTracker}
              setParentCandidates={setCandidates}
            />
          </div>
        </div>
      </div>
      <div style={{ ...style.simpleColumn, flex: 1 }}>
        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              setIsProcessingPDF(true);
              console.log('dContext: ', clone(dContext));
              getCandidatesWithSchedules(
                scheduleID ? scheduleID : row ? row.pk_InterviewDate : null,
                (candidatesWithSchedules) => {
                  console.log('candidatesWithSchedules: ', candidatesWithSchedules);

                  if (candidatesWithSchedules && candidatesWithSchedules.length > 0) {
                    const candidate0 = candidatesWithSchedules[0];
                    if (candidate0.schedule && candidate0.schedule[0]) {
                      const StartTime = candidate0.schedule[0].StartTime;
                      const startFormatted = moment.tz(StartTime, TimeZone).format('hh:mm A');

                      console.log("candidate 0's first schedule slot's StartTime: ", StartTime);
                      console.log(
                        `candidate 0's first schedule slot's StartTime formatted in timezone=${TimeZone}: ${startFormatted}`,
                      );
                    }
                  }

                  createPDFForCandidateSchedule({
                    department: dContext.department,
                    candidates: candidatesWithSchedules,
                    interviewDate: scheduleData || row,
                    callback: () => {
                      setIsProcessingPDF(false);
                    },
                  });
                },
              );
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faCalendarDay} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Candidate Schedules</div>
            </div>
          </Button>
        </div>
        <div style={style.simpleRow}>
          <Button
            disabled={isDisabledButtons}
            style={{ ...style.genButton, width: '100%' }}
            onClick={() => {
              setIsProcessingPDF(true);

              getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (candidates) => {
                createPDFForCandidateBadges({
                  department: dContext.department,
                  candidates,
                  interviewDate: scheduleData || row,
                  callback: () => {
                    setIsProcessingPDF(false);
                  },
                });
              });
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faIdCardAlt} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Candidate Badges (5390)</div>
            </div>
          </Button>
        </div>

        <div style={style.simpleRow}>
          <Button
            disabled={isDisabledButtons}
            style={{ ...style.genButton, width: '100%' }}
            onClick={() => {
              setIsProcessingPDF(true);
              getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (candidates) => {
                createPDFForCandidateBadges2({
                  department: dContext.department,
                  candidates,
                  interviewDate: scheduleData || row,
                  callback: () => {
                    setIsProcessingPDF(false);
                  },
                });
              });
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faIdCardAlt} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Candidate Badges (5834)</div>
            </div>
          </Button>
        </div>

        <div style={style.buttonLabel}>When Printing Avery Templates, use "Actual Size" option.</div>
      </div>

      <div style={{ ...style.simpleColumn, flex: 1 }}>
        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              setIsProcessingPDF(true);
              getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (candidates) => {
                createPDFForCandidateFaces({
                  department: dContext.department,
                  candidates,
                  interviewDate: scheduleData || row,
                  callback: () => {
                    setIsProcessingPDF(false);
                  },
                });
              });
            }}
          >
            <div style={{ ...style.spacedBetweenRow }}>
              <FontAwesomeIcon icon={faUserAlt} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Face Sheet Grid</div>
            </div>
          </Button>
        </div>

        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              setIsProcessingPDF(true);

              getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (candidates) => {
                createPDFForCandidateFacesWithNotes({
                  department: dContext.department,
                  candidates,
                  interviewDate: scheduleData || row,
                  activeFields: dataTypes || [],
                  callback: () => {
                    setIsProcessingPDF(false);
                  },
                });
              });
            }}
          >
            <div style={{ ...style.spacedBetweenRow }}>
              <FontAwesomeIcon icon={faUserAlt} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Face Sheet With Notes</div>
            </div>
          </Button>
        </div>

        <div style={style.simpleRow}>
          <Button
            style={{ ...style.genButton, width: '100%' }}
            disabled={isDisabledButtons}
            onClick={() => {
              setIsProcessingPDF(true);
              getCandidatesForDate(scheduleID ? scheduleID : row ? row.pk_InterviewDate : null, (candidates) => {
                createPDFForCandidateFactCards({
                  department: dContext.department,
                  candidates,
                  interviewDate: scheduleData || row,
                  callback: () => {
                    setIsProcessingPDF(false);
                  },
                });
              });
            }}
          >
            <div style={{ ...style.spacedBetweenRow }}>
              <FontAwesomeIcon icon={faAddressCard} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Fact Sheet</div>
            </div>
          </Button>
        </div>

        <div style={style.simpleRow}>
          <Button
            disabled={row == null || row.CountOfCandidates <= 0 || isDisabledButtons}
            style={{ ...style.genButton, width: '100%' }}
            onClick={() => {
              downloadCandidatesCSV();
            }}
          >
            <div style={{ ...style.spacedBetweenRow, width: '100%' }}>
              <FontAwesomeIcon icon={faUsers} style={style.buttonIcon} />
              <div style={style.buttonLabel}>Candidate List (Excel CSV)</div>
            </div>
          </Button>
        </div>
      </div>
      {renderCandidateCSVDownload()}
      {renderPreviewModal()}
    </div>
  );
};

export default PrintDropDown;
