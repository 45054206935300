import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import imageToBase64 from 'image-to-base64';

import moment from 'moment';
import tinycolor from 'tinycolor2';
import { clone } from '../../schedule';
import Swal from 'sweetalert2';
import profilePlaceholder from '../CandidateBadges/Portrait_Placeholder.png';
import rezRateLogo from '../rezRateLogo.png';

let rezRateLogoBase64 = null;
let profilePlaceholderBase64 = null;
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// Cheat Sheet
// 1 inch = 72 units.
// LETTER size is 612x792

const tableLayouts = {
  customTable: {
    hLineWidth: (i, node) => {
      return 0;
      // return 0;
      // if (i === 0 || i === node.table.body.length) {
      //   return 0;
      // }
      return i === node.table.headerRows ? 2 : 1;
    },
    vLineWidth: (i) => {
      return 0;
    },
    hLineColor: (i) => {
      return '#aaa';
      return i === 1 ? 'black' : '#aaa';
    },
    paddingLeft: (i) => {
      return 0;
    },
    paddingRight: (i, node) => {
      return 0;
    },
    paddingTop: (i, node) => {
      return 0;
    },
    paddingBottom: (i, node) => {
      return 0;
    },
  },
};

const truncate = (wordToTruncate, limit = 0, forcedCut) => {
  if (wordToTruncate == null) {
    return '';
  }
  if (limit == 0 || wordToTruncate.length < limit + 1) {
    return wordToTruncate;
  } else if (forcedCut) {
    return `${wordToTruncate.slice(0, limit)}`;
  } else if (wordToTruncate.length > limit + 1) {
    return `${wordToTruncate.slice(0, limit - 5)}. . .`;
  }
};

const renderLines = (lineCount) => {
  let counter = 0;
  const lines = [];
  while (counter < lineCount) {
    lines.push([
      {
        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 520, y2: 0, lineWidth: 1, color: '#3b3b3b' }],
        margin: [10, 25, 0, counter == lineCount - 1 ? 10 : 0],
        // pageBreak: counter == lineCount - 1 ? 'after' : null,
      },
    ]);

    counter++;
  }

  return lines;
};

const createPDFProper = (data) => {
  const { interviewDate, candidates, department, departmentLogo, activeFields, questions } = data;
  const { metaData, MomentDateOfInterview } = interviewDate;
  const { TimeZone } = department;

  let dateToDisplay = '';
  let labelToDisplay = '';

  if (metaData) {
    const { DateOfInterview, StartTime, Label } = metaData;
    dateToDisplay = moment(`${DateOfInterview} ${StartTime}`).format('MMM DD, YYYY');
    labelToDisplay = Label || '';
  } else if (MomentDateOfInterview) {
    dateToDisplay = MomentDateOfInterview.format('MMM DD, YYYY');
    labelToDisplay = interviewDate.Label || '';
  }

  const rows = [];
  let counter = 0;

  // Renders each candidate section. Each Candidate is rendered a Card, each card containing the candidate header (photo, name, evaluator, etc), the canvas for the line divider, and the Table containing rows of questions. The table is set to NOT break rows, so an entire row consisting, question text, choices and all, get rendered together, always.
  const renderCard = (candidate, questionData, endOfTheLine) => {
    const { userPhotoBase64 } = candidate;
    const cardRows = [];
    const userDetailRows = [];
    const questionRows = [];
    const questionGroups = questionData.EvalQuestions;
    const { DefaultScale } = questionData;

    const userPhoto = userPhotoBase64
      ? {
          columns: [
            {
              image: userPhotoBase64 ? `data:image/png;base64,${userPhotoBase64}` : null,
              fit: [60, 60],
            },
          ],
          margin: [0, 0, 0, 10],
        }
      : {
          columns: [
            {
              image: profilePlaceholderBase64 ? `data:image/png;base64,${profilePlaceholderBase64}` : null,
              fit: [60, 60],
            },
          ],
          margin: [0, 0, 0, 10],
        };

    userDetailRows.push([userPhoto]);

    cardRows.push([
      {
        columns: [
          { columns: [userDetailRows], width: 210, margin: [0, 0, 10, 0], width: 70 },
          {
            columns: [
              [
                [
                  {
                    style: { alignment: 'left', bold: true },
                    text: `${candidate.LastName}, ${candidate.FirstName} `,
                    fontSize: 12,
                    margin: [0, 0, 0, 2],
                    width: 6.0 * 72,
                  },
                ],
                [
                  {
                    style: { alignment: 'left' },
                    text: `${candidate.CandidateSubHeading ? candidate.CandidateSubHeading : ' '}`,
                    fontSize: 9,
                    width: 6.0 * 72,
                    margin: [0, 0, 0, 0],
                  },
                ],
                [
                  {
                    style: { alignment: 'left' },
                    text: `${candidate.Email ? candidate.Email : ' '}`,
                    fontSize: 10,
                    width: 6.0 * 72,
                    margin: [0, 0, 0, 0],
                  },
                ],
                [
                  {
                    style: { alignment: 'left' },
                    text: `${candidate.PhoneNumber ? candidate.PhoneNumber : ' '}`,
                    fontSize: 10,
                    margin: [0, 0, 0, 0],
                    width: 6.0 * 72,
                  },
                ],
              ],
            ],
            width: 4.5 * 72,
          },
          {
            columns: [
              [
                [
                  {
                    style: { alignment: 'left', bold: true },
                    text: dateToDisplay,
                    fontSize: 8,
                    margin: [0, 0, 0, 0],
                    width: 1.0 * 72,
                  },
                  {
                    style: { alignment: 'left', bold: true, color: '#999999' },
                    text: labelToDisplay,
                    fontSize: 8,
                    margin: [0, 0, 0, 5],
                    width: 1.0 * 72,
                  },
                ],
                [
                  {
                    columns: [
                      {
                        style: { alignment: 'left', bold: true },
                        text: `Evaluator: `,
                        fontSize: 8,
                        margin: [0, 0, 0, 5],
                        width: 0.5 * 72,
                      },
                      {
                        canvas: [{ type: 'line', x1: 0, y1: 0, x2: 120, y2: 0, lineWidth: 1, color: '#cccccc' }],
                        margin: [0, 12, 0, 0],
                      },
                    ],
                    width: 72,
                  },
                ],
              ],
            ],
            width: 2.5 * 72,
          },
        ],
        margin: [10, 0, 0, 0],
      },
    ]);

    cardRows.push({
      canvas: [{ type: 'line', x1: 0, y1: 0, x2: 570, y2: 0, lineWidth: 2, color: 'black' }],
      margin: [0, 0, 20, 0],
    });

    questionGroups.forEach((qGroup) => {
      const { GroupName, questions = [] } = qGroup;

      questionRows.push([
        {
          style: { alignment: 'center', bold: true },
          text: `${GroupName} `,
          fontSize: 12,
          margin: [0, 10, 0, 20],
          width: '100%',
        },
      ]);

      questions
        .sort((a, b) => {
          return a.EvaluationQuestionSortOrder - b.EvaluationQuestionSortOrder;
        })
        .filter((q) => {
          return q.EnableEvaluationForm;
        })
        .forEach((q, i) => {
          const { WeightQuestionType, MaxAnswerValue, QuestionText } = q;

          const questionText = [
            {
              style: { alignment: 'left', bold: true },
              text: `${i + 1}. ${QuestionText} `,
              fontSize: 10,
              margin: [0, 10, 0, 2],
              width: '100%',
              pageHeaderData: JSON.stringify({ candidate }),
            },
          ];
          if (WeightQuestionType === 'scale') {
            let counter = 0;
            let choices = [];
            let defaultX = DefaultScale.length > 5 ? 24 : 50;

            DefaultScale.forEach((choice, i) => {
              const { DisplayValue, NumericValue, Label, Description } = choice;

              choices.push([
                [
                  {
                    text: `${DisplayValue || NumericValue}`,
                    fontSize: 15, // 22 - 26
                    relativePosition: { x: NumericValue > 9 ? defaultX : defaultX + 4, y: 22 },
                    style: { color: 'black' },
                  },
                  {
                    canvas: [
                      {
                        type: 'ellipse',
                        x: 30,
                        y: 30,
                        lineColor: 'gray',
                        fillOpacity: 1,
                        r1: 20,
                        r2: 20,
                      },
                    ],
                    style: { alignment: 'center' },
                  },
                  {
                    text: `${Label}`,
                    fontSize: 8,
                    bold: true,
                    margin: [10, 10, 0, 0],
                    //   relativePosition: { x: 0, y: 0 },
                    style: { color: 'black', alignment: 'center' },
                  },
                  {
                    text: `${Description}`,
                    fontSize: 6,
                    margin: [10, 0, 0, 0],
                    //   relativePosition: { x: 0, y: 0 },
                    style: { color: 'black', alignment: 'center', italics: true },
                  },
                ],
              ]);
            });
            questionRows.push([[[questionText], { columns: choices, margin: [0, 0, 0, 10] }]]);
          } else if (WeightQuestionType === 'text') {
            questionRows.push([[[questionText], [...renderLines(3)]]]);
          }
        });
    });

    cardRows.push([
      {
        table: { heights: 'auto', widths: ['100%'], body: questionRows, dontBreakRows: true },
        headerRows: 1,
        margin: [20, 0, 20, 0],
        layout: 'customTable',
        pageBreak: endOfTheLine ? null : 'after',
      },
    ]);
    return cardRows;
  };

  while (counter < candidates.length) {
    const columns = [];
    const tableRow = [];
    rows.push(renderCard(candidates[counter], questions, counter == candidates.length - 1));

    counter++;
  }

  if (rows.length <= 0) {
    Swal.fire('Error', 'There are no Candidates for this Interview Date. There is nothing to print!', 'error');
    return;
  }

  let pdfObject = [rows];

  const docDefinition = {
    info: { title: 'Candidate Evaluations' },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    content: pdfObject,
    defaultStyle: {
      fontSize: 10,
    },
    pageMargins: [0.25 * 72, 0.5 * 72, 0.25 * 72, 0.5 * 72],
    footer: (currentPage) => {
      const footerData = getDataForHeaderFooter(docDefinition, currentPage);

      const { candidatePages, candidateData } = footerData;
      if (candidateData) {
        const candidatePagesActual = candidatePages[candidateData.pk_Candidate];
        const candidatePagesCount = candidatePagesActual.length;
        const currentCandidatePage = candidatePagesActual.indexOf(currentPage) + 1;
        return [
          {
            text: `${candidateData.LastName}, ${candidateData.FirstName}`,
            alignment: 'center',
            width: '100%',
            fontSize: 8,
          },
          {
            text: `${currentCandidatePage} of ${candidatePagesCount}`,
            alignment: 'center',
            width: '100%',
            fontSize: 8,
          },
        ];
      }
    },
  };

  pdfMake.createPdf(docDefinition, tableLayouts).open({}, window.open('', '_blank'));
};

/**
 * We plant the header data we want on each element we add to the content array we put in the docDef.
 * Each element when processed, have a pageNumber value embedded deep in it. We use this function is used to get that
 * embedded header data, in this case, the candidate. The location of this data is retrieved based on the
 * @param {object} documentDefinition  contains the elements to render.
 * @param {number} currentPage what page the header is being rendered on.
 * @returns {object} Returns hte header object with all the data for header/footer, in this case,
 */
const getDataForHeaderFooter = (documentDefinition, currentPage) => {
  let headerData = {};
  let candidatePages = {}; // arrays of page numbers involved with a candidate

  documentDefinition.content.forEach((questionGroup, questionGroupIndex) => {
    questionGroup.stack.forEach((candidateEvaluation) => {
      candidateEvaluation.stack[2].stack[0].table.body.forEach((tableRow, tableRowIndex) => {
        if (tableRowIndex > 0) {
          const pageNumber = tableRow[0].positions[0].pageNumber;
          const rawHeaderData = tableRow[0].stack[0].stack[0].stack[0].pageHeaderData
            ? JSON.parse(tableRow[0].stack[0].stack[0].stack[0].pageHeaderData).candidate
            : null;

          if (rawHeaderData) {
            const oldPages = candidatePages[rawHeaderData.pk_Candidate] || [];
            if (!oldPages.includes(pageNumber)) {
              oldPages.push(pageNumber);
              candidatePages[rawHeaderData.pk_Candidate] = oldPages;
            }
          }

          if (pageNumber == currentPage) {
            headerData.candidateData = rawHeaderData ? rawHeaderData : {};
          }
        }
      });
    });
  });

  headerData.candidatePages = candidatePages;
  return headerData;
};

export const createPDFForCandidateEvaluations = ({
  interviewDate,
  department,
  candidates = [],
  questions = [], // Array of Question Groups, each with questions property
  callback,
}) => {
  const candidatesCopy = clone(candidates);

  // Process each candidate's photo and convert to base64 before generating pdf proper.
  Promise.all([
    ...candidatesCopy.map((c) => {
      return new Promise((resolve, reject) => {
        if (c.PhotoUrl) {
          imageToBase64(c.PhotoUrl)
            .then((base64Img) => {
              c.userPhotoBase64 = base64Img;
              resolve(c);
            })
            .catch((err) => {
              resolve({ error: true, ...c });
            });
        } else {
          resolve(c);
        }
      });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(profilePlaceholder)
        .then((base64) => {
          profilePlaceholderBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),
    new Promise((resolve, reject) => {
      imageToBase64(rezRateLogo)
        .then((base64) => {
          rezRateLogoBase64 = base64;
          resolve(base64);
        })
        .catch((err) => {
          reject(err);
        });
    }),
  ])
    .then((res) => {
      createPDFProper({ candidates: candidatesCopy, department, interviewDate, questions });
      if (callback) {
        callback();
      }
    })
    .catch((err) => {
      createPDFProper({ candidates: candidatesCopy, department, interviewDate, questions });
      if (callback) {
        callback(err);
      }
    });
};
