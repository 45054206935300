import { DepartmentContext } from 'DepartmentWrapper';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledTooltip,
} from 'reactstrap';
import style from './ManageScores.js.style';
import { fetchDataAgnostic, postDataAgnostic } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faMagnifyingGlass, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { clone, isArray, set } from 'lodash';
import moment from 'moment-timezone';
import { options } from 'sanitize-html';
import { useAlert } from 'react-alert';

const ManageScores = ({ departments = [] }) => {
  const [organizations, setOrganizations] = useState([]);
  // const [departments, setDepartments] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [candidates, setCandidates] = useState([]);

  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState(null);

  const [orgSearchString, setOrgSearchString] = useState('');
  const [deptSearchString, setDeptSearchString] = useState('');
  const [seasonSearchString, setSeasonSearchString] = useState('');
  const [candidateSearchString, setCandidateSearchString] = useState('');

  const [isLoadingOrganizations, setIsLoadingOrganizations] = useState(true);
  const [isLoadingDepartments, setIsLoadingDepartments] = useState(false);
  const [isLoadingSeasons, setIsLoadingSeasons] = useState(false);
  const [isLoadingSessions, setIsLoadingSessions] = useState(false);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false);
  const [isComputing, setIsComputing] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();
  useEffect(() => {
    getAllOrganizations();
  }, []);

  useEffect(() => {
    setSeasons([]);
    setSessions([]);
    setCandidates([]);

    setSelectedDepartment(null);
    setSelectedSeason(null);
  }, [selectedOrganization]);

  useEffect(() => {
    setSeasons([]);
    setSessions([]);
    setCandidates([]);

    setSelectedSeason(null);
    setSelectedSession(null);
    setSelectedCandidates([]);

    if (selectedDepartment != null) {
      getSeasonsForDepartment(selectedDepartment.pk_Department);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    setSessions([]);
    setCandidates([]);

    setSelectedCandidates([]);

    if (selectedSeason != null) {
      getCandidatesForSeason({ pk_Season: selectedSeason.pk_Season, pk_Department: selectedDepartment.pk_Department });
    }
  }, [selectedSeason]);

  const getAllOrganizations = () => {
    setIsLoadingOrganizations(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('organization/all', null, formatBearerToken(token))
          .then((result) => {
            setIsLoadingOrganizations(false);
            const sortedOrgs = result.data.sort((a, b) =>
              a.OrganizationName > b.OrganizationName ? 1 : b.OrganizationName > a.OrganizationName ? -1 : 0,
            );

            setOrganizations(sortedOrgs);
            if (sortedOrgs && sortedOrgs.length > 0) {
              setSelectedOrganization(sortedOrgs[0].pk_Organization);
            }
            setSelectedOrganization(sortedOrgs[0].pk_Organization);
          })
          .catch((err) => {
            console.log('GET orgs err: ', err);
            setIsLoadingOrganizations(false);
          });
      })
      .catch((err) => {
        setIsLoadingOrganizations(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getSeasonsForDepartment = (pk_Department) => {
    setIsLoadingSeasons(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'organization/department',
          {
            pk_Department: pk_Department,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsLoadingSeasons(false);
            if (res.data && res.data.error == null) {
              setSeasons(res.data.Seasons || []);
            }
          })
          .catch((err) => {
            console.log('GET seasons err: ', err);
            setIsLoadingSeasons(false);
          });
      })
      .catch((err) => {
        console.log('GET seasons err: ', err);
        setIsLoadingSeasons(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const getCandidatesForSeason = (options) => {
    const { pk_Season, pk_Department } = options;
    setIsLoadingCandidates(true);
    getTokenSilently()
      .then((t) => {
        fetchDataAgnostic(
          'department/candidates',
          { pk_Department: pk_Department, pk_Season: pk_Season },
          formatBearerToken(t),
        )
          .then((res) => {
            setIsLoadingCandidates(false);
            setCandidates(
              Object.values(res.data)
                .map((candidate) => {
                  return {
                    ...candidate,
                    FullName: `${candidate.LastName || ''}${candidate.LastName ? ',' : ''}${candidate.FirstName || ''}`,
                  };
                })
                .sort((a, b) => {
                  const aFullName = `${a.LastName || ''}${a.LastName ? ',' : ''}${a.FirstName || ''}`;
                  const bFullName = `${b.LastName || ''}${b.LastName ? ',' : ''}${b.FirstName || ''}`;

                  if (!aFullName) {
                    return 1;
                  }

                  if (!bFullName) {
                    return -1;
                  }
                  return aFullName > bFullName ? 1 : bFullName > aFullName ? -1 : 0;
                }),
            );
          })
          .catch((err) => {
            console.log('GET candidates err: ', err);
            setIsLoadingCandidates(false);
          });
      })
      .catch((err) => {
        setIsLoadingCandidates(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const computeScores = (payload) => {
    setIsComputing(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic('/department/computeScores', null, payload, formatBearerToken(token))
          .then((res) => {
            setIsComputing(false);
            setSelectedCandidates([]);
            alert.success(res.data);
          })
          .catch((err) => {
            console.log('Compute err: ', err);
            setIsComputing(false);
            console.log('computeScores err: ', err);
            alert.error('Error updating!');
          });
      })
      .catch((err) => {
        setIsComputing(false);
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const renderSearchInput = (options) => {
    const { onClear, onSearch, placeholder = 'Search. . .', fieldValue, onScoreUpdate, enableUpdateButton } = options;

    return (
      <InputGroup style={{ width: '100%' }}>
        <InputGroupAddon addonType="prepend">
          <Button
            onClick={() => {
              if (onClear) {
                onClear();
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </InputGroupAddon>
        <Input
          type="text"
          value={fieldValue}
          placeholder={placeholder ? placeholder : 'Search. . .'}
          onChange={(e) => {
            if (onSearch) {
              onSearch(e.target.value);
            }
          }}
        />
        <InputGroupAddon addonType="append">
          <Button color="success">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </InputGroupAddon>
        {onScoreUpdate ? (
          <InputGroupAddon addonType="append">
            <Button
              disabled={!enableUpdateButton}
              color="primary"
              size="sm"
              onClick={() => {
                if (onScoreUpdate) {
                  onScoreUpdate();
                }
              }}
            >
              Update
            </Button>
          </InputGroupAddon>
        ) : null}
      </InputGroup>
    );
  };

  const listWrapper = (options) => {
    const {
      listToDisplay,
      labelKey,
      valueKey,
      isLoading,
      loadingMessage = 'Loading. . .',
      searchString = '',
      onClick,
      selectedValue,
      width,
      onClear,
      onSearch,
      onScoreUpdate,
    } = options;

    const listIsArray = selectedValue && isArray(selectedValue);

    return (
      <Card>
        <CardBody>
          <div style={{ ...style.simpleRow, width: width - 40 }}>
            <div style={style.simpleColumn}>
              <div style={{ ...style.simpleRow, marginBottom: 10 }}>
                {renderSearchInput({
                  onClear,
                  onSearch,
                  onScoreUpdate: listIsArray ? onScoreUpdate : null,
                  enableUpdateButton: listIsArray && selectedValue.length > 0,
                })}
              </div>
              <div
                style={{ display: 'flex', flexDirection: 'row', overflowY: 'scroll', height: `calc(100vh - 450px)` }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', width: width - 100 }}>
                  {isLoading ? (
                    <div
                      style={{
                        ...style.simpleRow,
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        // borderBottom: `1px solid gray`,
                        wordWrap: 'break-word',
                        textAlign: 'center',
                      }}
                    >
                      {loadingMessage}
                    </div>
                  ) : listToDisplay && listToDisplay.length > 0 ? (
                    listToDisplay.map((listItem, i) => {
                      const {
                        TransitionedToStoredCalculations,
                        pk_Season,
                        EarliestStoredCalculationTimestamp,
                      } = listItem;
                      const label = listItem[labelKey] || '';
                      const value = listItem[valueKey] || '';
                      const isInSelectedList =
                        selectedValue && isArray(selectedValue)
                          ? selectedValue.find((s) => {
                              return s[valueKey] == value;
                            })
                          : null;

                      if (!searchString || (searchString && label.toLowerCase().includes(searchString.toLowerCase()))) {
                        return (
                          <div
                            style={{
                              ...style.simpleRow,
                              fontSize: 15,
                              fontWeight: 'bold',
                              paddingTop: 10,
                              paddingBottom: 10,
                              borderBottom: `1px solid gray`,
                              wordWrap: 'break-word',
                              backgroundColor:
                                (selectedValue && value == selectedValue[valueKey]) || isInSelectedList
                                  ? '#d9f5ff'
                                  : 'white',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              if (onClick) {
                                onClick(listItem);
                              }
                            }}
                          >
                            <div style={{ ...style.simpleColumn, width: '100%' }}>
                              ({value}) {label}
                            </div>
                            {!listIsArray && onScoreUpdate ? (
                              <div style={{ ...style.simpleColumn, width: 100, marginRight: 10 }}>
                                <Button
                                  disabled={isComputing || isLoading}
                                  size="sm"
                                  color={
                                    TransitionedToStoredCalculations != undefined
                                      ? TransitionedToStoredCalculations
                                        ? 'success'
                                        : 'secondary'
                                      : 'primary'
                                  }
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    if (onScoreUpdate) {
                                      onScoreUpdate(value);
                                    }
                                  }}
                                >
                                  <div style={style.simpleRow}>
                                    <div style={style.simpleColumn}>Update</div>
                                    {TransitionedToStoredCalculations != undefined ? (
                                      TransitionedToStoredCalculations ? (
                                        <div
                                          style={{
                                            ...style.simpleColumn,
                                            width: 40,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            style={{ cursor: 'pointer', color: 'white' }}
                                            id={`info_${pk_Season}`}
                                            icon={faInfoCircle}
                                          />
                                          <UncontrolledTooltip target={`info_${pk_Season}`}>
                                            {EarliestStoredCalculationTimestamp
                                              ? `Last Updated: ${moment(EarliestStoredCalculationTimestamp).format(
                                                  'MMM DD, YYYY',
                                                )}`
                                              : 'No stored calculations'}
                                          </UncontrolledTooltip>
                                        </div>
                                      ) : null
                                    ) : null}
                                  </div>
                                </Button>
                              </div>
                            ) : null}
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div
                      style={{
                        ...style.simpleRow,
                        fontSize: 15,
                        paddingTop: 10,
                        paddingBottom: 10,
                        // borderBottom: `1px solid gray`,
                        wordWrap: 'break-word',
                        textAlign: 'center',
                      }}
                    >
                      No Items
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    );
  };

  const renderOrgList = (width) => {
    return listWrapper({
      listToDisplay: organizations,
      valueKey: 'pk_Organization',
      labelKey: 'OrganizationName',
      isLoading: isLoadingOrganizations,
      searchString: orgSearchString,
      loadingMessage: 'Loading Organizations. . .',
      onClick: (value) => {
        setSelectedOrganization(value);
      },
      selectedValue: selectedOrganization,
      width: width,
      onClear: () => {
        setOrgSearchString('');
      },
      onSearch: (newStr) => {
        setOrgSearchString(newStr);
      },
      onScoreUpdate: (pk_Organization) => {
        computeScores({ pk_Organization });
      },
    });
  };

  const renderDepartmentList = (width) => {
    const departmentsToDisplay = departments.filter((dept) => {
      return (
        !isLoadingOrganizations &&
        (selectedOrganization == null || dept.pk_Organization == selectedOrganization.pk_Organization)
      );
    });
    return listWrapper({
      listToDisplay: departmentsToDisplay,
      valueKey: 'pk_Department',
      labelKey: 'DepartmentName',
      isLoading: isLoadingDepartments,
      loadingMessage: 'Loading Departments. . .',
      searchString: deptSearchString,
      onClick: (value) => {
        setSelectedDepartment(value);
      },
      selectedValue: selectedDepartment,
      width: width,
      onClear: () => {
        setDeptSearchString('');
      },
      onSearch: (newStr) => {
        setDeptSearchString(newStr);
      },
      onScoreUpdate: (pk_Department) => {
        computeScores({ pk_Organization: selectedOrganization.pk_Organization, pk_Department });
      },
    });
  };

  const renderSeasonList = (width) => {
    return listWrapper({
      listToDisplay: seasons,
      valueKey: 'pk_Season',
      labelKey: 'SeasonName',
      isLoading: isLoadingSeasons,
      loadingMessage: 'Loading Seasons. . .',
      searchString: seasonSearchString,
      onClick: (value) => {
        setSelectedSeason(value);
      },
      selectedValue: selectedSeason,
      width: width,
      onClear: () => {
        setSeasonSearchString('');
      },
      onSearch: (newStr) => {
        setSeasonSearchString(newStr);
      },
      onScoreUpdate: (pk_Season) => {
        computeScores({
          pk_Organization: selectedOrganization.pk_Organization,
          pk_Department: selectedDepartment.pk_Department,
          pk_Season,
        });
      },
    });
  };

  const renderSessionList = (width) => {
    return listWrapper({
      listToDisplay: sessions,
      valueKey: 'pk_InterviewDate',
      labelKey: 'dateLabel',
      onClick: (value) => {
        setSelectedSession(value);
      },
      selectedValue: selectedSession,
      width: width,
      onClear: () => {},
      onSearch: () => {},
    });
  };

  const renderCandidateList = (width) => {
    return listWrapper({
      listToDisplay: candidates,
      valueKey: 'pk_Candidate',
      labelKey: 'FullName',
      isLoading: isLoadingCandidates,
      loadingMessage: 'Loading Candidates. . .',
      searchString: candidateSearchString,
      onClick: (value) => {
        const oldSelectedCandidates = clone(selectedCandidates);
        const existsInOldList = oldSelectedCandidates.findIndex((c) => c.pk_Candidate == value.pk_Candidate);
        if (existsInOldList >= 0) {
          oldSelectedCandidates.splice(existsInOldList, 1);
        } else {
          oldSelectedCandidates.push(value);
        }
        setSelectedCandidates(oldSelectedCandidates);
      },
      selectedValue: selectedCandidates,
      width: width,
      onClear: () => {
        setCandidateSearchString('');
      },
      onSearch: (newStr) => {
        setCandidateSearchString(newStr);
      },
      onScoreUpdate: () => {
        if (selectedCandidates && selectedCandidates.length > 0) {
          const payload = {
            pk_Organization: selectedOrganization.pk_Organization,
            pk_Department: selectedDepartment.pk_Department,
            pk_Season: selectedSeason.pk_Season,
            pk_Candidates: selectedCandidates.map((c) => c.pk_Candidate),
          };
          computeScores(payload);
        }
      },
    });
  };

  return (
    <div>
      <Card>
        <CardHeader>Manage Scoring</CardHeader>
        <CardBody>
          <div style={{ ...style.simpleRow, overflowX: 'scroll', alignItems: 'start', width: '100%' }}>
            <div style={{ ...style.simpleColumn, width: 400, paddingRight: 10 }}>
              <div>Organizations</div>
              {renderOrgList(400)}
            </div>
            <div style={{ ...style.simpleColumn, width: 500, paddingRight: 10 }}>
              <div>Departments</div>
              {renderDepartmentList(500)}
            </div>
            <div style={{ ...style.simpleColumn, width: 400, paddingRight: 10 }}>
              {' '}
              <div>Seasons</div>
              {renderSeasonList(400)}
            </div>
            {/* <div style={{ ...style.simpleColumn, width: 400, paddingRight: 10 }}>{renderSessionList(400)}</div> */}
            <div style={{ ...style.simpleColumn, width: 500 }}>
              {' '}
              <div>Candidates</div>
              {renderCandidateList(500)}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ManageScores;
