import {
  faChevronDown,
  faCopy,
  faEye,
  faFlask,
  faPaperPlane,
  faPlus,
  faSpinner,
  faSave,
  faTimes,
  faSearch,
  faTimesCircle,
  faPortrait,
  faChevronUp,
  faEnvelope,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { useAlert } from 'react-alert';
import Popup from 'reactjs-popup';
import AttachmentsPopover from './AttachmentPopover/AttachmentsPopover';
import moment from 'moment-timezone';
import parse, { domToReact, htmlToDOM, Element } from 'html-react-parser';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import {
  Button,
  CardBody,
  CardHeader,
  CustomInput,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import { PopupWithCloseButton } from '../../../../../../Common.jsx';
import Swal from 'sweetalert2';
import { useAuth0, Auth0Context } from '../../../../../../auth0/reactAuth0Spa';
import { contactSupport, formatBearerToken, PopupWrapper } from '../../../../../../Common.functions';
import { postDataAgnostic, putData, fetchDataAgnostic, postData } from '../../../../../../Services/dataApi';
import Wysiwyg from '../../../../Wysiwyg/Wysiwyg';
import style from './AdminMessageComposer.style';
import './AdminMessageComposer.style.css';
import TemplateItem from './TemplateItem/TemplateItem';
import { connect } from 'react-redux';
import { faEnvelopeOpen } from '@fortawesome/free-regular-svg-icons';
import SingleCandidateDragAndDropItem from 'Body/DragAndDropFiles/SingleCandidateDragAndDropItem';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ConfirmModal from 'Body/RezRATE/Coordinator/Schedule/ConfirmModal';

var myCurrentDate = new Date();

const candidatePlaceholder = [
  {
    AAMCID: '000000',
    CountOfScheduleInterviews: 1,
    DateOfInterview: '2023-06-24',
    Email: 'example@example.com',
    FirstName: 'Jane',
    EnableIsInvited: false,
    InterviewStartTime: '07:00:00',
    IsScheduled: true,
    IsUnresponsive: false,
    Label: '',
    LastName: 'Doe',
    MedicalSchoolOfGraduation: 'Medical School Name',
    MiddleName: '',
    NRMPID: '000000',
    PhotoUrl: '',
    PrescreenAssignedEvaluators: '',
    SFMatchID: '000000',
    Status: '',
    Tags: [],
    WaitlistDates: [
      {
        pk_InterviewDate: 907,
        pk_Candidate: 22833,
        PreferenceOrder: 0,
        Removed: false,
        EmailSent: false,
        ChosenByCandidate: false,
        State: 1,
        Label: 'Label',
        DateOfInterview: '2024-05-10',
        StartTime: '14:00',
      },
      {
        pk_InterviewDate: 908,
        pk_Candidate: 22833,
        PreferenceOrder: 0,
        Removed: false,
        EmailSent: false,
        ChosenByCandidate: false,
        State: 1,
        Label: 'Label 2',
        DateOfInterview: '2024-05-15',
        StartTime: '14:00',
      },
    ],
    color: '#7671BF',
    createdAt: myCurrentDate,
    fk_interviewDate: 1,
    pk_Candidate: -1,
  },
];

const createActionButton = (options) => {
  const { buttonText } = options;

  return `<div style="width: 210px; display: flex; margin:2px;">
      <div style="font-family:sans-serif; font-size:14px; vertical-align:top; background-color:#3498db; border-radius:5px; text-align:center">
        <a
          href=""
          target="_blank"
          style="display:inline-block; color:#ffffff; background-color:#3498db; border:solid 1px #3498db; border-radius:5px; box-sizing:border-box; text-decoration:none; font-size:14px; font-weight:bold; margin:0; padding:12px 25px; text-transform:capitalize; border-color:#3498db"
        >
          ${buttonText}
        </a>
      </div>
    </div>`;

  // return `<table style="margin-right: 3px; margin-left: 3px; margin-top: 2px; margin-bottom: 2px;" cellspacing="2" cellpadding="0" border="0">
  // <tr>
  //   <td bgcolor="#3498db" style="border-radius: 5px; padding: 12px 24px; border: 1px solid #3498db;">
  //     <a href='' target="_blank" alt="Register For Interview Button" style="font-size: 14px; font-family: Arial, sans-serif; color: #ffffff; text-decoration: none; display: inline-block; background: #3498db; font-weight: bold;">${buttonText}</a>
  //   </td>
  // </tr>
  // </table>`;

  // return (
  //   <span style="width: auto; display: inline-block; margin-right: 3px; margin-left: 3px; margin-top: 2px; margin-bottom: 2px;">
  //     <span style="font-family:sans-serif; font-size:14px; vertical-align:top; background-color:#3498db; border-radius:5px; text-align:center">
  //       <a
  //         href=""
  //         target="_blank"
  //         style="display:inline-block; color:#ffffff; background-color:#3498db; border:solid 1px #3498db; border-radius:5px; box-sizing:border-box; text-decoration:none; font-size:14px; font-weight:bold; margin:0; padding:12px 25px; text-transform:capitalize; border-color:#3498db"
  //       >
  //         {buttonText}
  //       </a>
  //     </span>
  //   </span>
  // );
};

const uploadButton = createActionButton({ buttonText: 'Upload Photo' });

// const uploadButton = `<span style="width: auto; display: inline-block;margin-right: 3px; margin-left: 3px; margin-top: 2px; margin-bottom: 2px;">
// <span style="font-family:sans-serif; font-size:14px; vertical-align:top; background-color:#3498db; border-radius:5px; text-align:center">
//   <a
//     href=''
//     target="_blank"
//     style="display:inline-block; color:#ffffff; background-color:#3498db; border:solid 1px #3498db; border-radius:5px; box-sizing:border-box; text-decoration:none; font-size:14px; font-weight:bold; margin:0; padding:12px 25px; text-transform:capitalize; border-color:#3498db"
//   >
//   Upload Photo
//   </a>
// </span>
// </span>`;

// `
// <span style="width: 186px; display: inline-block;" >
//   <div style="font-family:sans-serif; font-size:14px; vertical-align:top; background-color:#3498db; border-radius:5px; text-align:center">
//   <a
//   href=''
//   target="_blank"
//   style="display:inline-block; color:#ffffff; background-color:#3498db; border:solid 1px #3498db; border-radius:5px; box-sizing:border-box; text-decoration:none; font-size:14px; font-weight:bold; margin:0; padding:12px 25px; text-transform:capitalize; border-color:#3498db"
// >
//       Upload Photo
//     </a>
//   </div>
// </span>
// `;

const scheduleInterviewButton = createActionButton({ buttonText: 'Register for Interview' });

// const scheduleInterviewButton = `
// <span style="width: auto; display: inline-block; margin-right: 3px; margin-left: 3px; margin-top: 2px; margin-bottom: 2px;">
//   <span style="font-family:sans-serif; font-size:14px; vertical-align:top; background-color:#3498db; border-radius:5px; text-align:center">
//     <a
//       href=''
//       target="_blank"
//       style="display:inline-block; color:#ffffff; background-color:#3498db; border:solid 1px #3498db; border-radius:5px; box-sizing:border-box; text-decoration:none; font-size:14px; font-weight:bold; margin:0; padding:12px 25px; text-transform:capitalize; border-color:#3498db"
//     >
//       Register for Interview
//     </a>
//   </span>
//   </span>
// `;

const viewScheduleButton = createActionButton({ buttonText: 'View Schedule' });

// const viewScheduleButton = `
// <span style="width: auto; display: inline-block; margin-right: 3px; margin-left: 3px; margin-top: 2px; margin-bottom: 2px;">
//   <span style="font-family:sans-serif; font-size:14px; vertical-align:top; background-color:#3498db; border-radius:5px; text-align:center">
//     <a
//       href=''
//       target="_blank"
//       style="display:inline-block; color:#ffffff; background-color:#3498db; border:solid 1px #3498db; border-radius:5px; box-sizing:border-box; text-decoration:none; font-size:14px; font-weight:bold; margin:0; padding:12px 25px; text-transform:capitalize; border-color:#3498db"
//     >
//       View Schedule
//     </a>
//   </span>
//   </span>
// `;

// this exists on the backend as well, be sure to change that
const formatWaitlistInfo = (candidateDates = [], candidate) => {
  const { DateOfInterview, pk_Candidate } = candidate || {};

  if ((!candidateDates || candidateDates.length === 0 || DateOfInterview) && pk_Candidate !== -1) {
    return 'You are not waitlisted for any dates.';
  }
  let waitlistString = `You've been waitlisted for the following date${candidateDates.length !== 1 ? 's' : ''}:`;
  waitlistString += '<ol style="margin-top: 0; margin-bottom: 0; font-family: sans-serif;">';
  candidateDates
    .sort((a, b) => {
      return a.PreferenceOrder - b.PreferenceOrder;
    })
    .forEach((item) => {
      const { DateOfInterview, Label, State } = item;
      if (!State) {
        return;
      }
      waitlistString += `<li>${moment(item.DateOfInterview).format('dddd, MMMM DD, YYYY')} ${
        Label && Label !== '' ? `(${Label})` : ''
      }</li>`;
    });
  waitlistString += '</ol>';
  return waitlistString;
};

const formatInterviewDate = (dateOfInterview, label) => {
  if (!dateOfInterview) return 'NO DATE SCHEDULED';

  let interviewFormatDate = '';
  if (label && label !== '') {
    interviewFormatDate = `${moment(dateOfInterview).format('dddd, MMMM DD, YYYY')} ${label}`;
    return interviewFormatDate;
  }

  interviewFormatDate = `${moment(dateOfInterview).format('dddd, MMMM DD, YYYY')}`;
  return interviewFormatDate;
};

const AdminMessageComposer = ({
  activeDepartment,
  availableTemplates,
  getTemplateById,
  selectedCandidateIds,
  setSelectedCandidateIds,
  setCandidates,
  setSelectedCandidate,
  setSelectedTemplate,
  getTemplates,
  candidates,
  selectedTemplate,
  selectedTemplateId,
  selectedCandidate,
  isUpdating,
  setSelectedTemplateId,
  setPageChanges,
  pageChanges,
  resetList,
  getCandidates,
  user,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [referenceEditorState, setReferenceEditorState] = useState(EditorState.createEmpty());
  const [subject, setSubject] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [fullEmailPreview, setFullEmailPreview] = useState('<div></div>');
  const [selectedCandidateIdsKeyed, setSelectedCandidateIdsKeyed] = useState({});
  const [isSending, setIsSending] = useState(false);
  const [editorText, setEditorText] = useState('');
  const [candidatesFilteredBySelected, setCandidatesFilteredBySelected] = useState([]);
  const [candidateForPreview, setCandidateForPreview] = useState(candidatePlaceholder[0]);
  const [templateToEdit, setTemplateToEdit] = useState(null); // Used for name change. has no isAutomatedTemplate
  const [mySelectedTemplate, setMySelectedTemplate] = useState(null); // used for checking if isAutomatedTemplate or no.

  const [templateWrapper, setTemplateWrapper] = useState('');
  const [collapseHeader, setCollapseHeader] = useState(false);
  const [emailBodyHasChanges, setEmailBodyHasChanges] = useState(false);
  const [subjectHasChanges, setSubjectHasChanges] = useState(false);

  const [templateNameToFilter, setTemplateNameToFilter] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const [isSaving, setIsSaving] = useState(false);
  const [showMissingEmailsModal, setShowMissingEmailsModal] = useState(false);
  const [candidatesWithNoEmail, setCandidatesWithNoEmail] = useState([]);
  const [forcePlainText, setForcePlainText] = useState();

  const dContext = useContext(DepartmentContext);
  const aContext = useContext(Auth0Context);

  const [showTemplatesPopover, setShowTemplatesPopover] = useState();
  const [showAttachmentPopover, setShowAttachmentPopover] = useState(false);
  const [forceChildEditorState, setForceChildEditorState] = useState();

  const templateInput = useRef();
  const alert = useAlert();
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const buttonsHTML = {
    '{PhotoUploadButton}': uploadButton,
    '{ViewScheduleButton}': viewScheduleButton,
    '{RegistrationInvitationButton}': scheduleInterviewButton,
  };

  useEffect(() => {
    getSettings();
    getTemplateWrapper();
  }, []);

  useEffect(() => {
    if (subject) {
      if (selectedTemplate && subject.trim() === selectedTemplate.Subject.trim()) {
        setSubjectHasChanges(false);
      } else {
        setSubjectHasChanges(true);
      }
    } else if (!selectedTemplate || !selectedTemplate.Subject) {
      setSubjectHasChanges(false);
    }
  }, [subject]);

  useEffect(() => {
    if (emailBodyHasChanges || subjectHasChanges) {
      setPageChanges({ ...pageChanges, sendMessage: true });
    } else {
      setPageChanges({ ...pageChanges, sendMessage: false });
    }
  }, [emailBodyHasChanges, subjectHasChanges]);

  useEffect(() => {
    if (selectedTemplate) {
      setPageChanges({ ...pageChanges, sendMessage: false });
      setSubject(selectedTemplate.Subject);
      convertHtmlToDraft(selectedTemplate.Body || '', {
        callback: (newEditorState) => {
          setReferenceEditorState(newEditorState);
          if (forceChildEditorState) {
            forceChildEditorState(newEditorState);
          }
        },
      });

      setMySelectedTemplate(
        availableTemplates.find((t) => {
          return t.pk_CommunicationTemplate == selectedTemplate.pk_CommunicationTemplate;
        }),
      );
    } else {
      // if user selects "No Template Selected..." just reset everything
      setSubject('');
      convertHtmlToDraft('', {
        callback: (newEditorState) => {
          setReferenceEditorState(newEditorState);
          if (forceChildEditorState) {
            forceChildEditorState(newEditorState);
          }
        },
      });
    }
  }, [JSON.stringify(selectedTemplate)]);

  useEffect(() => {
    if (candidateForPreview && editorText.trim().length > 0) {
      const email = constructEmail({ candidate: candidateForPreview, wrapper: templateWrapper, addActual: true });

      setFullEmailPreview(email);
    } else {
      // if there is no template selected just show the wrapper

      setFullEmailPreview(templateWrapper.replace('{EMAILBODY}', ''));
    }
  }, [templateWrapper, showPreview, candidateForPreview]);

  useEffect(() => {
    // map them to keys so matching them up with candidates is faster
    if (selectedCandidateIds) {
      const selectedCandidateIdsKeyed = {};
      selectedCandidateIds.forEach((item) => {
        selectedCandidateIdsKeyed[item] = true;
      });
      setSelectedCandidateIdsKeyed(selectedCandidateIdsKeyed);

      if (candidates && candidates.length > 0) {
        const candidatesFilteredBySelected = candidates.filter((item) => {
          if (selectedCandidateIdsKeyed[item.pk_Candidate]) {
            return true;
          }
          return false;
        });
        setCandidatesFilteredBySelected(candidatesFilteredBySelected);
      }
    }
  }, [selectedCandidateIds]);

  useEffect(() => {
    if (availableTemplates) {
      const newFilteredTemplates = availableTemplates.filter((t) => {
        return t.Title.toLowerCase().indexOf(templateNameToFilter) >= 0 || !templateNameToFilter;
      });
      setTemplateToEdit(null);
      setFilteredTemplates(newFilteredTemplates);
    }
  }, [availableTemplates, templateNameToFilter]);

  useEffect(() => {
    setTemplateNameToFilter('');
  }, [showTemplatesPopover]);

  useEffect(() => {
    // console.log(parseHTML(fullEmailPreview));
  }, [fullEmailPreview]);

  const resetChanges = () => {
    setPageChanges({ ...pageChanges, sendMessage: false });
    setEmailBodyHasChanges(false);
    setSubjectHasChanges(false);
  };

  const onEditorStateChange = (newEditorState) => {
    const blocks = convertToRaw(newEditorState.getCurrentContent()).blocks;

    const value = blocks.map((block) => (!block.text.trim() && '\n') || block.text).join('\n');
    // const value = blocks
    //   .map((block) => {
    //     return block.text || '';
    //   })
    //   .join('\n');

    if (selectedTemplate) {
      const blocksFromHtml = htmlToBlocks(selectedTemplate.Body);
      const selectedTemplateValue = blocksFromHtml
        .map((block) => (!block.text.trim() && '\n') || block.text)
        .join('\n');
      if (value !== selectedTemplateValue) {
        setEmailBodyHasChanges(true);
      } else {
        setEmailBodyHasChanges(false);
      }
    } else if (value.trim().length > 0) {
      setEmailBodyHasChanges(true);
    } else {
      setEmailBodyHasChanges(false);
      // setSubjectHasChanges(true);
    }
    setEditorState(newEditorState);
    setEditorText(value);
  };

  const noEmailCandidates = (pk_List, candidateList) => {
    const noEmailCandidates = candidateList.filter((item) => {
      return pk_List.includes(item.pk_Candidate) && (!item.Email || item.Email === '');
    });
    return noEmailCandidates;
  };

  const htmlToBlocks = (html = '') => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    return contentBlocks;
  };

  const convertHtmlToDraft = (html, options) => {
    const { isInline, callback } = options || {};
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const editorState = EditorState.createWithContent(contentState);

    if (callback) {
      callback(editorState);
    }

    if (isInline) {
      return;
    } else {
      onEditorStateChange(editorState);
    }
  };

  const constructEmail = (options) => {
    const { candidate, template, wrapper, addActual } = options;

    const { DateOfInterview, FirstName, LastName, WaitlistDates, InterviewStartTime, Label, Email } = candidate;

    let bodyCopy = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      .replace(/<p>(\s|\n)*<\/p>/g, '<span style="display: inline-block; width: 100%;">&nbsp;</span>')
      .replace(/\<p>/g, '<p style="margin-top: 0; margin-bottom: 0;">');

    // TODO: try adding align styling to buttons depending on their <p> parent. Check if <p> can even accept non-text.

    const interviewDate = DateOfInterview
      ? moment.tz(`${DateOfInterview || ''} ${InterviewStartTime || ''}`, null).format('dddd, MMMM DD, YYYY')
      : '<span style="font-weight: 700">NO ASSIGNED INTERVIEW DATE</span>';
    const { REACT_APP_BUILD_TYPE, NODE_ENV } = process.env;

    let server = 'https://dev.rezrate.com/candidate';
    // process.env.NODE_ENV === 'development'
    //   ? 'https://dev.rezrate.com/candidate'
    //   : 'https://app.rezrate.com/candidate';

    if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'liveproduction') {
      server = 'https://app.rezrate.com/candidate';
    }

    if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'livedevelopment') {
      server = 'https://dev.rezrate.com/candidate';
    }

    const cUUID = candidateForPreview.CandidateUUID || 'demo';
    const sUUID = candidateForPreview.ScheduleUUID || 'demo';

    bodyCopy = bodyCopy
      .replaceAll('text-align:center;', 'text-align:left;')
      .replaceAll('text-align:right;', 'text-align:left;')
      .replaceAll('justify-content:center;', 'justify-content:left;')
      .replaceAll('justify-content:right;', 'justify-content:left;');

    bodyCopy = bodyCopy.replace(/\{FirstName}/g, FirstName || '');
    bodyCopy = bodyCopy.replace(/\{LastName}/g, LastName || '');

    bodyCopy = bodyCopy.replace(/target="_self"/g, 'target="_blank"');

    bodyCopy = addButtonsToEmail({ bodyCopy, addActual });

    bodyCopy = bodyCopy.replace(/\{WaitlistInfo}/g, formatWaitlistInfo(WaitlistDates, candidate));
    bodyCopy = bodyCopy.replace(
      /\{InterviewDate}/g,
      interviewDate ? `${interviewDate}${Label ? ` ${Label}` : ''}` : '',
    );

    // bodyCopy = bodyCopy.replace(
    //   /\{RegistrationInvitationButton}/g,
    //   scheduleInterviewButton.replace(`href=''`, `href='${server}/${cUUID}'`),
    // );
    // bodyCopy = bodyCopy.replace(
    //   /\{ViewScheduleButton}/g,
    //   viewScheduleButton.replace(`href=''`, `href='${server}/schedule/${sUUID}'`),
    // );

    // bodyCopy = bodyCopy.replace(
    //   /\{PhotoUploadButton}/g,
    //   uploadButton.replace(`href=''`, `href='${server}/photo/${cUUID}'`),
    // );

    // Removed double spacing for Enter linebreaks. Makes them look similar to Shift + Enter linebreaks.============
    bodyCopy = bodyCopy.replace(/<p>(\s|\n)*<\/p>/g, '<span style="display: inline-block; width: 100%;">&nbsp;</span>');
    bodyCopy = bodyCopy.replace(/\<p>/g, '<p style="margin-top: 0; margin-bottom: 0;">');

    // ===============================================================================================================

    let fullEmail = wrapper.replace('{EMAILBODY}', bodyCopy);

    fullEmail = fullEmail.replace(/\{{MAILTOADDRESS}}/g, email ? email : '');
    return fullEmail;
  };

  // Add styling to button spans. This is a workaround for the fact that the buttons are being stripped of their styles
  const addButtonsToEmail = (options) => {
    let { bodyCopy, addActual = true } = options;
    const { REACT_APP_BUILD_TYPE, NODE_ENV } = process.env;
    bodyCopy = bodyCopy.replace(/<br>/g, '<span style="display: inline-block; width: 100%;">&nbsp;</span>');
    // bodyCopy = '<span id="tempWrapper">' + bodyCopy + '</span>';

    const htmlString = bodyCopy;
    const stringsToReplace = Object.keys(buttonsHTML);

    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');

    const cUUID = candidateForPreview.CandidateUUID || 'demo';
    const sUUID = candidateForPreview.ScheduleUUID || 'demo';

    let server = 'https://dev.rezrate.com/candidate';

    if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'liveproduction') {
      server = 'https://app.rezrate.com/candidate';
    }

    if (REACT_APP_BUILD_TYPE && REACT_APP_BUILD_TYPE === 'livedevelopment') {
      server = 'https://dev.rezrate.com/candidate';
    }

    // Replace ALL <p> elements with Buttons with span, and preserve styling. (We lost styling unless we do this.)
    // doc.querySelectorAll('p').forEach((paragraph) => {
    //   let textContent = paragraph.textContent; // removeAllButButtonStringsFromString(paragraph.textContent, Object.keys(buttonsHTML));
    //   let innerHTML = paragraph.innerHTML;
    //   const pattern = new RegExp(`(${stringsToReplace.join('|')})`, 'g');
    //   let textContentArray = textContent.split(pattern).filter(Boolean);
    //   if (textContent.indexOf("You've been waitlisted for the following dates:") > -1) {
    //     console.log('paragraph.innerHTML: ', paragraph);
    //     console.log('paragraph: ', paragraph.querySelectorAll(':scope > *'));
    //   }
    //   let hasButton = false;
    //   const replacementDiv = doc.createElement('span');
    //   // Set the innerHTML of the <div> with the content of the <p>
    //   // replacementDiv.innerHTML = paragraph.innerHTML;
    //   replacementDiv.innerText = '';
    //   // Copy styles from the <p> element to the replacement <div>
    //   const computedStyles = window.getComputedStyle(paragraph);
    //   for (const styleName of computedStyles) {
    //     replacementDiv.style[styleName] = computedStyles[styleName];
    //   }
    //   replacementDiv.style.cssText = paragraph.style.cssText;
    //   replacementDiv.style.width = '100%';
    //   replacementDiv.style.display = 'flex';
    //   replacementDiv.style.fontFamily = 'sans-serif';
    //   replacementDiv.style.alignItems = 'center';

    //   if (paragraph.style.textAlign) {
    //     replacementDiv.style.justifyContent = paragraph.style.textAlign;
    //   }

    //   if (addActual) {
    //     textContentArray.forEach((text, index) => {
    //       if (stringsToReplace.indexOf(text) > -1) {
    //         hasButton = true;
    //         let buttonHTML = buttonsHTML[text];
    //         if (addActual) {
    //           switch (text) {
    //             case '{RegistrationInvitationButton}':
    //               buttonHTML = buttonHTML.replace(`href=''`, `href='${server}/${cUUID}'`);
    //               break;
    //             case '{PhotoUploadButton}':
    //               buttonHTML = buttonHTML.replace(`href=''`, `href='${server}/photo/${cUUID}'`);
    //               break;
    //             case '{ViewScheduleButton}':
    //               buttonHTML = buttonHTML.replace(`href=''`, `href='${server}/schedule/${sUUID}'`);
    //               break;
    //           }
    //         }

    //         const replacementHTML = parser.parseFromString(buttonHTML, 'text/html').body.firstChild;
    //         replacementDiv.appendChild(replacementHTML);
    //       } else {
    //         console.log('enteredtextNode! text: ', text);
    //         const textNode = doc.createElement('span');
    //         textNode.innerText = text;
    //         replacementDiv.appendChild(textNode);
    //       }
    //     });
    //   } else {
    //     console.log('');
    //     replacementDiv.innerHTML = innerHTML;
    //     // replacementDiv.innerText = textContent;
    //     // while (paragraph.firstChild) {
    //     //   replacementDiv.appendChild(paragraph.firstChild);
    //     // }
    //   }

    //   if (hasButton || !addActual) {
    //     // console.log('replacementDiv.style: ', replacementDiv.style);
    //     // console.log('paragraph.style: ', paragraph.style);
    //     // console.log('computedStyles: ', computedStyles);
    //     // paragraph.style.cssText = '';
    //     // replacementDiv.attributes.class = 'p to span';
    //     // replacementDiv.innerHTML = paragraph.innerHTML;
    //     // paragraph.replaceWith(replacementDiv);
    //   } else {
    //   }
    //   paragraph.replaceWith(replacementDiv);
    // });

    doc.querySelectorAll('p').forEach((paragraph) => {
      paragraph.style['margin-top'] = '0';
      paragraph.style['margin-bottom'] = '0';
      paragraph.style.fontFamily = 'sans-serif';
      if (paragraph.innerText === '' || !paragraph.innerText) {
        paragraph.innerHTML = '&nbsp;';
      }
    });

    doc.querySelectorAll('ol').forEach((ol) => {
      ol.style['margin-top'] = '0';
      ol.style['margin-bottom'] = '0';
      ol.style.fontFamily = 'sans-serif';
    });

    doc.querySelectorAll('ul').forEach((ul) => {
      ul.style['margin-top'] = '0';
      ul.style['margin-bottom'] = '0';
      ul.style.fontFamily = 'sans-serif';
    });

    doc.querySelectorAll('ins').forEach((ins) => {
      // Replace all ins tag with <u> tag, but keep everything inside as is.
      const u = doc.createElement('u');
      u.innerHTML = ins.innerHTML;
      ins.replaceWith(u);
    });

    doc.querySelectorAll('span').forEach((span) => {
      span.style.fontFamily = `sans-serif`;
      if (span.innerText === '' || !span.innerText) {
        span.innerHTML = '&nbsp;';
      }
    });
    doc.querySelectorAll('a').forEach((a) => {
      a.style.textDecoration = 'underline';
    });
    // Add Table layout
    // if (!addActual) {
    //   // Get the parent element
    //   let tempWrapper = doc.getElementById('tempWrapper');
    //   let spans = tempWrapper.querySelectorAll(':scope > *');

    //   // Create a new table
    //   let table = document.createElement('table');
    //   let tbody = document.createElement('tbody');
    //   table.style.width = '100%';
    //   table.style.padding = 0;
    //   table.style['border-spacing'] = 0;
    //   table.appendChild(tbody);

    //   // Wrap each span in a <tr><td> and append to the table
    //   spans.forEach((span) => {
    //     let tr = document.createElement('tr');
    //     let td = document.createElement('td');
    //     tr.style.backgroundColor = 'initial';
    //     tr.style.padding = 0;
    //     td.style.padding = 0;

    //     // Move the span from the original parent to the new <td>
    //     td.appendChild(span);
    //     tr.appendChild(td);
    //     tbody.appendChild(tr);
    //   });

    //   doc.body.replaceChild(table, tempWrapper);
    // }

    let modifiedHtmlString = doc.body.outerHTML;
    if (addActual) {
      // Replace all button elements not inside a <p> element with an actual button.

      modifiedHtmlString = modifiedHtmlString.replace(
        /\{RegistrationInvitationButton}/g,
        buttonsHTML[`{RegistrationInvitationButton}`].replace(`href=""`, `href='${server}/${cUUID}'`),
      );

      modifiedHtmlString = modifiedHtmlString.replace(
        /\{ViewScheduleButton}/g,
        buttonsHTML[`{ViewScheduleButton}`].replace(`href=""`, `href='${server}/schedule/${sUUID}'`),
      );

      modifiedHtmlString = modifiedHtmlString.replace(
        /\{PhotoUploadButton}/g,
        buttonsHTML[`{PhotoUploadButton}`].replace(`href=""`, `href='${server}/photo/${cUUID}'`),
      );
    }
    return modifiedHtmlString;
  };

  const removeAllButButtonStringsFromString = (stringToClean = '', stringsToUse) => {
    const pattern = new RegExp(stringsToUse.join('|'), 'g');
    const newString = stringToClean.replace(new RegExp(`[^${stringsToUse.join('')}]+`, 'g'), '');
    return newString;
  };

  const toggleAttachmentPopup = () => {
    setShowAttachmentPopover(true);
  };

  const getTemplateWrapper = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/template/wrapper',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setTemplateWrapper(result.data.Wrapper);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const createNewTemplate = async (isNew, isBlank) => {
    const { value: title } = await Swal.fire({
      title: isNew
        ? 'New Template'
        : `Cloning "${selectedTemplate && selectedTemplate.Title ? selectedTemplate.Title : '(No Title)'}"`,
      input: 'text',
      inputLabel: 'Template Title',
      inputPlaceholder: 'Please enter a title for your template',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value || value.length === 0) {
          return 'Please make sure to include a title';
        }
      },
    });

    if (title) {
      const hasText = editorState.getCurrentContent().hasText();
      setIsSaving(true);
      const html = !isBlank
        ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
        : isNew || isBlank
        ? EditorState.createEmpty()
        : null;

      getTokenSilently().then((token) => {
        postDataAgnostic(
          'department/createCommunicationTemplate',
          { pk_Department: activeDepartment.pk_Department },
          { html, subject: isNew || isBlank ? '' : subject, title },
          formatBearerToken(token),
        )
          .then((result) => {
            if (result) {
              alert.success('Template successfully saved.');
              if (result.data && result.data.pk_CommunicationTemplate) {
                getTemplates(result.data.pk_CommunicationTemplate);
              }
              setIsSaving(false);
              resetChanges();
            } else {
              alert.error(contactSupport);
              setIsSaving(false);
            }
          })
          .catch((err) => {
            alert.error(contactSupport);
            if (err.message === 'Login required') {
              loginWithRedirect();
            }
          });
      });
    }
  };

  const getSettings = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/settings',
          { pk_Department: activeDepartment.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setEmail(res.data[0].DepartmentEmail);
            setName(res.data[0].DepartmentName);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const parseHTML = (input) => {
    return parse(input, {
      replace: (domNode, index) => {
        const buttonClasses = ['upload_button'];
        if (domNode instanceof Element && domNode.attribs.class === 'remove') {
          return <></>;
        }

        // if (domNode.name === 'div' && buttonClasses.indexOf(domNode.attribs.classname) > -1) {
        //   // console.log('domNode: ', domNode);
        //   // console.log('domNode.attribs: ', domNode.attribs);
        //   // console.log('domNode.children: ', domNode.children);

        //   const parseChildNodes = (children) => {
        //     return children.map((child, childIndex) => {
        //       if (child.type === 'tag') {
        //         return (
        //           <div key={childIndex}>
        //             {React.createElement(
        //               child.name,
        //               { key: childIndex, ...child.attribs },
        //               parseChildNodes(child.children),
        //             )}
        //           </div>
        //         );
        //       } else if (child.type === 'text') {
        //         return child.data;
        //       } else {
        //         return null;
        //       }
        //     });
        //   };

        //   // return <div key={index}>{React.Children.toArray(domNode.children)}</div>;
        //   const modifiedChildren = domNode.children.map((child, childIndex) => {
        //     if (child.type === 'tag') {
        //       // If the child is an HTML element (tag), handle it accordingly
        //       return (
        //         <div key={childIndex}>
        //           {React.createElement(
        //             child.name,
        //             {
        //               key: index,
        //               ...child.attribs,
        //               classname: `${child.attribs.classname} ${child.parent.attribs.classname}`,
        //             },
        //             parseChildNodes(child.children),
        //           )}
        //         </div>
        //       );
        //     } else if (child.type === 'text') {
        //       // If the child is a text node, render its data
        //       return child.data;
        //     } else {
        //       // For other types of children, you may need to handle them accordingly
        //       return <div>Unknown child</div>;
        //     }

        //     return <div key={index}>{modifiedChildren}</div>;
        //   });
        // }
      },
    });
  };

  const handleSendMail = (isTest) => {
    if (!editorText.trim().length === 0 || !candidatesFilteredBySelected) return;

    let candidateEmails;

    const formattedBody = addButtonsToEmail({
      bodyCopy: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        .replace(/<p>(\s|\n)*<\/p>/g, '<p style="display: inline-block; width: 100%;">&nbsp;</p>')
        .replace(/\<p>/g, '<p style="margin-top: 0; margin-bottom: 0;">')
        .replaceAll('text-align:center;', 'text-align:left;')
        .replaceAll('text-align:right;', 'text-align:left;')
        .replaceAll('justify-content:center;', 'justify-content:left;')
        .replaceAll('justify-content:right;', 'justify-content:left;'),
      addActual: false,
    });
    // const fullEmail = templateWrapper.replace(
    //   '{EMAILBODY}',
    //   draftToHtml(convertToRaw(editorState.getCurrentContent()))
    //     .replace(/<p>(\s|\n)*<\/p>/g, '<br/>')
    //     .replace(/\<p/g, '<p style="margin-top: 0; margin-bottom: 0;"'),
    // );

    const fullEmail = templateWrapper.replace('{EMAILBODY}', formattedBody);

    if (isTest) {
      const firstDay = new Date();
      const nextWeek = new Date(firstDay.getTime() + 60 * 24 * 60 * 60 * 1000);
      const { email, email_verified, name, nickname, picture, sub, updated_at } = aContext.user;
      candidateEmails = [
        {
          FirstName: 'Jane',
          LastName: 'Doe',
          RegistrationInvitationButton: '',
          ViewScheduleButton: '',
          WaitlistInfo: '',
          ScheduleUUID: 'demo',
          CandidateUUID: 'demo',
          InterviewDate: moment(nextWeek).format('dddd, MMM. DD, YYYY'),
          PhotoUploadButton: '',
          UNSUBSCRIBE: '',
          Email: email,
          pk_Candidate: null,
        },
      ];
    } else {
      candidateEmails = candidatesFilteredBySelected.map((candidate) => {
        const {
          FirstName,
          LastName,
          DateOfInterview,
          pk_Candidate,
          Email,
          ScheduleUUID,
          pk_InterviewDate,
          CandidateUUID,
          WaitlistDates,
          Label,
        } = candidate;

        return {
          FirstName: FirstName,
          LastName: LastName,
          RegistrationInvitationButton: '',
          ViewScheduleButton: '',
          CandidateUUID,
          pk_InterviewDate,
          ScheduleUUID,
          WaitlistInfo: formatWaitlistInfo(WaitlistDates, candidate),
          InterviewDate: formatInterviewDate(DateOfInterview, Label),
          PhotoUploadButton: '',
          UNSUBSCRIBE: '',
          Email,
          pk_Candidate,
        };
      });
    }

    setIsSending(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/admin/sendCandidateEmails',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Template: selectedTemplate ? selectedTemplate.pk_CommunicationTemplate : null,
          },
          { candidateEmails, emailBody: fullEmail, from: 'message@rezrate.com', subject },
          formatBearerToken(token),
        )
          .then((result) => {
            if (isTest) {
              Swal.fire('Success!', 'Test Email has been sent to your inbox', 'success');
              setIsSending(false);
              return;
            }
            Swal.fire('Success!', 'Emails have been sent', 'success');
            resetList();

            getCandidates();

            setSelectedCandidateIds([]);
            resetChanges();
          })
          .catch((err) => {
            console.log('err: ', err);
            Swal.fire(
              'Error',
              'Something went wrong sending the emails, please contact support or try again later.',
              'error',
            );
          })
          .finally(() => {
            setIsSending(false);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const updateExistingTemplate = () => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const fieldsToUpdate = {
      html,
      subject: subject ? subject.trim() : '',
    };
    getTokenSilently()
      .then((token) => {
        putData(
          'department/updateCommunicationTemplateById',
          {
            pk_CommunicationTemplate: selectedTemplate.pk_CommunicationTemplate,
            pk_Department: activeDepartment.pk_Department,
          },
          { fieldsToUpdate },
          formatBearerToken(token),
        )
          .then((result) => {
            if (!result.error && !result.data.InvalidTemplate) {
              alert.success('Template successfully updated.');
              resetChanges();
              getTemplates();
            } else if (result.data.InvalidTemplate) {
              const { Message } = result.data;
              const { Header, Body } = Message || {};
              Swal.fire(
                Header ? Header : 'Error',
                Body ? Body : 'Something went wrong updating the template, please contact support or try again later.',
                'error',
              );
            } else {
              alert.error(contactSupport);
            }
          })
          .catch((err) => {
            alert.error(contactSupport);
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  const saveTemplate = () => {
    if (selectedTemplate) {
      updateExistingTemplate();
    } else {
      createNewTemplate(true);
    }
  };

  const myGetTemplates = (id = null) => {
    getTemplates(id, (err) => {
      if (!err) {
      }
    });
  };

  const renderTemplatesPopover = () => {
    return (
      <Popover
        flip={false}
        placementPrefix="templatesPopover bs-popover"
        isOpen={showTemplatesPopover}
        placement="bottom"
        trigger="legacy"
        target="template_btn"
        toggle={(e) => {
          if (e.target.innerHTML !== 'Cancel') {
            if (showTemplatesPopover) {
              setTemplateToEdit(null);
            }

            setShowTemplatesPopover(!showTemplatesPopover);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Templates</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setTemplateToEdit(null);
                  setShowTemplatesPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div style={{ marginBottom: 20, display: 'flex' }}>
            <Input
              type="text"
              placeholder="Search Templates"
              onChange={(e) => {
                setTemplateNameToFilter(e.target.value.toLowerCase());
              }}
            />
          </div>
          <div style={{ height: 250, overflowY: 'scroll' }}>
            {filteredTemplates
              ? filteredTemplates.map((template, i) => {
                  return (
                    <TemplateItem
                      selectedTemplate={selectedTemplate}
                      setSelectedTemplate={setSelectedTemplate}
                      templateToEdit={templateToEdit}
                      setTemplateToEdit={setTemplateToEdit}
                      showTemplatesPopover={showTemplatesPopover}
                      template={{ ...template, index: i }}
                      getTemplateById={getTemplateById}
                      setShowTemplatesPopover={setShowTemplatesPopover}
                      getTemplates={myGetTemplates}
                      pageChanges={pageChanges}
                      setPageChanges={setPageChanges}
                      setSubjectHasChanges={setSubjectHasChanges}
                      setEmailBodyHasChanges={setEmailBodyHasChanges}
                    />
                  );
                })
              : null}
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const hasSearchValues = true;

  const renderCandidates = () => {
    if (candidates && candidateForPreview) {
      const candidatesInFilter = candidatesFilteredBySelected && candidatesFilteredBySelected.length > 0;
      let candidateArrayToUse = candidatesInFilter ? candidatesFilteredBySelected : candidatePlaceholder;

      return (
        <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontWeight: '700' }}>Candidates</p>
          </div>
          {candidateArrayToUse.map((item, i) => {
            const { FirstName, LastName, PhotoUrl, pk_Candidate } = item;

            const orgBackgroundColor = i % 2 == 0 ? 'rgb(199, 203, 209)' : '#f8f9fa';
            const selectedCandidate = pk_Candidate === candidateForPreview.pk_Candidate;
            const backgroundColor = selectedCandidate ? 'rgb(217, 245, 255)' : orgBackgroundColor;
            return (
              <div
                style={{ display: 'flex', padding: '15px', backgroundColor, cursor: 'pointer' }}
                onClick={() => setCandidateForPreview(item)}
              >
                <div style={{ width: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {PhotoUrl ? (
                    <img src={PhotoUrl} style={{ height: '40px', width: 'auto' }} />
                  ) : (
                    <FontAwesomeIcon icon={faPortrait} style={{ fontSize: 30 }} />
                  )}
                </div>
                <div>{`${LastName ? `${LastName}, ` : ''}${FirstName || ''}`}</div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div />;
    }
  };

  const renderAttachmentsPopover = () => {
    return;
    return (
      <Popover
        flip={false}
        placementPrefix="attachmentPopver bs-popover"
        isOpen={showAttachmentPopover}
        placement="bottom"
        trigger="legacy"
        target="attachment_button"
        toggle={(e) => {
          setShowAttachmentPopover((prevState) => !prevState);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={style.buttonLabel}>Attachments</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={() => {
                  setTemplateToEdit(null);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <AttachmentsPopover showNewAttachmentPopover={true} setShowNewAttachmentPopover={true} />
        </PopoverBody>
      </Popover>
    );
  };

  const renderPreviewModal = () => {
    const { Email } = candidateForPreview;
    return (
      <div id="popup_email_preview_wrapper">
        <PopupWithCloseButton closeOnDocumentClick={true} open={showPreview} closeFunction={setShowPreview}>
          <div style={{ display: 'flex' }}>
            <div style={{ width: 200 }}>{renderCandidates()}</div>
            <div>
              <div style={style.simpleRow}>
                <div style={style.simpleColumn}>
                  <div>
                    <div style={style.inputContainer}>
                      <div style={{ ...style.simpleColumn, width: '50%' }}>
                        <div style={style.simpleRow}>
                          <div style={style.templateLabelContainer}>
                            <div style={style.templateLabel}>From:</div>
                          </div>
                          <div style={style.templateInputContainer}>
                            <Input
                              size="sm"
                              className="preview-inputs"
                              disabled={true}
                              value={email}
                              placeholder="From Email"
                              type="text"
                              style={style.templateInput}
                            />
                          </div>
                        </div>
                      </div>
                      <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 10 }}>
                        <div style={style.simpleRow}>
                          <div style={style.templateLabelContainer}>
                            <div style={style.templateLabel}>To:</div>
                          </div>
                          <div style={style.templateInputContainer}>
                            <Input
                              size="sm"
                              className="preview-inputs"
                              disabled={true}
                              value={Email || ''}
                              invalid={Email == null || Email == ''}
                              placeholder="No Email"
                              type="text"
                              style={style.templateInput}
                            />
                            {/* <FormFeedback>Candidate has no email!</FormFeedback> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={style.inputContainer}>
                      <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 10 }}>
                        <div style={style.simpleRow}>
                          <div style={style.templateLabelContainer}>
                            <div style={style.templateLabel}>Subject:</div>
                          </div>
                          <div style={style.templateInputContainer}>
                            <Input
                              size="sm"
                              className="preview-inputs"
                              disabled={true}
                              value={subject}
                              placeholder="Subject"
                              type="text"
                              style={style.templateInput}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ overflow: 'scroll', maxHeight: '600px', maxWidth: '750px' }}>
                {parseHTML(fullEmailPreview)}
              </div>
            </div>
          </div>
        </PopupWithCloseButton>
      </div>
    );
  };

  const renderMissingEmailsModal = () => {
    // showMissingEmailsModal
    let candidateNames = [];

    candidatesWithNoEmail.forEach((item, i) => {
      candidateNames.push(
        <div style={{ ...style.simpleRow, justifyContent: 'left' }}>{`${i + 1}.) ${item.FirstName ||
          ''} ${item.LastName || ''} \n`}</div>,
      );
    });

    const message = (
      <div style={style.simpleColumn}>
        <div>The following candidates are missing email addresses:</div>
        <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ ...style.simpleColumn, alignItems: 'flex-start', justifyContent: 'left', width: '80%' }}>
            {candidateNames}
          </div>
        </div>
      </div>
    );
    return (
      <ConfirmModal
        isOpen={showMissingEmailsModal}
        title={'Unable to send.'}
        message={message}
        buttonChoices={
          <Button
            style={{ width: 100 }}
            onClick={() => {
              setShowMissingEmailsModal(false);
            }}
          >
            Ok
          </Button>
        }
      />
    );
  };

  const oldContent = convertToRaw(referenceEditorState.getCurrentContent());
  const oldTextContent = oldContent.blocks.map((block) => block.text).join('\n');

  const newContent = convertToRaw(editorState.getCurrentContent());
  const newTextContent = newContent.blocks.map((block) => block.text).join('\n');

  return (
    <>
      <Popup open={isSending} closeOnDocumentClick={false}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '15px',
            alignItems: 'center',
          }}
        >
          <div style={{ paddingBottom: '15px' }}>
            <FontAwesomeIcon icon={faSpinner} spin size="3x" />
          </div>
          <h3>Sending Emails...</h3>
        </div>
      </Popup>
      {renderPreviewModal()}

      <CardHeader style={{ position: 'sticky', top: 0, zIndex: 5, backgroundColor: '#eeeeee' }}>
        <div style={style.simpleRow}>
          <div style={style.simpleColumn}>
            <div style={{ ...style.inputContainer, marginBottom: 10 }}>
              <div style={{ ...style.simpleColumn, width: '100%' }}>
                <div style={style.simpleRow}>
                  <div style={style.templateLabelContainer}>
                    <div style={style.templateLabel}>Template</div>
                  </div>
                  <div style={style.templateInputContainer}>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FontAwesomeIcon
                              id="robot_icon"
                              style={{
                                fontSize: 13,
                                cursor:
                                  mySelectedTemplate && mySelectedTemplate.IsAutomationTemplate ? 'pointer' : 'default',
                              }}
                              icon={
                                mySelectedTemplate == null || !mySelectedTemplate.IsAutomationTemplate
                                  ? faEnvelopeOpen
                                  : faRobot
                              }
                            />
                            {mySelectedTemplate && mySelectedTemplate.IsAutomationTemplate ? (
                              <UncontrolledTooltip placement="top" target="robot_icon">
                                The Template is tied to{' '}
                                {mySelectedTemplate ? mySelectedTemplate.AutomatedTemplateType : '---'} automation.
                              </UncontrolledTooltip>
                            ) : null}
                          </div>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        size="sm"
                        id="template_btn"
                        autocomplete={'fake_dropdown_template'}
                        ref={templateInput}
                        disabled={isUpdating || isSaving}
                        placeholder="Template"
                        type="text"
                        style={style.templateInput}
                        onClick={(e) => {
                          if (e) {
                            e.preventDefault();
                            e.target.blur();
                          }

                          setShowTemplatesPopover(true);
                        }}
                        // onChange={getTemplateById}
                        defaultValue={selectedTemplate && selectedTemplate.Title ? selectedTemplate.Title : ''}
                      />
                      <InputGroupAddon addonType="append">
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          style={{ position: 'absolute', top: 12, right: 10, fontSize: 13 }}
                          onClick={() => {
                            templateInput.current.props.onClick();
                          }}
                        />
                      </InputGroupAddon>
                      {renderTemplatesPopover()}
                      {renderAttachmentsPopover()}
                    </InputGroup>
                  </div>
                </div>
              </div>
              <div style={{ ...style.simpleColumn, width: 200, alignItems: 'center', marginLeft: 10 }}>
                <div style={{ ...style.actionButtonsContainer, marginTop: 0, paddingTop: 5 }}>
                  <Button
                    size="sm"
                    id="addNewTemplate"
                    color="success"
                    style={style.actionButton}
                    onClick={(e) => {
                      e.preventDefault();

                      if (emailBodyHasChanges || subjectHasChanges || pageChanges.sendMessage) {
                        Swal.fire({
                          title: 'Warning!',
                          text: `You have unsaved changes. Are you sure you want to discard these changes and create a new blank template?`,
                          type: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: 'red',
                          confirmButtonText: `Discard and Create`,
                          stopKeydownPropagation: true,
                          keydownListenerCapture: true,
                        }).then((result, e) => {
                          if (result.value) {
                            createNewTemplate(true, true);
                          }
                        });
                        return;
                      }
                      createNewTemplate(true, true);
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={faPlus} />
                    </div>
                  </Button>
                  <UncontrolledTooltip target="addNewTemplate">Add a New Blank template</UncontrolledTooltip>
                  <Button
                    size="sm"
                    disabled={(!emailBodyHasChanges && !subjectHasChanges) || isSaving || isUpdating}
                    id="saveTemplate"
                    color="success"
                    style={style.actionButton}
                    onClick={(e) => {
                      e.preventDefault();
                      saveTemplate();
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={faSave} />
                      {/* <div style={style.centeredText}>Save Template</div> */}
                    </div>
                  </Button>
                  <UncontrolledTooltip target="saveTemplate">Save template</UncontrolledTooltip>
                  <Button
                    size="sm"
                    disabled={selectedTemplate == null}
                    id="saveTemplateAs"
                    color="primary"
                    style={style.actionButton}
                    onClick={(e) => {
                      e.preventDefault();
                      createNewTemplate();
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={faCopy} />
                      {/* <div style={style.centeredText}>Save Template As</div> */}
                    </div>
                  </Button>
                  <UncontrolledTooltip target="saveTemplateAs">Copy Template</UncontrolledTooltip>

                  {/* <Button
                    size="sm"
                    id="collapseButton"
                    color="primary"
                    style={style.actionButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setCollapseHeader(!collapseHeader);
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={collapseHeader ? faChevronDown : faChevronUp} />
                    
                    </div>
                  </Button>
                  <UncontrolledTooltip target="collapseButton">
                    {collapseHeader ? 'Expand header' : 'Collapse header'}
                  </UncontrolledTooltip> */}
                </div>
              </div>
            </div>

            {!collapseHeader ? (
              <>
                <div style={{ ...style.inputContainer, marginBottom: 10 }}>
                  <div style={{ ...style.simpleColumn, width: '50%' }}>
                    <div style={style.simpleRow}>
                      <div style={style.templateLabelContainer}>
                        <div style={style.templateLabel}>From Email</div>
                      </div>
                      <div style={style.templateInputContainer}>
                        <Input
                          size="sm"
                          disabled={true}
                          value={email}
                          placeholder="From Email"
                          type="text"
                          style={style.templateInput}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div style={{ ...style.simpleColumn, width: '50%', paddingLeft: 10 }}>
                    <div style={style.simpleRow}>
                      <div style={style.templateLabelContainer}>
                        <div style={style.templateLabel}>From Name</div>
                      </div>
                      <div style={style.templateInputContainer}>
                        <Input
                          size="sm"
                          disabled={true}
                          value={name}
                          placeholder="From Name"
                          type="text"
                          style={style.templateInput}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ ...style.inputContainer, marginBottom: 0 }}>
                  <div style={style.templateLabelContainer}>
                    <div style={style.templateLabel}>Subject</div>
                  </div>
                  <div style={style.templateInputContainer}>
                    <Input
                      size="sm"
                      placeholder="Subject"
                      disabled={isUpdating || isSaving}
                      type="text"
                      style={style.templateInput}
                      value={subject}
                      onChange={(e) => {
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </CardHeader>
      <CardBody style={{ padding: 0 }}>
        <div
          style={{
            ...style.simpleRow,
            height: 'calc(100vh - 440px)',
          }}
        >
          <div
            style={{
              ...style.simpleColumn,
              overflow: 'auto',
            }}
          >
            <Wysiwyg
              isUpdating={isUpdating || isSaving}
              editorState={editorState}
              setForceChildEditorState={setForceChildEditorState}
              toggleAttachmentPopup={toggleAttachmentPopup}
              setEditorState={setEditorState}
              onEditorStateChange={onEditorStateChange}
              selectedCandidate={selectedCandidate}
              forcePlainText={true}
            />
            <div style={style.actionButtonsContainer}>
              <div style={{ ...style.simpleColumn, width: 180, justifyContent: 'center' }}>
                {/* <CustomInput
                  type={'switch'}
                  label="Plain Text Only"
                  id="plainTextSwitch"
                  onChange={() => {
                    console.log('plainText');
                    setForcePlainText(!forcePlainText);
                  }}
                  size="sm"
                  checked={forcePlainText}
                /> */}
              </div>
              <div style={{ ...style.simpleColumn, width: 'auto' }}>
                <div style={style.simpleRow}>
                  <Button
                    color="primary"
                    size="sm"
                    style={style.actionButton}
                    disabled={editorText.trim().length === 0 || isSending}
                    onClick={(e) => {
                      e.preventDefault();
                      if (candidatesFilteredBySelected && candidatesFilteredBySelected.length > 0) {
                        setCandidateForPreview(candidatesFilteredBySelected[0]);
                      } else {
                        setCandidateForPreview(candidatePlaceholder[0]);
                      }
                      setShowPreview(true);
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={faEye} style={{ marginRight: 10 }} />
                      <div style={style.centeredText}>Preview</div>
                    </div>
                  </Button>
                  <Button
                    color="primary"
                    size="sm"
                    style={style.actionButton}
                    // disabled={editorText.trim().length === 0 || selectedCandidateIds.length === 0 || isSending}
                    onClick={(e) => {
                      e.preventDefault();

                      if (!subject || subject.trim() === '') {
                        Swal.fire('No Subject', 'Please make sure to include a subject for your email.', 'error');
                        return;
                      }

                      handleSendMail(true);
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={faFlask} style={{ marginRight: 10 }} />
                      <div style={style.centeredText}>Send Test</div>
                    </div>
                  </Button>
                  <Button
                    color="success"
                    size="sm"
                    style={style.actionButton}
                    // disabled={editorText.trim().length === 0 || selectedCandidateIds.length === 0 || isSending}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!subject || subject.trim() === '') {
                        Swal.fire('No Subject', 'Please make sure to include a subject for your email.', 'error');
                        return;
                      }

                      if (!name) {
                        Swal.fire(
                          'No Name',
                          'Please make sure the Reply Address Display Name/Program Name field is filled in in the Settings page.',
                          'error',
                        );
                        return;
                      }

                      if (!email) {
                        Swal.fire(
                          'No Email',
                          'Please make sure the Reply Email field is filled in in the Settings page.',
                          'error',
                        );
                        return;
                      }

                      if (!editorText.replace(/(\r\n|\n|\r)/gm, '')) {
                        Swal.fire('No Body', 'Please make sure the Email has a body/content', 'error');
                        return;
                      }

                      if (selectedCandidateIds.length <= 0) {
                        Swal.fire(
                          'No Candidates Selected',
                          'Please make sure you have candidates selected as recipients.',
                          'error',
                        );
                        return;
                      }

                      const candidatesWithNoEmail = noEmailCandidates(selectedCandidateIds, candidates);

                      if (candidatesWithNoEmail.length > 0) {
                        setShowMissingEmailsModal(true);
                        setCandidatesWithNoEmail(candidatesWithNoEmail);
                        return;
                      }

                      Swal.fire({
                        title: 'Are you sure?',
                        text: `Are you sure you want to send this message to ${selectedCandidateIds.length} Candidate${
                          selectedCandidateIds.length > 1 ? 's' : ''
                        }?`,
                        showCancelButton: true,
                        showCancelButton: true,
                        cancelButtonColor: '#28a745',
                        confirmButtonColor: 'gray',
                        confirmButtonText: 'Cancel',
                        cancelButtonText: 'Send',
                      }).then((result) => {
                        if (result.isDismissed) {
                          handleSendMail(false);
                        }
                      });
                    }}
                  >
                    <div style={style.spacedBetweenRow}>
                      <FontAwesomeIcon icon={faPaperPlane} style={{ marginRight: 10 }} />
                      <div style={style.centeredText}>Send to {selectedCandidateIds.length} Candidates</div>
                    </div>
                  </Button>
                  {/* <Button
                    color="primary"
                    onClick={() => {
                      getTokenSilently().then((token) => {
                        postDataAgnostic('/candidate/automatedEmails', null, null, formatBearerToken(token))
                          .then((result) => {
                            console.log('automatedEmails result: ', result);
                          })
                          .catch((err) => {
                            console.log('automatedEmails err: ', err);
                          });
                      });
                    }}
                  >
                    SEND AUTO
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderMissingEmailsModal()}
      </CardBody>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, null)(AdminMessageComposer);
