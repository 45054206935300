import { faCheck, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAlert } from 'react-alert';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { deleteData, postDataAgnostic, putData } from '../../../../../Services/dataApi';
import AddTimeSlotPopover from '../AddTimeSlotPopover';
import '../Schedule.style.css';
import style from './style.js';
import { clone } from '../../../../../Services/schedule';

const TimeSlotHeaderPopover = ({
  scheduleData,
  fetchSchedule,
  hideHeaderPopover,
  updateAllData,
  setShowHeaderPopOver,
  showHeaderPopOver,
  forcedContext,
  pk_InterviewDate,
  selectedHeader,
  dateIsInThePast,
  willEditThePast,
  fireConfirmationForEditingPastSchedule,
}) => {
  const { metaData } = scheduleData;
  const [standardDuration, setStandardDuration] = useState(1);
  const [standardPassingDuration, setStandardPassingDuration] = useState(1);
  const [startDateAndTime, setStartDateAndTime] = useState();
  const [startTime, setStartTime] = useState();
  const [showAddTimeSlotPopover, setShowAddTimeSlotPopover] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState();

  const alert = useAlert();
  const history = useHistory();
  const deptContext = useContext(DepartmentContext);
  const dContext = forcedContext ? forcedContext : deptContext;

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const { StandardDurationInMinutes, StandardPassingDurationInMinutes, DateOfInterview, StartTime } = metaData || {};
    const timeZone = dContext.department.timeZone;
    setStandardDuration(StandardDurationInMinutes);
    setStandardPassingDuration(StandardPassingDurationInMinutes);
    setStartDateAndTime(`${DateOfInterview} ${StartTime}`);
    setStartTime(moment.tz(`${DateOfInterview} ${StartTime}`, null).format('HH:mm'));
  }, []);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showAddTimeSlotPopover', showAddTimeSlotPopover);
  }, [showAddTimeSlotPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showConfirmModal', showConfirmModal);
  }, [showConfirmModal]);

  const handleParentPopoverVisibilityTracker = (key, value) => {
    const newShowHeaderPopOver = clone(showHeaderPopOver);
    const visibility = showHeaderPopOver['timeSlotHeader'];
    visibility[key] = value;
    newShowHeaderPopOver.timeSlotHeader = visibility;
    setShowHeaderPopOver(newShowHeaderPopOver);
  };

  const renderAddTimeSlotPopOver = () => {
    return (
      <Popover
        placementPrefix="addTimeSlotPopover bs-popover" // Change with room id later
        target={`header_timeSlot`} // Change with room id later
        trigger={`legacy`}
        isOpen={showAddTimeSlotPopover}
        toggle={async () => {
          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
          setShowAddTimeSlotPopover(!showAddTimeSlotPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.buttonLabel}> Add time slot/s to schedule</div>
        </PopoverHeader>
        <PopoverBody>
          <AddTimeSlotPopover
            fetchSchedule={fetchSchedule}
            scheduleData={scheduleData}
            hideHeaderPopover={hideHeaderPopover}
            setShowAddTimeSlotPopover={setShowAddTimeSlotPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const save = () => {
    const { department, season } = dContext;
    const pathExploded = history.location.pathname.split('/');
    const scheduleID = pk_InterviewDate ? pk_InterviewDate : pathExploded[pathExploded.length - 1];
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: department.pk_Department,
            pk_InterviewDate: scheduleID,
            pk_Season: season.pk_Season,
          },
          {
            entry: {
              StartTime: startTime,
              StandardDurationInMinutes: standardDuration,
              StandardPassingDurationInMinutes: standardPassingDuration,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            updateAllData();
            hideHeaderPopover();
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  /**Deletes all rows and columns */
  const clearSchedule = () => {
    const { department, season } = dContext;
    const pathExploded = history.location.pathname.split('/');
    const scheduleID = pk_InterviewDate ? pk_InterviewDate : pathExploded[pathExploded.length - 1];
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/interviewDate',
          {
            pk_Department: department.pk_Department,
            pk_InterviewDate: Number(scheduleID),
            pk_Season: season.pk_Season,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            updateAllData();
            hideHeaderPopover();
            alert.success('Schedule cleared!');
          })
          .catch((err) => {
            alert.error('Delete schedule error!');
          });
      })
      .catch((err) => {
        if (err.message === 'Login required') {
          loginWithRedirect();
        }
      });
  };

  return (
    <div style={style.mainBody}>
      <FormGroup style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>
        <Label style={style.inputLabel} for="startTime">
          Start Time
        </Label>
        <Input
          type="time"
          name="startTime"
          id="startTime"
          defaultValue={startTime}
          onClick={async (e) => {
            if (dateIsInThePast && !willEditThePast) {
              // e.preventDefault();
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
          }}
          onChange={async (e) => {
            // deconstructing this value is a necessity, otherwise the event target might change while waiting for the async
            // function to resolve
            const { value } = e.target;
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            setStartTime(value);
          }}
          placeholder="Enter Time. . ."
        />
      </FormGroup>
      <FormGroup
        style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}
        onClick={async () => {
          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
        }}
      >
        <Label style={style.inputLabel} for="interviewDuration">
          Interview Duration
        </Label>
        <InputGroup>
          <Input
            type="number"
            style={style.inputFields}
            name="interviewDuration"
            id="interviewDuration"
            value={standardDuration}
            placeholder="Enter Duration. . ."
            onChange={(e) => {
              const newValue = parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0;
              setStandardDuration(newValue);
            }}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>minutes</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <FormGroup
        style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}
        onClick={async () => {
          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
        }}
      >
        <Label style={style.inputLabel} for="passingPeriod">
          Passing Period
        </Label>
        <InputGroup>
          <Input
            type="number"
            style={style.inputFields}
            name="passingPeriod"
            id="passingPeriod"
            value={standardPassingDuration}
            placeholder="Enter Passing Period. . ."
            onChange={(e) => {
              const newValue = parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0;
              setStandardPassingDuration(newValue);
            }}
          />
          <InputGroupAddon addonType="append">
            <InputGroupText>minutes</InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </FormGroup>
      <div style={style.inputLabel}>Block Duration: {`${standardDuration + standardPassingDuration} minutes`}</div>
      <br />
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button
          style={{ ...style.genButton, backgroundColor: '#0a91ff' }}
          onClick={async () => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            const data = {
              standardDuration,
              standardPassingDuration,
              startTime,
            };

            save(data);
          }}
        >
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faCheck} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Save</div>
          </div>
        </Button>
      </div>
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button id="header_timeSlot" color="success" style={style.genButton}>
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faPlus} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Insert Time Slot(s)</div>
          </div>
        </Button>
      </div>
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button
          color="danger"
          style={{ ...style.genButton, backgroundColor: null }}
          onClick={async () => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            setShowConfirmModal(true);
            Swal.fire({
              title: 'Are you sure you want to delete all time slots and evaluator rooms',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#d33',
              cancelButtonColor: 'gray',
              confirmButtonText: 'Delete Schedule',
            }).then((result) => {
              if (result.isConfirmed) {
                clearSchedule();
                // DELETE API here
              } else {
              }

              setShowConfirmModal(false);
            });
          }}
        >
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faTrashAlt} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Delete Entire Schedule</div>
          </div>
        </Button>
      </div>
      {renderAddTimeSlotPopOver()}
    </div>
  );
};

export default TimeSlotHeaderPopover;
